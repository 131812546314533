import axios from "axios";
import jwt from "jsonwebtoken";


export const getresetpwBygenId = async (id) => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/cpw/" + id, {headers: {"authorization": 'Bearer ' + await getToken()}});
    return res.data.data;
}


export const deleteResetpwToken = async (id) => {
    let status = 0;

    await axios.delete(process.env.VUE_APP_DB + "/api2/cpw/" + id, {headers: {"authorization": 'Bearer ' + await getToken()}}).then(res => status = res.status).catch(err => {
    });
    return status;
}


export const getToken = async () => {

    let payload = {
        name: "kunde"
    }

    let token = jwt.sign(payload, process.env.VUE_APP_JWTSECRET, {expiresIn: 46800});
    return token;
}
