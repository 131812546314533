<template>
  <div class="body-orders">
    <v-snackbar
        v-model="notificationsnackbar"
        :timeout="8000"
        :color="notificationcolor"
        :top=true
        :right=true
        :vertical=true
    >
      {{ notificationtext }}
      <v-btn
          color="white"
          text
          @click="notificationsnackbar = false"
      >
        {{ $t("Notification.Schliessen") }}
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>


    <div class="bodyheader-orders">

      <div class="bodyheader-orders1">
        <v-icon class="icon-start" color="white">mdi-bookmark-check</v-icon>
        <label class="label-bodyheader-order">

          {{ $t("Orders.Reservierungen") }}
        </label>

      </div>
      <div>
        <v-text-field
            label="Suche"
            solo
            v-model="searchtext"
            prepend-inner-icon="search"
            color="var(--Primary)"
            @input="searchEvent"

        />
      </div>

      <v-btn icon color="white" @click="openfilter">
        <v-icon color="white">mdi-filter-variant</v-icon>
      </v-btn>
    </div>


    <div v-if="!showfilter" class="div-filter-orders">
      <v-btn class="button-close-filter-orders" icon color="white" @click="openfilter">
        <v-icon color="black">mdi-close</v-icon>
      </v-btn>
      <label>

        {{ $t("Orders.AnzahlReservierungen") }}:

        <label v-if="this.orderTemp !== null">{{ this.orderTemp.length }}</label>
      </label>

      <div class="div-filter-orders1">
        <v-chip
            class="ma-2"
            :input-value="neu_0"
            filter
            @click="neuEvent"
            color="#2196f3"
            style="color: white"
        >
          {{ $t("Orders.Neu") }}
        </v-chip>
        <v-chip
            class="ma-2"
            :input-value="wartend_7"
            filter
            @click="wartendEvent"
            color="#4C0099"
            style="color: white"
        >
          {{ $t("Orders.Wartend") }}
        </v-chip>
        <v-chip
            class="ma-2"
            :input-value="freigegeben_1"
            filter
            @click="freigegebenEvent"
            color="#F3B821"
            style="color: white"
        >
          {{ $t("Orders.Freigegeben") }}
        </v-chip>
        <v-chip
            class="ma-2"
            :input-value="bezahlt_2"
            filter
            @click="bezahltEvent"
            color="#4caf50"
            style="color: white"
        >
          {{ $t("Orders.Bezahlt") }}

        </v-chip>
        <v-chip
            class="ma-2"
            :input-value="teilzahlung_3"
            filter
            @click="teilzahlungEvent"
            color="grey"
            style="color: white"
        >
          {{ $t("Orders.Teilzahlung") }}
        </v-chip>
        <v-chip
            class="ma-2"
            :input-value="storniert_4"
            filter
            @click="storniertEvent"
            color="error"
            style="color: white"
        >
          {{ $t("Orders.Storniert") }}
        </v-chip>
        <v-chip
            class="ma-2"
            :input-value="verzug_5"
            filter
            @click="verzugEvent"
            color="#940000"
            style="color: white"
        >
          {{ $t("Orders.Verzug") }}
        </v-chip>

        <v-chip
            class="ma-2"
            :input-value="geschlossen_6"
            filter
            @click="geschlossenEvent"
            color="black"
            style="color: white"
        >
          {{ $t("Orders.Geschlossen") }}

        </v-chip>

      </div>

    </div>

    <div class="div-body-orders">
      <div class="div-export-buttons-orders">
        <!--         v-if="this.user.role !== 'admin'"-->


        <!-- ------------------------------------------------ PDF Liste DOWNLOAD ------------------------------------------------ -->
        <!--        <v-tooltip bottom>-->
        <!--          <template v-slot:activator="{ on }">-->
        <!--            <v-btn class="btn-PDFerzeugen-orders" @click="createPDFListe" v-on="on">-->
        <!--              <v-icon color="red">mdi-pdf-box</v-icon>-->
        <!--              PDF-->
        <!--            </v-btn>-->
        <!--          </template>-->
        <!--          <span>{{ $t("Orders.ListalsPDF") }}</span>-->
        <!--        </v-tooltip>-->
        <!-- ------------------------------------------------ PDF Liste DOWNLOAD ENDE ------------------------------------------------ -->


        <!-- ------------------------------------------------ CSV DOWNLOAD ------------------------------------------------ -->
        <!--        <download-csv-->
        <!--            class="v-btn"-->
        <!--            :data="csvData"-->
        <!--            name="Liste_MyTour-Travel.csv"-->
        <!--            :separator-excel=true-->
        <!--        >-->

        <!--          <v-tooltip bottom>-->
        <!--            <template v-slot:activator="{ on }">-->
        <!--              <v-btn class="btn-PDFerzeugen-orders" v-on="on">-->
        <!--                <v-icon color="green">mdi-microsoft-excel</v-icon>-->
        <!--                CSV-->
        <!--              </v-btn>-->
        <!--            </template>-->
        <!--            <span>{{ $t("Orders.ListalsCSV") }}</span>-->
        <!--          </v-tooltip>-->

        <!--        </download-csv>-->
        <!-- ------------------------------------------------ CSV DOWNLOAD ENDE ------------------------------------------------ -->
      </div>


      <OverlayOrderState
          :showOverlay="asktosave"
          :title="titleOverlay"
          :new-state="newState"
          :item="item"
          :button="buttonOverlay"
          :buttonColor="buttonColor"
          :pLogo="pLogo"
          :pDomain="pDomain"
          :pRechtliches="pRechtliches"
          :pUserinfo="pUserinfo"
          :checkPaypal="overlayAskForPaypal"

      />


      <v-expansion-panels
          v-model="panel"
          multiple
      >
        <v-expansion-panel v-for="(item,i) in orderTemp">
          <v-expansion-panel-header>

            <div class="div-detailsheader-orders">

              <div
                  v-if="item.halalerror === '1' && (pRolle === 'admin' || pRolle === 'superadmin') "
                  style="border: 1px solid red"
              >
                <v-icon color="error" large>mdi-alert</v-icon>
                <label style="color: red">{{ $t("Orders.FehlerbeisendenHalal") }}</label>
              </div>
              <div>
                <label>
                  {{ $t("Orders.ReservierungsNr") }}: {{ getSlideReserviernr(item.order_id) }}
                </label>
                <span v-if="(item.hotel_owner ==='1') && (pRolle === 'admin' || pRolle === 'superadmin') ">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-on="on" color="amber accent">
                        mdi-star-circle-outline
                      </v-icon>
                    </template>
                    <span>{{ 'Halalbooking-Hotel' }}</span>
                  </v-tooltip>
                </span>
                <span v-if="(item.hotel_owner ==='2') && (pRolle === 'admin' || pRolle === 'superadmin') ">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-on="on" color="blue">
                        mdi-star-circle-outline
                      </v-icon>
                    </template>
                    <span>{{ 'Eigenes Hotel' }}</span>
                  </v-tooltip>
                </span>

                <span v-if="item.order_by==='2' && (pRolle === 'admin' || pRolle === 'superadmin') ">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-on="on" color="amber accent-4">mdi-account-tie</v-icon>
                    </template>
                    <span> {{ $t("Acc.Reisebüro") }}: {{ item.hotelinfos.reiseburo.firma }}</span>
                  </v-tooltip>
                </span>
                <span v-if="item.order_by==='0' && (pRolle === 'admin' || pRolle === 'superadmin') ">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-on="on" color="blue">mdi-account-tie</v-icon>
                    </template>
                    <span> Admin </span>
                  </v-tooltip>
                </span>
                <span v-if="item.order_by==='1' && (pRolle === 'admin' || pRolle === 'superadmin') ">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-on="on" color="green">mdi-account-tie</v-icon>
                    </template>
                    <span> Kunde </span>
                  </v-tooltip>
                </span>

                <span v-if="item.order.stages_attributes && (pRolle === 'admin' || pRolle === 'superadmin') && item.hotel_owner!=='2'">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-on="on" style="color: red">mdi-bookmark-remove-outline</v-icon>
                    </template>
                    <span>{{ $t("Orders.NichtanHalalgesendet") }}</span>
                  </v-tooltip>
                </span>
                <template v-if="item.paypal_response">
                  <span v-if="item.paypal_response.captures[0].status!=='COMPLETED' && (pRolle === 'admin' || pRolle === 'superadmin') ">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-on="on" color="error">mdi-alert</v-icon>
                      </template>
                      <span> {{ $t("Orders.PaypalFehler") }}</span>
                    </v-tooltip>
                  </span>
                </template>

              </div>
              <label>
                {{ $t("Orders.ReservierungsDatum") }}: {{ moment(item.date).format('DD.MM.YYYY') }}
              </label>

              <div>
                {{ $t("Orders.Kunde") }}: {{ item.order.first_name + " " + item.order.last_name }}

                <div style="font-weight: bold; margin: 3px 0; display: flex; align-items: baseline;">

                  <span v-if="item.hotel_owner==='2'">
                    {{ $t("Orders.Gesamtpreis") }}:
                    {{ formatPrice(item.hotelinfos.total_price_eur) }}
                    <v-icon small color="black">mdi-currency-eur</v-icon>

                    <span style="color: grey; font-weight: lighter">
                      ({{ formatPrice(item.hotelinfos.total_price_try) }}
                      <v-icon small color="grey" style="margin: -7px;">mdi-currency-try</v-icon>
                      )
                    </span>
                  </span>

                  <span v-else>
                    {{ $t("Orders.Gesamtpreis") }}:
                    {{ formatPrice(item.hotelinfos.total_price) }}
                    <v-icon small color="black">{{ getCurrencyIcon(item.hotelinfos.currency) }}</v-icon>

                    <span
                        v-if="item.hotelinfos.total_price_eur !== undefined && item.hotelinfos.total_price_eur >0"
                        style="color: grey; font-weight: lighter"
                    >
                      ({{ formatPrice(item.hotelinfos.total_price_eur) }} €)
                    </span>
                  </span>
                </div>
                <div
                    style="font-weight: bold; display: flex; align-items: baseline;"
                    v-if="item.hotelinfos.changed_price !== '0' && item.hotelinfos.changed_price !== ''&& (pRolle === 'admin' || pRolle === 'superadmin')"
                >
                  {{ $t("Orders.NeuerPreis") }}:
                  {{ formatPrice(item.hotelinfos.changed_price) }}
                  <v-icon small color="black">mdi-currency-eur</v-icon>
                </div>
              </div>
              <label>
                <v-chip
                    class="ma-2"
                    :color="changeColor(item.order_state)"
                    text-color="white"
                >
                  {{ $t(getOrderState(item.order_state)) }}
                </v-chip>

              </label>
            </div>
          </v-expansion-panel-header>


          <v-expansion-panel-content>
            <div class="div-details-orders">


              <!------------------------------------- PDF für Kunden ------------------------------------------ -->
              <!--              <div class="div-pdfkunde-orders">-->
              <!--                <v-tooltip bottom>-->
              <!--                  <template v-slot:activator="{ on }">-->
              <!--                    <v-btn class="btn-PDFerzeugen-orders" @click="createPDFInvoiceCustomer(item)" v-on="on">-->
              <!--                      <v-icon color="red">mdi-pdf-box</v-icon>-->
              <!--                      PDF-->
              <!--                    </v-btn>-->
              <!--                  </template>-->
              <!--                  <span>{{ $t("Orders.PDFInfoFurKunde") }}</span>-->
              <!--                </v-tooltip>-->
              <!--              </div>-->

              <!------------------------------------- PDF für Kunden ENDE------------------------------------------ -->


              <div>
                <div class="div-reise-orders" v-if="item.hotelinfos">
                  <div
                      v-if="item.order.stages_attributes && (pRolle === 'admin' || pRolle === 'superadmin')  && item.hotel_owner!=='2'"
                      style="margin-bottom: 20px;"
                  >

                    <v-icon style="color: red">mdi-bookmark-remove-outline</v-icon>
                    <b>{{ $t("Orders.NichtanHalalgesendet") }}</b>
                    <v-divider></v-divider>

                  </div>

                  <label><b>{{ $t("Orders.Hotel") }}: {{ item.hotelinfos.hotelname }}</b></label>

                  <br>

                  <label>
                    {{ $t("Orders.ReserviertDurch") }}:
                    {{ $t(getOrderBy(item.order_by)) }}<span
                      v-if="item.hotelinfos.reiseburo"
                      style="font-style: italic"
                  >:
                   {{ item.hotelinfos.reiseburo.firma }}
                  </span>
                  </label>

                  <div style="margin:40px 0 30px 0;">

                    <div style="font-weight: bold; display: flex; align-items: baseline">
                      <span v-if="item.hotel_owner==='2'">

                        {{ $t("Orders.Gesamtpreis") }}:
                        <!--                    {{ parseFloat(item.hotelinfos.total_price).toFixed(2).replace('.', ',') }}-->
                        {{ formatPrice(item.hotelinfos.total_price_eur) }}
                        <v-icon small color="black">mdi-currency-eur</v-icon>
                        <span style="color: grey; font-weight: lighter">
                          ({{
                            formatPrice(item.hotelinfos.total_price_usd)
                          }}
                          <v-icon small color="grey" style="margin: -7px;">mdi-currency-usd</v-icon>
                          )

                        </span>
                        <span style="color: grey; font-weight: lighter">
                          ({{
                            formatPrice(item.hotelinfos.total_price_try)
                          }}
                          <v-icon small color="grey" style="margin: -7px;">mdi-currency-try</v-icon>
                          )

                        </span>

                      </span>
                      <span v-else>
                        {{ $t("Orders.Gesamtpreis") }}:
                        <!--                    {{ parseFloat(item.hotelinfos.total_price).toFixed(2).replace('.', ',') }}-->
                        {{ formatPrice(item.hotelinfos.total_price) }}
                        <v-icon small color="black">{{ getCurrencyIcon(item.hotelinfos.currency) }}</v-icon>
                        <span v-if="item.hotelinfos.total_price_try >0" style="color: grey; font-weight: lighter">
                          ({{
                            formatPrice(item.hotelinfos.total_price_try)
                          }}
                          <v-icon small color="grey" style="margin: -7px;">mdi-currency-try</v-icon>
                          )

                        </span>

                        <span
                            v-if="item.hotelinfos.total_price_eur !== undefined && item.hotelinfos.total_price_eur >0"
                            style="color: grey; font-weight: lighter"
                        >
                          ({{ formatPrice(item.hotelinfos.total_price_eur) }} €)
                        </span>
                      </span>
                    </div>

                    <div
                        style="font-weight: bold; display: flex; align-items: baseline;"
                        v-if="item.hotelinfos.changed_price !== '0' && item.hotelinfos.changed_price !== ''&& (pRolle === 'admin' || pRolle === 'superadmin')"
                    >
                      {{ $t("Orders.NeuerPreis") }}:
                      {{ formatPrice(item.hotelinfos.changed_price) }}
                      <v-icon small color="black">mdi-currency-eur</v-icon>

                      <span
                          style="color: grey; font-weight: lighter"
                          v-if="item.hotelinfos.changed_price_usd !== undefined &&item.hotelinfos.changed_price_usd !== '0.00' && item.hotelinfos.changed_price !== ''"
                      >

                        ({{ formatPrice(item.hotelinfos.changed_price_usd) }} $)
                      </span>
                      <span
                          style="color: grey; font-weight: lighter"
                          v-if="item.hotelinfos.changed_price_try !== undefined && item.hotelinfos.changed_price !== '' && item.hotelinfos.total_price_try >0"
                      >

                        ({{
                          formatPrice(item.hotelinfos.changed_price_try)
                        }}
                        <v-icon small color="grey" style="margin: -7px;">mdi-currency-try</v-icon>
                        )
                      </span>
                    </div>

                    <div v-if="item.order_state === '0' &&(pRolle === 'admin' || pRolle === 'superadmin')">

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-on="on" small color="#ff7300" style="cursor: pointer">mdi-pencil</v-icon>
                          <label
                              v-on="on"
                              style="color: #ff7300; cursor: pointer"
                          >
                            {{ $t("Orders.NeuerPreis") }}
                          </label>
                        </template>
                        <span>{{ $t("Orders.NeuerPreisFreigabe") }}</span>
                      </v-tooltip>

                    </div>

                    <div
                        class="div-change-price-orders" @click="showChangedPrice = !showChangedPrice"
                        v-if="item.order_state !== '0' && (pRolle === 'admin' || pRolle === 'superadmin')"
                        style="color:#ff7300"
                    >
                      <v-icon small color="#ff7300">mdi-pencil</v-icon>
                      {{ $t("Orders.Preisaendern") }}
                    </div>
                    <div
                        v-if="showChangedPrice && (pRolle === 'admin' || pRolle === 'superadmin')"
                        style="display: flex; max-width: 400px; margin-bottom: 20px"
                    >
                      <v-text-field
                          :label="$t('Orders.Betrag')"
                          solo
                          type="number"
                          class="text-field-zahlung-orders"
                          v-model="item.hotelinfos.changed_price"
                          append-icon="mdi-currency-eur"
                          @input="calculateNewPrice(item)"
                      />
                      <v-btn class="btn-zahlung-orders" color="var(--Primary)" @click="addNewPrice(i)">
                        {{ $t("Orders.NeuerPreis") }}
                      </v-btn>
                    </div>

                    <!--                  <br>-->
                    <div style="margin: 20px 0;">
                      <div v-if="(item.hotel_owner === '1'||item.hotel_owner === '2' )&& (pRolle === 'admin' || pRolle === 'superadmin')">
                        <span v-if="item.hotel_owner==='2'">

                          {{ $t("Orders.ProvisionHotel") }}: {{ item.hotelinfos.OHP.ohp_provision }}% -

                          {{
                            formatPrice(((parseFloat(item.hotelinfos.OHP.ohp_provision) * parseFloat(item.hotelinfos.total_price_eur)) / 100))
                          }}
                          <v-icon small color="black">mdi-currency-eur</v-icon>
                          <span style="color: grey; font-weight: lighter">
                            ({{
                              formatPrice((parseFloat(item.hotelinfos.OHP.ohp_provision) * parseFloat(item.hotelinfos.total_price_usd)) / 100)
                            }}
                            <v-icon small color="grey" style="margin: -7px;">mdi-currency-usd</v-icon>
                            )
                          </span>
                          <span
                              v-if="item.hotelinfos.total_price_try !== undefined && item.hotelinfos.total_price_try >0"
                              style="color: grey; font-weight: lighter"
                          >
                            ({{
                              formatPrice((parseFloat(item.hotelinfos.OHP.ohp_provision) * parseFloat(item.hotelinfos.total_price_try)) / 100)
                            }}
                            <v-icon small color="grey" style="margin: -7px;">mdi-currency-try</v-icon>
                            )
                          </span>
                        </span>
                        <span v-else>
                          {{ $t("Orders.ProvisionHotel") }}: {{ item.hotelinfos.OHP.ohp_provision }}% -
                          {{
                            formatPrice(((parseFloat(item.hotelinfos.OHP.ohp_provision) * parseFloat(item.hotelinfos.total_price)) / 100))
                          }}
                          <v-icon small color="black">{{ getCurrencyIcon(item.hotelinfos.currency) }}</v-icon>
                          <span
                              v-if="item.hotelinfos.total_price_eur !== undefined && item.hotelinfos.total_price_eur >0"
                              style="color: grey; font-weight: lighter"
                          >
                            ({{
                              formatPrice((parseFloat(item.hotelinfos.OHP.ohp_provision) * parseFloat(item.hotelinfos.total_price_eur)) / 100)
                            }} €)
                          </span>
                          <span
                              v-if="item.hotelinfos.total_price_try !== undefined && item.hotelinfos.total_price_try >0"
                              style="color: grey; font-weight: lighter"
                          >
                            ({{
                              formatPrice((parseFloat(item.hotelinfos.OHP.ohp_provision) * parseFloat(item.hotelinfos.total_price_try)) / 100)
                            }}
                            <v-icon small color="grey" style="margin: -7px;">mdi-currency-try</v-icon>
                            )
                          </span>
                        </span>
                      </div>
                      <div v-if="(item.hotel_owner === '1'||item.hotel_owner === '2' ) && (pRolle === 'admin' || pRolle === 'superadmin')">
                        <span v-if="item.hotel_owner==='2'">
                          {{ $t("Orders.ZahlungHotel") }}:

                          {{
                            formatPrice(item.hotelinfos.total_price_eur - ((parseFloat(item.hotelinfos.OHP.ohp_provision) * parseFloat(item.hotelinfos.total_price_eur)) / 100))
                          }}

                          <v-icon small color="black">mdi-currency-eur</v-icon>
                          <span style="color: grey; font-weight: lighter">
                            ({{
                              formatPrice(item.hotelinfos.total_price_usd - ((parseFloat(item.hotelinfos.OHP.ohp_provision) * parseFloat(item.hotelinfos.total_price_usd)) / 100))
                            }}
                            <v-icon small color="grey" style="margin: -7px;">mdi-currency-usd</v-icon>
                            )
                          </span>
                          <span style="color: grey; font-weight: lighter">
                            ({{
                              formatPrice(item.hotelinfos.total_price_try - ((parseFloat(item.hotelinfos.OHP.ohp_provision) * parseFloat(item.hotelinfos.total_price_try)) / 100))
                            }}
                            <v-icon small color="grey" style="margin: -7px;">mdi-currency-try</v-icon>
                            )
                          </span>
                        </span>
                        <span v-else>
                          {{ $t("Orders.ZahlungHotel") }}:

                          {{
                            formatPrice(item.hotelinfos.total_price - ((parseFloat(item.hotelinfos.OHP.ohp_provision) * parseFloat(item.hotelinfos.total_price)) / 100))
                          }}

                          <v-icon small color="black">{{ getCurrencyIcon(item.hotelinfos.currency) }}</v-icon>
                          <span
                              v-if="item.hotelinfos.total_price_eur !== undefined && item.hotelinfos.total_price_eur >0"
                              style="color: grey; font-weight: lighter"
                          >
                            ({{
                              formatPrice(item.hotelinfos.total_price_eur - ((parseFloat(item.hotelinfos.OHP.ohp_provision) * parseFloat(item.hotelinfos.total_price_eur)) / 100))
                            }} €)
                          </span>
                          <span
                              v-if="item.hotelinfos.total_price_try !== undefined && item.hotelinfos.total_price_try >0"
                              style="color: grey; font-weight: lighter"
                          >
                            ({{
                              formatPrice(item.hotelinfos.total_price_try - ((parseFloat(item.hotelinfos.OHP.ohp_provision) * parseFloat(item.hotelinfos.total_price_try)) / 100))
                            }}
                            <v-icon small color="grey" style="margin: -7px;">mdi-currency-try</v-icon>
                            )
                          </span>
                        </span>
                      </div>
                    </div>

                    <div v-if="item.hotelinfos.reiseburo">
                      <span v-if="item.hotel_owner==='2'">
                        {{ $t("Orders.ProvisionReisebüro") }}: {{ item.hotelinfos.reiseburo.provisionRB }}% -

                        {{
                          formatPrice(((parseFloat(item.hotelinfos.reiseburo.provisionRB * item.hotelinfos.total_price_eur)) / 100))
                        }}
                        <v-icon small color="black">mdi-currency-eur</v-icon>
                        <span style="color: grey; font-weight: lighter">
                          ({{
                            formatPrice((parseFloat(item.hotelinfos.reiseburo.provisionRB * item.hotelinfos.total_price_usd)) / 100)
                          }}
                          <v-icon small color="grey" style="margin: -7px;">mdi-currency-usd</v-icon>
                          )
                        </span>
                        <span style="color: grey; font-weight: lighter">
                          ({{
                            formatPrice((parseFloat(item.hotelinfos.reiseburo.provisionRB * item.hotelinfos.total_price_try)) / 100)
                          }}
                          <v-icon small color="grey" style="margin: -7px;">mdi-currency-try</v-icon>
                          )
                        </span>
                      </span>
                      <span v-else>
                        {{ $t("Orders.ProvisionReisebüro") }}: {{ item.hotelinfos.reiseburo.provisionRB }}% -

                        {{
                          formatPrice(((parseFloat(item.hotelinfos.reiseburo.provisionRB * item.hotelinfos.total_price)) / 100))
                        }}
                        <v-icon small color="black">{{ getCurrencyIcon(item.hotelinfos.currency) }}</v-icon>
                        <span
                            v-if="item.hotelinfos.total_price_eur !== undefined && item.hotelinfos.total_price_eur >0"
                            style="color: grey; font-weight: lighter"
                        >
                          ({{
                            formatPrice((parseFloat(item.hotelinfos.reiseburo.provisionRB * item.hotelinfos.total_price_eur)) / 100)
                          }} €)
                        </span>
                        <span
                            v-if="item.hotelinfos.total_price_try !== undefined && item.hotelinfos.total_price_try >0"
                            style="color: grey; font-weight: lighter"
                        >
                          ({{
                            formatPrice((parseFloat(item.hotelinfos.reiseburo.provisionRB * item.hotelinfos.total_price_try)) / 100)
                          }}
                          <v-icon small color="grey" style="margin: -7px;">mdi-currency-try</v-icon>
                          )
                        </span>
                      </span>
                    </div>

                    <div v-if="item.hotelinfos.reiseburo">
                      <span v-if="item.hotel_owner==='2'">

                        {{ $t("Orders.ZahlungReisebüro") }}:
                        {{
                          formatPrice(item.hotelinfos.total_price_eur - ((parseFloat(item.hotelinfos.reiseburo.provisionRB * item.hotelinfos.total_price_eur)) / 100))
                        }}
                        <v-icon small color="black">mdi-currency-eur</v-icon>
                        <span style="color: grey; font-weight: lighter">
                          ({{
                            formatPrice(item.hotelinfos.total_price_usd - ((parseFloat(item.hotelinfos.reiseburo.provisionRB * item.hotelinfos.total_price_usd)) / 100))
                          }}
                          <v-icon small color="grey" style="margin: -7px;">mdi-currency-usd</v-icon>
                          )
                        </span>
                        <span style="color: grey; font-weight: lighter">
                          ({{
                            formatPrice(item.hotelinfos.total_price_try - ((parseFloat(item.hotelinfos.reiseburo.provisionRB * item.hotelinfos.total_price_try)) / 100))
                          }}
                          <v-icon small color="grey" style="margin: -7px;">mdi-currency-try</v-icon>
                          )
                        </span>
                      </span>
                      <span v-else>
                        {{ $t("Orders.ZahlungReisebüro") }}:
                        {{
                          formatPrice(item.hotelinfos.total_price - ((parseFloat(item.hotelinfos.reiseburo.provisionRB * item.hotelinfos.total_price)) / 100))
                        }}
                        <v-icon small color="black">{{ getCurrencyIcon(item.hotelinfos.currency) }}</v-icon>
                        <span
                            v-if="item.hotelinfos.total_price_eur !== undefined && item.hotelinfos.total_price_eur >0"
                            style="color: grey; font-weight: lighter"
                        >
                          ({{
                            formatPrice(item.hotelinfos.total_price_eur - ((parseFloat(item.hotelinfos.reiseburo.provisionRB * item.hotelinfos.total_price_eur)) / 100))
                          }} €)
                        </span>
                        <span
                            v-if="item.hotelinfos.total_price_try !== undefined && item.hotelinfos.total_price_try >0"
                            style="color: grey; font-weight: lighter"
                        >
                          ({{
                            formatPrice(item.hotelinfos.total_price_try - ((parseFloat(item.hotelinfos.reiseburo.provisionRB * item.hotelinfos.total_price_try)) / 100))
                          }}
                          <v-icon small color="grey" style="margin: -7px;">mdi-currency-try</v-icon>
                          )
                        </span>
                      </span>
                    </div>
                  </div>

                  <!--                  <br>-->
                  <v-divider></v-divider>
                  <!--                  <br>-->


                  <div style="margin: 30px 0;">
                    <div>
                      {{ $t("Orders.Checkin") }}:
                      <span>{{ moment(item.hotelinfos.checkin, "YYYY-MM-DD").format('DD.MM.YYYY') }}</span>
                      <span> - <span v-if="pLang!== 'TR'">{{ $t("Place.Check-in-nach") }}</span>
                             {{ item.hotelinfos.checkin_time }}
                        <span v-if="pLang=== 'TR'">{{ $t("Place.Check-in-nach") }}</span>
                      </span>
                    </div>

                    <div>
                      {{ $t("Orders.Checkout") }}:
                      <span>{{ moment(item.hotelinfos.checkout, "YYYY-MM-DD").format('DD.MM.YYYY') }}</span>
                      <span> - <span v-if="pLang!== 'TR'">{{ $t("Place.Check-out-vor") }}</span>
                             {{ item.hotelinfos.checkout_time }}<span v-if="pLang=== 'TR'">
                          {{ $t("Place.Check-out-vor") }}</span></span>
                    </div>
                  </div>
                  <br>

                  <div v-for="(it, n) in item.hotelinfos.rooms">
                    <div style="font-weight: bolder">
                      <!--                      {{ $t("Orders.Zimmer") }} #{{ n + 1 }} - {{ it.room_name }}-->
                      {{ $t("Place.Gruppe") }} #{{ n + 1 }} -
                      <span v-if="it.quantity && it.quantity>1">{{ it.quantity }} x </span>
                      <span v-if="item.hotel_owner==='2'">{{ it['room_name_' + pLang.toLowerCase()] }}</span>
                      <span v-else>{{ it.room_name }}</span>
                    </div>

                    <div v-if="item.hotel_owner === '2'">
                      {{ getRatePlanByID(item.hotelinfos.rateplans[n].rateplan_name) }}
                    </div>
                    <div v-else>
                      {{ item.hotelinfos.rateplans[n].rateplan_name }}
                    </div>

                    <div>
                      {{ $t("Orders.Personen") }}:
                      {{ item.hotelinfos.countPersons[n].adults }} {{ $t("Orders.Erwachsene") }}<span
                        v-if="item.hotelinfos.countPersons[n].childs >0"
                    >,
                     {{ item.hotelinfos.countPersons[n].childs }} {{ $t("Orders.Kinder") }}

                     <!--                      <span v-for="gruppe in item.hotelinfos.gruppe">-->
                     <!--                      <template v-if="gruppe.kinderalter.length>0">-->
                     (
                      <template v-for="(age,ind) in item.hotelinfos.gruppe[n].kinderalter">
                        <span> {{ age.date }}
                          <template
                              v-if="item.hotelinfos.gruppe[n].kinderalter.length -1 !== ind"
                          > /
                          </template>
                        </span>
                      </template>
                     )
                     <!--                      </template>-->
                     <!--                      </span>-->
                    </span>
                    </div>
                    <div style="display: flex; align-items: baseline">

                      <span v-if="item.hotel_owner==='2'">
                        {{ $t("Orders.Preis") }}:
                        {{ formatPrice(item.hotelinfos.price_per_group_eur[n]) }}
                        <v-icon small color="black"> mdi-currency-eur</v-icon>

                        <span style="color: grey; font-weight: lighter">
                          (
                          {{ formatPrice(item.hotelinfos.price_per_group_usd[n]) }}
                          <v-icon small color="grey" style="margin: -7px"> mdi-currency-usd</v-icon>
                          )
                        </span>

                        <span style="color: grey; font-weight: lighter">
                          (
                          {{ formatPrice(item.hotelinfos.price_per_group_try[n]) }}
                          <v-icon small color="grey" style="margin: -7px"> mdi-currency-try</v-icon>
                          )
                        </span>


                      </span>


                      <span v-else>

                        {{ $t("Orders.Preis") }}:
                        {{ formatPrice(item.hotelinfos.rateplans[n].room_price) }}
                        <v-icon small color="black">{{ getCurrencyIcon(item.hotelinfos.currency) }}</v-icon>
                        <span
                            v-if="item.hotelinfos.price_per_group[n] !== undefined "
                            style="color: grey; font-weight: lighter"
                        >
                          ({{ formatPrice(item.hotelinfos.price_per_group[n].price) }} €)
                        </span>

                        <span
                            v-if="item.hotelinfos.price_per_group_try[n] !== undefined "
                            style="color: grey; font-weight: lighter"
                        >
                          ({{ formatPrice(item.hotelinfos.price_per_group_try[n].price) }}

                          <v-icon small color="grey" style="margin: -7px;">mdi-currency-try</v-icon>
                          )
                        </span>
                      </span>

                    </div>

                    <div class="div-tooltip-cardroom">
                      <label style="font-size: 14px;">
                        {{ $t("Card.Stornierung") }}
                      </label>
                      <br>
                      <div v-if="item.hotel_owner==='2'" style="text-align: start; max-width: 300px; font-size: 10px">
                        {{ item.hotelinfos.rooms[n]['cancellation_' + pLang.toLowerCase()] }}
                      </div>
                      <div
                          v-else
                          style="text-align: start; max-width: 300px; font-size: 10px"
                          v-html="item.hotelinfos.rateplans[n].room_cancellation"
                      >
                      </div>

                    </div>


                    <br>
                  </div>

                  <div v-if="item.order.notes !== ''" style="margin-bottom: 20px">
                    <div>
                      <b>{{ $t("Orders.Kundenhinweis") }}:</b>
                    </div>
                    <div style="white-space: pre-line">
                      {{ item.order.notes }}
                    </div>

                  </div>

                </div>
              </div>


              <!-------------------------------------------- Kunde ------------------------------------------>
              <div class="div-reise-orders" style="position: relative">
                <div>
                  <v-overlay
                      opacity="0.9"
                      :value="showoverlaySendMail"
                      class="overlay-sendmail-orders"

                  >
                    <div class="div-overlay-senmail-orders">
                      <h3 style="color: grey">
                        {{ $t("Orders.Reservierungsbestätitungsenden") }}
                      </h3>
                      <div class="div-overlay-senmail-orders1">
                        <v-text-field
                            dark
                            outlined
                            v-model="item.rechnungsadresse.email"
                            label="E-Mail an"
                            color="grey"
                            placeholder="beispiel1@gmail.com; beispiel2@ceeyuu.com"

                        />
                        <v-text-field
                            outlined
                            label="Cc: Kopie an"
                            v-model="item.rechnungsadresse.emailcc"

                            placeholder="beispiel1@gmail.com; beispiel2@ceeyuu.com"
                            color="grey"

                        />

                      </div>
                      <div style="display: flex; justify-content: space-around;">
                        <v-btn
                            class="white--text"
                            color="error"
                            @click="showoverlaySendMail = false"

                            width="135px"
                        >
                          {{ $t("Button.Abbrechen") }}
                        </v-btn>

                        <v-btn
                            class="white--text"
                            color="var(--Primary)"
                            @click="sendConfirmMailToCustomer(item)"
                            width="135px"
                        >
                          {{ $t("Button.Senden") }}
                        </v-btn>
                      </div>
                    </div>
                  </v-overlay>
                </div>
                <label>
                  <b>{{ $t("Orders.Kunde") }}</b>
                </label>

                <div style="position: absolute; top:0; right: 0">

                  <div style="width: 100%">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-on="on"
                            color="blue-grey"
                            class="ma-2 white--text"

                            :loading="loadingSendMail"
                            :disabled="loadingSendMail"
                            @click="showoverlaySendMail = true"
                        >
                          {{ $t("Orders.MailanKunde") }}
                          <v-icon right dark>
                            mdi-email-send-outline
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("Orders.Reservierungsbestätigungerneut") }}</span>
                    </v-tooltip>
                  </div>
                  <div style="width: 100%">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-on="on"
                            color="red"
                            class="ma-2 white--text"

                            :loading="loadingSendMail"
                            :disabled="loadingSendMail"
                            @click="createPDFCustomerConfirmation(item, i)"
                        >
                          {{ $t('Orders.Kunde') }} PDF
                          <v-icon right dark>
                            mdi-file-document-outline
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Reservierungsbestätigung für Kunde</span>
                    </v-tooltip>
                  </div>
                </div>

                <label>
                  {{ item.order.title }} {{ item.order.first_name }} {{ item.order.last_name }}
                </label>

                <label>
                  E-Mail: {{ item.hotelinfos.kunden_email }}
                </label>
                <label>
                  Tel.: {{ item.rechnungsadresse.tel }}
                </label>
                <br>
                <label>
                  {{ item.rechnungsadresse.str }} {{ item.rechnungsadresse.nr }}
                </label>

                <label v-if="item.rechnungsadresse.adressz !== ''">
                  {{ item.rechnungsadresse.adressz }}
                </label>
                <label>
                  {{ item.rechnungsadresse.plz }} {{ item.rechnungsadresse.ort }}
                </label>
              </div>

              <!-------------------------------------------- Kunde ENDE  ------------------------------------------>


              <!-------------------------------------------- Zahlungen  ------------------------------------------>

              <div class="div-zahlungen-orders" v-if="pRolle === 'admin' || pRolle === 'superadmin'">

                <label>
                  <b>{{ $t("Orders.Zahlungen") }}</b>
                </label>
                <br>
                <div>
                  <label>
                    {{ $t("Orders.Bezahltdurch") }}:
                    <span v-if="item.payed_by === '0'">PayPal</span>
                    <span v-if="item.payed_by === '1'">{{ $t("Orders.KreditKarte") }}</span>
                    <span v-if="item.payed_by === '2'">{{ $t("Orders.Lastschrift") }}</span>
                    <span v-if="item.payed_by === '3'">{{ $t("Orders.Rechnung") }}</span>
                    <span v-if="item.payed_by === '4'">{{ $t("Orders.Barzahlung") }}</span>
                  </label>
                  <div v-if="item.payed_by === '0'">
                    PayPal {{ $t("Orders.Transaktionsnummer") }}: {{ item.paypal_response.captures[0].id }}
                  </div>


                  <div v-if="item.paypal_response" style="text-align: center">
                    <div
                        v-if="item.paypal_response.captures[0].status==='COMPLETED'  && !item.paypal_response.invoice_created"
                        style="max-width: 500px; display: flex"
                    >

                      <div v-if="item.payed_by === '0'">
                        <span class="template-datepayment-orders">
                          <v-menu
                              v-model="paypaldatemanu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                  v-model="datePaypalPayment"
                                  label="Datum"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"

                              ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="datePaypalPayment"
                                @input="paypaldatemanu = false"
                                locale="de-de"
                                :first-day-of-week="1"
                            ></v-date-picker>
                          </v-menu>
                        </span>

                      </div>
                      <div style="width: 200px">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                                color="var(--Primary)" style="color: white" v-on="on"
                                @click="createNewInvoice(item,i,0)" :disabled="checkPaypalDate"
                            >
                              <v-icon small>mdi-file-pdf-box</v-icon>
                              {{ $t("Orders.Rechnungerstellen") }}


                            </v-btn>
                          </template>
                          <span>{{ $t("Orders.Rechnungerstellen") }}</span>
                        </v-tooltip>
                      </div>
                    </div>

                    <div
                        v-if="item.paypal_response.captures[0].status!=='COMPLETED'  && !item.paypal_response.invoice_created"
                        style="max-width: 700px; display: flex; margin: 20px"
                    >
                      <v-icon color="error" large>mdi-alert</v-icon>
                      <label style="font-weight: bold">
                        {{ $t("Orders.PaypalZahlunvolstaendig") }}

                      </label>

                      <div style="width: 200px">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                                color="var(--Primary)"
                                style="color: white" v-on="on"
                                @click="submitPayment(item)"
                            >
                              {{ $t("Orders.Freigeben") }}
                            </v-btn>
                          </template>
                          <span>
                            {{ $t("Orders.ZahlungBestatigen") }}
                          </span>
                        </v-tooltip>
                      </div>

                    </div>


                    <div v-if="item.paypal_response.invoice_created === true ">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn text outlined v-on="on" @click="createPDFInvoiceCustomer(item,0)">
                            <v-icon color="red">mdi-pdf-box</v-icon>
                            {{ $t("Orders.Rechnung") }}
                          </v-btn>
                        </template>
                        <span>{{ $t("Orders.Rechnunganzeigen") }}</span>
                      </v-tooltip>
                    </div>
                  </div>


                </div>


                <v-divider></v-divider>


                <div
                    style="margin: 10px 0; font-weight: bold"
                    v-if="item.order_state === '0' && item.payed_by !== '0'"
                >
                  {{ $t("Orders.FreigabeTeilzahlungen") }}
                </div>


                <div class="div-zahlungen-orders2" v-if="item.payed_by !== '0'">
                  <label><b>{{ $t("Orders.Teilzahlungen") }}</b></label>
                  <div style="display: flex; align-items: baseline;">
                    {{ $t("Orders.Restsumme") }}:
                    {{
                      formatPrice(parseFloat(calculateRest((item.hotelinfos.total_price_eur > 0 ? item.hotelinfos.total_price_eur : item.hotelinfos.total_price), item.hotelinfos.changed_price, item.part_payment)))
                    }}
                    <v-icon small color="black">mdi-currency-eur</v-icon>
                  </div>

                  <div class="div-erhalteneZahlungen-orders" v-for="(payment,index) in item.part_payment">

                    <label style="font-weight: bold;">
                      <span style=" display: flex; align-items: baseline; justify-content: flex-end;">
                        {{ formatPrice(payment.value) }}
                        <v-icon small color="black">mdi-currency-eur</v-icon>
                      </span>
                    </label>
                    <label style="color: grey">
                      {{ payment.date | moment("DD.MM.YYYY") }}
                    </label>

                    <div v-if="payment.invoice_created === false ">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn icon v-on="on" @click="deletePartPayment(i,index)">
                            <v-icon small>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("Orders.ZahlungLoschen") }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else></div>

                    <div v-if="payment.invoice_created === false ">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn text outlined v-on="on" @click="createNewInvoice(item,i,index)">
                            <v-icon small color="red">mdi-file-pdf-box</v-icon>
                            {{ $t("Orders.Rechnungerstellen") }}
                          </v-btn>
                        </template>
                        <span>{{ $t("Orders.Rechnungerstellen") }}</span>
                      </v-tooltip>
                    </div>
                    <div v-else></div>

                    <div v-if="payment.invoice_created === true ">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn text outlined v-on="on" @click="createPDFInvoiceCustomer(item,index)">
                            <v-icon color="red">mdi-pdf-box</v-icon>
                            {{ $t("Orders.Rechnung") }}
                          </v-btn>
                        </template>
                        <span>{{ $t("Orders.Rechnunganzeigen") }}</span>
                      </v-tooltip>
                    </div>

                  </div>
                  <div
                      class="div-zahlung-txt-btn-orders"
                      v-if="item.order_state === '1'|| item.order_state === '3'|| item.order_state === '5'"
                  >
                    <!--                    append-icon="mdi-currency-eur"-->
                    <v-text-field
                        :label="$t('Orders.Betrag')"
                        solo
                        type="number"
                        class="text-field-zahlung-orders"
                        v-model="partpayment"
                        @input="checkPartPayment(i)"

                    />
                    <span class="template-datepayment-orders">
                      <v-menu
                          v-model="menu2"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="DatePayment"
                              label="Datum"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"

                          ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="DatePayment"
                            @input="menu2 = false"
                            locale="de-de"
                            :first-day-of-week="1"
                        ></v-date-picker>
                      </v-menu>
                    </span>

                    <v-btn
                        class="btn-zahlung-orders" color="var(--Primary)" @click="addPartPayment(i)"
                        :disabled="!checkPayment"
                    >
                      {{ $t("Orders.Erhalten") }}
                    </v-btn>
                  </div>
                  <!--                  <div v-if="item.order_state === '0'">-->
                  <!--                    <label>-->
                  <!--                      {{ $t("Orders.FreigabeTeilzahlungen") }}-->
                  <!--                    </label>-->
                  <!--                  </div>-->
                </div>
              </div>

              <!-------------------------------------------- Zahlungen ENDE ------------------------------------------>


              <!-------------------------------------------- Kommentare ------------------------------------------>

              <div class="div-zahlungen-orders" v-if="pRolle === 'admin' || pRolle === 'superadmin'">

                <label>
                  <b>{{ $t("Orders.Kommentare") }}</b>
                </label>
                <br>

                <template v-for="(comment,commindex) in item.comment">
                  <div class="div-comments-orders">
                    <div style="color: grey; margin-right: 10px">
                      {{ comment.datum | moment("DD.MM.YYYY HH:mm") }}
                    </div>
                    <div class="div-comments-orders1">
                      <div style="white-space: pre-line">
                        {{ comment.text }}
                      </div>


                      <div v-if="comment.datum !== ''">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on" @click="deletecomment(i,commindex)">
                              <v-icon small>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t("Orders.KommentarLoeschen") }}</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </div>

                  <v-divider v-if="comment.text !== ''"></v-divider>
                  <br v-if="comment.text !== ''">


                </template>
                <br>
                <div style="padding: 10px 20px" v-if="showComments">
                  <v-textarea
                      solo
                      name="input-7-4"
                      :label="$t('Button.kommentarhinzufuegen')+'...'"
                      v-model="commentModel"
                  />
                </div>

                <div style="text-align: end; margin: 0 40px" v-if="showComments">
                  <v-btn color="var(--Primary)" style="color: white" @click="saveComment(i)">
                    {{ $t("Button.Speichern") }}
                  </v-btn>
                </div>

                <div>
                  <v-btn color="var(--Primary)" style="color: white" v-if="showCommentButton" @click="viewcomment(i)">
                    <v-icon>mdi-plus</v-icon>
                    {{ $t("Button.kommentarhinzufuegen") }}
                  </v-btn>
                </div>

              </div>

              <!-------------------------------------------- Kommentare ENDE ------------------------------------------>


              <!-------------------------------------------- Status ändern ------------------------------------------>
              <template v-if="pRolle === 'admin' || pRolle === 'superadmin'">
                <div
                    class="div-status-orders"
                    v-if=" item.order_state === '0' ||
                   item.order_state === '1' ||
                   item.order_state === '2' ||
                   item.order_state === '3' ||
                   item.order_state === '5' ||
                   item.order_state === '7'"
                >


                  <template v-if="item.order_state=== '0'">
                    <h4>
                      {{ $t("Orders.ReservierungFreigeben") }}?
                    </h4>

                    <div class="div-status-orders1">
                      <v-btn class="button-state-orders" color="success" @click="changeState0(item)">
                        {{ $t("Orders.Freigeben") }}
                      </v-btn>
                    </div>
                  </template>

                  <template v-if="item.order_state === '7'  ">
                    <h4>
                      {{ $t("Orders.ReservierungBestaetigt") }}?
                    </h4>

                    <div class="div-status-orders1">
                      <v-btn class="button-state-orders" color="#4C0099" @click="confirmOrder(item)">
                        {{ $t("Button.Bestaetigt") }}
                      </v-btn>
                    </div>
                  </template>


                  <template v-if="item.order_state !== '5' && item.order_state !== '6' && item.order_state !== '4'">
                    <h4>
                      {{ $t("Orders.ReservierungStornieren") }}?
                    </h4>

                    <div class="div-status-orders1">
                      <v-btn class="button-state-orders" color="error" @click="cancelOrder(item)">
                        {{ $t("Orders.Stornieren") }}
                      </v-btn>
                    </div>
                  </template>


                  <template v-if="item.order_state === '2' || item.order_state === '5' ">
                    <h4>
                      {{ $t("Orders.Reservierungschliessen") }}?
                    </h4>

                    <div class="div-status-orders1">
                      <v-btn class="button-state-orders" color="black" @click="closeOrder(item)">
                        {{ $t("Orders.Schliessen") }}
                      </v-btn>
                    </div>
                  </template>


                </div>
              </template>

              <!--------------------------------- Status ändern ENDE ------------------------------------------>

              <!-------------------------------------------- Order löschen ------------------------------------------>

              <!--              <div class="div-status-orders" v-if="user.role ==='admin'">-->

              <!--                <v-btn v-if="user.role ==='admin'" color="red"  @click="asktosaveDeleteOrder = !asktosaveDeleteOrder">-->
              <!--                  Reservierung löschen-->
              <!--                </v-btn>-->
              <!--                <div class="div-asktoSave-orders" v-if="asktosaveDeleteOrder">-->
              <!--                  <div class="div-asktoSave-orders1">-->

              <!--                    <div class="div-status-orders1">-->

              <!--                      <v-btn class="button-close-asktosave-orders" icon color="black" @click="asktosaveDeleteOrder = !asktosaveDeleteOrder">-->
              <!--                        <v-icon color="black">mdi-close</v-icon>-->
              <!--                      </v-btn>-->
              <!--                    <v-btn v-if="user.role ==='admin'" color="red"  @click="deleteOrder(item._id)">-->
              <!--                      Reservierung löschen-->
              <!--                    </v-btn><br>-->
              <!--                    <v-btn class="button-state-orders" color="grey" @click="asktosaveDeleteOrder = !asktosaveDeleteOrder">-->
              <!--                      {{ $t("Orders.Ablehnen") }}-->
              <!--                    </v-btn>-->

              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                            </div>-->

              <!-------------------------------------------- Order löschen ENDE ------------------------------------------>

            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>


  </div>
</template>

<script>
import moment from 'moment';
import {
  deleteOrder,
  getAllOrder,
  getOrderByFirmaID, getOrderByRBFirmaID,
  getSlideReserviernr,
  updateOrder,
  updateOrderOnlyComments
} from "@/util/orderUtil";
import {checkOrderPaymentState, getNextState, getOrderState} from "@/util/OrderState";
import {getOrderBy} from "@/util/OrderBy";
import OverlayOrderState from "@/components/overlay/OverlayOrderState";
import {formatPrice, getCurrencyIcon} from "@/util/currency";
import {pdfRechnung} from "@/util/PDFDokumente/PDFRechnung";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {createInvoice, getInvoiceByInvNr} from "@/util/invoiceUtil";
import {getUser} from "@/util/Auth";
import {MailTocustomerbycustomer1} from "@/util/mailSend";
import {getIDFromAdminUser} from "@/util/userUtil";
import {getKontaktFromUserinfoByUserId, getLogo, getRechtlichesByID} from "@/util/userinfoDBUtil";
import {getBase64FromUrl} from "@/util/PDFDokumente/PDFHeader";
import {getRatePlanByID} from "@/util/rateplansUtil";
import {pdfReservierungKunde} from "@/util/PDFDokumente/PDFReservierungsbestätigung";
import Vue from "vue";


export default {
  name: "Orders",
  props: [
    'pLang',
    'pRolle',
    'pUser',
    'pUserinfo',
    'pLogo',
    'pDomain',
    'pRechtliches',
  ],
  components: {
    OverlayOrderState,
  },
  data() {
    return {

      logoB64: "",
      notificationsnackbar: false,
      notificationtext: "",
      notificationcolor: "green",
      loader: null,
      loading: false,

      admin_userinfo: "",
      reiseburo_userinfo: "",


      commentModel: "",
      showfilter: false,
      trips: [],
      dropdownTrips: [],

      // Panel: für das auf und zuklappen der Accordions
      panel: [],
      items: null,
      orders: null,
      orderTemp: null,
      searchtext: "",
      searchTripTitle: "",
      tempsearchTripTitle: "",

      dropdownDates: [],
      searchTripDate: "",

      dropdownStatus: "",
      searchOrderStatus: "",

      statusfarbe: "",


      asktosaveDeleteOrder: false,

      csvData: [],

      datumheute: (moment(new Date()).format("DD.MM.YYYY")),


      datePaypalPayment: "",
      paypaldatemanu: false, //Datumspicker für Paypal
      checkPaypalDate: false,

      checkPayment: false,
      partpayment: "",
      DatePayment: "",
      restbetrag: "",
      menu2: false, //Datumspicker für Teilzahlung

      showChangedPrice: false,
      showComments: false,
      showCommentButton: true,

      // Checkboxen für die Filterung der Status
      neu_0: false,
      freigegeben_1: false,
      bezahlt_2: false,
      teilzahlung_3: false,
      storniert_4: false,
      verzug_5: false,
      geschlossen_6: false,
      wartend_7: false,


      //Overlay Attribute
      asktosave: false,
      titleOverlay: "",
      newState: "",
      buttonOverlay: "",
      buttonColor: "",
      item: "",

      overlayAskForPaypal: false,


      loadingSendMail: false,
      logo: "",
      showoverlaySendMail: false,


    }
  },

  methods: {
    getRatePlanByID,
    getOrderState,
    getOrderBy,
    getSlideReserviernr,
    getCurrencyIcon,
    formatPrice,


    openfilter() {
      this.showfilter = !this.showfilter;
    },

    notification(text, color) {
      this.notificationsnackbar = true;
      this.notificationtext = text;
      this.notificationcolor = color;
    },

    submitPayment(item) {
      this.item = item;
      this.asktosave = true;
      this.titleOverlay = "PayPal " + this.$t('Orders.ZahlungBestatigen');
      this.overlayAskForPaypal = true;

      this.buttonOverlay = this.$t('Orders.Freigeben');
      this.buttonColor = 'var(--Primary)';
    },

    async sendConfirmMailToCustomer(item) {
      this.loader = 'this.loadingSendMail';

      let admin = await getIDFromAdminUser();

      this.logo = await getLogo(admin._id);

      let adminRechtliches = await getRechtlichesByID(admin._id);
      let adminkontakt = await getKontaktFromUserinfoByUserId(admin._id);


      item.rechtliches = adminRechtliches;
      item.domain = this.pDomain;
      item.userinfo = adminkontakt;

      item.logo = process.env.VUE_APP_IMAGES + this.logo.logo.picture;

      let lowitem = JSON.parse(JSON.stringify(item));
      delete lowitem.date;
      delete lowitem.comment;
      delete lowitem.firma_id;
      delete lowitem.halalerror;
      delete lowitem.hotelinfos.halalbookingProvision;
      delete lowitem.hotelinfos.OHP;
      delete lowitem.hotelinfos.changed_price;
      delete lowitem.hotelinfos.changed_price_try;
      delete lowitem.hotelinfos.changed_price_usd;
      delete lowitem.hotelinfos.isConfirmed;
      delete lowitem.order_by;
      delete lowitem.order_state;
      delete lowitem.part_payment;
      delete lowitem.payed_by;
      delete lowitem.paypal_response;

      let status = await MailTocustomerbycustomer1(lowitem);
      let cc = item.rechnungsadresse.emailcc;
      if (cc !== undefined) {
        cc = " - Cc an=  " + item.rechnungsadresse.emailcc;
      } else {
        cc = "";
      }

      let text = "System: Bestätigungs-Mail an= " + item.rechnungsadresse.email + cc;
      if (status === 200) {
        item.comment.push({
          datum: new Date(),
          text: text
        });

        this.notification("Mail versendet", "green");
        item.rechnungsadresse.email = item.hotelinfos.kunden_email;
        // console.log("item");
        // console.log(item);
        await updateOrderOnlyComments(item);
        this.showoverlaySendMail = false;
      } else {

        this.notification("Fehler beim Versenden", "error");

      }

    },

    async createPDFCustomerConfirmation(order, index) {
      // Image to Base64
      let logo = "";
      await getBase64FromUrl(this.pLogo).then((image) => {
        logo = image;
      });


      pdfMake.vfs = pdfFonts.pdfMake.vfs;

      let ui = this.pUserinfo;

      if (order.hotel_owner === '2') {
        order.hotelinfos.hoteladress = order.hotelinfos.hotellocation.strnr + ", " + order.hotelinfos.hotellocation.plz + ", " + order.hotelinfos.hotellocation.city + ", " + order.hotelinfos.hotellocation.country;
        order.hotelinfos.rooms[0].room_name = order.hotelinfos.rooms[0].room_name_de;
      }

      // if (order.hotelinfos)

      let docDefinition = pdfReservierungKunde(order, index, ui, this.pDomain, logo);

      // pdfMake.createPdf(docDefinition).download(order.order.last_name + '_efehotels');

      pdfMake.createPdf(docDefinition).open();

    },

    async createPDFInvoiceCustomer(order, index) {

      // Image to Base64
      let logo = "";
      await getBase64FromUrl(this.pLogo).then((image) => {
        logo = image;
      });


      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      let inv = "";
      if (order.paypal_response.captures) {
        inv = await getInvoiceByInvNr(order.paypal_response.invoicenr);
      } else {
        inv = await getInvoiceByInvNr(order.part_payment[index].invoicenr);
      }

      let ui = this.pUserinfo;
      if (order.hotel_owner === '2') {
        order.hotelinfos.hoteladress = order.hotelinfos.hotellocation.strnr + ", " + order.hotelinfos.hotellocation.plz + ", " + order.hotelinfos.hotellocation.city + ", " + order.hotelinfos.hotellocation.country;
        order.hotelinfos.rooms[0].room_name = order.hotelinfos.rooms[0].room_name_de;
      }

      let docDefinition = pdfRechnung(order, index, inv, ui, this.pDomain, logo);

      // pdfMake.createPdf(docDefinition).download(order.order.last_name + '_efehotels');

      pdfMake.createPdf(docDefinition).open();
    },


    cancelOrder(item) {
      this.item = item;
      this.asktosave = true;
      this.titleOverlay = this.$t('Orders.ReservierungStornieren');
      this.newState = "4";
      this.buttonOverlay = this.$t('Orders.Stornieren');
      this.buttonColor = "error";
    },

    closeOrder(item) {
      this.item = item;
      this.asktosave = true;
      this.titleOverlay = this.$t('Orders.Reservierungschliessen');
      this.newState = "6";
      this.buttonOverlay = this.$t('Orders.Schliessen');
      this.buttonColor = "black";
    },

    confirmOrder(item) {
      this.item = item;
      this.asktosave = true;
      this.titleOverlay = this.$t('Orders.ReservierungBestaetigt');
      if (item.paypal_response.captures) {
        if (item.paypal_response.captures[0].status === "COMPLETED") {
          this.newState = "2";
        } else {
          this.newState = "1";
        }
      } else {
        this.newState = "1";
      }

      this.buttonOverlay = this.$t('Button.Bestaetigt');
      this.buttonColor = "#4C0099";
    },

    changeState0(item) {
      this.item = item;
      this.asktosave = true;
      this.titleOverlay = this.$t('Orders.ReservierungFreigeben');

      if ((item.hotel_owner !== "1" && item.hotel_owner !== "2") && item.hotelinfos.delay === false) {
        item.hotelinfos.isConfirmed = "isConfirmed";
      }

      if (item.paypal_response.captures) {
        if (item.paypal_response.captures[0].status === "COMPLETED") {
          if (item.hotelinfos.isConfirmed === "notConfirmed") {

            this.newState = "7";
          } else {
            this.newState = "2";
          }
        } else {
          if (item.hotelinfos.isConfirmed === "notConfirmed") {
            if (item.hotelinfos.delay) {
              this.newState = "7";
            } else {
              this.newState = "1";
            }

          } else {
            this.newState = "1";
          }

        }
      } else {
        if (item.hotelinfos.isConfirmed === "notConfirmed") {

          if (item.hotelinfos.delay || item.hotel_owner === "1" || item.hotel_owner === "2") {
            this.newState = "7";
          } else {
            this.newState = "1";
          }
        } else {
          this.newState = "1";
        }
      }


      this.buttonOverlay = this.$t('Orders.Freigeben');
      this.buttonColor = "green";
    },

    async searchEvent() {
      this.orderTemp = this.orders;
      let b = [];
      if (this.neu_0) {

        b.push("0");
      }
      if (this.freigegeben_1) {

        b.push("1");
      }
      if (this.bezahlt_2) {

        b.push("2");
      }
      if (this.teilzahlung_3) {

        b.push("3");
      }
      if (this.storniert_4) {

        b.push("4");
      }
      if (this.verzug_5) {

        b.push("5");
      }
      if (this.geschlossen_6) {
        b.push("6");
      }
      if (this.wartend_7) {
        b.push("7");
      }


      if (this.searchtext !== '') {
        if (b.length !== 0) {
          this.orderTemp = this.orderTemp.filter(it => b.includes(it.order_state));
        }

        this.orderTemp = this.orderTemp.filter(it => {
              try {
                if (it.hotelinfos.reiseburo) {
                  return it.order.first_name.toLowerCase().includes(this.searchtext.toLowerCase())
                      || it.order.last_name.toLowerCase().includes(this.searchtext.toLowerCase())
                      || it.hotelinfos.kunden_email.toLowerCase().includes(this.searchtext.toLowerCase())
                      || it.order_id.toLowerCase().includes(this.searchtext.toLowerCase())
                      || it.hotelinfos.hotelname.toLowerCase().includes(this.searchtext.toLowerCase())
                      || it.hotelinfos.reiseburo.firma.toLowerCase().includes(this.searchtext.toLowerCase())
                } else {
                  return it.order.first_name.toLowerCase().includes(this.searchtext.toLowerCase())
                      || it.order.last_name.toLowerCase().includes(this.searchtext.toLowerCase())
                      || it.hotelinfos.kunden_email.toLowerCase().includes(this.searchtext.toLowerCase())
                      || it.order_id.toLowerCase().includes(this.searchtext.toLowerCase())
                      || it.hotelinfos.hotelname.toLowerCase().includes(this.searchtext.toLowerCase())

                }
              } catch (e) {

              }
            }
        );


      } else {
        if (b.length !== 0) {
          this.orderTemp = this.orderTemp.filter(it => b.includes(it.order_state));

        } else {
          this.orderTemp = this.orders;
        }
      }


    },
    neuEvent() {
      this.neu_0 = !this.neu_0

      this.searchEvent();
    },
    freigegebenEvent() {
      this.freigegeben_1 = !this.freigegeben_1

      this.searchEvent();
    },
    bezahltEvent() {
      this.bezahlt_2 = !this.bezahlt_2;

      this.searchEvent();
    },
    teilzahlungEvent() {
      this.teilzahlung_3 = !this.teilzahlung_3;

      this.searchEvent();
    },
    storniertEvent() {
      this.storniert_4 = !this.storniert_4;

      this.searchEvent();
    },
    verzugEvent() {
      this.verzug_5 = !this.verzug_5;

      this.searchEvent();
    },
    geschlossenEvent() {
      this.geschlossen_6 = !this.geschlossen_6;

      this.searchEvent();
    },
    wartendEvent() {
      this.wartend_7 = !this.wartend_7;

      this.searchEvent();
    },
    async saveComment(index) {
      this.commentModel = this.commentModel.trim();

      if (this.commentModel.trim() !== '') {
        this.loader = 'loading'
        let order = this.orderTemp[index];
        order.comment[order.comment.length - 1] = {datum: new Date(), text: this.commentModel.trim()}

        await updateOrder(order);
      } else {
        this.notification("Kommentar ist leer!", "error");
      }
      this.commentModel = "";
      this.showComments = !this.showComments;
      this.showCommentButton = !this.showCommentButton;
    },


    viewcomment(index) {
      this.showComments = !this.showComments;
      this.showCommentButton = !this.showCommentButton;
      let order = this.orderTemp[index];
      order.comment.push({datum: "", text: ""});
      this.orderTemp[index] = order;
    },


    async deletecomment(index, commindex) {
      let order = this.orderTemp[index];
      order.comment.splice(commindex, 1);

      await updateOrder(order);
    },


    calculateRest(total, change, part) {
      let sum = 0.00;

      if (parseFloat(change) === 0.00 || change === '') {
        let s = parseFloat(total);

        part.map(item => {
          s -= parseFloat(item.value);
        })
        sum = s;
      } else {
        let s = parseFloat(change);

        part.map(item => {
          s -= parseFloat(item.value);

        })
        sum = s;
      }
      return sum.toFixed(2).toString().replace(".", ",");
    },


    changeColor(status) {
      switch (status) {
        case '0':
          return "#2196f3";
        case '1':
          return "#F3B821";
        case '2':
          return "#4caf50";
        case '3':
          return "grey";
        case '4':
          return "error";
        case '5':
          return "#940000";
        case '6':
          return "black";
        case '7':
          return "#4C0099";
        default:
          return "white";
      }
    },


    checkPartPayment(index) {

      if (this.partpayment.length > 0) {
        this.checkPayment = true;


      } else {

        this.checkPayment = false;
      }

    },

    async addPartPayment(index) {

      let order = this.orderTemp[index];
      let teilzahlung = [];

      if (this.partpayment.trim().length > 0) {
        if (order.part_payment !== undefined) {
          if (order.part_payment.length > 0) {
            teilzahlung = order.part_payment;
          }
        }


        let date = new Date();

        if (this.DatePayment !== '') {
          date = new Date(this.DatePayment);
        }


        teilzahlung.push({value: this.partpayment, date: date});
        this.partpayment = "";
        order.part_payment = teilzahlung;


        let rest = this.calculateRest(order.hotelinfos.total_price, order.hotelinfos.changed_price, order.part_payment)

        let state = getNextState(order.order_state, '', parseFloat(rest) > 0);
        order.order_state = state;

        if (order.order_state === "2") {
          this.notification(this.$t("NotificationStatusAufBezahlt"), "green");

        }
        if (order.order_state === "3") {
          this.notification("Teilzahlung gespeichert", "green");

        }

        await updateOrder(order);
        location.reload();


      } else {
        this.notification(this.$t("NotificationKorrekteZahl"), "error");
      }

    },
    async deletePartPayment(orderindex, teilzahlungindex) {

      let order = this.orderTemp[orderindex];
      let teilzahlung = [];
      let removeValue = "";
      if (order.part_payment !== undefined) {
        if (order.part_payment.length > 0) {
          teilzahlung = order.part_payment;
        }
      }

      removeValue = teilzahlung[teilzahlungindex];
      if (teilzahlung.length > 1) {
        teilzahlung = teilzahlung.filter((s, index) => index !== teilzahlungindex);
      } else {
        teilzahlung.pop();
      }

      order.part_payment = teilzahlung;

      let part = "";
      let rest = this.calculateRest(order.hotelinfos.total_price, order.hotelinfos.changed_price, order.part_payment)

      if (parseFloat(rest) === parseFloat(order.hotelinfos.total_price) || parseFloat(rest) === parseFloat(order.hotelinfos.changed_price)) {
        part = ''
        this.notification("Status in Freigegeben", "#e8d159");
      } else {

        part = parseFloat(rest) >= 0;
        this.notification("Teilzahung gelöscht", "green");
      }

      let state = getNextState(order.order_state, '', part);
      order.order_state = state;
      await updateOrder(order);
    },


    async createNewInvoice(item, i, index) {


      let invoice = {
        ordernr: item.order_id,
        payment: "",
        invoicedate: "",
        invoicenr: "xx"
      }

      if (item.paypal_response.captures) {
        if (item.paypal_response.captures[0].status === 'COMPLETED') {
          //console.log("diese Buchung wurde mit Paypal bezahlt");
          invoice.invoicedate = this.datePaypalPayment;
          invoice.payment = item.paypal_response.captures[0].amount.value;
        }
      } else {
        //console.log("diese Buchung wurde bar bezahlt, hier die Teilsumme");
        // console.log(item.part_payment[index].date);
        invoice.invoicedate = item.part_payment[index].date;
        invoice.payment = item.part_payment[index].value;
      }
      if (invoice.invoicedate === "") {
        this.notification("Datum auswählen", "error");

      } else {


        let res = await createInvoice(invoice);
        if (res.status === 201) {
          this.notification("Rechnung erstellt", "green");
          if (item.paypal_response.captures) {
            if (item.paypal_response.captures[0].status === 'COMPLETED') {
              item.paypal_response = {
                ...item.paypal_response,
                invoice_created: true,
                invoicenr: res.data.invoicenr
              }
            }

          } else {
            item.part_payment[index] = {
              ...item.part_payment[index],
              invoice_created: true,
              invoicenr: res.data.invoicenr
            }
          }


          await updateOrder(item);
        } else {
          this.notification("Fehler beim erstellen.", "error");
        }
      }
    },


    //TODO: Methode zum löschen eines Orders
    async delOrder(order_id) {
      await deleteOrder(order_id);
    },

    calculateNewPrice(item) {
      let kurs = 0.00;
      let kurs_try = 0.00;
      let kurs_usd = 0.00;
      let value = 0.00;
      let value_try = 0.00;
      let value_usd = 0.00;

      //In EUR reserviert
      if (item.hotelinfos.currency === 'EUR') {
        kurs_try = item.hotelinfos.total_price_try / item.hotelinfos.total_price;
        value_try = kurs_try * item.hotelinfos.changed_price;

      }
      //In TRY reserviert
      if (item.hotelinfos.currency === 'TRY') {
        kurs_try = item.hotelinfos.total_price / item.hotelinfos.total_price_eur;
        value_try = kurs_try * item.hotelinfos.changed_price;
      }
      //In USD reserviert
      if (item.hotelinfos.currency === 'USD') {
        kurs_usd = item.hotelinfos.total_price / item.hotelinfos.total_price_eur;
        kurs_try = item.hotelinfos.total_price_try / item.hotelinfos.total_price_eur;
        value_usd = kurs_usd * item.hotelinfos.changed_price;
        value_try = kurs_try * item.hotelinfos.changed_price;
      }

      item.hotelinfos.changed_price_try = value_try.toFixed(2);
      item.hotelinfos.changed_price_usd = value_usd.toFixed(2);


    },

    async addNewPrice(index) {
      let order = this.orderTemp[index];

      let sum = 0.00;
      order.part_payment.map(item => {
        sum += parseFloat(item.value);
      })
      let changed_price = parseFloat(order.hotelinfos.changed_price);
      if (sum === changed_price) {
        order.order_state = "2";
      } else {
        if (sum > 0) {
          order.order_state = "3";
        } else {
          order.order_state = "1";
        }
      }
      await updateOrder(order);

      this.showChangedPrice = false;
      this.notification("Neuer Preis gespeichert", "green");

    },


  },

  async created() {


    let user = await getUser(localStorage.getItem("token"));

    if (user.rolle !== "reiseburo") {
      this.orders = await getAllOrder();
    } else {
      // this.orders = await getOrderByFirmaID(user.firma_id);
      this.orders = await getOrderByRBFirmaID(user.firma_id);
    }
    // this.orders.reverse();
    this.orderTemp = [...this.orders];
    // this.orderTemp.reverse();
    await checkOrderPaymentState(this.orderTemp);

    this.$root.$on('showOverlay', (val) => {
      this.asktosave = val;
    });

    this.$root.$on('fehlerbeisendenanhalal', (val) => {
      this.notification("Fehler, Reservierung wurde nicht an Halal gesendet", "error");
    });


    this.$root.$on('bestaetigungPaypal', (val) => {
      this.notification(this.$t('NotificationSpeichernErfolgreich'), "green");
    });

    //
    // this.$root.$on('changeKey', async (val) => {
    //   this.orders = undefined;
    //
    //   if (user.rolle !== "reiseburo") {
    //     this.orders = await getAllOrder();
    //   } else {
    //     this.orders = await getOrderByFirmaID(user.firma_id);
    //   }
    //
    //   console.log("changeKEy");
    //   this.$forceUpdate();
    //
    // });


    this.$root.$on('fehlerPaypal', (val) => {
      this.notification("Fehler", "green");
    });


    if (localStorage.getItem('stateto1')) {
      this.notification("Reservierung wurde freigegeben", "green");
      localStorage.removeItem('stateto1');
    }

    if (localStorage.getItem('canOr')) {
      this.notification("Reservierung wurde storniert", "green");
      localStorage.removeItem('canOr');
    }
    if (localStorage.getItem('closeOr')) {
      this.notification("Reservierung ist geschlossen", "green");
      localStorage.removeItem('closeOr');
    }
    if (localStorage.getItem('halalerror')) {
      this.notification("Fehler, Reservierung wurde nicht an Halal gesendet", "error");
      localStorage.removeItem('halalerror');
    }


    // console.log("this.pDomain");
    // console.log(this.pDomain);
  },

  watch: {
    loader() {
      const l = this.loader
      this[l] = !this[l]

      setTimeout(() => (this[l] = false), 3000)

      this.loader = null
    },
  }

}
</script>


<style scoped>
@import "../css/Orders.css";
</style>
