const classes = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];


export default class ClassUitil {
    constructor() {
        this.classes = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
        this.index = 0;
    }

    getNext(arrayElement) {
        let e = true;
        while (e) {
            e = arrayElement.some(item => item.classname === this.classes[this.index])

            if (e) {
                this.index++;
            }
        }
        return this.classes[this.index];
    }
}