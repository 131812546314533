<template>
  <!--  <div class="home" v-if="pRolle==='admin' || pRolle==='superadmin'">-->
  <div class="home">
    <v-snackbar
        v-model="notificationsnackbar"
        :timeout="6000"
        :color="notificationcolor"
        :top="true"
        :right="true"
        :vertical="true"
    >
      {{ notificationtext }}
      <v-btn
          color="white"
          text
          @click="notificationsnackbar = false"
      >
        {{ $t("Notification.Schliessen") }}
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>


    <div class="bodyheader-home">
      <label class="label-bodyheader-home">
        <v-icon class="icon-home">mdi-home-city</v-icon>
        Eigene Hotels
        <!--        {{ $t("home.Hotels") }}-->
      </label>


    </div>


    <div>


      <div style="max-width: 800px; margin: 50px auto;" v-if="pRolle==='admin' || pRolle==='superadmin'">
        <template>
          <div>
            <v-data-table
                :headers="headers"
                :items="allOwnHalalProvision"
                item-key="name"
                class="elevation-1"
                :search="search"
                :custom-filter="filterOnlyCapsText"
            >

              <template v-slot:top>


                <v-toolbar flat>
                  <v-toolbar-title>Halal-Hotels mit eigener Provision</v-toolbar-title>


                  <v-spacer></v-spacer>

                  <v-dialog
                      v-model="dialog"
                      max-width="600px"
                  >
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">{{ formTitle }}</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col
                                cols="12"
                                sm="6"
                                md="4"
                            >

                              <v-text-field
                                  v-model="editedItem.halal_place_id"
                                  label="Hotel-ID"
                                  disabled
                              ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                md="4"
                            >
                              <v-text-field
                                  v-model="editedItem.halal_place_name"
                                  label="Hotel"
                                  disabled
                              ></v-text-field>
                            </v-col>

                            <v-col
                                cols="12"
                                sm="6"
                                md="4"
                            >
                              <v-text-field
                                  v-model="editedItem.provision"
                                  label="Provision"
                                  append-icon="mdi-percent-outline"
                              ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                md="4"
                            >
                              <v-text-field
                                  v-model="editedItem.email"
                                  label="E-Mail"

                              ></v-text-field>
                            </v-col>

                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="close"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="save"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="600px">
                    <v-card>
                      <v-card-title class="text-h5" style="word-break: unset">Wollen Sie dieses Hotel entgültig
                                                                              löschen?
                      </v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>

                <v-text-field
                    v-model="search"
                    label="Suche..."
                    class="mx-4"
                    prepend-inner-icon="mdi-magnify"
                ></v-text-field>
              </template>


              <template v-slot:item.provision="{ item }">

                {{ item.provision }} %

              </template>

              <template v-slot:item.actions="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                    small
                    @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>


            </v-data-table>
          </div>
        </template>
      </div>


    </div>


    <div>


      <div style="max-width: 800px; margin: 50px auto;">
        <template>
          <div>
            <v-data-table
                :headers="headersOwnPlaces"
                :items="allOwnPlaces"
                item-key="name"
                class="elevation-1"
                :search="search"
                :custom-filter="filterOnlyCapsText"
            >

              <template v-slot:top>


                <v-toolbar flat>
                  <v-toolbar-title>Eigene Hotels</v-toolbar-title>


                </v-toolbar>

                <v-text-field
                    v-model="search"
                    label="Suche..."
                    class="mx-4"
                    prepend-inner-icon="mdi-magnify"
                ></v-text-field>
              </template>


              <template v-slot:item.status_place="{ item }">
                <div>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                          :color="item.status_place === '1' ? 'green' : 'error'"
                          v-bind="attrs"
                          v-on="on"
                      >
                        mdi-{{ item.status_place === '1' ? 'briefcase-check' : 'close-octagon' }}
                      </v-icon>
                    </template>
                    <span>{{ item.status_place === '1' ? 'Aktiv' : 'Deaktiviert' }}</span>
                  </v-tooltip>
                </div>
              </template>


              <template v-slot:item.provision="{ item }">
                {{ item.provision }} %
              </template>

              <template v-slot:item.actions="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    @click="editOwnPlace(item)"
                >
                  mdi-pencil
                </v-icon>
              </template>


            </v-data-table>
          </div>
        </template>
      </div>


    </div>

  </div>
</template>

<script>
import {deleteOwnHalalProvisionByID, getAllOwnHalalProvision, updateOwnHalalProvision} from "@/util/halalplaceUtil";
import {getOwnPlaceByPlaceHID, getAllOwnPlaces} from "@/util/OwnPlace";

export default {
  name: "Hotellist",
  props: [
    'pLang',
    'pRolle',
    'pUser',
  ],
  data() {
    return {
      notificationsnackbar: false,
      notificationtext: "",
      notificationcolor: "grey",


      allOwnHalalProvision: [],
      search: '',

      allOwnPlaces: [],
      searchOwnPlaces: '',


      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        halal_place_id: "",
        halal_place_name: "",
        provision: "",
        email: "",
        _id: ""
      },
      defaultItem: {
        halal_place_id: "",
        halal_place_name: "",
        provision: "",
        email: ""
      },


    };
  },
  methods: {
    zuStart() {
      this.$router.push('/login');
    },
    newHotel() {
      this.$router.push("/createhotel");
    },

    notification(text, color) {
      this.notificationsnackbar = true;
      this.notificationtext = text;
      this.notificationcolor = color;
    },

    filterOnlyCapsText(value, search, item) {
      return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLocaleLowerCase().indexOf(search.toString().toLocaleLowerCase()) !== -1
    },

    editItem(item) {
      this.editedIndex = this.allOwnHalalProvision.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true


    },

    deleteItem(item) {
      this.editedIndex = this.allOwnHalalProvision.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      await deleteOwnHalalProvisionByID(this.editedItem._id);
      this.allOwnHalalProvision.splice(this.editedIndex, 1)
      this.closeDelete()

      this.notification("Hotel erfolgreich gelöscht!", "green");
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async save() {
      if (this.editedIndex > -1) {
        Object.assign(this.allOwnHalalProvision[this.editedIndex], this.editedItem)
      } else {
        this.allOwnHalalProvision.push(this.editedItem)
      }
      await updateOwnHalalProvision(this.editedItem);
      this.close()
    },

    editOwnPlace(item) {

      localStorage.setItem("ownp", JSON.stringify(item));
      this.$router.push({
        path: 'createhotel',

      })
    }

  },


  // async created() {
  async mounted() {


    if (localStorage.getItem("nlo")) {
      localStorage.removeItem("nlo");
      location.reload();
    }

    this.allOwnHalalProvision = await getAllOwnHalalProvision();

    let notiy = localStorage.getItem("notiy");

    if (notiy !== null) {
      this.notification(notiy, "green");
      localStorage.removeItem("notiy");
    }


    let rolle = this.pUser.rolle;
    if (rolle !== undefined && rolle !== "") {
      if (rolle !== "hotel") {
        this.allOwnPlaces = await getAllOwnPlaces();
      } else {
        this.allOwnPlaces = await getOwnPlaceByPlaceHID(this.pUser._id);
      }
    }


  },


  computed: {
    headers() {
      return [
        {
          text: 'ID',
          align: 'start',
          value: 'halal_place_id',
        },
        {text: 'Hotel', value: 'halal_place_name'},
        {text: 'Provision', value: 'provision'},
        {text: 'E-Mail', value: 'email'},
        {text: 'Actions', value: 'actions', sortable: false},


      ]
    },
    headersOwnPlaces() {
      return [
        {
          text: 'ID',
          align: 'start',
          value: 'place_id',
        },
        {text: 'Hotel', value: 'place_name_de'},
        {text: 'Provision', value: 'provision'},
        {text: 'E-Mail', value: 'contact.email'},
        {text: 'Status', value: 'status_place'},
        {text: 'Actions', value: 'actions', sortable: false},


      ]
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Bearbeiten'
    },

  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  }
};
</script>

<style scoped>
@import "../css/Hotellist.css";
</style>