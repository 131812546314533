export const getCountries = (lang) => {
    switch (lang) {
        case 'DE':
            return countries_de;
        case 'EN':
            return countries_en;
        case 'TR':
            return countries_tr;
        default:
            return countries_en;
    }
}

export const getCountry = (lang, id) => {

    if (id === "") {
        id = "TR"
    }

    if (lang === 'DE') {
        return countries_de.find(item => item.countryid === id).text;
    }
    if (lang === 'TR') {
        return countries_tr.find(item => item.countryid === id).text;
    }

    if (lang === 'EN') {
        return countries_en.find(item => item.countryid === id).text;
    }


}


export const getCountryIDByValue = (lang, value) => {

    if (lang === 'DE') {
        return countries_de.find(item => item.text === value).countryid;
    }
    if (lang === 'TR') {
        return countries_tr.find(item => item.text === value).countryid;
    }

    if (lang === 'EN') {
        return countries_en.find(item => item.text === value).countryid;
    }

}


const countries_de = [
    {text: 'Afghanistan', countryid: 'AF'},
    {text: 'Ägypten', countryid: 'EG'},
    {text: 'Ålandinseln', countryid: 'AX'},
    {text: 'Albanien', countryid: 'AL'},
    {text: 'Algerien', countryid: 'DZ'},
    {text: 'Amerikanische Jungferninseln', countryid: 'VI'},
    {text: 'Amerikanisch-Samoa', countryid: 'AS'},
    {text: 'Andorra', countryid: 'AD'},
    {text: 'Angola', countryid: 'AO'},
    {text: 'Anguilla', countryid: 'AI'},
    {text: 'Antarktis', countryid: 'AQ'},
    {text: 'Antigua und Barbuda', countryid: 'AG'},
    {text: 'Äquatorialguinea', countryid: 'GQ'},
    {text: 'Argentinien', countryid: 'AR'},
    {text: 'Armenien', countryid: 'AM'},
    {text: 'Aruba', countryid: 'AW'},
    {text: 'Aserbaidschan', countryid: 'AZ'},
    {text: 'Äthiopien', countryid: 'ET'},
    {text: 'Australien', countryid: 'AU'},
    {text: 'Bahamas', countryid: 'BS'},
    {text: 'Bahrain', countryid: 'BH'},
    {text: 'Bangladesch', countryid: 'BD'},
    {text: 'Barbados', countryid: 'BB'},
    {text: 'Belarus', countryid: 'BY'},
    {text: 'Belgien', countryid: 'BE'},
    {text: 'Belize', countryid: 'BZ'},
    {text: 'Benin', countryid: 'BJ'},
    {text: 'Bermuda', countryid: 'BM'},
    {text: 'Bhutan', countryid: 'BT'},
    {text: 'Bolivien', countryid: 'BO'},
    {text: 'Bosnien und Herzegowina', countryid: 'BA'},
    {text: 'Botsuana', countryid: 'BW'},
    {text: 'Bouvetinsel', countryid: 'BV'},
    {text: 'Brasilien', countryid: 'BR'},
    {text: 'Britische Jungferninseln', countryid: 'VG'},
    {text: 'Britisches Territorium im Indischen Ozean', countryid: 'IO'},
    {text: 'Brunei', countryid: 'BN'},
    {text: 'Bulgarien', countryid: 'BG'},
    {text: 'Burkina Faso', countryid: 'BF'},
    {text: 'Burundi', countryid: 'BI'},
    {text: 'Cabo Verde', countryid: 'CV'},
    {text: 'Chile', countryid: 'CL'},
    {text: 'China', countryid: 'CN'},
    {text: 'Clipperton', countryid: 'CP'},
    {text: 'Cookinseln', countryid: 'CK'},
    {text: 'Costa Rica', countryid: 'CR'},
    {text: 'Curaçao', countryid: 'CW'},
    {text: 'Dänemark', countryid: 'DK'},
    {text: 'Deutschland', countryid: 'DE'},
    {text: 'Dominica', countryid: 'DM'},
    {text: 'Dominikanische Republik', countryid: 'DO'},
    {text: 'Dschibuti', countryid: 'DJ'},
    {text: 'Ecuador', countryid: 'EC'},
    {text: 'El Salvador', countryid: 'SV'},
    {text: 'Elfenbeinküste', countryid: 'CI'},
    {text: 'England', countryid: 'GB'},
    {text: 'Eritrea', countryid: 'ER'},
    {text: 'Estland', countryid: 'EE'},
    {text: 'Eswatini', countryid: 'SZ'},
    {text: 'Falklandinseln', countryid: 'FK'},
    {text: 'Färöer', countryid: 'FO'},
    {text: 'Fidschi', countryid: 'FJ'},
    {text: 'Finnland', countryid: 'FI'},
    {text: 'Frankreich', countryid: 'FR'},
    {text: 'Französische Süd- und Antarktisgebiete', countryid: 'TF'},
    {text: 'Französisch-Polynesien', countryid: 'PF'},
    {text: 'Gabun', countryid: 'GA'},
    {text: 'Gambia', countryid: 'GM'},
    {text: 'Georgien', countryid: 'GE'},
    {text: 'Ghana', countryid: 'GH'},
    {text: 'Gibraltar', countryid: 'GI'},
    {text: 'Grenada', countryid: 'GD'},
    {text: 'Griechenland', countryid: 'GR'},
    {text: 'Grönland', countryid: 'GL'},
    {text: 'Guadeloupe', countryid: 'GP'},
    {text: 'Guam', countryid: 'GU'},
    {text: 'Guatemala', countryid: 'GT'},
    {text: 'Guayana', countryid: 'GF'},
    {text: 'Guernsey', countryid: 'GG'},
    {text: 'Guinea', countryid: 'GN'},
    {text: 'Guinea-Bissau', countryid: 'GW'},
    {text: 'Guyana', countryid: 'GY'},
    {text: 'Haiti', countryid: 'HT'},
    {text: 'Heard und die McDonaldinseln', countryid: 'HM'},
    {text: 'Honduras', countryid: 'HN'},
    {text: 'Hongkong', countryid: 'HK'},
    {text: 'Indien', countryid: 'IN'},
    {text: 'Indonesien', countryid: 'ID'},
    {text: 'Insel Man', countryid: 'IM'},
    {text: 'Irak', countryid: 'IQ'},
    {text: 'Iran', countryid: 'IR'},
    {text: 'Irland', countryid: 'IE'},
    {text: 'Island', countryid: 'IS'},
    {text: 'Israel', countryid: 'IL'},
    {text: 'Italien', countryid: 'IT'},
    {text: 'Jamaika', countryid: 'JM'},
    {text: 'Japan', countryid: 'JP'},
    {text: 'Jemen', countryid: 'YE'},
    {text: 'Jersey', countryid: 'JE'},
    {text: 'Jordanien', countryid: 'JO'},
    {text: 'Kaimaninseln', countryid: 'KY'},
    {text: 'Kambodscha', countryid: 'KH'},
    {text: 'Kamerun', countryid: 'CM'},
    {text: 'Kanada', countryid: 'CA'},
    {text: 'Kasachstan', countryid: 'KZ'},
    {text: 'Katar', countryid: 'QA'},
    {text: 'Kenia', countryid: 'KE'},
    {text: 'Kirgisistan', countryid: 'KG'},
    {text: 'Kiribati', countryid: 'KI'},
    {text: 'Kleinere Amerikanische Überseeinseln', countryid: 'UM'},
    {text: 'Kokosinseln', countryid: 'CC'},
    {text: 'Kolumbien', countryid: 'CO'},
    {text: 'Komoren', countryid: 'KM'},
    {text: 'Kongo', countryid: 'CD'},
    {text: 'Kongo', countryid: 'CG'},
    {text: 'Kosovo', countryid: 'XK'},
    {text: 'Kroatien', countryid: 'HR'},
    {text: 'Kuba', countryid: 'CU'},
    {text: 'Kuwait', countryid: 'KW'},
    {text: 'Laos', countryid: 'LA'},
    {text: 'Lesotho', countryid: 'LS'},
    {text: 'Lettland', countryid: 'LV'},
    {text: 'Libanon', countryid: 'LB'},
    {text: 'Liberia', countryid: 'LR'},
    {text: 'Libyen', countryid: 'LY'},
    {text: 'Liechtenstein', countryid: 'LI'},
    {text: 'Litauen', countryid: 'LT'},
    {text: 'Luxemburg', countryid: 'LU'},
    {text: 'Macau', countryid: 'MO'},
    {text: 'Madagaskar', countryid: 'MG'},
    {text: 'Malawi', countryid: 'MW'},
    {text: 'Malaysia', countryid: 'MY'},
    {text: 'Malediven', countryid: 'MV'},
    {text: 'Mali', countryid: 'ML'},
    {text: 'Malta', countryid: 'MT'},
    {text: 'Marokko', countryid: 'MA'},
    {text: 'Marshallinseln', countryid: 'MH'},
    {text: 'Martinique', countryid: 'MQ'},
    {text: 'Mauretanien', countryid: 'MR'},
    {text: 'Mauritius', countryid: 'MU'},
    {text: 'Mayotte', countryid: 'YT'},
    {text: 'Mexiko', countryid: 'MX'},
    {text: 'Mikronesien', countryid: 'FM'},
    {text: 'Moldawien', countryid: 'MD'},
    {text: 'Monaco', countryid: 'MC'},
    {text: 'Mongolei', countryid: 'MN'},
    {text: 'Montenegro', countryid: 'ME'},
    {text: 'Montserrat', countryid: 'MS'},
    {text: 'Mosambik', countryid: 'MZ'},
    {text: 'Myanmar/Birma', countryid: 'MM'},
    {text: 'Namibia', countryid: 'NA'},
    {text: 'Nauru', countryid: 'NR'},
    {text: 'Nepal', countryid: 'NP'},
    {text: 'Neukaledonien', countryid: 'NC'},
    {text: 'Neuseeland', countryid: 'NZ'},
    {text: 'Nicaragua', countryid: 'NI'},
    {text: 'Niederlande', countryid: 'NL'},
    {text: 'Niger', countryid: 'NE'},
    {text: 'Nigeria', countryid: 'NG'},
    {text: 'Niue', countryid: 'NU'},
    {text: 'Nordkorea', countryid: 'KP'},
    {text: 'Nördliche Marianen', countryid: 'MP'},
    {text: 'Nordmazedonien', countryid: 'MK'},
    {text: 'Norfolkinsel', countryid: 'NF'},
    {text: 'Norwegen', countryid: 'NO'},
    {text: 'Oman', countryid: 'OM'},
    {text: 'Österreich', countryid: 'AT'},
    {text: 'Osttimor', countryid: 'TL'},
    {text: 'Pakistan', countryid: 'PK'},
    {text: 'Palästina', countryid: 'PS'},
    {text: 'Palau', countryid: 'PW'},
    {text: 'Panama', countryid: 'PA'},
    {text: 'Papua-Neuguinea', countryid: 'PG'},
    {text: 'Paraguay', countryid: 'PY'},
    {text: 'Peru', countryid: 'PE'},
    {text: 'Philippinen', countryid: 'PH'},
    {text: 'Pitcairninseln', countryid: 'PN'},
    {text: 'Polen', countryid: 'PL'},
    {text: 'Portugal', countryid: 'PT'},
    {text: 'Puerto Rico', countryid: 'PR'},
    {text: 'Réunion', countryid: 'RE'},
    {text: 'Ruanda', countryid: 'RW'},
    {text: 'Rumänien', countryid: 'RO'},
    {text: 'Russland', countryid: 'RU'},
    {text: 'Salomonen', countryid: 'SB'},
    {text: 'Sambia', countryid: 'ZM'},
    {text: 'Samoa', countryid: 'WS'},
    {text: 'San Marino', countryid: 'SM'},
    {text: 'São Tomé und Príncipe', countryid: 'ST'},
    {text: 'Saudi-Arabien', countryid: 'SA'},
    {text: 'Schweden', countryid: 'SE'},
    {text: 'Schweiz', countryid: 'CH'},
    {text: 'Senegal', countryid: 'SN'},
    {text: 'Serbien', countryid: 'RS'},
    {text: 'Seychellen', countryid: 'SC'},
    {text: 'Sierra Leone', countryid: 'SL'},
    {text: 'Simbabwe', countryid: 'ZW'},
    {text: 'Singapur', countryid: 'SG'},
    {text: 'Sint Maarten', countryid: 'SX'},
    {text: 'Slowakei', countryid: 'SK'},
    {text: 'Slowenien', countryid: 'SI'},
    {text: 'Somalia', countryid: 'SO'},
    {text: 'Spanien', countryid: 'ES'},
    {text: 'Sri Lanka', countryid: 'LK'},
    {text: 'St. Barthélemy', countryid: 'BL'},
    {text: 'St. Helena', countryid: 'SH'},
    {text: 'St. Kitts und Nevis', countryid: 'KN'},
    {text: 'St. Lucia', countryid: 'LC'},
    {text: 'St. Martin', countryid: 'MF'},
    {text: 'St. Pierre und Miquelon', countryid: 'PM'},
    {text: 'St. Vincent und die Grenadinen', countryid: 'VC'},
    {text: 'Südafrika', countryid: 'ZA'},
    {text: 'Sudan', countryid: 'SD'},
    {text: 'Südgeorgien und die Südlichen Sandwichinseln', countryid: 'GS'},
    {text: 'Südkorea', countryid: 'KR'},
    {text: 'Südsudan', countryid: 'SS'},
    {text: 'Suriname', countryid: 'SR'},
    {text: 'Svalbard und Jan Mayen', countryid: 'SJ'},
    {text: 'Syrien', countryid: 'SY'},
    {text: 'Tadschikistan', countryid: 'TJ'},
    {text: 'Taiwan', countryid: 'TW'},
    {text: 'Tansania', countryid: 'TZ'},
    {text: 'Thailand', countryid: 'TH'},
    {text: 'Togo', countryid: 'TG'},
    {text: 'Tokelau', countryid: 'TK'},
    {text: 'Tonga', countryid: 'TO'},
    {text: 'Trinidad und Tobago', countryid: 'TT'},
    {text: 'Tschad', countryid: 'TD'},
    {text: 'Tschechien', countryid: 'CZ'},
    {text: 'Tunesien', countryid: 'TN'},
    {text: 'Türkei', countryid: 'TR'},
    {text: 'Turkmenistan', countryid: 'TM'},
    {text: 'Turks- und Caicosinseln', countryid: 'TC'},
    {text: 'Tuvalu', countryid: 'TV'},
    {text: 'Uganda', countryid: 'UG'},
    {text: 'Ukraine', countryid: 'UA'},
    {text: 'Ungarn', countryid: 'HU'},
    {text: 'Uruguay', countryid: 'UY'},
    {text: 'Usbekistan', countryid: 'UZ'},
    {text: 'Vanuatu', countryid: 'VU'},
    {text: 'Vatikanstadt', countryid: 'VA'},
    {text: 'Venezuela', countryid: 'VE'},
    {text: 'Vereinigte Arabische Emirate', countryid: 'AE'},
    {text: 'Vereinigte Staaten', countryid: 'US'},
    {text: 'Vereinigtes Königreich', countryid: 'UK'},
    {text: 'Vietnam', countryid: 'VN'},
    {text: 'Wallis und Futuna', countryid: 'WF'},
    {text: 'Weihnachtsinsel', countryid: 'CX'},
    {text: 'Westsahara', countryid: 'EH'},
    {text: 'Zentralafrika', countryid: 'CF'}
]


const countries_tr = [
    {text: 'ABD', countryid: 'US'},
    {text: 'ABD küçük dış adaları', countryid: 'UM'},
    {text: 'ABD Virjin Adaları', countryid: 'VI'},
    {text: 'Afganistan', countryid: 'AF'},
    {text: 'Ålandinseln', countryid: 'AX'},
    {text: 'Almanya', countryid: 'DE'},
    {text: 'Amerikan Samoası', countryid: 'AS'},
    {text: 'Andorra', countryid: 'AD'},
    {text: 'Angola', countryid: 'AO'},
    {text: 'Anguilla', countryid: 'AI'},
    {text: 'Antarktika', countryid: 'AQ'},
    {text: 'Antigua ve Barbuda', countryid: 'AG'},
    {text: 'Arjantin', countryid: 'AR'},
    {text: 'Arnavutluk', countryid: 'AL'},
    {text: 'Aruba', countryid: 'AW'},
    {text: 'Avustralya', countryid: 'AU'},
    {text: 'Avusturya', countryid: 'AT'},
    {text: 'Azerbaycan', countryid: 'AZ'},
    {text: 'Bahamalar', countryid: 'BS'},
    {text: 'Bahreyn', countryid: 'BH'},
    {text: 'Bangladeş', countryid: 'BD'},
    {text: 'Barbados', countryid: 'BB'},
    {text: 'Batı Sahra', countryid: 'EH'},
    {text: 'Belçika', countryid: 'BE'},
    {text: 'Belize', countryid: 'BZ'},
    {text: 'Benin', countryid: 'BJ'},
    {text: 'Bermuda', countryid: 'BM'},
    {text: 'Beyaz Rusya', countryid: 'BY'},
    {text: 'Bhutan', countryid: 'BT'},
    {text: 'Birleşik Arap Emirlikleri', countryid: 'AE'},
    {text: 'Bolivya', countryid: 'BO'},
    {text: 'Bosna-Hersek', countryid: 'BA'},
    {text: 'Botsvana', countryid: 'BW'},
    {text: 'Bouvet Adası', countryid: 'BV'},
    {text: 'Brezilya', countryid: 'BR'},
    {text: 'Britanya Hint Okyanusu Toprakları ', countryid: 'IO'},
    {text: 'Britanya Virjin Adaları', countryid: 'VG'},
    {text: 'Brunei', countryid: 'BN'},
    {text: 'Bulgaristan', countryid: 'BG'},
    {text: 'Burkina Faso', countryid: 'BF'},
    {text: 'Burundi', countryid: 'BI'},
    {text: 'Çad', countryid: 'TD'},
    {text: 'Cayman Adaları', countryid: 'KY'},
    {text: 'Cebelitarık', countryid: 'GI'},
    {text: 'Çek Cumhuriyeti', countryid: 'CZ'},
    {text: 'Cezayir', countryid: 'DZ'},
    {text: 'Christmas Adası', countryid: 'CX'},
    {text: 'Cibuti', countryid: 'DJ'},
    {text: 'Çin', countryid: 'CN'},
    {text: 'Clipperton Adası', countryid: 'CP'},
    {text: 'Cocos Adaları', countryid: 'CC'},
    {text: 'Cook Adaları', countryid: 'CK'},
    {text: 'Curaçao', countryid: 'CW'},
    {text: 'Danimarka', countryid: 'DK'},
    {text: 'Doğu Timor', countryid: 'TL'},
    {text: 'Dominik Cumhuriyeti', countryid: 'DO'},
    {text: 'Dominika', countryid: 'DM'},
    {text: 'Ekvador', countryid: 'EC'},
    {text: 'Ekvator Ginesi', countryid: 'GQ'},
    {text: 'El Salvador', countryid: 'SV'},
    {text: 'Endonezya', countryid: 'ID'},
    {text: 'Eritre', countryid: 'ER'},
    {text: 'Ermenistan', countryid: 'AM'},
    {text: 'Estonya', countryid: 'EE'},
    {text: 'Esvatini', countryid: 'SZ'},
    {text: 'Etiyopya', countryid: 'ET'},
    {text: 'Falkland Adaları', countryid: 'FK'},
    {text: 'Faroe Adaları', countryid: 'FO'},
    {text: 'Fas', countryid: 'MA'},
    {text: 'Fiji', countryid: 'FJ'},
    {text: 'Fildişi Sahili', countryid: 'CI'},
    {text: 'Filipinler', countryid: 'PH'},
    {text: 'Filistin', countryid: 'PS'},
    {text: 'Finlandiya', countryid: 'FI'},
    {text: 'Fransa', countryid: 'FR'},
    {text: 'Fransız Güney ve Antarktika Toprakları', countryid: 'TF'},
    {text: 'Fransız Polinezyası', countryid: 'PF'},
    {text: 'Gabon', countryid: 'GA'},
    {text: 'Gambiya', countryid: 'GM'},
    {text: 'Gana', countryid: 'GH'},
    {text: 'Gine', countryid: 'GN'},
    {text: 'Gine Bissau', countryid: 'GW'},
    {text: 'Grenada', countryid: 'GD'},
    {text: 'Grönland', countryid: 'GL'},
    {text: 'Guadeloupe', countryid: 'GP'},
    {text: 'Guam', countryid: 'GU'},
    {text: 'Guatemala', countryid: 'GT'},
    {text: 'Guernsey', countryid: 'GG'},
    {text: 'Güney Afrika', countryid: 'ZA'},
    {text: 'Güney Georgia ve Güney Sandwich Adaları', countryid: 'GS'},
    {text: 'Güney Kore', countryid: 'KR'},
    {text: 'Güney Sudan', countryid: 'SS'},
    {text: 'Gürcistan', countryid: 'GE'},
    {text: 'Guyana', countryid: 'GF'},
    {text: 'Guyana', countryid: 'GY'},
    {text: 'Haiti', countryid: 'HT'},
    {text: 'Heard Adası ve McDonald Adaları', countryid: 'HM'},
    {text: 'Hindistan', countryid: 'IN'},
    {text: 'Hırvatistan', countryid: 'HR'},
    {text: 'Hollanda', countryid: 'NL'},
    {text: 'Honduras', countryid: 'HN'},
    {text: 'Hong Kong', countryid: 'HK'},
    {text: 'Ingiltere', countryid: 'GB'},
    {text: 'Irak', countryid: 'IQ'},
    {text: 'İran', countryid: 'IR'},
    {text: 'İrlanda', countryid: 'IE'},
    {text: 'İspanya', countryid: 'ES'},
    {text: 'İsrail', countryid: 'IL'},
    {text: 'İsveç', countryid: 'SE'},
    {text: 'İsviçre', countryid: 'CH'},
    {text: 'İtalya', countryid: 'IT'},
    {text: 'İzlanda', countryid: 'IS'},
    {text: 'Jamaika', countryid: 'JM'},
    {text: 'Japonya', countryid: 'JP'},
    {text: 'Jersey', countryid: 'JE'},
    {text: 'Kamboçya', countryid: 'KH'},
    {text: 'Kamerun', countryid: 'CM'},
    {text: 'Kanada', countryid: 'CA'},
    {text: 'Karadağ', countryid: 'ME'},
    {text: 'Katar', countryid: 'QA'},
    {text: 'Kazakistan', countryid: 'KZ'},
    {text: 'Kenya', countryid: 'KE'},
    {text: 'Kıbrıs', countryid: 'CY'},
    {text: 'Kırgızistan', countryid: 'KG'},
    {text: 'Kiribati', countryid: 'KI'},
    {text: 'Kolombiya', countryid: 'CO'},
    {text: 'Komorlar', countryid: 'KM'},
    {text: 'Kongo', countryid: 'CD'},
    {text: 'Kongo Cumhuriyeti', countryid: 'CG'},
    {text: 'Kosova', countryid: 'XK'},
    {text: 'Kosta Rika', countryid: 'CR'},
    {text: 'Küba', countryid: 'CU'},
    {text: 'Kuveyt', countryid: 'KW'},
    {text: 'Kuzey Kore', countryid: 'KP'},
    {text: 'Kuzey Makedonya', countryid: 'MK'},
    {text: 'Kuzey Mariana Adaları', countryid: 'MP'},
    {text: 'Laos', countryid: 'LA'},
    {text: 'Lesoto', countryid: 'LS'},
    {text: 'Letonya', countryid: 'LV'},
    {text: 'Liberya', countryid: 'LR'},
    {text: 'Libya', countryid: 'LY'},
    {text: 'Lihtenştayn', countryid: 'LI'},
    {text: 'Litvanya', countryid: 'LT'},
    {text: 'Lübnan', countryid: 'LB'},
    {text: 'Lüksemburg', countryid: 'LU'},
    {text: 'Macaristan', countryid: 'HU'},
    {text: 'Madagaskar', countryid: 'MG'},
    {text: 'Makao', countryid: 'MO'},
    {text: 'Malavi', countryid: 'MW'},
    {text: 'Maldivler', countryid: 'MV'},
    {text: 'Malezya', countryid: 'MY'},
    {text: 'Mali', countryid: 'ML'},
    {text: 'Malta', countryid: 'MT'},
    {text: 'Man Adası', countryid: 'IM'},
    {text: 'Marshall Adaları', countryid: 'MH'},
    {text: 'Martinique', countryid: 'MQ'},
    {text: 'Mauritius', countryid: 'MU'},
    {text: 'Mayotte', countryid: 'YT'},
    {text: 'Meksika', countryid: 'MX'},
    {text: 'Mikronezya', countryid: 'FM'},
    {text: 'Mısır', countryid: 'EG'},
    {text: 'Moğolistan', countryid: 'MN'},
    {text: 'Moldova', countryid: 'MD'},
    {text: 'Monako', countryid: 'MC'},
    {text: 'Montserrat', countryid: 'MS'},
    {text: 'Moritanya', countryid: 'MR'},
    {text: 'Mozambik', countryid: 'MZ'},
    {text: 'Myanmar/Burma', countryid: 'MM'},
    {text: 'Namibya', countryid: 'NA'},
    {text: 'Nauru', countryid: 'NR'},
    {text: 'Nepal', countryid: 'NP'},
    {text: 'Nijer', countryid: 'NE'},
    {text: 'Nijerya', countryid: 'NG'},
    {text: 'Nikaragua', countryid: 'NI'},
    {text: 'Niue', countryid: 'NU'},
    {text: 'Norfolk Adası', countryid: 'NF'},
    {text: 'Norveç', countryid: 'NO'},
    {text: 'Orta Afrika', countryid: 'CF'},
    {text: 'Özbekistan', countryid: 'UZ'},
    {text: 'Pakistan', countryid: 'PK'},
    {text: 'Palau', countryid: 'PW'},
    {text: 'Panama', countryid: 'PA'},
    {text: 'Papua Yeni Gine', countryid: 'PG'},
    {text: 'Paraguay', countryid: 'PY'},
    {text: 'Peru', countryid: 'PE'},
    {text: 'Pitcairn Adaları', countryid: 'PN'},
    {text: 'Polonya', countryid: 'PL'},
    {text: 'Portekiz', countryid: 'PT'},
    {text: 'Porto Riko', countryid: 'PR'},
    {text: 'Réunion', countryid: 'RE'},
    {text: 'Romanya', countryid: 'RO'},
    {text: 'Ruanda', countryid: 'RW'},
    {text: 'Rusya', countryid: 'RU'},
    {text: 'Saint Barthélemy', countryid: 'BL'},
    {text: 'Saint Helena', countryid: 'SH'},
    {text: 'Saint Kitts ve Nevis', countryid: 'KN'},
    {text: 'Saint Lucia', countryid: 'LC'},
    {text: 'Saint Pierre ve Miquelon', countryid: 'PM'},
    {text: 'Saint Vincent ve Grenadinler', countryid: 'VC'},
    {text: 'Samoa', countryid: 'WS'},
    {text: 'San Marino', countryid: 'SM'},
    {text: 'São Tomé ve Príncipe', countryid: 'ST'},
    {text: 'Senegal', countryid: 'SN'},
    {text: 'Seyşeller', countryid: 'SC'},
    {text: 'Sierra Leone', countryid: 'SL'},
    {text: 'Şili', countryid: 'CL'},
    {text: 'Singapur', countryid: 'SG'},
    {text: 'Sint Maarten', countryid: 'SX'},
    {text: 'Sırbistan', countryid: 'RS'},
    {text: 'Slovakya', countryid: 'SK'},
    {text: 'Slovenya', countryid: 'SI'},
    {text: 'Solomon Adaları', countryid: 'SB'},
    {text: 'Somali', countryid: 'SO'},
    {text: 'Sri Lanka', countryid: 'LK'},
    {text: 'St. Martin', countryid: 'MF'},
    {text: 'Sudan', countryid: 'SD'},
    {text: 'Surinam', countryid: 'SR'},
    {text: 'Suriye', countryid: 'SY'},
    {text: 'Suudi Arabistan', countryid: 'SA'},
    {text: 'Svalbard ve Jan Mayen', countryid: 'SJ'},
    {text: 'Tacikistan', countryid: 'TJ'},
    {text: 'Tanzanya', countryid: 'TZ'},
    {text: 'Tayland', countryid: 'TH'},
    {text: 'Tayvan', countryid: 'TW'},
    {text: 'Togo', countryid: 'TG'},
    {text: 'Tokelau', countryid: 'TK'},
    {text: 'Tonga', countryid: 'TO'},
    {text: 'Trinidad ve Tobago', countryid: 'TT'},
    {text: 'Tunus', countryid: 'TN'},
    {text: 'Türkiye', countryid: 'TR'},
    {text: 'Türkmenistan', countryid: 'TM'},
    {text: 'Turks ve Caicos Adaları', countryid: 'TC'},
    {text: 'Tuvalu', countryid: 'TV'},
    {text: 'Uganda', countryid: 'UG'},
    {text: 'Ukrayna', countryid: 'UA'},
    {text: 'Umman', countryid: 'OM'},
    {text: 'Ürdün', countryid: 'JO'},
    {text: 'Uruguay', countryid: 'UY'},
    {text: 'Vanuatu', countryid: 'VU'},
    {text: 'Vatikan', countryid: 'VA'},
    {text: 'Venezuela', countryid: 'VE'},
    {text: 'Vietnam', countryid: 'VN'},
    {text: 'Wallis ve Futuna (Fransa)', countryid: 'WF'},
    {text: 'Yemen', countryid: 'YE'},
    {text: 'Yeni Kaledonya', countryid: 'NC'},
    {text: 'Yeni Zelanda', countryid: 'NZ'},
    {text: 'Yeşil Burun Adaları', countryid: 'CV'},
    {text: 'Yunanistan', countryid: 'EL'},
    {text: 'Zambiya', countryid: 'ZM'},
    {text: 'Zimbabve', countryid: 'ZW'}
]


const countries_en = [
    {text: 'Afghanistan', countryid: 'AF'},
    {text: 'Åland Islands', countryid: 'AX'},
    {text: 'Albania', countryid: 'AL'},
    {text: 'Algeria', countryid: 'DZ'},
    {text: 'American Samoa', countryid: 'AS'},
    {text: 'Andorra', countryid: 'AD'},
    {text: 'Angola', countryid: 'AO'},
    {text: 'Anguilla', countryid: 'AI'},
    {text: 'Antarctica', countryid: 'AQ'},
    {text: 'Antigua And Barbuda', countryid: 'AG'},
    {text: 'Argentina', countryid: 'AR'},
    {text: 'Armenia', countryid: 'AM'},
    {text: 'Aroe Islands', countryid: 'FO'},
    {text: 'Aruba', countryid: 'AW'},
    {text: 'Australia', countryid: 'AU'},
    {text: 'Austria', countryid: 'AT'},
    {text: 'Azerbaijan', countryid: 'AZ'},
    {text: 'Bahamas', countryid: 'BS'},
    {text: 'Bahrain', countryid: 'BH'},
    {text: 'Bangladesh', countryid: 'BD'},
    {text: 'Barbados', countryid: 'BB'},
    {text: 'Belarus', countryid: 'BY'},
    {text: 'Belgium', countryid: 'BE'},
    {text: 'Belize', countryid: 'BZ'},
    {text: 'Benin', countryid: 'BJ'},
    {text: 'Bermuda', countryid: 'BM'},
    {text: 'Bhutan', countryid: 'BT'},
    {text: 'Bosnia And Herzegovina', countryid: 'BA'},
    {text: 'Botswana', countryid: 'BW'},
    {text: 'Bouvet Island', countryid: 'BV'},
    {text: 'Brazil', countryid: 'BR'},
    {text: 'British Indian Ocean Territory', countryid: 'IO'},
    {text: 'British Virgin Islands', countryid: 'VG'},
    {text: 'Brunei Darussalam', countryid: 'BN'},
    {text: 'Bulgaria', countryid: 'BG'},
    {text: 'Burkina Faso', countryid: 'BF'},
    {text: 'Burundi', countryid: 'BI'},
    {text: 'Cambodia', countryid: 'KH'},
    {text: 'Cameroon', countryid: 'CM'},
    {text: 'Canada', countryid: 'CA'},
    {text: 'Cape Verde', countryid: 'CV'},
    {text: 'Cayman Islands', countryid: 'KY'},
    {text: 'Central Africa', countryid: 'CF'},
    {text: 'Chad', countryid: 'TD'},
    {text: 'Chile', countryid: 'CL'},
    {text: 'China', countryid: 'CN'},
    {text: 'Clipperton Island', countryid: 'CP'},
    {text: 'Cocos (Keeling) Islands', countryid: 'CC'},
    {text: 'Colombia', countryid: 'CO'},
    {text: 'Comoros', countryid: 'KM'},
    {text: 'Congo', countryid: 'CD'},
    {text: 'Congo', countryid: 'CG'},
    {text: 'Cook Islands', countryid: 'CK'},
    {text: 'Costa Rica', countryid: 'CR'},
    {text: 'Côte D`Ivoire', countryid: 'CI'},
    {text: 'Croatia', countryid: 'HR'},
    {text: 'Cuba', countryid: 'CU'},
    {text: 'Curaçao', countryid: 'CW'},
    {text: 'Cyprus', countryid: 'CY'},
    {text: 'Czechia', countryid: 'CZ'},
    {text: 'Denmark', countryid: 'DK'},
    {text: 'Djibouti', countryid: 'DJ'},
    {text: 'Dominica', countryid: 'DM'},
    {text: 'Dominican Republic', countryid: 'DO'},
    {text: 'Ecuador', countryid: 'EC'},
    {text: 'Egypt', countryid: 'EG'},
    {text: 'El Salvador', countryid: 'SV'},
    {text: 'England', countryid: 'GB'},
    {text: 'Equatorial Guinea', countryid: 'GQ'},
    {text: 'Eritrea', countryid: 'ER'},
    {text: 'Estonia', countryid: 'EE'},
    {text: 'Eswatini', countryid: 'SZ'},
    {text: 'Ethiopia', countryid: 'ET'},
    {text: 'Falkland Islands (Malvinas)', countryid: 'FK'},
    {text: 'Fiji', countryid: 'FJ'},
    {text: 'Finland', countryid: 'FI'},
    {text: 'France', countryid: 'FR'},
    {text: 'French Polynesia', countryid: 'PF'},
    {text: 'French Southern Territories', countryid: 'TF'},
    {text: 'Gabon', countryid: 'GA'},
    {text: 'Gambia', countryid: 'GM'},
    {text: 'Georgia', countryid: 'GE'},
    {text: 'Germany', countryid: 'DE'},
    {text: 'Ghana', countryid: 'GH'},
    {text: 'Gibraltar', countryid: 'GI'},
    {text: 'Greece', countryid: 'GR'},
    {text: 'Greenland', countryid: 'GL'},
    {text: 'Grenada', countryid: 'GD'},
    {text: 'Guadeloupe', countryid: 'GP'},
    {text: 'Guam', countryid: 'GU'},
    {text: 'Guatemala', countryid: 'GT'},
    {text: 'Guernsey', countryid: 'GG'},
    {text: 'Guiana', countryid: 'GF'},
    {text: 'Guinea', countryid: 'GN'},
    {text: 'Guinea-Bissau', countryid: 'GW'},
    {text: 'Guyana', countryid: 'GY'},
    {text: 'Haiti', countryid: 'HT'},
    {text: 'Heard Island And Mcdonald Islands', countryid: 'HM'},
    {text: 'Honduras', countryid: 'HN'},
    {text: 'Hong Kong', countryid: 'HK'},
    {text: 'Hristmas Island', countryid: 'CX'},
    {text: 'Hungary', countryid: 'HU'},
    {text: 'Iceland', countryid: 'IS'},
    {text: 'India', countryid: 'IN'},
    {text: 'Indonesia', countryid: 'ID'},
    {text: 'Iran', countryid: 'IR'},
    {text: 'Iraq', countryid: 'IQ'},
    {text: 'Ireland', countryid: 'IE'},
    {text: 'Isle Of Man', countryid: 'IM'},
    {text: 'Israel', countryid: 'IL'},
    {text: 'Italy', countryid: 'IT'},
    {text: 'Jamaica', countryid: 'JM'},
    {text: 'Japan', countryid: 'JP'},
    {text: 'Jersey', countryid: 'JE'},
    {text: 'Jordan', countryid: 'JO'},
    {text: 'Kazakhstan', countryid: 'KZ'},
    {text: 'Kenya', countryid: 'KE'},
    {text: 'Kiribati', countryid: 'KI'},
    {text: 'Kosovo', countryid: 'XK'},
    {text: 'Kuwait', countryid: 'KW'},
    {text: 'Kyrgyzstan', countryid: 'KG'},
    {text: 'Lao', countryid: 'LA'},
    {text: 'Latvia', countryid: 'LV'},
    {text: 'Lebanon', countryid: 'LB'},
    {text: 'Lesotho', countryid: 'LS'},
    {text: 'Liberia', countryid: 'LR'},
    {text: 'Libya', countryid: 'LY'},
    {text: 'Liechtenstein', countryid: 'LI'},
    {text: 'Lithuania', countryid: 'LT'},
    {text: 'Luxembourg', countryid: 'LU'},
    {text: 'Macao', countryid: 'MO'},
    {text: 'Macedonia', countryid: 'MK'},
    {text: 'Madagascar', countryid: 'MG'},
    {text: 'Malawi', countryid: 'MW'},
    {text: 'Malaysia', countryid: 'MY'},
    {text: 'Maldives', countryid: 'MV'},
    {text: 'Mali', countryid: 'ML'},
    {text: 'Malta', countryid: 'MT'},
    {text: 'Marshall Islands', countryid: 'MH'},
    {text: 'Martinique', countryid: 'MQ'},
    {text: 'Mauritania', countryid: 'MR'},
    {text: 'Mauritius', countryid: 'MU'},
    {text: 'Mayotte', countryid: 'YT'},
    {text: 'Mexico', countryid: 'MX'},
    {text: 'Micronesia', countryid: 'FM'},
    {text: 'Moldova', countryid: 'MD'},
    {text: 'Monaco', countryid: 'MC'},
    {text: 'Mongolia', countryid: 'MN'},
    {text: 'Montenegro', countryid: 'ME'},
    {text: 'Montserrat', countryid: 'MS'},
    {text: 'Morocco', countryid: 'MA'},
    {text: 'Mozambique', countryid: 'MZ'},
    {text: 'Myanmar', countryid: 'MM'},
    {text: 'Namibia', countryid: 'NA'},
    {text: 'Nauru', countryid: 'NR'},
    {text: 'Nepal', countryid: 'NP'},
    {text: 'Netherlands', countryid: 'NL'},
    {text: 'New Caledonia', countryid: 'NC'},
    {text: 'New Zealand', countryid: 'NZ'},
    {text: 'Nicaragua', countryid: 'NI'},
    {text: 'Niger', countryid: 'NE'},
    {text: 'Nigeria', countryid: 'NG'},
    {text: 'Niue', countryid: 'NU'},
    {text: 'Norfolk Island', countryid: 'NF'},
    {text: 'North Korea', countryid: 'KP'},
    {text: 'Northern Mariana Islands', countryid: 'MP'},
    {text: 'Norway', countryid: 'NO'},
    {text: 'Olivia', countryid: 'BO'},
    {text: 'Oman', countryid: 'OM'},
    {text: 'Pakistan', countryid: 'PK'},
    {text: 'Palau', countryid: 'PW'},
    {text: 'Palestine', countryid: 'PS'},
    {text: 'Panama', countryid: 'PA'},
    {text: 'Papua New Guinea', countryid: 'PG'},
    {text: 'Paraguay', countryid: 'PY'},
    {text: 'Peru', countryid: 'PE'},
    {text: 'Philippines', countryid: 'PH'},
    {text: 'Pitcairn', countryid: 'PN'},
    {text: 'Poland', countryid: 'PL'},
    {text: 'Portugal', countryid: 'PT'},
    {text: 'Puerto Rico', countryid: 'PR'},
    {text: 'Qatar', countryid: 'QA'},
    {text: 'Réunion', countryid: 'RE'},
    {text: 'Romania', countryid: 'RO'},
    {text: 'Russia', countryid: 'RU'},
    {text: 'Rwanda', countryid: 'RW'},
    {text: 'Saint Barthélemy', countryid: 'BL'},
    {text: 'Saint Helena', countryid: 'SH'},
    {text: 'Saint Kitts And Nevis', countryid: 'KN'},
    {text: 'Saint Lucia', countryid: 'LC'},
    {text: 'Saint Martin', countryid: 'MF'},
    {text: 'Saint Pierre And Miquelon', countryid: 'PM'},
    {text: 'Saint Vincent And The Grenadines', countryid: 'VC'},
    {text: 'Samoa', countryid: 'WS'},
    {text: 'San Marino', countryid: 'SM'},
    {text: 'Sao Tome And Principe', countryid: 'ST'},
    {text: 'Saudi Arabia', countryid: 'SA'},
    {text: 'Senegal', countryid: 'SN'},
    {text: 'Serbia', countryid: 'RS'},
    {text: 'Seychelles', countryid: 'SC'},
    {text: 'Sierra Leone', countryid: 'SL'},
    {text: 'Singapore', countryid: 'SG'},
    {text: 'Sint Maarten', countryid: 'SX'},
    {text: 'Slovakia', countryid: 'SK'},
    {text: 'Slovenia', countryid: 'SI'},
    {text: 'Solomon Islands', countryid: 'SB'},
    {text: 'Somalia', countryid: 'SO'},
    {text: 'South Africa', countryid: 'ZA'},
    {text: 'South Georgia And South Sandwich Islands', countryid: 'GS'},
    {text: 'South Korea', countryid: 'KR'},
    {text: 'South Sudan', countryid: 'SS'},
    {text: 'Spain', countryid: 'ES'},
    {text: 'Sri Lanka', countryid: 'LK'},
    {text: 'Sudan', countryid: 'SD'},
    {text: 'Suriname', countryid: 'SR'},
    {text: 'Svalbard And Jan Mayen', countryid: 'SJ'},
    {text: 'Sweden', countryid: 'SE'},
    {text: 'Switzerland', countryid: 'CH'},
    {text: 'Syria', countryid: 'SY'},
    {text: 'Taiwan', countryid: 'TW'},
    {text: 'Tajikistan', countryid: 'TJ'},
    {text: 'Tanzania', countryid: 'TZ'},
    {text: 'Thailand', countryid: 'TH'},
    {text: 'Timor-Leste', countryid: 'TL'},
    {text: 'Togo', countryid: 'TG'},
    {text: 'Tokelau', countryid: 'TK'},
    {text: 'Tonga', countryid: 'TO'},
    {text: 'Trinidad And Tobago', countryid: 'TT'},
    {text: 'Tunisia', countryid: 'TN'},
    {text: 'Turkey', countryid: 'TR'},
    {text: 'Turkmenistan', countryid: 'TM'},
    {text: 'Turks And Caicos Islands', countryid: 'TC'},
    {text: 'Tuvalu', countryid: 'TV'},
    {text: 'U.S. Virgin Islands', countryid: 'VI'},
    {text: 'Uganda', countryid: 'UG'},
    {text: 'Ukraine', countryid: 'UA'},
    {text: 'United Arab Emirates', countryid: 'AE'},
    {text: 'United States', countryid: 'US'},
    {text: 'United States Minor Outlying Islands', countryid: 'UM'},
    {text: 'Uruguay', countryid: 'UY'},
    {text: 'Uzbekistan', countryid: 'UZ'},
    {text: 'Vanuatu', countryid: 'VU'},
    {text: 'Vatican ', countryid: 'VA'},
    {text: 'Venezuela', countryid: 'VE'},
    {text: 'Viet Nam', countryid: 'VN'},
    {text: 'Wallis And Futuna', countryid: 'WF'},
    {text: 'Western Sahara', countryid: 'EH'},
    {text: 'Yemen', countryid: 'YE'},
    {text: 'Zambia', countryid: 'ZM'},
    {text: 'Zimbabwe', countryid: 'ZW'}
]


