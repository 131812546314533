import {getUser} from "@/util/Auth";
import {getAllSettings, getHSettings, getSettingByFirmaID} from "@/util/settingsUtil";

export const getUserToken = async () => {

    //let user = await getUser(localStorage.getItem("token"));
    let settings = ""
    settings = await getHSettings();


    let username = settings.halalbookingusername;
    let password = settings.halalbookingpassword;

    const token = `${username}:${password}`;
    const encodedToken = Buffer.from(token).toString('base64');

    return encodedToken;
}