<template>
  <div class="div-settingfavorites">
    <v-snackbar
        v-model="notificationsnackbar"
        :timeout="6000"
        :color="notificationcolor"
        :top="true"
        :right="true"
        :vertical="true"
    >
      {{ notificationtext }}
      <v-btn
          color="white"
          text
          @click="notificationsnackbar = false"
      >
        {{ $t("Notification.Schliessen") }}
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-overlay :value="loading">
      <v-progress-circular
          v-if="loading"
          indeterminate
          color="primary"
          :size="50"
          :width="7"
      />
    </v-overlay>
    <div class="bodyheader-accounts">
      <label class="label-bodyheader-accounts">

        <v-icon class="icon-start" color="white">mdi-map-marker-star-outline</v-icon>

        {{ $t("Setting.Favoriten") }}
      </label>
      <span></span>

    </div>


    <div class="body-settingfavorites">
      <v-overlay v-if="overlayPhotoUpload">
        <div class="div-overlay-photo-settingsfavorites">

          <v-btn
              text color="black" style="position: absolute; right: 2px; top: 2px; z-index: 1"
              @click="overlayPhotoUpload = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <h3 class="label-overlay-settingsfavorites">Bild hochladen</h3>
          <div class="div-overlay-photo-settingsfavorites1">
            <div>max. 100 KB</div>
            <input type="file" name="file" @change="this.onFileHandler"/>
            <br/>
            <div className="form-group">
              <Progress
                  max="100" color="success"
                  :value="this.loaded"
              >{{ Math.round(this.loaded, 2) }}%
              </Progress>

              <div class="div-savebtns-settingsfavorites">
                <v-btn @click="overlayPhotoUpload = false" color="error" outlined>Abbrechen</v-btn>
                <v-btn @click="savePhoto" color="var(--Primary)">Speichern</v-btn>
              </div>

            </div>
          </div>
        </div>

      </v-overlay>


      <div style="margin: 20px 0;">
        <h3>

          {{ $t("Setting.FavoritenHinweistext") }}
        </h3>
      </div>
      <br>


      <v-divider></v-divider>


      <!-- -------------------------------- Suche --------------------------------------- -->

      <div style="margin-bottom: 40px">
        <h3 style="margin: 15px 0">
          Für die Suche
        </h3>
        <div>
          <v-text-field
              :label="$t('Setting.Land')"
              :placeholder="$t('Setting.Land')"
              ref="searchtextSearch"
              solo
              clearable
              v-model="searchtextSearch"
              @input="searchSearchLocation"
              @click:clear="clearSearchParameter"
              style="margin-top: 10px"

          >
            <template v-slot:prepend-inner>
              <v-icon color="var(--Primary)">
                mdi-magnify
              </v-icon>
            </template>

          </v-text-field>

        </div>


        <v-progress-linear
            v-if="loadingSearchSearchtext"
            indeterminate
            rounded
            height="6"
        ></v-progress-linear>

        <div class="div-search-results-settingfavorites" v-if="searchtextSearch !== '' ">
          <v-list
              nav
              dense
          >
            <v-list-item-group
                color="primary"
            >
              <!--              :key="index"-->
              <v-list-item
                  v-for="(item, index) in  resulttextSearch.results"
                  v-if="item['search-type'] === 'location' && (item.type === 'country' || item.type ==='city')"
                  @click="setFavParameterIndex(index)"
              >
                <v-list-item-icon>
                  <v-icon>mdi-map-marker</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>


        </div>
        <div v-if="resulttext_TR !== '' && searchtextSearch !== '' ">
          <v-icon color="green">mdi-check</v-icon>

          <label>{{ resulttext_EN }}</label>
          <label style="display: none">{{ resulttext_TR }}</label>
          <label style="display: none">{{ resulttext_DE }}</label>
        </div>

        <div v-else>
          <!--          <template v-if="settingFavcountry.length <8">-->
          <template>
            <v-icon color="error">mdi-close</v-icon>
            <label>Land oder Stadt auswählen</label>
          </template>
        </div>

        <v-btn @click="saveFavSearch" color="var(--Primary)" class="vbtn-savefav-settingsfavorites">
          {{ $t("Button.Speichern") }}
        </v-btn>


        <div style="text-align: center">
          <h3 v-if="settingFavcountry.length <8">
            Bitte Orte für die Suche eingeben
          </h3>

          <FavRow
              :favPlace="settingFavsearch"
              :pLang="pLang"
              favCategory="search"
          />
        </div>


      </div>


      <v-divider></v-divider>


      <!-- -------------------------------- Hotels --------------------------------------- -->


      <div>
        <h3 style="margin: 15px 0">
          {{ $t("Setting.BeliebteHotels") }}
        </h3>
        <v-text-field
            :label="$t('Setting.Hotel')"
            :placeholder="$t('Setting.Hotel')"
            ref="searchtextHotel"
            solo
            clearable
            v-model="searchtextHotel"
            @input="searchFavHotels"
            @click:clear="clearSearchParameter"
            style="margin-top: 10px"
            v-if="settingFavHotel.length <12 && ownplaces ===''"
        >
          <template v-slot:prepend-inner>
            <v-icon color="var(--Primary)">
              mdi-magnify
            </v-icon>
          </template>

        </v-text-field>

        <v-progress-linear
            v-if="loadingSearchHotel"
            indeterminate
            rounded
            height="6"
        ></v-progress-linear>

        <div class="div-search-results-settingfavorites" v-if="searchtextHotel !== '' && ownplaces ===''">
          <v-list
              nav
              dense
          >
            <v-list-item-group
                color="primary"
            >
              <!--              :key="index"-->
              <v-list-item
                  v-for="(item, index) in  resulttextHotel.results"
                  v-if="item['search-type'] ==='property'"
                  @click="setFavParameterIndex(index)"
              >
                <v-list-item-icon>
                  <v-icon>mdi-map-marker</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>


        </div>

        <div v-if="resulttext_TR !== '' && searchtextHotel !== '' && ownplaces ===''">
          <v-icon color="green">mdi-check</v-icon>

          <label>{{ resulttext_EN }}</label>
          <label style="display: none">{{ resulttext_TR }}</label>
          <label style="display: none">{{ resulttext_DE }}</label>
        </div>

        <div v-else>
          <template v-if="settingFavHotel.length <12 && ownplaces ===''">
            <v-icon color="error">mdi-close</v-icon>
            <label>Kein Hotel ausgewählt</label>
          </template>
        </div>

        <div v-if="settingFavHotel.length <12">

          <v-select
              label="Eigene Hotels"
              :items="ownPlaces"
              item-text="place_name_de"
              item-value="place_id"
              v-model="selectedOwnPlace"
              @change="clearSearchParameter"
              outlined
          />

          <!--          <v-text-field-->
          <!--              label="Eigene Hotels"-->
          <!--              ref="searchtextHotel"-->
          <!--              solo-->
          <!--              clearable-->
          <!--              v-model="searchOwnPlace"-->
          <!--              @input="searchOwnHotels"-->
          <!--              @click:clear="clearOwnPlaceParameter"-->
          <!--              style="margin-top: 10px"-->
          <!--              v-if="settingFavHotel.length <12"-->
          <!--          >-->
          <!--            <template v-slot:prepend-inner>-->
          <!--              <v-icon color="var(&#45;&#45;Primary)">-->
          <!--                mdi-magnify-->
          <!--              </v-icon>-->
          <!--            </template>-->
          <!---->
          <!--          </v-text-field>-->
          <div v-if="ownplaces !==''">

            <v-icon color="green">mdi-check</v-icon>
            {{ ownplaces.place_name_de }}
          </div>
        </div>


        <v-btn @click="saveFavHotels" color="var(--Primary)" class="vbtn-savefav-settingsfavorites">
          {{ $t("Button.Speichern") }}
        </v-btn>
      </div>


      <div style="text-align: center">


        <h3 v-if="settingFavHotel.length <12">
          Bitte 12 Hotels anlegen!
        </h3>

        <FavRow
            :favPlace="settingFavHotel"
            :pLang="pLang"
            favCategory="hotel"
        />

      </div>


      <br>
      <v-divider></v-divider>
      <br>


      <!-- -------------------------------- Regionen --------------------------------------- -->


      <div>

        <h3 style="text-align: start;margin: 15px 0">
          {{ $t("Setting.BeliebteRegionen") }}
        </h3>
        <v-text-field
            :label="$t('Setting.Region')"
            :placeholder="$t('Setting.Region')"
            ref="searchtextLocRegion"
            solo
            clearable
            v-model="searchtextLocRegion"
            @input="searchFavLocRegion"
            @click:clear="clearSearchParameter"
            style="margin-top: 10px"
            v-if="settingFavLoc.length <6"
        >
          <template v-slot:prepend-inner>
            <v-icon color="var(--Primary)">
              mdi-magnify
            </v-icon>
          </template>

        </v-text-field>

        <v-progress-linear
            v-if="loadingSearchLocRegion"
            indeterminate
            rounded
            height="6"
        ></v-progress-linear>

        <div class="div-search-results-settingfavorites" v-if="searchtextLocRegion !== '' ">
          <v-list
              nav
              dense
          >
            <v-list-item-group
                color="primary"
            >
              <!--              :key="index"-->
              <v-list-item
                  v-for="(item, index) in  resulttextLocRegion.results"

                  v-if="item['search-type'] ==='location' && item.type ==='region'"
                  @click="setFavParameterIndex(index)"
              >
                <v-list-item-icon>
                  <v-icon>mdi-map-marker</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>


        </div>

        <div v-if="resulttext_TR !== '' && searchtextLocRegion !== '' ">
          <v-icon color="green">mdi-check</v-icon>

          <label>{{ resulttext_EN }}</label>
          <label style="display: none">{{ resulttext_TR }}</label>
          <label style="display: none">{{ resulttext_DE }}</label>
        </div>

        <div v-else>
          <template v-if="settingFavLoc.length <6">
            <v-icon color="error">mdi-close</v-icon>
            <label>Keine Region ausgewählt</label>
          </template>
        </div>

        <v-btn @click="saveFavLocRegion" color="var(--Primary)" class="vbtn-savefav-settingsfavorites">
          {{ $t("Button.Speichern") }}
        </v-btn>

      </div>

      <div style="text-align: center">

        <h3 v-if="settingFavLoc.length <6">
          Bitte 6 Regionen anlegen!
        </h3>

        <FavRow
            :favPlace="settingFavLoc"
            :pLang="pLang"
            favCategory="region"
        />
      </div>

      <br>
      <v-divider></v-divider>
      <br>


      <!-- -------------------------------- Länder --------------------------------------- -->


      <div>
        <h3 style="text-align: start;margin: 15px 0">
          {{ $t("Setting.BeliebteLaender") }}
        </h3>
        <v-text-field
            :label="$t('Setting.Land')"
            :placeholder="$t('Setting.Land')"
            ref="searchtextLocCountry"
            solo
            clearable
            v-model="searchtextLocCountry"
            @input="searchFavLocCountry"
            @click:clear="clearSearchParameter"
            style="margin-top: 10px"
            v-if="settingFavcountry.length <8"
        >
          <template v-slot:prepend-inner>
            <v-icon color="var(--Primary)">
              mdi-magnify
            </v-icon>
          </template>

        </v-text-field>

        <v-progress-linear
            v-if="loadingSearchLocCountry"
            indeterminate
            rounded
            height="6"
        ></v-progress-linear>

        <div class="div-search-results-settingfavorites" v-if="searchtextLocCountry !== '' ">
          <v-list
              nav
              dense
          >
            <v-list-item-group
                color="primary"
            >
              <!--              :key="index"-->
              <v-list-item
                  v-for="(item, index) in  resulttextLocCountry.results"
                  v-if="item['search-type'] === 'location' && item.type === 'country'"
                  @click="setFavParameterIndex(index)"
              >
                <v-list-item-icon>
                  <v-icon>mdi-map-marker</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>


        </div>
        <div v-if="resulttext_TR !== '' && searchtextLocCountry !== '' ">
          <v-icon color="green">mdi-check</v-icon>

          <label>{{ resulttext_EN }}</label>
          <label style="display: none">{{ resulttext_TR }}</label>
          <label style="display: none">{{ resulttext_DE }}</label>
        </div>

        <div v-else>
          <template v-if="settingFavcountry.length <8">
            <v-icon color="error">mdi-close</v-icon>
            <label>Kein Land ausgewählt</label>
          </template>
        </div>

        <v-btn @click="saveFavLocCountry" color="var(--Primary)" class="vbtn-savefav-settingsfavorites">
          {{ $t("Button.Speichern") }}
        </v-btn>

      </div>

      <div style="text-align: center">
        <h3 v-if="settingFavcountry.length <8">
          Bitte 8 Länder anlegen!
        </h3>

        <FavRow
            :favPlace="settingFavcountry"
            :pLang="pLang"
            favCategory="country"
        />
      </div>


      <br>
      <v-divider></v-divider>
      <br>

      <div>

        <h3 style="text-align: start;margin: 15px 0">
          {{ $t("Setting.BeliebteStaedte") }}

        </h3>
        <v-text-field
            :label="$t('Setting.Stadt')"
            :placeholder="$t('Setting.Stadt')"
            ref="searchtextLocCity"
            solo
            clearable
            v-model="searchtextLocCity"
            @input="searchFavLocCity"
            @click:clear="clearSearchParameter"
            style="margin-top: 10px"
            v-if="settingFavcity.length <6"
        >
          <template v-slot:prepend-inner>
            <v-icon color="var(--Primary)">
              mdi-magnify
            </v-icon>
          </template>

        </v-text-field>

        <v-progress-linear
            v-if="loadingSearchLocCity"
            indeterminate
            rounded
            height="6"
        ></v-progress-linear>

        <div class="div-search-results-settingfavorites" v-if="searchtextLocCity !== '' ">
          <v-list
              nav
              dense
          >
            <v-list-item-group
                color="primary"
            >

              <!--              :key="index"-->
              <v-list-item
                  v-for="(item, index) in  resulttextLocCity.results"
                  @click="setFavParameterIndex(index)"
              >
                <v-list-item-icon>
                  <v-icon>mdi-map-marker</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>


        </div>

        <div v-if="resulttext_TR !== '' && searchtextLocCity !== ''">
          <v-icon color="green">mdi-check</v-icon>

          <label>{{ resulttext_EN }}</label>
          <label style="display: none">{{ resulttext_TR }}</label>
          <label style="display: none">{{ resulttext_DE }}</label>
        </div>
        <div v-else>
          <template v-if="settingFavcity.length <6">
            <v-icon color="error">mdi-close</v-icon>
            <label>Keine Stadt ausgewählt</label>
          </template>
        </div>


        <v-btn @click="saveFavLocCity" color="var(--Primary)" class="vbtn-savefav-settingsfavorites">
          {{ $t("Button.Speichern") }}
        </v-btn>

      </div>

      <div style="text-align: center">
        <h3 v-if="settingFavcity.length <6">
          Bitte 6 Städte anlegen!
        </h3>

        <FavRow
            :favPlace="settingFavcity"
            :pLang="pLang"
            favCategory="city"
        />

      </div>


      <br>
      <v-divider></v-divider>
      <br>


    </div>

  </div>
</template>

<script>
import {halalsearch, halalsearchDE, halalsearchEN, halalsearchTR} from "@/util/searchUtil";
import {getAllSettings, updateSettings} from "@/util/settingsUtil";
import {onePlaceByID} from "@/util/halalapi";
import {Container, Draggable} from 'vue-dndrop';
import {fileupload} from "@/util/fileUtil";
import {deleteFile} from "@/util/userinfoDBUtil";
import FavRow from "@/components/card/FavRow";
import {getAllOwnPlaces, getOwnPlaceByCY, getOwnPlaces} from "@/util/OwnPlace";


export default {
  name: "SettingsFavorites",
  props: [
    'pLang',
    'pRolle',
    'pUser',
    'pUserinfo'
  ],
  components: {

    FavRow,
    Container,
    Draggable
  },

  data() {
    return {
      notificationsnackbar: false,
      notificationtext: "",
      notificationcolor: "grey",

      loading: false,
      loadingSearchSearchtext: false,
      loadingSearchHotel: false,
      loadingSearchLocRegion: false,
      loadingSearchLocCountry: false,
      loadingSearchLocCity: false,

      //Eigene Hotels
      searchOwnPlace: "",
      ownplaces: "",
      ownPlaces: [],
      selectedOwnPlace: "",


      searchtextSearch: "",
      resulttextSearch: "",

      searchtextHotel: "",
      resulttextHotel: "",


      searchtextLocRegion: "",
      resulttextLocRegion: "",

      searchtextLocCountry: "",
      resulttextLocCountry: "",

      searchtextLocCity: "",
      resulttextLocCity: "",


      resulttext_EN: "",
      resulttext_TR: "",
      resulttext_DE: "",


      searchParameter: "",
      searchParameterID: "",
      searchParameterType: "",

      showselectedParameter: "",

      model_de: "",
      model_tr: "",
      model_en: "",
      photo: "",

      placeinfos_de: "",
      placeinfos_tr: "",
      placeinfos_en: "",
      min_price: [],

      settingFavsearch: [],
      settingFavHotel: [],
      settingFavLoc: [],
      settingFavcountry: [],
      settingFavcity: [],
      settings: "",

      overlayPhotoUpload: false,
      selectedFile: "",
      loaded: "",

      item: "",
      error: "",
      shortDescr: [],
      properties_count: "",
      fav_type: "",


    }
  },
  methods: {

    onDrop(source, dropResult) {
      let removedIndex = dropResult["removedIndex"];
      let addedIndex = dropResult["addedIndex"];

      if (removedIndex !== null && addedIndex !== null) {
        let itemToAdd = source.splice(removedIndex, 1)[0];
        source.splice(addedIndex, 0, itemToAdd);
      }
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },


    notification(text, color) {
      this.notificationsnackbar = true;
      this.notificationtext = text;
      this.notificationcolor = color;
    },

    async searchOwnHotels() {
      this.clearSearchParameter();

      this.resulttext_TR = '';
      this.resulttext_EN = '';
      this.resulttext_DE = '';
      this.ownplaces = await getOwnPlaceByCY(this.searchOwnPlace);

    },

    async clearOwnPlaceParameter() {

      this.ownplaces = "";
    },


    onFileHandler(event) {
      let file = event.target.files[0]
      if (this.checkMimeType(event) && this.checkFileSize(event)) {
        // if return true allow to setState
        this.selectedFile = file;
      }
    },

    checkMimeType(event) {
      //getting file object
      let file = event.target.files[0];
      //define message container
      let err = ''
      // list allow mime type
      const types = ['image/png', 'image/jpeg', 'image/gif']
      // loop access array

      // compare file type find doesn't matach
      if (types.every(type => file.type !== type)) {
        // create error message and assign to container
        err += file.type + ' is not a supported format\n';


        this.notification(this.$t("NotificationFormatfalsch"), 'error');


      }


      if (err !== '') { // if message not same old that mean has error
        event.target.value = null // discard selected file
        return false;
      }
      return true;

    },

    checkFileSize(event) {
      let file = event.target.files[0]
      let size = 100000
      let err = "";

      if (file.size > size) {
        err += file.type + 'Datei zu groß, bitte die maximale größe beachten.\n';

        this.notification(this.$t("NotificationBildzugross100kb"), 'error');


      } else {
        this.error = "";
      }

      if (err !== '') {
        event.target.value = null
        this.error = err;
        return false
      }
      return true;
    },

    async onClickHandler() {
      if (this.error === "" && this.selectedFile !== null) {
        // await this.deletePhoto();
        let fileUploadData = await fileupload(this.selectedFile);
        this.photo = {picture: fileUploadData.filename, pictureinfo: fileUploadData.originalname};
      }

    },


    async deletePhoto(item) {
      if (item === undefined) {
        item = this.item;
      }

      if (item !== undefined) {

        if (item !== null) {
          let foto = item.photo;

          foto = foto.replace("//", "");
          foto = foto.split("/")[1];

          let res = await deleteFile(foto);

          if (res.status === 200) {
            this.notification("Foto gelöscht", "green");

            await new Promise(resolve => setTimeout(resolve, 500));
            // location.reload();

          }
        } else {
          this.notification("Fehler", "error");
        }

      }
    },


    async savePhoto() {
      await this.onClickHandler();

      this.settingFavLoc.map(location => {
        if (location._id === this.item._id) {
          location.photo = process.env.VUE_APP_IMAGES + this.photo.picture;
        }
      })

      this.settingFavcountry.map(location => {
        if (location._id === this.item._id) {
          location.photo = process.env.VUE_APP_IMAGES + this.photo.picture;
        }
      })

      this.settingFavcity.map(location => {
        if (location._id === this.item._id) {


          location.photo = process.env.VUE_APP_IMAGES + this.photo.picture;
        }
      })


      let setting = this.settings;
      let s = {
        ...setting,
        favorite_location: this.settingFavLoc,
        favorite_country: this.settingFavcountry,
        favorite_city: this.settingFavcity
      }


      await updateSettings(s);
      this.overlayPhotoUpload = false;
      location.reload();
    },

    async saveFavSearch() {
      this.loading = true;


      let favsearch = {
        name: this.searchParameter,
        name_en: this.resulttext_EN,
        name_de: this.resulttext_DE,
        name_tr: this.resulttext_TR,
        id: this.searchParameterID,
        fav_type: 'search',
        search_type: this.searchParameterType
      }

      if (favsearch.name !== "" && favsearch.id !== "") {
        this.settingFavsearch.push(favsearch);
      }
      let setting = {
        ...this.settings,
        favorite_search: this.settingFavsearch
      }

      await updateSettings(setting);
      await new Promise(resolve => setTimeout(resolve, 500));
      this.notification(this.$t('NotificationSpeichernErfolgreich'), "green");
      this.loading = false;

      location.reload();

    },


    async saveFavHotels() {
      this.loading = true;
      let favHotel = {}

      if (this.searchParameter !== "") {
        favHotel = {

          name_en: this.resulttext_EN,
          name_de: this.resulttext_DE,
          name_tr: this.resulttext_TR,
          id: this.searchParameterID,
          photo: this.photo,
          fav_type: 'hotel',
          infos: {
            place_id: this.placeinfos_en.place.id,
            place: {
              stars: this.placeinfos_en.place.stars,
              overview_de: this.placeinfos_de.place.overview,
              overview_tr: this.placeinfos_tr.place.overview,
              overview_en: this.placeinfos_en.place.overview,
              location: {
                city: this.model_de.place.location.city,
                subregion: this.model_de.place.location.subregion,
                country: this.model_de.place.location.country
              }
            }
          },
          min_price: this.min_price[0]

        }
      }

      if (this.selectedOwnPlace !== "") {
        this.ownplaces = this.ownPlaces.find(item => item.place_id === this.selectedOwnPlace);
      }
      if (this.ownplaces !== "") {
        favHotel = {

          name_en: this.ownplaces.place_name_en,
          name_de: this.ownplaces.place_name_de,
          name_tr: this.ownplaces.place_name_tr,
          id: 0,
          photo: this.ownplaces.pictures_place[0].url,
          fav_type: 'hotel',
          infos: {
            place_id: this.ownplaces.place_id,
            place: {
              stars: this.ownplaces.stars,
              overview_de: this.ownplaces.place_desc_de,
              overview_tr: this.ownplaces.place_desc_tr,
              overview_en: this.ownplaces.place_desc_en,
              location: {
                city: this.ownplaces.location.city,
                subregion: this.ownplaces.location.subregion,
                country: this.ownplaces.location.country
              }
            }
          },
          min_price: 0

        }
      }

      if (favHotel.name_en !== undefined && favHotel.id !== undefined && favHotel.photo !== undefined) {
        this.settingFavHotel.push(favHotel);
      }

      let setting = {
        ...this.settings,
        favorite_hotel: this.settingFavHotel
      }

      await updateSettings(setting);
      this.notification(this.$t('NotificationSpeichernErfolgreich'), "green");
      await new Promise(resolve => setTimeout(resolve, 500));
      this.loading = false;
      location.reload();
    },


    async saveFavLocRegion() {
      this.loading = true;

      let favLoc = {
        name: this.searchParameter,
        name_en: this.resulttext_EN,
        name_de: this.resulttext_DE,
        name_tr: this.resulttext_TR,
        id: this.searchParameterID,
        photo: this.photo,
        fav_type: 'region',
        properties_count: this.properties_count,
      }

      if (favLoc.name !== "" && favLoc.id !== "") {
        this.settingFavLoc.push(favLoc);
      }
      let setting = {
        ...this.settings,
        favorite_location: this.settingFavLoc
      }

      await updateSettings(setting);
      this.notification(this.$t('NotificationSpeichernErfolgreich'), "green");
      await new Promise(resolve => setTimeout(resolve, 500));
      this.loading = false;
      location.reload();

    },


    async saveFavLocCountry() {
      this.loading = true;


      let favcountry = {
        name: this.searchParameter,
        name_en: this.resulttext_EN,
        name_de: this.resulttext_DE,
        name_tr: this.resulttext_TR,
        id: this.searchParameterID,
        photo: this.photo,
        fav_type: 'country',
        properties_count: this.properties_count
      }

      if (favcountry.name !== "" && favcountry.id !== "") {
        this.settingFavcountry.push(favcountry);
      }
      let setting = {
        ...this.settings,
        favorite_country: this.settingFavcountry
      }

      await updateSettings(setting);
      await new Promise(resolve => setTimeout(resolve, 500));
      this.notification(this.$t('NotificationSpeichernErfolgreich'), "green");
      this.loading = false;

      location.reload();
    },


    async saveFavLocCity() {
      this.loading = true;


      let favcity = {

        name: this.searchParameter,
        name_en: this.resulttext_EN,
        name_de: this.resulttext_DE,
        name_tr: this.resulttext_TR,
        id: this.searchParameterID,
        photo: this.photo,
        fav_type: 'city',
        properties_count: this.properties_count
      }

      if (favcity.name !== "" && favcity.id !== "") {
        this.settingFavcity.push(favcity);
      }
      let setting = {
        ...this.settings,
        favorite_city: this.settingFavcity
      }

      await updateSettings(setting);
      this.notification(this.$t('NotificationSpeichernErfolgreich'), "green");
      await new Promise(resolve => setTimeout(resolve, 500));
      this.loading = false;
      location.reload();

    },


    async deleteFavSearch(item) {
      let hotels = this.settingFavsearch;
      let tmp = hotels.filter(it => it._id !== item._id);
      let setting = {
        ...this.settings,
        favorite_search: tmp
      }

      this.loading = true;
      await updateSettings(setting);
      location.reload();
    },

    async deleteFavHotel(item) {
      let hotels = this.settingFavHotel;
      let tmp = hotels.filter(it => it._id !== item._id);
      let setting = {
        ...this.settings,
        favorite_hotel: tmp
      }

      this.loading = true;
      await updateSettings(setting);
      location.reload();
    },


    async deleteFavlocation(item) {
      this.item = item;
      let loc = this.settingFavLoc;
      let tmp = loc.filter(it => it._id !== item._id);
      let setting = {
        ...this.settings,
        favorite_location: tmp
      }
      this.loading = true;
      await this.deletePhoto(item);
      await new Promise(resolve => setTimeout(resolve, 500));
      await updateSettings(setting);
      location.reload();
    },


    async deleteFavCountry(item) {
      this.item = item;
      let country = this.settingFavcountry;
      let tmp = country.filter(it => it._id !== item._id);
      let setting = {
        ...this.settings,
        favorite_country: tmp
      }
      this.loading = true;
      await this.deletePhoto(item);
      await new Promise(resolve => setTimeout(resolve, 500));
      await updateSettings(setting);
      location.reload();
    },


    async deleteFavCity(item) {
      this.item = item;
      let city = this.settingFavcity;
      let tmp = city.filter(it => it._id !== item._id);
      let setting = {
        ...this.settings,
        favorite_city: tmp
      }
      this.loading = true;
      await this.deletePhoto(item);
      await new Promise(resolve => setTimeout(resolve, 500));
      await updateSettings(setting);
      location.reload();
    },


    openPhotoOverlay(item) {
      this.item = item;
      this.overlayPhotoUpload = true;
    },


    async searchSearchLocation() {
      this.searchtextHotel = '';
      this.searchtextLocRegion = '';
      this.searchtextLocCity = '';
      this.resulttext_TR = '';
      this.resulttext_EN = '';
      this.resulttext_DE = '';
      this.properties_count = '';

      if (this.searchtextSearch !== '' && this.searchtextSearch !== null) {
        this.loadingSearchSearchtext = true;
        this.resulttextSearch = await halalsearch(this.searchtextSearch);
      }
      this.loadingSearchSearchtext = false;
      this.loadingSearchHotel = false;
      this.loadingSearchLocRegion = false;
      this.loadingSearchLocCity = false;
    },


    async searchFavHotels() {
      this.selectedOwnPlace = "";

      this.searchtextLocRegion = '';
      this.searchtextLocCountry = '';
      this.searchtextLocCity = '';
      this.resulttext_TR = '';
      this.resulttext_EN = '';
      this.resulttext_DE = '';
      this.properties_count = '';

      if (this.searchtextHotel !== '' && this.searchtextHotel !== null) {
        this.loadingSearchHotel = true;
        this.resulttextHotel = await halalsearch(this.searchtextHotel);
      }

      this.loadingSearchLocRegion = false;
      this.loadingSearchLocCountry = false;
      this.loadingSearchLocCity = false;
    },

    async searchFavLocRegion() {
      this.searchtextHotel = '';
      this.searchtextLocCountry = '';
      this.searchtextLocCity = '';
      this.resulttext_TR = '';
      this.resulttext_EN = '';
      this.resulttext_DE = '';
      this.properties_count = '';

      if (this.searchtextLocRegion !== '' && this.searchtextLocRegion !== null) {
        this.loadingSearchLocRegion = true;
        this.resulttextLocRegion = await halalsearch(this.searchtextLocRegion);
      }

      this.loadingSearchHotel = false;
      this.loadingSearchLocCountry = false;
      this.loadingSearchLocCity = false;
    },

    async searchFavLocCountry() {
      this.searchtextHotel = '';
      this.searchtextLocRegion = '';
      this.searchtextLocCity = '';
      this.resulttext_TR = '';
      this.resulttext_EN = '';
      this.resulttext_DE = '';
      this.properties_count = '';

      if (this.searchtextLocCountry !== '' && this.searchtextLocCountry !== null) {
        this.loadingSearchLocCountry = true;
        this.resulttextLocCountry = await halalsearch(this.searchtextLocCountry);
      }
      this.loadingSearchHotel = false;
      this.loadingSearchLocRegion = false;
      this.loadingSearchLocCity = false;
    },

    async searchFavLocCity() {
      this.searchtextHotel = '';
      this.searchtextLocRegion = '';
      this.searchtextLocCountry = '';
      this.resulttext_TR = '';
      this.resulttext_EN = '';
      this.resulttext_DE = '';
      this.properties_count = '';

      if (this.searchtextLocCity !== '' && this.searchtextLocCity !== null) {
        this.loadingSearchLocCity = true;
        this.resulttextLocCity = await halalsearch(this.searchtextLocCity);
      }

      this.loadingSearchHotel = false;
      this.loadingSearchLocRegion = false;
      this.loadingSearchLocCountry = false;
    },

    clearSearchParameter() {

      this.searchtextHotel = null;
      this.resulttextHotel = "";
      this.searchtextLocRegion = null;
      this.resulttextLocRegion = "";
      this.searchtextLocCountry = null;
      this.resulttextLocCountry = "";
      this.searchtextLocCity = null;
      this.resulttextLocCity = "";


      this.searchParameter = "";
      this.searchParameterType = "";
      this.searchParameterID = "";

      this.loadingSearchHotel = false;
      this.loadingSearchLocRegion = false;
      this.loadingSearchLocCountry = false;
      this.loadingSearchLocCity = false;
    },

    async searchParameterLang(searchpar, id) {
      let s = "";
      s = await halalsearchTR(searchpar);
      let res = s.results.find(item => item.id === id);
      this.resulttext_TR = res["name"];

      s = await halalsearchEN(searchpar);
      res = s.results.find(item => item.id === id);
      this.resulttext_EN = res["name"];

      s = await halalsearchDE(searchpar);
      res = s.results.find(item => item.id === id);
      this.resulttext_DE = res["name"];
    },


    async setFavParameterIndex(index) {
      this.loading = true;


      if (this.searchtextHotel !== '' && this.searchtextHotel !== null) {
        this.searchParameter = this.resulttextHotel.results[index]["name-en"].includes(",") ? this.resulttextHotel.results[index]["name-en"].split(",")[0] : this.resulttextHotel.results[index]["name-en"];
        this.searchParameterID = this.resulttextHotel.results[index].id;
        this.searchtextHotel = this.resulttextHotel.results[index]["name"];
        this.searchParameterType = this.resulttextHotel.results[index]["search-type"];

        this.model_de = await onePlaceByID(this.searchParameterID, "DE");
        this.model_tr = await onePlaceByID(this.searchParameterID, "TR");
        this.model_en = await onePlaceByID(this.searchParameterID, "EN");
        this.photo = this.model_en.place.photo

        for (let i = 0; i < 3; i++) {
          let lang = ['de', 'tr', 'en'];
          delete this['model_' + lang[i]].groups;
          delete this['model_' + lang[i]].currency;
          delete this['model_' + lang[i]].place.photos;
          delete this['model_' + lang[i]].place.facilities;
          delete this['model_' + lang[i]].place.facts;
          delete this['model_' + lang[i]].place.facts;
        }


        this.placeinfos_de = this.model_de;
        this.placeinfos_tr = this.model_tr;
        this.placeinfos_en = this.model_en;
        this.resulttext_DE = this.model_de.place.name;
        this.resulttext_TR = this.model_tr.place.name;
        this.resulttext_EN = this.model_en.place.name;
        // await this.searchParameterLang(this.resulttextHotel.results[index]["name-en"], this.searchParameterID);
      }


      if (this.searchtextSearch !== '' && this.searchtextSearch !== null) {

        this.searchParameter = this.resulttextSearch.results[index]["name-en"].includes(",") ? this.resulttextSearch.results[index]["name-en"].split(",")[0] : this.resulttextSearch.results[index]["name-en"];
        this.searchParameterID = this.resulttextSearch.results[index].id;
        this.searchtextSearch = this.resulttextSearch.results[index]["name"];
        this.searchParameterType = this.resulttextSearch.results[index]["search-type"];
        this.properties_count = this.resulttextSearch.results[index]["properties-count"];

        await this.searchParameterLang(this.resulttextSearch.results[index]["name-en"], this.searchParameterID);

      }

      if (this.searchtextLocRegion !== '' && this.searchtextLocRegion !== null) {
        this.searchParameter = this.resulttextLocRegion.results[index]["name-en"].includes(",") ? this.resulttextLocRegion.results[index]["name-en"].split(",")[0] : this.resulttextLocRegion.results[index]["name-en"];
        this.searchParameterID = this.resulttextLocRegion.results[index].id;
        this.searchtextLocRegion = this.resulttextLocRegion.results[index]["name"];
        this.searchParameterType = this.resulttextLocRegion.results[index]["search-type"];
        this.properties_count = this.resulttextLocRegion.results[index]["properties-count"];


        await this.searchParameterLang(this.resulttextLocRegion.results[index]["name-en"], this.searchParameterID);
      }


      if (this.searchtextLocCountry !== '' && this.searchtextLocCountry !== null) {
        this.searchParameter = this.resulttextLocCountry.results[index]["name-en"].includes(",") ? this.resulttextLocCountry.results[index]["name-en"].split(",")[0] : this.resulttextLocCountry.results[index]["name-en"];
        this.searchParameterID = this.resulttextLocCountry.results[index].id;
        this.searchtextLocCountry = this.resulttextLocCountry.results[index]["name"];
        this.searchParameterType = this.resulttextLocCountry.results[index]["search-type"];
        this.properties_count = this.resulttextLocCountry.results[index]["properties-count"];

        await this.searchParameterLang(this.resulttextLocCountry.results[index]["name-en"], this.searchParameterID);

      }

      if (this.searchtextLocCity !== '' && this.searchtextLocCity !== null) {
        this.searchParameter = this.resulttextLocCity.results[index]["name-en"].includes(",") ? this.resulttextLocCity.results[index]["name-en"].split(",")[0] : this.resulttextLocCity.results[index]["name-en"];
        this.searchParameterID = this.resulttextLocCity.results[index].id;
        this.searchtextLocCity = this.resulttextLocCity.results[index]["name"];
        this.searchParameterType = this.resulttextLocCity.results[index]["search-type"];
        this.properties_count = this.resulttextLocCity.results[index]["properties-count"];

        await this.searchParameterLang(this.resulttextLocCity.results[index]["name-en"], this.searchParameterID);
      }

      this.loadingSearchHotel = false;
      this.loadingSearchLocRegion = false;
      this.loadingSearchLocCountry = false;
      this.loadingSearchLocCity = false;

      this.loading = false;
    },
  },


  async created() {
    window.scrollTo(0, 0);


    this.settings = await getAllSettings();
    this.settingFavsearch = this.settings.favorite_search;
    this.settingFavHotel = this.settings.favorite_hotel;
    this.settingFavLoc = this.settings.favorite_location;
    this.settingFavcountry = this.settings.favorite_country;
    this.settingFavcity = this.settings.favorite_city;

    this.ownPlaces = await getOwnPlaces();

    this.$root.$on('shortDescr', (val, index) => {
      this.shortDescr[index] = val;
    });
    this.$root.$on('fav_type', (val) => {
      this.fav_type = val;
    });


    this.$root.$on('openOverlayPhotoUpload', val => {
      this.openPhotoOverlay(val);
    });

    this.$root.$on('deleteFavSearch', val => {
      this.deleteFavSearch(val);
    });

    this.$root.$on('deleteFavHotel', val => {
      this.deleteFavHotel(val);
    });

    this.$root.$on('deleteFavCountry', val => {
      this.deleteFavCountry(val);
    });

    this.$root.$on('deleteFavlocation', val => {
      this.deleteFavlocation(val);
    });

    this.$root.$on('deleteFavCity', val => {
      this.deleteFavCity(val);
    });

  },

}
</script>

<style scoped>
@import "../css/SettingsFavorites.css";

</style>
