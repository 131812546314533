import language_de from "../json/languages_de.json"
import language_tr from "../json/languages_tr.json"
import language_en from "../json/languages_en.json"


export const getLanguages = () => {
    let lang = localStorage.getItem("lang");
    if (lang === "DE") {
        return language_de.languages_de;
    }
    if (lang === "TR") {
        return language_tr.languages_tr;
    }

    if (lang === "EN") {
        return language_en.languages_en;
    }
}


export const getLanguageByid = (id) => {
    let lang = localStorage.getItem("lang");
    if (lang === "DE") {
        return language_de.languages_de.find(item => item.lang_id === id).lang;
    }
    if (lang === "TR") {
        return language_tr.languages_tr.find(item => item.lang_id === id).lang;
    }

    if (lang === "EN") {
        return language_en.languages_en.find(item => item.lang_id === id).lang;
    }
}


export const getValue = (lang, settings, objectName, id) => {

    if (lang === "DE") {
        try {

            return settings.facilities_de[objectName].find(item => item.f_id === id).text;
        } catch (e) {

        }
    }

    if (lang === "TR") {
        try {
            return settings.facilities_tr[objectName].find(item => item.f_id === id).text;
        } catch (e) {

        }

    }

    if (lang === "EN") {
        try {
            return settings.facilities_en[objectName].find(item => item.f_id === id).text;
        } catch (e) {

        }
    }

}