<template>
  <div>
    <v-snackbar
        v-model="notificationsnackbar"
        :timeout="6000"
        :color="notificationcolor"
        :top=true
        :right=true
        :vertical=true
    >
      {{ notificationtext }}
      <v-btn
          color="white"
          text
          @click="notificationsnackbar = false"
      >
        {{ $t("NotificationSchliessen") }}
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <div class="div-setting1" id="SettingsPayment">
      <h2>{{ $t("Setting.Zugangsdaten") }}</h2>

      <label><b>E-Mail: {{ this.user.email }} </b></label>

      <br>

      <label><b>{{ $t("Setting.E-MailAndern") }}</b></label>
      <v-text-field
          label="E-Mail"
          v-model="loginmail"
          outlined
          color=var(--Primary)
      />
      <v-text-field
          :label="$t('Setting.E-MailWiederholen')"
          v-model="loginmail1"
          outlined
          color=var(--Primary)
      />
      <v-text-field
          :label="$t('Setting.AktuellesPasswort')"
          v-model="passwortaktuellmail"
          outlined
          color=var(--Primary)

          :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showpass ? 'text' : 'password'"
          @click:append="showpass = !showpass"
      />
      <v-btn
          class="ma-2"
          color=var(--Primary)
          style="color: white"
          @click="saveloginmail"
      >
        {{ $t("Button.Speichern") }}
      </v-btn>
      <br>


      <br>

      <label><b>{{ $t("Setting.PasswortAndern") }}</b></label>
      <v-text-field
          :label="$t('Setting.AktuellesPasswort')"
          v-model="passwortaktuell"
          outlined
          color=var(--Primary)

          :append-icon="showaktuell ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showaktuell ? 'text' : 'password'"
          @click:append="showaktuell = !showaktuell"
      />
      <v-text-field
          :label="$t('Setting.NeuesPasswort')"
          v-model="passwortneu"
          outlined
          color=var(--Primary)

          :append-icon="shownewp ? 'mdi-eye' : 'mdi-eye-off'"
          :type="shownewp ? 'text' : 'password'"
          @click:append="shownewp = !shownewp"
      />
      <v-text-field
          :label="$t('Setting.NeuesPasswortWiederholen')"
          v-model="passwortneu1"
          outlined
          color=var(--Primary)

          :append-icon="shownewp1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="shownewp1 ? 'text' : 'password'"
          @click:append="shownewp1 = !shownewp1"
      />
      <v-btn
          class="ma-2"
          color=var(--Primary)
          style="color: white"
          @click="savepassword"
      >
        {{ $t("Button.Speichern") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import {getUser} from "@/util/Auth";
import {signIn, updateUser} from "@/util/userUtil";


export default {
  name: "SettingsLogin",
  data() {
    return {
      loginmail: "",
      loginmail1: "",

      passwortaktuell: "",
      passwortneu: "",
      passwortneu1: "",
      passwortaktuellmail: "",

      showpass: false,
      showaktuell: false,
      shownewp: false,
      shownewp1: false,

      notificationsnackbar: false,
      notificationtext: "",
      notificationcolor: "",


      user: {}
    }
  },
  methods: {
    async saveloginmail() {

      try {
        if (this.loginmail === this.loginmail1 && this.loginmail !== "") {
          //let id = localStorage.getItem('aid');
          //let user = await getUserByID(id);
          let res = await signIn(this.user.email, this.passwortaktuellmail);
          this.user.email = this.loginmail;

          if (res.status === 200) {
            //this.user.password = this.passwortaktuellmail;

            let status = await updateUser(this.user);

            if (status === 200) {
              // this.user.email = this.loginmail;
              this.passwortaktuellmail = "";
              this.loginmail = "";
              this.loginmail1 = "";
              this.notificationsnackbar = true;
              this.notificationtext = this.$t("NotificationPWgeandert");
              this.notificationcolor = "green";
            } else {
              this.notificationsnackbar = true;
              this.notificationtext = this.$t("NotificationMailfehler");
              this.notificationcolor = "red";
            }
          } else {
            this.notificationsnackbar = true;
            this.notificationtext = this.$t("NotificationMailfehler");
            this.notificationcolor = "red";
          }

        } else {
          this.notificationsnackbar = true;
          this.notificationtext = this.$t("NotificationMailungleich");
          this.notificationcolor = "red";
        }

      } catch (e) {

      }
    },
    async savepassword() {

      let mail = this.user.email;
      let user = this.user;
      try {


        let res = await signIn(mail, this.passwortaktuell);

        if (res.status === 200) {
          if (this.passwortneu === this.passwortneu1 && this.passwortneu !== "") {
            //let id = localStorage.getItem('aid');
            //let user = await getUserByID(id);
            user.password = this.passwortneu;
            let status = await updateUser(user);

            if (status === 200) {
              this.passwortaktuell = "";
              this.passwortneu = "";
              this.passwortneu1 = "";
              this.notificationsnackbar = true;
              this.notificationtext = this.$t("NotificationPWgeandert");
              this.notificationcolor = "green";
            } else {
              this.notificationsnackbar = true;
              this.notificationtext = this.$t("NotificationPWfehler");
              this.notificationcolor = "red";
            }
          } else {
            this.notificationsnackbar = true;
            this.notificationtext = this.$t("NotificationPWungleich");
            this.notificationcolor = "red";
          }
        } else {
          this.notificationsnackbar = true;
          this.notificationtext = this.$t("NotificationPWnichtkorrekt");
          this.notificationcolor = "red";
        }
      } catch (e) {

      }


    }
  },
  async created() {

    let user = await getUser(localStorage.getItem('token'));
    this.user = user;
  }
}
</script>

<style scoped>

</style>
