import moment from "moment";

export const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
        }
    });
}


export const getHeaderonlyLogo = (logo) => {
    let header = {}

    header = {
        image: logo,
        style: 'headerRight',
        // width: 100,
        // height: 100
        fit: [130, 130],
    };
    return header;
}


export const getHeader = (userinfo) => {
    let date = (moment(new Date()).format("DD.MM.YYYY"));

    let header = {
        columns: [


            {
                text: userinfo.kontakt.firma,
                width: 150,
                style: 'labelheaderurl'
            },

            {
                text: 'www.efehotels.com',
                width: 250,
                style: 'labelheaderurl'
            }
        ]
    };

    return header;
}


export const getPDFHeaderAgency = (userinfo, logo) => {
    let date = (moment(new Date()).format("DD.MM.YYYY"));


    let header = {
        columns: [
            [
                {
                    image: logo,
                    // width: 100,
                    // height: 50
                },
                {text: userinfo.kontakt.firma + "\n", width: 350, style: 'labelheaderurl'},
                {text: userinfo.kontakt.strnr + "\n", style: 'labelheaderadresse'},
                {text: userinfo.kontakt.plz + " " + userinfo.kontakt.ort + "\n", style: 'labelheaderadresse'}
            ],

            {
                text: ' ',
                width: 50,
                style: 'labelheaderurl'
            },
            {
                text:
                    [
                        {text: 'poweredBy\n', italics: true, fontSize: 7,},
                        {text: 'CeeYuu.de\n\n', italics: true, fontSize: 8,},
                        {text: date}

                    ],
                width: 150,
                style: 'dateHeader'
            }
        ]
    };

    return header;
}

