<template>
  <div>
    <v-snackbar
        v-model="notificationsnackbar"
        :timeout="6000"
        :color="notificationcolor"
        :top=true
        :right=true
        :vertical=true
    >
      {{ notificationtext }}
      <v-btn
          color="white"
          text
          @click="notificationsnackbar = false"
      >
        {{ $t("NotificationSchliessen") }}
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <div class="div-setting1" id="SettingsLegal">


      <h2>{{ $t("Setting.Rechtliches") }}</h2>


      <label> </label>

      <v-text-field
          :label=" $t('Setting.Handelsregister')"
          v-model="handelsregister"
          outlined
          color=var(--Primary)
          @change="settingsObject"
      />

      <label> </label>
      <v-text-field
          :label=" $t('Setting.Geschäftsführung')"
          placeholder="Max Mustermann"
          v-model="geschaftsfuhrer"
          outlined
          color=var(--Primary)
          @change="settingsObject"
      />

      <label> </label>

      <v-text-field
          label="Steuer-Nr."
          v-model="umsatzsteuerid"
          outlined
          color=var(--Primary)
          @change="settingsObject"
      />

      <label> </label>

      <h3>{{ $t("Setting.Bankverbindung") }}</h3>
      <v-text-field
          :label=" $t('Setting.Kontoinhaber')"
          v-model="kontoinhaber"
          outlined
          color=var(--Primary)
          @change="settingsObject"
      />

      <v-text-field
          label="Bank"
          v-model="bank"
          outlined
          color=var(--Primary)
          @change="settingsObject"
      />

      <v-text-field
          :label=" $t('Setting.IBAN')"
          v-model="iban"
          outlined
          color=var(--Primary)
          @change="settingsObject"
      />

      <v-text-field
          :label=" $t('Setting.BIC')"
          v-model="bic"
          outlined
          color=var(--Primary)
          @change="settingsObject"
      />


      <v-btn
          class="ma-2"
          color=var(--Primary)
          style="color: white"
          @click="savecontact"
      >
        {{ $t("Button.Speichern") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import * as auth from "../../util/Auth";
import axios from "axios";
import {getUser} from "../../util/Auth";
import {getUserinfoByID, getUserinfoByUserId, updateUserInfo} from "../../util/userinfoDBUtil";

export default {
  name: "SettingsLegal",
  data() {
    return {

      geschaftsfuhrer: "",
      handelsregister: "",
      umsatzsteuerid: "",
      impressum: "",
      agb: "",
      disclaimer: "",
      kontoinhaber: "",
      bank: "",
      iban: "",
      bic: "",


      notificationsnackbar: false,
      notificationtext: "",
      notificationcolor: ""


    }
  },
  methods: {
    settingsObject(set) {
      // let set = this.$store.getters.getSettings;
      let settings = {
        ...set,
        rechtliches: {
          geschaftsfuhrer: this.geschaftsfuhrer,
          handelsregister: this.handelsregister,
          umsatzsteuerid: this.umsatzsteuerid,
          impressum: this.impressum,
          agb: this.agb,
          disclaimer: this.disclaimer,
          kontoinhaber: this.kontoinhaber,
          bank: this.bank,
          iban: this.iban,
          bic: this.bic

        }
      }

      this.$store.dispatch('settings', settings);
      return settings;
    },
    async savecontact() {
      let user = await getUser(localStorage.getItem("token"));
      let userinfo = await getUserinfoByUserId(user._id);
      let ui = this.settingsObject(userinfo);
      await updateUserInfo(ui);


      this.notificationsnackbar = true;
      this.notificationtext = this.$t("NotificationSpeichernErfolgreich");
      this.notificationcolor = "green";
    }
  },
  async created() {

    let user = await getUser(localStorage.getItem("token"));
    let userinfo = await getUserinfoByUserId(user._id);
    // this.userinfo = userinfo;

    this.geschaftsfuhrer = userinfo.rechtliches.geschaftsfuhrer;
    this.handelsregister = userinfo.rechtliches.handelsregister;
    this.umsatzsteuerid = userinfo.rechtliches.umsatzsteuerid;
    this.impressum = userinfo.rechtliches.impressum;
    this.agb = userinfo.rechtliches.agb;
    this.disclaimer = userinfo.rechtliches.disclaimer;
    this.kontoinhaber = userinfo.rechtliches.kontoinhaber;
    this.bank = userinfo.rechtliches.bank;
    this.iban = userinfo.rechtliches.iban;
    this.bic = userinfo.rechtliches.bic;

  },
  async beforeMount() {
    // let token = localStorage.getItem("token");
    // let check = await auth.result(token);
    // if (check) {
    //   let user = await auth.getUser(token);
    //
    //   this.user = user;
    //
    //   let asetting = await axios.get(process.env.VUE_APP_DB + "/api/asettingagency/" + user._id,{headers:{"authorization": 'Bearer '+localStorage.getItem('token')}});
    //   asetting = asetting.data.data;
    //   this.geschaftsfuhrer = asetting.rechtliches.geschaftsfuhrer,
    //       this.handelsregister = asetting.rechtliches.handelsregister,
    //       this.umsatzsteuerid = asetting.rechtliches.umsatzsteuerid,
    //       this.impressum = asetting.rechtliches.impressum,
    //       this.agb = asetting.rechtliches.agb,
    //       this.disclaimer = asetting.rechtliches.disclaimer,
    //       this.kontoinhaber = asetting.rechtliches.kontoinhaber,
    //       this.iban = asetting.rechtliches.iban,
    //       this.bic = asetting.rechtliches.bic
    // }
  }
}
</script>

<style scoped>

</style>
