import {updateOrder} from "@/util/orderUtil";

export const states_de = ['', 'NEU', 'FREIGEGEBEN', 'BEZAHLT', 'TEILZAHLUNG', 'STORNIERT', 'VERZUG', 'GESCHLOSSEN', 'WARTEND'];
const states_tr = ['', 'YENİ', 'ONAYLANDI', 'ÖDENDİ', 'KISMEN ÖDENDİ', 'İPTAL EDİLDİ', 'GECİKME', 'KAPATILDI', 'BEKLENİYOR'];
const states_en = ['', 'NEW', 'APPROVED', 'PAID', 'PART PAID', 'CANCELLED', 'DELAY', 'CLOSED', 'PENDING'];

export const getOrderState = (state) => {
    switch (state) {
        case "0":
            return 'Orders.Neu';
        case "1":
            return 'Orders.Freigegeben';
        case "2":
            return 'Orders.Bezahlt';
        case "3":
            return 'Orders.Teilzahlung';
        case "4":
            return 'Orders.Storniert';
        case "5":
            return 'Orders.Verzug';
        case "6":
            return 'Orders.Geschlossen';
        case "7":
            return 'Orders.Wartend';

    }
}

export const getNextState = (state, paypal_completed, part_payment) => {
    let new_state = "";
    if (state === '0') {
        if (paypal_completed) {
            new_state = '2';
        } else {
            new_state = '1';
        }
    }

    if (state === '1') {
        if (part_payment) {
            new_state = '3';
        } else {
            new_state = '2';
        }
    }

    if (state === '2') {
        if (part_payment !== '') {
            if (part_payment) {
                new_state = '3';
            }
        } else {
            new_state = '1';
        }
    }

    if (state === '3') {
        if (part_payment !== '') {
            if (!part_payment) {
                new_state = '2';
            } else {
                new_state = '3';
            }
        } else {
            new_state = '1';
        }
    }

    if (state === '5') {
        if (!part_payment) {
            new_state = '2';
        } else {
            new_state = '5';
        }
    }

    return new_state;

}

export const getOrderStateNr = (state, lang) => {


    let states = '';
    if (lang === "DE") {
        states = states_de;
        return getStatusCodeDE(states, state)
    }
    if (lang === "TR") {
        states = states_tr;
        return getStatusCodeTR(states, state)
    }
    if (lang === "EN") {
        states = states_en;
        return getStatusCodeEN(states, state)
    }


}

export const orderStateNrExist = (status, selectedStates, lang) => {


    let states = '';
    //status
    // [NEU,IN ZAHLUNG]
    // DE
    for (let i = 0; i < selectedStates.length; i++) {
        let nr = ""
        if (lang === "DE") {
            states = states_de;
            nr = getStatusCodeDE(states, selectedStates[i])
        }
        if (lang === "TR") {
            states = states_tr;
            nr = getStatusCodeTR(states, selectedStates[i])
        }
        if (lang === "EN") {
            states = states_en;
            nr = getStatusCodeEN(states, selectedStates[i])
        }
        if (status === nr) {
            return true;
        }
    }
    return false;

}

const getStatusCodeDE = (states, state) => {
    switch (state) {
        case "NEU":
            return states.indexOf(state).toString();
        case "FREIGEGEBEN":
            return states.indexOf(state).toString();
        case "BEZAHLT":
            return states.indexOf(state).toString();
        case "TEILZAHLUNG":
            return states.indexOf(state).toString();
        case "STORNIERT":
            return states.indexOf(state).toString();
        case "VERZUG":
            return states.indexOf(state).toString();
        case "GESCHLOSSEN":
            return states.indexOf(state).toString();
        case "WARTEND":
            return states.indexOf(state).toString();
        default:
            return "";

    }
}

const getStatusCodeTR = (states, state) => {

    switch (state) {
        case "YENİ":
            return states.indexOf(state).toString();
        case "ONAYLANDI":
            return states.indexOf(state).toString();
        case "ÖDENDİ":
            return states.indexOf(state).toString();
        case "KISMEN ÖDENDİ":
            return states.indexOf(state).toString();
        case "İPTAL EDİLDİ":
            return states.indexOf(state).toString();
        case "GECİKME":
            return states.indexOf(state).toString();
        case "KAPATILDI":
            return states.indexOf(state).toString();
        case "BEKLENİYOR":
            return states.indexOf(state).toString();
        default:
            return "";

    }
}


const getStatusCodeEN = (states, state) => {
    switch (state) {
        case "NEW":
            return states.indexOf(state).toString();
        case "APPROVED":
            return states.indexOf(state).toString();
        case "PAID":
            return states.indexOf(state).toString();
        case "PART PAID":
            return states.indexOf(state).toString();
        case "CANCELLED":
            return states.indexOf(state).toString();
        case "DELAY":
            return states.indexOf(state).toString();
        case "CLOSED":
            return states.indexOf(state).toString();
        case "PENDING":
            return states.indexOf(state).toString();
        default:
            return "";

    }
}

export const checkOrderPaymentState = async (order) => {
    order.map(async item => {
        if (item.order_state === "0" || item.order_state === "1" || item.order_state === "3") {
            if (new Date(item.hotelinfos.checkin) < new Date()) {
                item.order_state = "5";
                await updateOrder(item);
            }
        }
    });
    //return order;

}