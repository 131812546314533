export const generateID = () => {
    return Math.floor(100000 + Math.random() * 900000);
}

export const generateReservierungsnr = (firma_id) => {
    let date = new Date();

    return firma_id + "-" + date.getTime() + "-" + generateID();
}


export const generatePlaceID = () => {
    return "cy-" + Math.floor(10000 + Math.random() * 90000);
}


export const generateRatePlanID = () => {
    return Math.floor(10000 + Math.random() * 90000);
}