<template>
  <div>
    <v-overlay v-if="asktodelete" opacity=".9">
      <h3>Zimmer löschen?</h3>
      <div style="display: flex; gap: 20px; margin-top: 20px">
        <v-btn @click="asktodelete = false">Abbrechen</v-btn>
        <v-btn @click="deleteRoom(rooms.room_id)" color="error">Zimmer Löschen</v-btn>
      </div>
    </v-overlay>


    <div v-if="rooms.status_room !==''">
      <h3>Status</h3>

      <div>
        <div>
          <div v-if="rooms.status_room ===1">
            <v-icon color="green" large>mdi-check</v-icon>
            Zimmer ist aktiv
          </div>
          <div v-if="rooms.status_room ===0">
            <v-icon color="error" large>mdi-alert-outline</v-icon>
            Zimmer ist nicht aktiv
          </div>
        </div>
        <v-btn v-if="rooms.status_room ===0" @click="activateRoom" color="green">Zimmer aktivieren</v-btn>
        <v-btn v-if="rooms.status_room ===1" @click="deactivateRoom" color="error">Zimmer deaktivieren</v-btn>

        <v-btn @click="asktodelete = true" color="error">Zimmer Löschen</v-btn>
      </div>

      <br>
      <v-divider></v-divider>
      <br>

    </div>

    <v-text-field
        label="Zimmer ID"
        v-model="rooms.room_id"
        outlined
        color=var(--Primary)
        disabled

    />
    <v-text-field
        label="Zimmername DE"
        v-model="rooms.name_de"
        outlined
        color=var(--Primary)
        @input="setRoominfos"
    />

    <v-text-field
        label="Zimmername TR"
        v-model="rooms.name_tr"
        outlined
        color=var(--Primary)
        @input="setRoominfos"
    />

    <v-text-field
        label="Zimmername EN"
        v-model="rooms.name_en"
        outlined
        color=var(--Primary)
        @input="setRoominfos"
    />

    <br>
    <v-divider></v-divider>
    <br>


    <!--    -->
    <!--    <v-text-field-->
    <!--        label="Preisfaktor"-->
    <!--        v-model="rooms.size"-->
    <!--        placeholder="Bsp.: 25m²"-->
    <!--        outlined-->
    <!--        color=var(&#45;&#45;Primary)-->
    <!--        @input="setRoominfos"-->
    <!--    />-->


    <!--    <br>-->
    <!--    <v-divider></v-divider>-->
    <!--    <br>-->
    <v-text-field
        label="Zimmergröße in m²"
        v-model="rooms.size"
        placeholder="Bsp.: 25m²"
        outlined
        type="number"
        color=var(--Primary)
        @input="setRoominfos"
    />

    <v-text-field
        label="Min Nächte"
        v-model="rooms.min_nights"
        placeholder="Bsp.: 3 Nächte"
        outlined
        type="number"
        color=var(--Primary)
        @input="setRoominfos"
    />

    <br>
    <v-divider></v-divider>
    <br>

    <v-text-field
        label="Kapazität min. Personen"
        v-model="rooms.min_persons"
        outlined
        type="number"
        color=var(--Primary)
        @input="setRoominfos"
    />

    <v-text-field
        label="Kapazität max. Personen"
        v-model="rooms.max_persons"
        outlined
        type="number"
        color=var(--Primary)
        @input="setRoominfos"
    />
    <v-text-field
        label="Anzahl Baby-Bett"
        v-model="rooms.baby_bett"
        outlined
        type="number"
        color=var(--Primary)
        @input="setRoominfos"
    />

    <!--    <v-text-field-->
    <!--        label="Multiplikator"-->
    <!--        v-model="rooms.multiplikator"-->
    <!--        outlined-->
    <!--        type="number"-->
    <!--        color=var(&#45;&#45;Primary)-->
    <!--        @input="setRoominfos"-->
    <!--    />-->

    <v-text-field
        :label="'Zimmer Aufpreis p.P/Tag ' + oplace.currency"
        v-model="rooms.aufpreis"
        outlined
        type="number"
        color=var(--Primary)
        @input="setRoominfos"
    />


    <v-select
        label="Buchungsbestätigung (sofort/24h)"
        :items="confirmationlist"
        v-model="rooms.confirmation"
        outlined
        @change="setRoominfos"
    />


    <br>
    <v-divider></v-divider>
    <br>

    <v-textarea
        label="Beschreibung DE"
        v-model="rooms.descr_de"
        auto-grow
        outlined
        rows="3"
        row-height="35"
        color=var(--Primary)
        @input="setRoominfos"
    />


    <v-textarea
        label="Beschreibung TR"
        v-model="rooms.descr_tr"
        auto-grow
        outlined
        rows="3"
        row-height="35"
        color=var(--Primary)
        @input="setRoominfos"
    />


    <v-textarea
        label="Beschreibung EN"
        v-model="rooms.descr_en"
        auto-grow
        outlined
        rows="3"
        row-height="35"
        color=var(--Primary)
        @input="setRoominfos"
    />

    <br>
    <v-divider></v-divider>
    <br>


    <v-textarea
        label="Stornierungsbedingungen DE"
        v-model="rooms.cancellation_de"
        auto-grow
        outlined
        rows="3"
        row-height="35"
        color=var(--Primary)
        @input="setRoominfos"
    />


    <v-textarea
        label="Stornierungsbedingungen TR"
        v-model="rooms.cancellation_tr"
        auto-grow
        outlined
        rows="3"
        row-height="35"
        color=var(--Primary)
        @input="setRoominfos"
    />


    <v-textarea
        label="Stornierungsbedingungen EN"
        v-model="rooms.cancellation_en"
        auto-grow
        outlined
        rows="3"
        row-height="35"
        color=var(--Primary)
        @input="setRoominfos"
    />

    <div>
      <PicturePool
          :pUserinfo="pUserinfo"
          :pictures_place="rooms.pictures_rooms"
      ></PicturePool>
    </div>

    <br>
    <v-divider></v-divider>
    <br>


    <div>

    </div>
  </div>
</template>

<script>
import PicturePool from "@/components/picture_pool/PicturePool";
import HotelDatePicker from 'vue-hotel-datepicker';

export default {
  name: "CreateRoomWrapper",
  components: {
    PicturePool,
    HotelDatePicker,
  },
  props: [
    'roomindex',
    'index',
    'pRooms',
    'pUserinfo',
    'checkEditRoom',
    'oplace',
    'save',
    'delete'
  ],
  data() {
    return {
      // selectedconfirmation: "",
      confirmationlist: ['instant', 'delayed'],
      asktodelete: false,

      rooms: {

        status_room: 0,
        room_id: this.roomindex,
        name_de: "",
        name_tr: "",
        name_en: "",

        descr_de: "",
        descr_tr: "",
        descr_en: "",

        size: "",
        min_nights: "",

        cancellation_de: "",
        cancellation_tr: "",
        cancellation_en: "",


        min_persons: "",
        max_persons: "",
        baby_bett: "",
        multiplikator: "",
        aufpreis: "",
        confirmation: "",
        room_facilities: [],
        pictures_rooms: [],

      },


    }
  },
  methods: {
    setRoominfos() {
      this.pRooms[this.index] = this.rooms;
    },

    async activateRoom() {
      this.rooms.status_room = 1;
      await this.save();
    },
    async deactivateRoom() {
      this.rooms.status_room = 0;
      await this.save();
    },
    async deleteRoom(id) {

      await this.delete(id);
    }
  },
  created() {
    if (this.checkEditRoom) {
      this.rooms = this.pRooms[this.index]
    }


    this.$root.$on("pictureplace", val => {
      this.rooms.pictures_rooms = val;
    })

  }
}
</script>

<style scoped>

</style>