<template>
  <div>
    <div class="div-pics-gallery">

      <div @click="showGalleryPopup= true" class="div-piclarge-gallery">
        <img :src="pics[0]" alt="Bild 0" class="img-piclarge-gallery">
      </div>

      <div @click="showGalleryPopup= true" class="div-picsmall-gallery">
        <div class="div-img-picsmall-gallery">
          <img
              :src="pics[1]"
              v-if="pics[1] !== undefined && pics[1] !== ''"
              alt="Bild 1"
              class="img-picsmall-gallery"
          >
        </div>

        <div class="div-img-picsmall-gallery">
          <img
              :src="pics[2]"
              v-if="pics[2] !== undefined && pics[2] !== ''"
              alt="Bild 2"
              class="img-picsmall-gallery"
          >
        </div>

        <div class="div-img-picsmall-gallery">
          <label class="label-picsmall3-gallery">
            <v-icon color="black">mdi-image-multiple</v-icon>
            <br>
            {{ (pics.length) - 3 }} weitere Bilder
          </label>
          <img
              :src="pics[3]"
              v-if="pics[3] !== undefined && pics[3] !== ''"
              alt="Bild 3"
              class="img-picsmall-gallery img-hide-gallery"
          >
        </div>

      </div>

    </div>
    <GalleryPopup :pics="pics" :show="showGalleryPopup"></GalleryPopup>


  </div>
</template>

<script>
import GalleryPopup from "@/components/gallery/GalleryPopup";
// import {VueperSlides, VueperSlide} from 'vueperslides';
// import 'vueperslides/dist/vueperslides.css';


export default {
  name: "Gallery",
  props: [
    'pics',
  ],
  components: {
    GalleryPopup,
    // VueperSlides,
    // VueperSlide
  },
  data() {
    return {
      // images: this.pics,

      // showPicSlider: false,
      showGalleryPopup: false
    }
  },
  methods: {
    showGallery() {
      this.show = false;
      this.$root.$emit("showGallerie", this.show);

    }
  },
  created() {

    this.$root.$on("showGallerie", (val) => {
      this.showGalleryPopup = val;
    });
  }
}
</script>

<style scoped>
@import "../../css/Gallery.css";
</style>