import axios from "axios";

export const postOrder = async (booked) => {
    let res = await axios.post(process.env.VUE_APP_DB + "/api2/order/", booked, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});
    return res.status;
}


export const getAllOrder = async () => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/order/", {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});
    return res.data.data;
}


export const getOrder = async (id) => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/order/" + id, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});
    return res.data.data;
}

export const getOrderByFirmaID = async (id) => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/orderbyfid/" + id, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});
    return res.data.data;
}

export const getOrderByRBFirmaID = async (id) => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/orderbyrbfid/" + id, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});
    return res.data.data;
}

export const updateOrder = async (order) => {
    let status = 0;
    await axios.put(process.env.VUE_APP_DB + "/api2/order/" + order._id, order, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}}).then(res => status = res.status).catch(err => {
    });
    return status;
}

export const updateOrderOnlyComments = async (order) => {
    let data = {
        comment: order.comment
    }
    let status = 0;
    await axios.put(process.env.VUE_APP_DB + "/api2/orderoc/" + order._id, data, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}}).then(res => status = res.status).catch(err => {
    });
    return status;
}

export const deleteOrder = async (order) => {
    let status = 0;
    await axios.delete(process.env.VUE_APP_DB + "/api2/order/" + order._id, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}}).then(res => status = res.status).catch(err => {
    });
    return status;
}

export const getSlideReserviernr = (reserviernr) => {
    let res = reserviernr.split("-");
    return res[2];
}


export const changeState = async (state, order) => {
    let or = {
        ...order,
        order_state: state
    }

    let status = await updateOrder(or);
    return status;
}

