<template>
  <div>
    <div class="div-pics-gallery">

      <div @click="showGalleryPopup= true" class="div-piclarge-gallery">
        <img :src="pics[0].url" alt="Bild 0" class="img-piclarge-gallery">
      </div>

      <div @click="showGalleryPopup= true" class="div-picsmall-gallery">
        <div class="div-img-picsmall-gallery">
          <img
              :src="pics[1].url"
              v-if="pics[1].url !== undefined && pics[1].url !== ''"
              alt="Bild 1"
              class="img-picsmall-gallery"
          >
        </div>

        <div class="div-img-picsmall-gallery">
          <img
              :src="pics[2].url"
              v-if="pics[2].url !== undefined && pics[2].url !== ''"
              alt="Bild 2"
              class="img-picsmall-gallery"
          >
        </div>

        <div class="div-img-picsmall-gallery" v-if="pics.length>3">
          <label class="label-picsmall3-gallery">
            <v-icon color="black">mdi-image-multiple</v-icon>
            <br>
            {{ (pics.length) - 3 }} weitere Bilder
          </label>
          <img
              :src="pics[3].url"
              v-if="pics[3].url !== undefined && pics[3].url !== ''"
              alt="Bild 3"
              class="img-picsmall-gallery img-hide-gallery"
          >
        </div>

      </div>

    </div>
    <CY_GalleryPopup v-if="showGalleryPopup" :pics="pics" :show="showGallery"></CY_GalleryPopup>


  </div>
</template>

<script>
import CY_GalleryPopup from "@/components/cy_gallery/CY_GalleryPopup";

export default {
  name: "CY_Gallery",
  props: [
    'pics',
  ],
  components: {
    CY_GalleryPopup,
  },
  data() {
    return {
      showGalleryPopup: false
    }
  },
  methods: {
    showGallery() {
      this.showGalleryPopup = false;
    }
  },
}
</script>

<style scoped>
@import "../../css/Gallery.css";
</style>