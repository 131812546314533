<template>
  <div class="div-setting">
    <div class="body-setting">


      <v-tabs
          v-model="tab"
          background-color="blue lighten-1"
          class="elevation-2"
          dark
          flat

          :centered="centered"
          :grow="grow"
          :vertical="vertical"
          :right="right"
          :prev-icon="prevIcon ? 'mdi-arrow-left-bold-box-outline' : undefined"
          :next-icon="nextIcon ? 'mdi-arrow-right-bold-box-outline' : undefined"
          :icons-and-text="icons"
      >
        <v-tabs-slider color="var(--Primary)"></v-tabs-slider>

        <!-- ----------------------------------- Tab 1 ----------------------------------- -->

        <v-tab :key="1" :href="`#tab-${1}`" @click.native="scrollToTop">
          <label class="label-tab-setting">{{ $t("Setting.Kontakt") }}</label>
          <v-icon v-if="icons" color="white">mdi-card-account-phone</v-icon>
        </v-tab>

        <v-tab-item :key="1" :value="'tab-' + 1">
          <v-card flat tile>
            <v-card-text>
              <SettingsContact class="step-setting" :pUserinfo="pUserinfo" :pRolle="pRolle"/>
            </v-card-text>
          </v-card>
        </v-tab-item>


        <!-- ----------------------------------- Tab 2 ----------------------------------- -->
        <v-tab :key="2" :href="`#tab-${2}`" @click.native="scrollToTop">
          <label class="label-tab-setting">{{ $t("Setting.Rechtliches") }}</label>

          <v-icon v-if="icons" color="white">mdi-domain</v-icon>
        </v-tab>
        <v-tab-item :key="2" :value="'tab-' + 2">
          <v-card flat tile>
            <v-card-text>
              <SettingsLegal class="step-setting"/>
            </v-card-text>
          </v-card>
        </v-tab-item>


        <!-- ----------------------------------- Tab 3 ----------------------------------- -->


        <!--        <v-tab :key="3" :href="`#tab-${3}`" @click.native="scrollToTop">-->
        <!--          <label class="label-tab-setting">Design</label>-->

        <!--          <v-icon v-if="icons" color="white">mdi-card-bulleted</v-icon>-->
        <!--        </v-tab>-->
        <!--        <v-tab-item :key="3" :value="'tab-' + 3">-->
        <!--          <v-card flat tile>-->
        <!--            <v-card-text>-->
        <!--              <SettingsDesign class="step-design-setting"/>-->
        <!--            </v-card-text>-->
        <!--          </v-card>-->
        <!--        </v-tab-item>-->

        <!-- ----------------------------------- Tab 4 ----------------------------------- -->


        <v-tab :key="4" :href="`#tab-${4}`" @click.native="scrollToTop">
          <label class="label-tab-setting">{{ $t("Setting.Informationen") }}</label>

          <v-icon v-if="icons" color="white">mdi-at</v-icon>
        </v-tab>
        <v-tab-item :key="4" :value="'tab-' + 4">
          <v-card flat tile>
            <v-card-text>
              <SettingsLogin class="step-setting"/>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </div>

  </div>
</template>

<script>
import SettingsContact from "../components/settings/SettingsContact";
import SettingsLegal from "../components/settings/SettingsLegal";
import SettingsLogin from "../components/settings/SettingsLogin";


export default {
  name: "Settings",

  props: [
    'pUserinfo',
    'pRolle',
  ],
  components: {
    SettingsContact,
    SettingsLegal,
    SettingsLogin
  },
  data() {
    return {
      tab: null,
      icons: true,
      centered: false,
      grow: true,
      vertical: false,
      prevIcon: false,
      nextIcon: false,
      right: false,
      tabs: 4,
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 2);
    }
  },
  created() {

  }


}
</script>

<style scoped>
@import "../css/Settings.css";

</style>
