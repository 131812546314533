export const getGruppe = (groups) => {
    let gruppen = [];
    if (groups !== undefined) {
        let split = groups.split("&groups[]=");
        //2,3,5
        if (split.length === 2) {
            split.map((item, index) => {
                if (index !== 0) {
                    let gruppe = item.split(",");
                    let kinder = [];
                    gruppe.map((it, ind) => {
                        if (ind !== 0) {
                            kinder.push({age: it, date: ""});
                        }
                    });
                    let element = {
                        erwachsene: parseInt(gruppe[0]),
                        kinderalter: kinder,
                        kinder: 0,
                        name: "Gruppe 1"
                    };
                    if (split.length === 2) {
                        gruppen = [element];

                    } else {

                        gruppen.push(element);


                    }

                    if (gruppe.length > 2) {
                        let kinder = [];
                        gruppe.map((it, ind) => {
                            if (ind !== 0) {
                                kinder.push({age: it, date: ""});
                            }
                        });
                        let element = {
                            erwachsene: parseInt(gruppe[0]),
                            kinderalter: kinder,
                            kinder: kinder.length,
                            name: "Gruppe " + (index + 1)
                        };
                        if (split.length === 2)
                            gruppen = [element];
                        else {
                            gruppen.push(element);

                        }
                    }
                } else {
                    gruppen = [];
                }
            });
        }
    }

    return gruppen;


}