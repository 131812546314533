import Vue from 'vue'
import vuetify from '@/plugins/vuetify'; // path to vuetify export
import App from './App.vue'
import router from './router'
import store from './store';
import messages from './lang';
import VueI18n from 'vue-i18n';
import VueMoment from 'vue-moment';
import moment from 'moment';

import VueSocialSharing from 'vue-social-sharing';


Vue.prototype.moment = moment;
Vue.use(VueMoment, {
    moment,
})


Vue.config.productionTip = false;
Vue.use(VueI18n);

Vue.use(VueSocialSharing);

export const i18n = new VueI18n({
    locale: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'DE',
    fallbackLocale: 'DE',
    messages,
});


new Vue({
    router,
    store,
    vuetify,
    i18n,
    VueSocialSharing,
    render: h => h(App)
}).$mount("#app");
