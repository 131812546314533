<template>
  <div class="div-picslider-gallerysmall">

    <div style="max-width: 1100px; margin: 0 auto;">
      <!--      <div style="text-align: end; height: 50px;">-->
      <!--        <v-tooltip left>-->
      <!--          <template v-slot:activator="{ on }">-->
      <!--            <v-btn @click="showGallery" v-on="on">-->
      <!--              <v-icon>mdi-close</v-icon>-->
      <!--            </v-btn>-->
      <!--          </template>-->
      <!--          <span>{{ $t("Button.Schliessen") }}</span>-->
      <!--        </v-tooltip>-->
      <!--      </div>-->
      <vueper-slides
          class="vueperslide-gallerysmall"
          ref="vueperslides1"
          :touchable="false"
          fade
          autoplay
          :duration="2000"
          :bullets="false"
          @slide="$refs.vueperslides2.goToSlide($event.currentSlide.index, { emit: false })"
      >

        <template v-if="from==='CY_Card'">
          <vueper-slide
              v-for="(slide, i) in pics"
              :key="i"
              :image="slide.url"
          >
          </vueper-slide>
        </template>

        <template v-else>
          <vueper-slide
              v-for="(slide, i) in pics"
              :key="i"
              :image="slide"
          >
          </vueper-slide>
        </template>
      </vueper-slides>

      <div class="div-thumbnails-gallerysmall">
        <vueper-slides
            class="no-shadow thumbnails"
            ref="vueperslides2"
            @slide="$refs.vueperslides1.goToSlide($event.currentSlide.index, { emit: false })"
            visible-slides="7"
            fixed-height="75px"
            :bullets="false"
            :touchable="false"
            :gap="2.5"
            :arrows="true"
        >

          <!--          :breakpoints="{-->
          <!--          1000: { visibleSlides: 7 },-->
          <!--          850: { visibleSlides: 5 },-->
          <!--          650: { visibleSlides: 3}}"-->
          <vueper-slide
              v-for="(slide, i) in pics"
              :key="i"
              :image="slide"
              @click.native="$refs.vueperslides2.goToSlide(i)"
          >
          </vueper-slide>
        </vueper-slides>
      </div>
    </div>


  </div>

</template>

<script>
import {VueperSlides, VueperSlide} from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';


export default {
  name: "GallerySmall",
  props: [
    'pics',
    'from'
  ],
  components: {
    VueperSlides,
    VueperSlide
  },
  data() {
    return {
      // images: this.pics,

      // showPicSlider: false,
      showGalleryPopup: false
    }
  },
  methods: {
    showGallery() {
      this.$root.$emit("showGallerySmall", false);

    }
  },
  created() {

    this.$root.$on("showGallerie", (val) => {
      this.showGalleryPopup = val;
    });
  }
}
</script>

<style scoped>
@import "../../css/Gallery.css";
</style>