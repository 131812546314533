<template>
  <div class="div-settingkeys" v-if="pRolle==='admin' || pRolle==='superadmin'">
    <v-snackbar
        v-model="notificationsnackbar"
        :timeout="6000"
        :color="notificationcolor"
        :top="true"
        :right="true"
        :vertical="true"
    >
      {{ notificationtext }}
      <v-btn
          color="white"
          text
          @click="notificationsnackbar = false"
      >
        {{ $t("Notification.Schliessen") }}
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-overlay :value="loading">
      <v-progress-circular
          v-if="loading"
          indeterminate
          color="primary"
          :size="50"
          :width="7"
      />
    </v-overlay>
    <div class="bodyheader-accounts">
      <label class="label-bodyheader-accounts">

        <v-icon class="icon-start" color="white">mdi-key-variant</v-icon>
        Keys
      </label>
      <span></span>

    </div>


    <div class="body-settingkeys">


      <div>
        <h3 style="margin: 15px 0">Halalbooking-Key</h3>
        <label>Aktueller Benutzername: {{ halalbookingusername }}</label>
        <v-text-field

            label="halalbooking-Benutzername"
            v-model="newHalalUsername"
            outlined
        />

        <v-text-field

            label="halalbooking-Passwort"
            v-model="newHalalPassword"
            outlined
        />
        <v-btn @click="saveHalalKey" color="var(--Primary)" style="color: white; margin-top: 30px">
          {{ $t("Button.Speichern") }}
        </v-btn>
      </div>

      <br>
      <v-divider></v-divider>
      <br>


      <div>

        <h3 style="text-align: start;margin: 15px 0">Google-Maps-Key</h3>
        <label style="text-align: start">Aktueller Schlussel: {{ googleMapsKey }}</label>
        <v-text-field

            label="Google-Maps-Key"
            v-model="newgoogleKey"
            outlined
        />
        <v-btn @click="saveGoogleMapsKey" color="var(--Primary)" style="color: white; margin-top: 30px">
          {{ $t("Button.Speichern") }}
        </v-btn>

      </div>

      <br>
      <v-divider></v-divider>
      <br>

      <div>

        <h3 style="text-align: start;margin: 15px 0">PayPal-Key</h3>
        <label style="text-align: start">Aktueller Schlussel: {{ paypalKey }}</label>
        <v-text-field

            label="PayPal-Key"
            v-model="newPaypalKey"
            outlined
        />
        <v-btn @click="savePaypalKey" color="var(--Primary)" style="color: white; margin-top: 30px">
          {{ $t("Button.Speichern") }}
        </v-btn>

      </div>

      <br>
      <v-divider></v-divider>
      <br>

      <div>

        <h3 style="text-align: start;margin: 15px 0">Curreny-Key</h3>
        <label style="text-align: start">Aktueller Schlussel: {{ currencyKey }}</label>
        <v-text-field

            label="PayPal-Key"
            v-model="newCurrencyKey"
            outlined
        />
        <v-btn @click="saveCurrencyKey" color="var(--Primary)" style="color: white; margin-top: 30px">
          {{ $t("Button.Speichern") }}
        </v-btn>

      </div>


    </div>

  </div>
</template>

<script>


import {getAllSettings, getSettingByFirmaID, updateSettings} from "@/util/settingsUtil";
import {getUser} from "@/util/Auth";
import {getAdminUser} from "@/util/userUtil";

export default {
  name: "SettingsKeys",
  props: [
    'pLang',
    'pRolle',
  ],
  data() {
    return {
      notificationsnackbar: false,
      notificationtext: "",
      notificationcolor: "grey",
      loading: false,

      halalbookingusername: "",
      halalbookingpassword: "",
      googleMapsKey: "",
      paypalKey: "",
      currencyKey: "",

      newHalalUsername: "",
      newHalalPassword: "",
      newgoogleKey: "",
      newPaypalKey: "",
      newCurrencyKey: "",
      settings: ""


    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    notification(text, color) {
      this.notificationsnackbar = true;
      this.notificationtext = text;
      this.notificationcolor = color;
    },
    async saveHalalKey() {
      this.loading = true;

      //Hier Halal Key speichern
      let set = {
        ...this.settings,
        halalbookingusername: this.newHalalUsername,
        halalbookingpassword: this.newHalalPassword
      }

      await updateSettings(set);


      // this.loading = false;
      this.notification("Speichern erfolgreich", "green");

      await new Promise(resolve => setTimeout(resolve, 1000));
      location.reload();
    },


    async saveGoogleMapsKey() {
      this.loading = true;


      //Hier GoogleMaps Key speichern
      let set = {
        ...this.settings,
        googleMapsKey: this.newgoogleKey
      }

      await updateSettings(set);


      // this.loading = false;
      this.notification("Speichern erfolgreich", "green");

      await new Promise(resolve => setTimeout(resolve, 1000));
      location.reload();
    },


    async savePaypalKey() {
      this.loading = true;


      //Hier Paypal Key speichern
      let set = {
        ...this.settings,
        paypalKey: this.newPaypalKey
      }

      await updateSettings(set);

      // this.loading = false;
      this.notification("Speichern erfolgreich", "green");

      await new Promise(resolve => setTimeout(resolve, 1000));
      location.reload();
    },


    async saveCurrencyKey() {
      this.loading = true;
      let set = {
        ...this.settings,
        currencyKey: this.newCurrencyKey
      }

      await updateSettings(set);


      this.notification("Speichern erfolgreich", "green");

      await new Promise(resolve => setTimeout(resolve, 1000));
      location.reload();
    }
  },

  async created() {

    this.settings = await getAllSettings();
    this.halalbookingusername = this.settings.halalbookingusername;
    this.googleMapsKey = this.settings.googleMapsKey;
    this.paypalKey = this.settings.paypalKey;
    this.currencyKey = this.settings.currencyKey;


  },

}
</script>

<style scoped>
@import "../css/SettingsKeys.css";

</style>
