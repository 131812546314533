<template>
  <div class="home" v-if="pRolle==='hotel' || pRolle==='admin' || pRolle==='superadmin'">
    <v-snackbar
        v-model="notificationsnackbar"
        :timeout="6000"
        :color="notificationcolor"
        :top="true"
        :right="true"
        :vertical="true"
    >
      {{ notificationtext }}
      <v-btn
          color="white"
          text
          @click="notificationsnackbar = false"
      >
        {{ $t("Notification.Schliessen") }}
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

    <div class="bodyheader-home">
      <label class="label-bodyheader-home">
        <v-icon class="icon-home">mdi-bed-king-outline</v-icon>
        {{ $t("Nav.ZimmerAnlegen") }}
      </label>

    </div>

    <div class="div-body-createhotel">


      <div class="div-tab-createhotel" v-if="checkHotelexist">

        <template v-if="(roomindex>-1 || checkEditRoom) && !checkSetSaleStop ">

          <CreateRoomWrapper
              :roomindex="roomindex"
              :pRooms="rooms"
              :index="index"
              :pUserinfo="pUserinfo"
              :checkEditRoom="checkEditRoom"
              :oplace="oplace"
              :save="saveNewRoom"
              :delete="dRoom"
          />

        </template>
        <div>

          <br>
          <v-btn
              color="var(--Primary)"
              outlined
              @click="setnewRoom"
              v-if="!newRoom && !checkEditRoom && !checkSetSaleStop"
          >
            Raum hinzufügen
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>


      </div>
      <div v-else style="margin: 30px">
        Hotel auswählen

        <v-select
            :items="hotellist"
            item-text="name"
            item-value="place_id"
            v-model="selectedhotel"
            @change="changeSelectedHotel"
        />

      </div>

      <br>
      <v-divider/>
      <br>

      <div style="margin: 50px 0" v-if="selectedhotel!=='' && (newRoom || checkEditRoom) ">
        <v-btn @click="saveNewRoom" color="var(--Primary)" style="color: white">
          {{ $t("Button.Speichern") }}
        </v-btn>

      </div>

      <div v-if="selectedhotel !=='' && !checkEditRoom && !checkSetSaleStop && !newRoom">
        Vorhandene Zimmer

        <div style="display: flex; gap: 10px">
          <div v-for="(item,index) in rooms">
            <div style="display: grid; border: 2px solid lightgrey">
              <!--              <v-icon color="green" v-if="item.status_room ==='1'">mdi-check</v-icon>-->
              <!--              <v-icon color="error" v-if="item.status_room ==='0'">mdi-alert-outline</v-icon>-->

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      :color="item.status_room === 1 ? 'green' : 'error'"
                      v-bind="attrs"
                      v-on="on"
                  >
                    mdi-{{ item.status_room === 1 ? 'bed' : 'close-octagon' }}
                  </v-icon>
                </template>
                <span>{{ item.status_room === 1 ? 'Aktiv' : 'Deaktiviert' }}</span>
              </v-tooltip>
              <v-divider></v-divider>

              <span>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">

                    <v-btn
                        @click="editRoom(index)"
                        class="mx-2"
                        text
                        small
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>


                  </template>
                  <span>Zimmer Bearbeiten</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">

                    <v-btn
                        @click="setSaleStop(index)"
                        class="mx-2"
                        text
                        small
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon>mdi-book-cancel-outline</v-icon>
                    </v-btn>

                  </template>
                  <span>Sale Stop</span>
                </v-tooltip>
              </span>

              <v-divider></v-divider>

              <label>{{ item.name_de }}</label>
              <CardSmallRoom
                  :item="item.pictures_rooms"
                  :pLang="pLang"
              ></CardSmallRoom>
            </div>
          </div>


        </div>
      </div>
      <div v-if="selectedhotel !=='' && checkSetSaleStop">

        <v-btn @click="cancelSaleStop" color="var(--Secondary)" style="color: white;">
          {{ $t('Button.Zurueck') }}
        </v-btn>

        <div>Sale Stop einrichten</div>
        <div>
          Zimmer: {{ rooms[index]['name_' + pLang.toLowerCase()] }}
        </div>

        <div>
          <div style="display: grid; gap: 20px">
            <template v-for="(sale,index) in salestops">
              <div style="display: flex">
                <HotelDatePickerWrapper
                    :salestops="salestops"
                    :saleindex="index"
                    :salekey="intsalestop"
                    :key="intsalestop"
                    from="salestop"
                />

                <span>

                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          class="mx-2"
                          fab
                          dark
                          small
                          color="primary"
                          @click="deleteNewSaleStop(index)"
                          v-bind="attrs"
                          v-on="on"
                      >
                        <v-icon dark>
                          mdi-delete
                        </v-icon>
                      </v-btn>

                    </template>
                    <span>Sale Stop {{ $t('Button.Loeschen') }}</span>
                  </v-tooltip>
                </span>

                <div v-if="salestops.length-1 !== index">

                </div>

                <div v-if="salestops.length-1 === index">

                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          class="mx-2"
                          fab
                          dark
                          small
                          color="primary"
                          @click="addNewSaleStop"
                          v-bind="attrs"
                          v-on="on"
                      >
                        <v-icon dark>
                          mdi-plus
                        </v-icon>
                      </v-btn>

                    </template>
                    <span>Sale Stop {{ $t('Button.Hinzufuegen') }}</span>
                  </v-tooltip>
                </div>
              </div>
            </template>
          </div>

          <div>

            <span>
              <v-btn
                  color="primary"
                  @click="saveNewSaleStop"
              >
                <v-icon dark>
                  mdi-check
                </v-icon>

                {{ $t('Button.Speichern') }}

              </v-btn>
            </span>
          </div>
        </div>


      </div>

    </div>


  </div>

</template>

<script>

import PicturePool from "@/components/picture_pool/PicturePool";
import CreateRoomWrapper from "@/components/createPlace/CreateRoomWrapper";
import {
  getOwnPlaceByPlaceHID,
  getOwnPlaceByPlaceID,
  updateOwnPlace,
  getAllOwnPlaces
} from "@/util/OwnPlace";
import CardSmallRoom from "@/components/card/CardSmallRoom";

import HotelDatePicker from 'vue-hotel-datepicker';
import HotelDatePickerWrapper from "@/components/DatePicker/HotelDatePickerWrapper";


export default {
  name: "CreateRoom",
  props: [
    'pLang',
    'pRolle',
    'pUser',
    'pSetting',
    'pUserinfo',

  ],
  components: {
    PicturePool,
    CreateRoomWrapper,
    CardSmallRoom,
    HotelDatePicker,
    HotelDatePickerWrapper
  },
  data() {
    return {
      notificationsnackbar: false,
      notificationtext: "",
      notificationcolor: "grey",
      index: 0,
      hotellist: [],
      selectedhotel: "",
      oplace: "",


      // Raum anlgegen  --- ANFANG -------------------
      // Tabs Headers
      checkHotelexist: false,


      status_room: '',
      roomindex: -1,

      newRoom: false,
      checkEditRoom: false,
      checkSetSaleStop: false,


      maxCount_de: 500,
      remainingCount_de: 500,
      message_de: '',
      hasError_de: false,

      maxCount_tr: 500,
      remainingCount_tr: 500,
      message_tr: '',
      hasError_tr: false,

      maxCount_en: 500,
      remainingCount_en: 500,
      message_en: '',
      hasError_en: false,


      offers: {
        //Preisgestaltung muss noch definiert werden
      },

      rooms_counter: 0,
      rooms: [{}],
      salestops: [{start: "", end: ""}],
      intsalestop: 0

    };
  },
  methods: {
    notification(text, color) {
      this.notificationsnackbar = true;
      this.notificationtext = text;
      this.notificationcolor = color;
    },


    setnewRoom() {
      let index = 0;


      this.roomindex = this.oplace.rooms_counter + 1;
      this.rooms_counter = this.oplace.rooms_counter + 1;

      this.rooms.push({});
      this.index = this.rooms.length - 1;

      this.newRoom = true;
    },

    async changeSelectedHotel() {
      this.checkHotelexist = true;
      this.oplace = await getOwnPlaceByPlaceID(this.selectedhotel);
      this.rooms = this.oplace.rooms;


      if (this.rooms[this.index].salestops !== undefined) {
        if (this.rooms[this.index].salestops.length > 0) {
          this.salestops = this.rooms[this.index].salestops;
        }
      }

      let a = this.salestops;
      a.sort(function (a, b) {
        const bandA = a.start;
        const bandB = b.start;

        let comparison = 0;
        if (bandA > bandB) {
          comparison = 1;
        } else if (bandA < bandB) {
          comparison = -1;
        }
        return comparison;
      });
      this.salestops = a;


    },


    async saveNewRoom() {
      let p = {
        ...this.oplace,
        rooms: this.rooms,
        rooms_counter: this.rooms_counter
      }

      let status = await updateOwnPlace(p);
      if (status === 200) {
        this.notification("Raum gespeichert", "green");
        await new Promise(resolve => setTimeout(resolve, 500));
        location.reload();
      } else {
        this.notification("Fehler beim Speichern", "error");
      }
    },

    async dRoom(id) {
      this.rooms = this.rooms.filter(item => item.room_id !== id);
      // console.log(id);
      let p = {
        ...this.oplace,
        rooms: this.rooms
      }

      let status = await updateOwnPlace(p);
      if (status === 200) {
        this.notification("Zimmer gelöscht", "green");
        await new Promise(resolve => setTimeout(resolve, 500));
        location.reload();
      } else {
        this.notification("Fehler beim Speichern", "error");
      }
    },

    async editRoom(index) {
      this.index = index;
      this.checkEditRoom = true;
      this.roomindex = this.rooms[index].room_id;
    },

    async setSaleStop(index) {
      this.index = index;
      this.checkSetSaleStop = true;
      this.roomindex = this.rooms[index].room_id;
      this.salestops = this.rooms[index].salestops;
      if (this.salestops.length === 0) {
        this.salestops.push({start: "", end: ""})
      }


    },

    async cancelSaleStop() {
      this.index = 0;
      this.checkSetSaleStop = false;
      this.newRoom = false;
      this.checkEditRoom = false;
      this.roomindex = -1;
    },

    async saveNewSaleStop() {
      this.rooms[this.index].salestops = this.salestops;
      let p = {
        ...this.oplace,
        rooms: this.rooms
      }

      let status = await updateOwnPlace(p);
      if (status === 200) {
        this.notification("SaleStop gespeichert", "green");
        await new Promise(resolve => setTimeout(resolve, 500));
        // location.reload();
      } else {
        this.notification("Fehler beim Speichern", "error");
      }
    },

    async deleteNewSaleStop(index) {

      let sale = this.salestops.filter((it, i) => i !== index);
      this.rooms[this.index].salestops = sale;
      let p = {
        ...this.oplace,
        rooms: this.rooms
      }

      let status = await updateOwnPlace(p);
      if (status === 200) {
        this.notification("SaleStop gelöscht", "green");
        await new Promise(resolve => setTimeout(resolve, 500));
        this.intsalestop++;
        // location.reload();

      } else {
        this.notification("Fehler beim Speichern", "error");
      }

    },

    async addNewSaleStop() {
      this.salestops.push({start: "", end: ""});

    },


    overview_countdown_de() {
      this.remainingCount_de = this.maxCount_de - this.place_desc_de.length;
      this.hasError_de = this.remainingCount_de < 0;
    },
    overview_countdown_tr() {
      this.remainingCount_tr = this.maxCount_tr - this.place_desc_tr.length;
      this.hasError_tr = this.remainingCount_tr < 0;
    },
    overview_countdown_en() {
      this.remainingCount_en = this.maxCount_en - this.place_desc_en.length;
      this.hasError_en = this.remainingCount_en < 0;
    }
  },

  async created() {


    let rolle = this.pUser.rolle;
    let h = "";
    if (rolle !== undefined && rolle !== "") {
      if (rolle !== "hotel") {
        h = await getAllOwnPlaces();
      } else {
        h = await getOwnPlaceByPlaceHID(this.pUser._id);
      }
    }


    h.map(item => {
      this.hotellist.push({name: item.place_name_de, place_id: item.place_id});
    });

    this.$root.$on("Roominfos", val => {
      //this.rooms.push(val);
    });
  }
};
</script>

<style scoped>
@import "../css/CreateRoom.css";
</style>