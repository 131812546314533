const replaceText = (text, expected, replaceWith) => {
    text = text.replace(expected, replaceWith);
    return text;
}

export const arr_DE = [
    "ALLGEMEINE EINRICHTUNGEN:",
    "AKTIVITÄTEN:",
    "SPEISEN &amp; GETRÄNKE:",
    "FÜR KINDER:",
    "UNTERHALTUNG:",
    "INTERNET:",
    "PARKPLATZ:",
    "GESCHÄFTE:",
    "REZEPTIONSSERVICE:",
    "BUSINESS-EINRICHTUNGEN:",
    "REINIGUNGSSERVICE:",
    "TRANSPORT:",
    "STRÄNDE:",
    "WELLNESS & SPA:",
    "POOLS:",
    "WICHTIGE HINWEISE:",
];
export const arr_edited_DE = [
    "ALLGEMEINE_EINRICHTUNGEN:",
    "AKTIVITÄTEN:",
    "SPEISEN_&amp;_GETRÄNKE:",
    "FÜR_KINDER:",
    "UNTERHALTUNG:",
    "INTERNET:",
    "PARKPLATZ:",
    "GESCHÄFTE:",
    "REZEPTIONSSERVICE:",
    "BUSINESS-EINRICHTUNGEN:",
    "REINIGUNGSSERVICE:",
    "TRANSPORT:",
    "STRÄNDE:",
    "WELLNESS_&_SPA:",
    "POOLS:",
    "WICHTIGE_HINWEISE:",
];

export const parseFacilities_DE = async (text) => {
    text = replaceAll_DE(text);
    text = text.replaceAll("\n\n", "\n\n ");
    text = text.replaceAll(":\n", ": ");
    text = text.replaceAll(": \n", ": ");
    let spText = await parseText(text);
    spText = await replaceAll_DE_Back(spText);
    return spText;
}

export const replaceAll_DE = (text) => {
    text = replaceText(text, "ALLGEMEINE EINRICHTUNGEN:", "ALLGEMEINE_EINRICHTUNGEN:");
    text = replaceText(text, "SPEISEN &amp; GETRÄNKE:", "SPEISEN_&amp;_GETRÄNKE:");
    text = replaceText(text, "FÜR KINDER:", "FÜR_KINDER:");
    text = replaceText(text, "WELLNESS & SPA:", "WELLNESS_&_SPA:");
    text = replaceText(text, "WICHTIGE HINWEISE", "WICHTIGE_HINWEISE:");
    return text;
}

export const replaceAll_DE_Back = (text) => {
    let arr = [];
    text.map(item => {
        let inOf = arr_edited_DE.indexOf(item.title);
        if (inOf !== -1) {
            item.title = arr_DE[inOf];
        } else {
            item.title = item.title.replaceAll("_", " ");
        }
        arr.push({title: item.title, text: item.text});
    })

    return arr;

}

export const splitText = (text) => {
    let splittedText = text.split(" ");
    return splittedText;
}


export const parseText = async (text) => {
    let splittedText = splitText(text);
    let saveIndex = 0;
    let endIndex = 0;
    let n = await iterateArray(splittedText, 0);
    let next = await iterateArray(splittedText, 1);
    let last = false;
    let arrTex = [];

    splittedText.map((text, index) => {
        if (n !== 15) {
            if (text.includes(arr_edited_DE[n])) {
                saveIndex = index;
            }
            if (text.includes(arr_edited_DE[next])) {
                endIndex = index;

                let text = getString(splittedText, saveIndex, endIndex);
                saveIndex = index;
                arrTex.push(text);
                n = iterateArray(splittedText, n + 1);
                next = iterateArray(splittedText, n + 1);
            }
        } else {
            if (!last) {
                let text = getString(splittedText, index - 1, splittedText.length - 1);
                arrTex.push(text);
                last = true;
            }
        }
    });

    return arrTex;
}


const iterateArray = (array, index) => {
    let save = 0;
    let test = false;
    for (let i = index; i < arr_edited_DE.length; i++) {
        if (contains(array, arr_edited_DE[i])) {
            if (!test) {
                save = i;
                test = true;
                return save;
            }
        }
    }
    return save;
}


function contains(a, obj) {
    let i = a.length;
    while (i--) {
        if (a[i].includes(obj)) {
            return true;
        }
    }
    return false;
}


const getString = (splittedText, von, bis) => {
    let text = {title: "", text: ""};
    text.title = splittedText[von];
    for (let i = von + 1; i < bis; i++) {
        text.text += splittedText[i] + " ";
    }
    return text;
}


// export const parsePool = async (text) => {
//
//     text = text.replaceAll("\n\n ", "\n");
//     let textArray = text.split("\n");
//     let poolArr = [];
//     textArray.map(item => {
//         let value = {
//             title: "",
//             desc: []
//         }
//         if (item.includes(":")) {
//             let it = item.split(":");
//
//             value.title = it[0] + ":";
//             if (it[1].includes(",")) {
//                 value.desc = it[1].split(",");
//             } else {
//                 value.desc = it[1];
//             }
//
//         } else {
//             value.desc = item;
//
//         }
//         poolArr.push(value);
//     })
//
//     let finalpool = await parsePoolEx(poolArr);
//     return finalpool;
// }
//
//
// const parsePoolEx = async (pool) => {
//     let arr = [];
//     let tmp = [];
//     let title = "";
//     pool.map((item, index) => {
//         if (!item.title.includes(":")) {
//             if (item.desc !== "")
//                 arr.push({title: "", desc: [], nontitle: item.desc});
//
//         } else {
//             if (item.title.includes(" ")) {
//                 let text = item.title.split(" ");
//
//                 if (!isNaN(parseInt(text[1].replace(":", "")))) {
//                     if (tmp.length > 0) {
//                         if (tmp[0].title !== "")
//                             arr.push(tmp);
//                         tmp = [];
//                     }
//                     tmp.push({title: item.title, desc: item.desc, nontitle: ""})
//                 } else {
//                     if (item.desc.length > 0) {
//                         tmp.push({title: item.title, desc: item.desc, nontitle: ""})
//                     }
//                 }
//             } else {
//                 tmp.push({title: item.title, desc: item.desc, nontitle: ""})
//             }
//
//         }
//     });
//     arr.push(tmp);
//     return arr;
// }
//
//
// export const beachParse = async (text) => {
//     let t = text.replaceAll("\n\n", "\n");
//     let arr = t.split("\n");
//
//     return arr;
// }
//
// export const beachSplit = async (beach) => {
//     let arr = [];
//     beach.map(async item => {
//         if (item !== "") {
//
//             if (item.includes(":")) {
//                 let title = await item.split(":");
//
//                 if (title[1].includes(",")) {
//                     let commSplit = title[1].split(",");
//                     arr.push({title: title[0] + ":", desc: commSplit});
//
//                 } else {
//                     let commSplit = [];
//                     commSplit.push(title[1]);
//                     arr.push({title: title[0] + ":" + title[1], desc: commSplit});
//                 }
//             } else {
//                 if (item !== " " && item !== "")
//                     arr.push({title: "", desc: [item]});
//             }
//         }
//     })
//     return arr;
// }
//
// export const spa = async (text) => {
//     text = text.replaceAll("\n", ":");
//     let splitSpa = await text.split(":");
//
//     let arr = [];
//     let desc = []
//     for (let i = 0; i < splitSpa.length; i = i + 3) {
//         if (splitSpa[i + 2] !== "" && splitSpa[i + 2] !== " " && splitSpa[i + 2] !== undefined) {
//             if (splitSpa[i + 2].includes(",")) {
//                 desc = await splitSpa[i + 2].split(",")
//
//
//             } else {
//                 desc.push(splitSpa[i + 2]);
//             }
//         }
//         if (splitSpa[i] !== "" && splitSpa[i] !== " ") {
//             arr.push({title: splitSpa[i], subtitle: splitSpa[i + 1], desc: desc});
//             desc = []
//         }
//     }
//     return arr;
// }
//
// export const parseBeachTitle = (beach) => {
//
//     beach.map(item => {
//         let index = item.title.indexOf(":");
//         if (index !== -1) {
//             let title = item.title.substring(0, index);
//             item.title = title;
//         }
//
//     });
//     return beach;
//
// }
//
// export const parseBeachtext = (beach) => {
//     let arr = [];
//
//     beach = beach.replaceAll("\n\n", "\n");
//     let it = beach;
//     let start = 0;
//     s
//     for (let i = 0; i < beach.split(":").length; i++) {
//
//
//         let indextitle = it.indexOf(":");
//         //title :
//         let title = it.substring(start, indextitle);
//         it = it.substring(indextitle + 1, it.length);
//         let back = it.indexOf("\n");
//         let des = getObject(it.substring(0, back));
//         it = it.substring(back, it.length);
//
//         arr.push({title: title, desc: des});
//     }
//
//     //myArr.push(o1);
//     //myArr.push(o2);
//     //myArr.push(o3);
//     //myArr.push(o4);
//
//     //arr.push(myArr);
//
//
//     return arr;
// }
//
// export const getObject = (desc) => {
//
//
//     let descArr = []
//     if (desc.includes(",")) {
//         descArr = desc.split(",");
//     } else {
//         descArr.push(desc);
//     }
//
//
//     return descArr;
// }