<template>
  <div class="div-cardsmalllocation">
    <img :src="item.photo" alt="Photo" class="img-cardsmalllocation">
    <h4 class="label-cardscmalllocation">
      {{ getTitle() }}
    </h4>
    <div class="div-background-cardsmalllocation"></div>

  </div>
</template>


<script>

export default {
  name: "CardSmallLocation",
  props: [
    'item',
    'pLang'
  ],

  data() {
    return {}
  },

  methods: {

    getTitle() {
      if (this.pLang.toLowerCase() === 'de') {
        // return this.item.name_de.includes(",") ? this.item.name_de.split(",")[0] : this.item.name_de;
        if (this.item.fav_type === "city") {
          return this.item.name_de.includes(",") ? this.item.name_de.split(",")[0] : this.item.name_de;
        }
        return this.item.name_de;
      }

      if (this.pLang.toLowerCase() === 'tr') {
        // return this.item.name_tr.includes(",") ? this.item.name_tr.split(",")[0] : this.item.name_tr;
        if (this.item.fav_type === "city") {
          return this.item.name_tr.includes(",") ? this.item.name_tr.split(",")[0] : this.item.name_tr;
        }
        return this.item.name_tr;

      }
      if (this.pLang.toLowerCase() === 'en') {
        // return this.item.name_en.includes(",") ? this.item.name_en.split(",")[0] : this.item.name_en;
        if (this.item.fav_type === "city") {
          return this.item.name_en.includes(",") ? this.item.name_en.split(",")[0] : this.item.name_de;
        }
        return this.item.name_en;
      }
    }

  }

}

</script>

<style lang="css" scoped>
@import "../../css/CardSmallLocation.css";
</style>
