import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from "@/views/Login";
import Hotellist from "@/views/Hotellist";
import CreateHotel from "@/views/CreateHotel";
import CreateRoom from "@/views/CreateRoom";
import CreateTransfer from "@/views/CreateTransfer";
import CreateUser from "@/views/CreateUser";
import Accounts from "@/views/Accounts";
import Orders from "@/views/Orders";
import Dashboard from "@/views/Dashboard";
import Place from "@/views/Place";
import Booking from "@/views/Booking";
import ResetPW from "@/views/ResetPW";
import Settings from "@/views/Settings";
import SettingsPlace from "../views/SettingsPlace";
import SettingsKeys from "@/views/SettingsKeys";
import SettingsBooking from "@/views/SettingsBooking";
import SettingsDesign from "@/views/SettingsDesign";
import SettingsFavorites from "@/views/SettingsFavorites";
import SuccessfulBooking from "@/views/SuccessfulBooking";
import Test from "@/views/Test";
import CY_Place from "@/views/CY_Place";
import CY_Booking from "@/views/CY_Booking";
import * as auth from "../util/Auth";
import {getUser} from "../util/Auth";
import store from '@/store';

Vue.use(VueRouter)

const routes = [
    {
        path: "/login",
        name: "Login",
        component: Login
    },
    {
        path: "/resetpw",
        name: "ResetPW",
        component: ResetPW
    },
    {
        path: "/",
        name: "Home",
        component: Home,
        async beforeEnter(to, from, next) {
            let token = localStorage.getItem("token");
            let check = false;
            if (token !== null)
                check = await auth.result(token);


            let user = "";
            let rolle = "";
            if (check) {
                user = await getUser(token);
                rolle = user.rolle;
            }


            // let rolle =  store.state.rolle;

            if (!check) {

                next('/login');

            } else {
                if (rolle === "reiseburo" || rolle === "admin" || rolle === "superadmin") {
                    next();
                } else {
                    next('/hotellist');
                }
            }
        }
    },
    {
        path: "/hotellist",
        name: "Hotellist",
        component: Hotellist,
        async beforeEnter(to, from, next) {
            let token = localStorage.getItem("token");

            let check = await auth.result(token);
            let user = await getUser(token);
            let rolle = user.rolle;
            // let rolle = store.state.rolle;

            if (!check) {

                next('/login');

            } else {
                if (rolle === "hotel" || rolle === "admin" || rolle === "superadmin") {
                    next();
                } else {
                    next('/');
                }
            }
        }
    },
    {
        path: "/createhotel",
        name: "CreateHotel",
        component: CreateHotel,
        async beforeEnter(to, from, next) {
            let token = localStorage.getItem("token");

            let check = await auth.result(token);
            let user = await getUser(token);
            let rolle = user.rolle;
            // let rolle = store.state.rolle;

            if (!check) {

                next('/login');

            } else {
                if (rolle === "hotel" || rolle === "admin" || rolle === "superadmin") {
                    next();
                } else {
                    next('/');
                }
            }
        }
    },
    {
        path: "/createroom",
        name: "CreateRoom",
        component: CreateRoom,
        async beforeEnter(to, from, next) {
            let token = localStorage.getItem("token");

            let check = await auth.result(token);
            let user = await getUser(token);
            let rolle = user.rolle;
            // let rolle = store.state.rolle;
            if (!check) {

                next('/login');

            } else {
                if (rolle === "hotel" || rolle === "admin" || rolle === "superadmin") {
                    next();
                } else {
                    next('/');
                }
            }
        }
    },
    {
        path: "/createtransfer",
        name: "CreateTransfer",
        component: CreateTransfer,
        async beforeEnter(to, from, next) {
            let token = localStorage.getItem("token");

            let check = await auth.result(token);
            let user = await getUser(token);
            let rolle = user.rolle;
            // let rolle = store.state.rolle;
            if (!check) {

                next('/login');

            } else {
                if (rolle === "superadmin") {
                    next();
                } else {
                    next('/');
                }
            }
        }
    },
    {
        path: "/accounts",
        name: "Accounts",
        component: Accounts,
        async beforeEnter(to, from, next) {
            let token = localStorage.getItem("token");

            let check = await auth.result(token);
            let user = await getUser(token);
            let rolle = user.rolle;
            // let rolle = store.state.rolle;

            if (!check) {

                next('/login');

            } else {
                if (rolle === "admin" || rolle === "superadmin") {
                    next();
                } else {
                    next('/');
                }
            }
        }
    },
    {
        path: "/createuser",
        name: "Createuser",
        component: CreateUser,
        async beforeEnter(to, from, next) {
            let token = localStorage.getItem("token");

            let check = await auth.result(token);
            let user = await getUser(token);
            let rolle = user.rolle;
            // let rolle = store.state.rolle;

            if (!check) {

                next('/login');

            } else {
                if (rolle === "admin" || rolle === "superadmin") {
                    next();
                } else {
                    next('/');
                }
            }
        }
    },
    {
        path: "/orders",
        name: "Orders",
        component: Orders,
        async beforeEnter(to, from, next) {
            let token = localStorage.getItem("token");

            let check = await auth.result(token);
            let user = await getUser(token);
            let rolle = user.rolle;
            // let rolle = store.state.rolle;

            if (!check) {

                next('/login');

            } else {
                if (rolle === "reiseburo" || rolle === "admin" || rolle === "superadmin") {
                    next();
                } else {
                    next('/');
                }
            }
        }
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        async beforeEnter(to, from, next) {
            let token = localStorage.getItem("token");

            let check = await auth.result(token);
            let user = await getUser(token);
            let rolle = user.rolle;
            // let rolle = store.state.rolle;

            if (!check) {

                next('/login');

            } else {
                if (rolle === "reiseburo" || rolle === "admin" || rolle === "superadmin") {
                    next();
                } else {
                    next('/');
                }
            }
        }
    },
    {
        path: "/place",
        name: "Place",
        component: Place,
        async beforeEnter(to, from, next) {
            let token = localStorage.getItem("token");

            let check = await auth.result(token);
            let user = await getUser(token);
            let rolle = user.rolle;
            // let rolle = store.state.rolle;

            if (!check) {

                next('/login');

            } else {
                if (rolle === "reiseburo" || rolle === "admin" || rolle === "superadmin") {
                    next();
                } else {
                    next('/');
                }
            }
        }
    },
    {
        path: "/booking",
        name: "Booking",
        component: Booking,
        async beforeEnter(to, from, next) {
            let token = localStorage.getItem("token");

            let check = await auth.result(token);
            let user = await getUser(token);
            let rolle = user.rolle;
            // let rolle = store.state.rolle;

            if (!check) {

                next('/login');

            } else {
                if (rolle === "reiseburo" || rolle === "admin" || rolle === "superadmin") {
                    next();
                } else {
                    next('/');
                }
            }
        }
    },
    {
        path: "/settings",
        name: "Settings",
        component: Settings,
        async beforeEnter(to, from, next) {
            let token = localStorage.getItem("token");

            let check = await auth.result(token);
            let user = await getUser(token);
            let rolle = user.rolle;
            // let rolle = store.state.rolle;

            if (!check) {

                next('/login');

            } else {
                next();
            }
        }
    },
    {
        path: "/settingsplace",
        name: "SettingsPlace",
        component: SettingsPlace,
        async beforeEnter(to, from, next) {
            let token = localStorage.getItem("token");

            let check = await auth.result(token);
            let user = await getUser(token);
            let rolle = user.rolle;
            // let rolle = store.state.rolle;

            if (!check) {

                next('/login');

            } else {
                if (rolle === "superadmin") {
                    next();
                } else {
                    next('/');
                }
            }
        }
    },
    {
        path: "/settingskeys",
        name: "SettingsKeys",
        component: SettingsKeys,
        async beforeEnter(to, from, next) {
            let token = localStorage.getItem("token");

            let check = await auth.result(token);
            let user = await getUser(token);
            let rolle = user.rolle;
            // let rolle = store.state.rolle;

            if (!check) {
                next('/login');
            } else {
                if (rolle === "admin" || rolle === "superadmin") {
                    next();
                } else {
                    next('/');
                }
            }
        }
    },
    {
        path: "/settingsbooking",
        name: "SettingsBooking",
        component: SettingsBooking,
        async beforeEnter(to, from, next) {
            let token = localStorage.getItem("token");

            let check = await auth.result(token);
            let user = await getUser(token);
            let rolle = user.rolle;
            // let rolle = store.state.rolle;

            if (!check) {
                next('/login');
            } else {
                if (rolle === "admin" || rolle === "superadmin") {
                    next();
                } else {
                    next('/');
                }
            }
        }
    },
    {
        path: "/settingsdesign",
        name: "SettingsDesign",
        component: SettingsDesign,
        async beforeEnter(to, from, next) {
            let token = localStorage.getItem("token");

            let check = await auth.result(token);
            let user = await getUser(token);
            let rolle = user.rolle;
            // let rolle = store.state.rolle;

            if (!check) {
                next('/login');
            } else {
                if (rolle === "admin" || rolle === "superadmin") {
                    next();
                } else {
                    next('/');
                }
            }
        }
    },
    {
        path: "/settingsFavorites",
        name: "SettingsFavorites",
        component: SettingsFavorites,
        async beforeEnter(to, from, next) {
            let token = localStorage.getItem("token");

            let check = await auth.result(token);
            let user = await getUser(token);
            let rolle = user.rolle;
            // let rolle = store.state.rolle;

            if (!check) {
                next('/login');
            } else {
                if (rolle === "admin" || rolle === "superadmin") {
                    next();
                } else {
                    next('/');
                }
            }
        }
    }, ,
    {
        path: "/successfulbooking",
        name: "SuccessfulBooking",
        component: SuccessfulBooking,
        async beforeEnter(to, from, next) {
            let token = localStorage.getItem("token");

            let check = await auth.result(token);
            let user = await getUser(token);
            let rolle = user.rolle;
            // let rolle = store.state.rolle;

            if (!check) {
                next('/login');
            } else {
                if (rolle === "reiseburo" || rolle === "admin" || rolle === "superadmin") {
                    next();
                } else {
                    next('/');
                }
            }
        }
    },
    {
        path: "/cy-place",
        name: "CY_Place",
        component: CY_Place,
        async beforeEnter(to, from, next) {
            let token = localStorage.getItem("token");

            let check = await auth.result(token);

            if (!check) {
                next('/login');
            } else {
                next();
            }
        }
    },
    {
        path: "/cy-booking",
        name: "CY_Booking",
        component: CY_Booking,
        async beforeEnter(to, from, next) {
            let token = localStorage.getItem("token");

            let check = await auth.result(token);
            if (!check) {

                next('/login');

            } else {
                next();
            }
        }
    },
    {
        path: "/test",
        name: "Test",
        component: Test,
        async beforeEnter(to, from, next) {
            let token = localStorage.getItem("token");

            let check = await auth.result(token);
            let user = await getUser(token);
            let rolle = user.rolle;
            // let rolle = store.state.rolle;

            if (!check) {

                next('/login');

            } else {
                if (rolle === "superadmin") {
                    next();
                } else {
                    next('/');
                }
            }
        }
    },

    //  Wenn eine Komponente importiert wird???
    // {
    //   path: '/about',
    //   name: 'About',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
