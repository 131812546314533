<template>
  <div class="div-booking">
    <v-snackbar
        v-model="notificationsnackbar"
        :timeout="6000"
        :color="notificationcolor"
        :top="true"
        :right="true"
        :vertical="true"
    >
      {{ notificationtext }}
      <v-btn
          color="white"
          text
          @click="notificationsnackbar = false"
      >
        {{ $t("Notification.Schliessen") }}
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

    <v-overlay :value="loadingb">
      <v-progress-circular
          v-if="loadingb"
          indeterminate
          color="primary"
          :size="50"
          :width="7"
      />
    </v-overlay>


    <div class="div-body-booking">

      <div class="div-body-booking1">


        <!-- ------------------------------------------------ Links -------------------------------------------------->
        <div class="div-body-left-booking">


          <!-- ------------------------------------------------ Hotelinfo -------------------------------------------------->
          <div class="div-guest-booking">
            <div class="div-hotelname-booking">
              <div>
                <img
                    v-if="ownPlace.place_id"
                    class="img-halalplace-booking"
                    :src='ownPlace.pictures_place[0].url'
                    alt=""
                >
              </div>
              <div class="div-hotelname-booking1">

                <v-rating
                    v-if="ownPlace.place_id"
                    v-model="ownPlace.stars"
                    background-color="transparent"
                    color="#FFDF00"
                    small
                    readonly
                />

                <label v-if="ownPlace.place_id" class="label-placename-booking">
                  {{ ownPlace['place_name_' + pLang.toLowerCase()] }}
                </label>

                <div v-if="ownPlace.place_id" class="div-location-booking">
                  <v-icon color="black" small>mdi-map-marker-radius</v-icon>
                  <label style="font-size: 15px">{{ ownPlace.location.city }},
                                                 {{ ownPlace.location.subregion }},
                                                 {{ ownPlace.location.country }}
                  </label>
                </div>

              </div>
            </div>

            <div class="div-hotelname-booking1">
              <div class="div-guests-nights-booking">

                <span>
                  {{ anzahlPersonen }} {{ anzahlPersonen === 1 ? $t('Booking.Gast') : $t('Booking.Gaeste') }},
                </span>

                <span>
                  {{ anzahlNaechte }} {{ anzahlNaechte === 1 ? $t('Booking.Nacht') : $t('Booking.Naechte') }}
                </span>

              </div>
              <div>
                <div class="div-checkin-booking">
                  <div>
                    <label style="color: darkgrey;">
                      {{ $t('Place.Check-in') }}
                    </label>
                  </div>
                  <div style="font-weight: bold">
                    {{ selectedstartdate | moment("ddd, DD. MMMM") }}
                  </div>

                  <div style="white-space: nowrap"><span v-if="pLang!=='TR'">
                    {{ $t('Place.Check-in-nach') }}
                  </span>{{ ownPlace.checkin_time }}<span v-if="pLang==='TR'">
                    {{ $t('Place.Check-in-nach') }}
                  </span>
                  </div>
                </div>
                <div class="div-checkin-booking">
                  <div>
                    <label style="color: darkgrey;">
                      {{ $t('Place.Check-out') }}
                    </label>
                  </div>
                  <div style="font-weight: bold">
                    {{ selectedenddate | moment("ddd, DD. MMMM") }}
                  </div>
                  <div style="white-space: nowrap"><span v-if="pLang!=='TR'">
                    {{ $t('Place.Check-out-vor') }}
                  </span>{{ ownPlace.checkout_time }}<span v-if="pLang==='TR'">
                    {{ $t('Place.Check-out-vor') }}
                  </span>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <!-- ------------------------------------------------ Räume -------------------------------------------------->
          <template v-for="(room,index) in gruppe">

            <div class="div-guest-booking">
              <div class="div-rooms-booking">
                <div class="div-roomtitle-booking">

                  <div>
                    <img
                        class="img-halalroom-booking"
                        :src='selectedRoom[index].pictures_rooms[0].url'
                        alt=""
                    >
                  </div>
                  <div>
                    <div v-if="gruppe.length>1">Gruppe {{ index + 1 }}</div>
                    <h3>
                      {{ selectedRoom[index]['name_' + pLang.toLowerCase()] }}
                    </h3>
                    <div class="div-persons-per-room-booking">
                      {{ room.erwachsene + room.kinder }}
                      <v-icon color="black" small style="margin-left: 5px">mdi-close</v-icon>
                      <v-icon color="black">mdi-account</v-icon>
                    </div>

                  </div>
                </div>

                <v-radio-group v-model="ratePlan[index]" v-if="selectedRoom[index].rate_plans[0]._id !== null">
                  <template v-for="(item,n) in selectedRoom[index].rate_plans">

                    <div class="div-roomprice-booking" @click="calculatePrice">
                      <v-radio
                          :key="n"
                          :value="item.rate_id"
                      >
                        <template v-slot:label>
                          <div class="div-roomprice-booking2">
                            <div class="div-roomprice-booking1">
                              {{
                                formatPrice(calculateCurrency(ownPlace.currency, pCurrency, pCurrencyValues, item.price))
                              }}
                              <v-icon color="white" small style="margin-left: 5px">{{ currencyicon }}</v-icon>
                            </div>
                            <div>
                              <div style="font-weight: bold; font-size: 18px">
                                {{ getRatePlanByID(item.rate_plan_id) }}
                              </div>

                              <v-tooltip bottom close-delay="0">
                                <template v-slot:activator="{ on }">
                                  <div v-on="on" style="display: flex; cursor: pointer;">
                                    <v-icon color="var(--Secondary)" small>mdi-book-cancel</v-icon>
                                    <div class="div-storno-booking">
                                      {{ $t("Booking.Stornierungsbedingungen") }}
                                    </div>
                                  </div>
                                </template>
                                <div class="div-tooltip-storno-booking">
                                  <div v-if="item.rate_plan_id" style="text-align: start; max-width: 300px;">
                                    {{ selectedRoom[index]['cancellation_' + pLang.toLowerCase()] }}
                                  </div>

                                </div>
                              </v-tooltip>

                            </div>
                          </div>
                        </template>
                      </v-radio>
                    </div>

                  </template>

                </v-radio-group>


              </div>

            </div>

          </template>


          <!-- ------------------------------------------------ Hauptgast -------------------------------------------------->

          <div class="div-guest-booking">

            <div style="text-align: start;">
              <h3>
                {{ $t("Booking.Hauptgast") }}

              </h3>
            </div>

            <div class="div-guest-name-booking">
              <v-select
                  v-model="hauptgast.anrede"
                  :items="anrede"
                  :item-value="'anredeid'"
                  :label="$t('Booking.Anrede')+'*'"
                  outlined
                  @change="validateInputs"
                  :error="checkAnrede"
              />
              <v-text-field
                  v-model="hauptgast.vorname"
                  :label="$t('Booking.Vorname')+'*'"
                  outlined
                  @change="validateInputs"
                  :error="checkVorname"
              />
              <v-text-field
                  v-model="hauptgast.nachname"
                  :label="$t('Booking.Nachname')+'*'"
                  outlined
                  @change="validateInputs"
                  :error="checkNachname"
              />
            </div>
            <div class="div-guest-mail-booking">
              <v-text-field
                  v-model="hauptgast.email"
                  :rules="[rules.required, rules.email]"
                  :label="$t('Booking.E-Mail')+'*'"
                  outlined
                  prepend-inner-icon="mdi-at"
                  @change="validateInputs"
                  :error="checkEmail"
                  class="vtext-email-booking"

              />
              <v-text-field
                  v-model="hauptgast.tel"
                  type="number"
                  :label="$t('Booking.Telefon')+'*'"
                  outlined
                  prepend-inner-icon="mdi-phone"
                  @change="validateInputs"
                  :error="checkTel"
                  class="vtext-email-booking"

              />
            </div>

            <div class="div-guest-adress-booking">
              <v-text-field
                  v-model="hauptgast.str"
                  :label="$t('Booking.Straße')+'*'"
                  outlined
                  @change="validateInputs"
                  :error="checkStr"

              />
              <v-text-field
                  v-model="hauptgast.strnr"
                  :label="$t('Booking.Nr.')+'*'"
                  outlined
                  @change="validateInputs"
                  :error="checkStrnr"

              />
            </div>
            <div>

              <v-text-field
                  v-model="hauptgast.addresszusatz"
                  :label="$t('Booking.Adresszusatz')"
                  outlined
              />
            </div>

            <div class="div-guest-plz-booking">
              <v-text-field
                  v-model="hauptgast.plz"
                  :label="$t('Booking.PLZ')+'*'"
                  outlined
                  type="number"
                  @change="validateInputs"
                  :error="checkPlz"
              />

              <v-text-field
                  v-model="hauptgast.ort"
                  :label="$t('Booking.Ort')+'*'"
                  outlined
                  @change="validateInputs"
                  :error="checkOrt"
              />

            </div>

            <div>
              {{ $t("Booking.ZusatzlicheInfos") }}
            </div>
            <v-textarea
                v-model="notes"
                :label="$t('Booking.Informationen')"
                outlined
                auto-grow
            />

          </div>

        </div>
        <!-- ------------------------------------------------ RECHTS -------------------------------------------------->

        <div class="div-body-right-booking">

          <!-- ------------------------------------------------ TotelPrice Anfang -------------------------------------------------->


          <CY_TotalPrice
              :checkin="selectedstartdate"
              :checkout="selectedenddate"
              :ownPlace="ownPlace"
              :ownPlaceGroups="ownPlace.groups"
              :gruppe="gruppe"
              :selectedRoom="selectedRoom"
              :gesamtpreis="gesamtpreis"
              :checkinput="checkinputs"
              :selectedRatePlan="selectedRatePlan"
              :showbutton="false"
              :eurogesamtpreis="price_eur"
              :europreisprogruppe="price_per_group_eur"
              :usdpreisprogruppe="price_per_group_usd"
              :trygesamtpreis="price_try"
              :usdgesamtpreis="price_usd"
              :trypreisprogruppe="price_per_group_try"
              :totalprice_int="totalprice_int"
              :key="totalprice_int"
              :pLang="pLang"
              :pCurrency="pCurrency"
              :pCurrencyValues="pCurrencyValues"
              :pPlaceCurrency="ownPlace.currency"
              from="CY_Booking"
          />

          <!-- ------------------------------------------------ TotelPrice ENDE -------------------------------------------------->


          <!-- ------------------------------------------------ Hinweise -------------------------------------------------->

          <div class="div-guest-booking" style="text-align: start" v-for="(cancle,n) in stornierung">

            Gruppe {{ n + 1 }}
            <h3>
              <v-icon color="error" style="position: unset">mdi-alert</v-icon>
              {{ $t("Booking.Stornierungsbedingungen") }}
            </h3>
            <div v-if="cancle">{{ cancle }}</div>
          </div>
        </div>


      </div>


      <div v-if="checkinputs" class="div-book-booking">
        <h3>
          {{ $t("Booking.Pflichtfelder") }}
        </h3>

        <v-btn
            @click="notification('Bitte alle Felder ausfüllen!','error')"
            large color="var(--Primary)"
            style="color: white"
        >
          {{ $t("Button.Buchen") }}
        </v-btn>

      </div>

      <div v-if="!checkinputs">
        <v-btn color="var(--Primary)" @click="setbooking('','4')" style="color: white">
          {{ $t("Button.HotelReservieren") }}
        </v-btn>

      </div>


    </div>


  </div>

</template>

<script>
import {formatPrice, getCurrency, getCurrencyIcon} from "@/util/currency";
import {getAllAnrede, getoneAnrede} from "@/util/Anrede";
import moment from 'moment';
import TotalPrice from "@/components/card/TotalPrice";
import {postOrder} from "@/util/orderUtil";
import {generateReservierungsnr} from "@/util/GenerateId";
import Paypal from "@/components/paypal/Paypal";
import {getApprovalSettings, getpclssSetting} from "@/util/settingsUtil";
import {
  MailToagencybyagency3,
  MailTocustomerbevorconfirmation7,
  MailTocustomerbycustomer1,
  MailTohotelbyall4
} from "@/util/mailSend";
import {getOwnPlaceByPlaceID} from "@/util/OwnPlace";
import CY_TotalPrice from "@/components/cy_card/CY_TotalPrice";
import {calculatePrice, checkChildrenAge, getDayPrice} from "@/util/PriceOwnPlaceUtil";
import {getRatePlanByID} from "@/util/rateplansUtil";
import {getAdminUser} from "@/util/userUtil";
import {calculateCurrency} from "@/util/currencyUtil";
import {getRechtlichesByID, getUserinfoByUserId} from "@/util/userinfoDBUtil";

moment.locale('de');


export default {
  name: "CY_Booking",
  props: [
    'pLang',
    'pRolle',
    'pLogo',
    'pUserinfo',
    'pUser',
    'pRechtliches',
    'pDomain',
    'pCurrency',
    'pCurrencyValues'
  ],
  components: {
    CY_TotalPrice,
    TotalPrice,
    Paypal
  },

  data() {
    return {
      notificationsnackbar: false,
      notificationtext: "",
      notificationcolor: "grey",
      loadingb: true,


      currencyicon: getCurrencyIcon(),
      ratePlan: [],
      hb_facts: [],
      place_adress: "",

      ownPlace: {},
      halalRoom: {},
      gruppe: [],
      anzahlPersonen: 0,
      anzahlNaechte: 0,

      checkin: "",
      checkout: "",
      groups: "",
      selectedstartdate: "",
      selectedenddate: "",


      gesamtpreis: 0,
      gesamtpreis_try: 0,

      selectedRoom: [{text: "Kein Raum ausgewählt!"}],
      selectedRatePlan: [{name: "", preis: 0, stornierung: ""}],
      stornierung: [],


      order_id: "",
      orid: "",
      OHP: "",


      anrede: [],
      hauptgast: {
        anrede: "",
        vorname: "",
        nachname: "",
        email: "",
        tel: "",
        str: "",
        strnr: "",
        plz: "",
        ort: "",
        addresszusatz: ""
      },
      notes: "",
      hotel_owner: "2",
      order_approval: "",


      checkinputs: true,
      checkAnrede: false,
      checkVorname: false,
      checkNachname: false,
      checkEmail: false,
      checkTel: false,
      checkStr: false,
      checkStrnr: false,
      checkPlz: false,
      checkOrt: false,

      place_usd: "",
      place_eur: "",
      place_try: "",
      offer_eur: [],
      offer_try: [],
      price_eur: 0,
      price_try: 0,
      price_usd: 0,
      price_per_group_eur: 0,
      price_per_group_try: 0,
      price_per_group_usd: 0,

      totalprice_int: 0,

      userinfo: "",
      rules: {
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value);
        }
      },

      check_facts: [
        {propertyType: false, title: "", text: ""},
        {stars: false, title: "", text: ""},
        {checkin: false, title: "", text: ""},
        {checkout: false, title: "", text: ""},
        {language: false, title: "", text: ""},
        {location: false, title: "", text: ""},
      ]
    };
  },


  methods: {
    calculateCurrency,
    getRatePlanByID,
    getCurrency,
    getCurrencyIcon,
    formatPrice,
    getoneAnrede,
    notification(text, color) {
      this.notificationsnackbar = true;
      this.notificationtext = text;
      this.notificationcolor = color;
    },
    zuPlace(id) {
      this.$router.push({path: 'Place', query: {id: id}});
      window.scrollTo(0, 0);
    },


    calculatePrice() {
      let gprice = 0;
      this.selectedRatePlan = [];
      this.stornierung = [];


      this.ratePlan.map((item, index) => {

        if (item !== null && this.selectedRoom[index].rate_plans[0].rate_plan_id !== null) {
          this.selectedRoom[index].rate_plans.map(it => {
            if (it.rate_id === item) {
              gprice += it.price;
              this.stornierung.push(this.selectedRoom[index]['cancellation_' + this.pLang.toLowerCase()]);
              this.selectedRatePlan.push({
                name: it.rate_plan_id,
                rate_id: this.ratePlan[index],
                preis: it.price,
                stornierung: it['cancellation_' + this.pLang.toLowerCase()]
              });
            }
          })

        }

      })

      // if (this.place_eur !== "") {
      //
      //   this.offer_eur = [];
      //   let tmpSelectedRoom = [];
      //   this.selectedRoom.map(item => {
      //     tmpSelectedRoom.push({value: item, found: false})
      //   })
      //
      //   this.selectedRoom.map((item, index) => {
      //     let rid = tmpSelectedRoom[index];
      //
      //     this.place_eur.groups.map((it, i) => {
      //       if (index === i) {
      //         it.offers.map((offer, oindex) => {
      //           if (offer.room.id === rid.value.room.id) {
      //
      //             if (rid.found === false) {
      //               this.offer_eur.push(offer);
      //
      //               rid.found = true;
      //             }
      //           }
      //         })
      //       }
      //     })
      //   })
      //
      // }

      // if (this.place_eur !== "") {
      //   let sum = 0;
      //   let price_per_group = [];
      //   let tmpselectedRatePlan = [];
      //   this.selectedRatePlan.map(item => {
      //     tmpselectedRatePlan.push({value: item, found: false})
      //   })
      //
      //   this.selectedRatePlan.map((item, index) => {
      //     this.offer_eur[index].rate_plans.map((r_plan, rateindex) => {
      //       if (r_plan.rate_plan.id === tmpselectedRatePlan[index].value.id) {
      //         if (tmpselectedRatePlan[index].found === false) {
      //           sum += r_plan.price;
      //           price_per_group.push({price: r_plan.price});
      //           tmpselectedRatePlan[index].found = true;
      //         }
      //       }
      //     })
      //   })
      //
      //
      //   this.price_eur = sum;
      //   this.price_per_group = price_per_group;
      // }


      this.gesamtpreis = gprice;

      // if (this.ownPlace.place.location.country === "Türkei" || this.ownPlace.place.location.country === "Türkiye" || this.ownPlace.place.location.country === "Turkey") {
      //   this.calculatePriceTRY();
      // }

      this.totalprice_int++;
      this.$forceUpdate();


    },


    calculatePriceTRY() {


      if (this.place_try !== "") {

        this.offer_try = [];
        let tmpSelectedRoom = [];
        this.selectedRoom.map(item => {
          tmpSelectedRoom.push({value: item, found: false})
        })

        this.selectedRoom.map((item, index) => {
          let rid = tmpSelectedRoom[index];

          this.place_try.groups.map((it, i) => {
            if (index === i) {
              it.offers.map((offer, oindex) => {
                if (offer.room.id === rid.value.room.id) {

                  if (rid.found === false) {
                    this.offer_try.push(offer);

                    rid.found = true;
                  }


                }
              })
            }
          })

        })
      }

      if (this.place_try !== "") {
        let sum = 0;
        let price_per_group = [];
        let tmpselectedRatePlan = [];
        this.selectedRatePlan.map(item => {
          tmpselectedRatePlan.push({value: item, found: false})
        })


        this.selectedRatePlan.map((item, index) => {

          this.offer_try[index].rate_plans.map((r_plan, rateindex) => {

            if (r_plan.rate_plan.id === tmpselectedRatePlan[index].value.id) {

              if (tmpselectedRatePlan[index].found === false) {

                sum += r_plan.price;
                price_per_group.push({price: r_plan.price});
                tmpselectedRatePlan[index].found = true;
              }
            }
          })

        })


        this.price_try = sum;
        this.price_per_group_try = price_per_group;
      }


      // this.gesamtpreis_try = gprice;
      this.$forceUpdate();
    },


    validateInputs() {
      let validate = true;

      if (this.hauptgast.anrede === "") {
        this.checkAnrede = true;
        validate = false;
      } else {
        this.checkAnrede = false;
      }

      if (validate === true && this.hauptgast.vorname === "") {
        this.checkVorname = true;
        validate = false;
      } else {
        this.checkVorname = false;
      }

      if (validate === true && this.hauptgast.nachname === "") {
        this.checkNachname = true;
        validate = false;
      } else {
        this.checkNachname = false;
      }

      if (validate === true && this.hauptgast.email === "") {
        this.checkEmail = true;
        validate = false;
      } else {
        this.checkEmail = false;
      }


      if (validate === true && this.hauptgast.tel === "") {
        this.checkTel = true;
        validate = false;
      } else {
        this.checkTel = false;
      }


      if (validate === true && this.hauptgast.str === "") {
        this.checkStr = true;
        validate = false;
      } else {
        this.checkStr = false;
      }


      if (validate === true && this.hauptgast.strnr === "") {
        this.checkStrnr = true;
        validate = false;
      } else {
        this.checkStrnr = false
      }


      if (validate === true && this.hauptgast.plz === "") {
        this.checkPlz = true;
        validate = false;
      } else {
        this.checkPlz = false;
      }


      if (validate === true && this.hauptgast.ort === "") {
        this.checkOrt = true;
        validate = false;
      } else {
        this.checkOrt = false;
      }


      if (validate === true) {
        this.checkinputs = false;
      }

      if (validate === false) {
        this.checkinputs = true;
      }

    },


    async setbooking(paypalresponse, payed_by) {


      let accommodations = [];
      this.gruppe.map((item, index) => {
        let erw = item.erwachsene + "";
        let kind = "";
        if (item.kinderalter.length > 0) {
          item.kinderalter.map((it, i) => {
            kind += it.age;
            if (item.kinderalter.length > (i)) {
              kind += ",";
            }
          });

        }
        let g = "";
        if (kind !== "") {
          g = erw + "," + kind;
        } else {
          g = erw;
        }
        accommodations.push({
          group: g,
          room_id: this.selectedRoom[index].room_id,

          rate_plan_id: this.selectedRatePlan[index].rate_id

        });

        accommodations.map(item => {
              if (item.rate_plan_id === null) {
                item.rate_plan_id = 0
              }
            }
        )

      })
      let lang = this.pLang;
      if (this.pLang === "TR") {
        lang = "DE";
      }


      let admin = await getAdminUser();
      let adminuserinfo = await getUserinfoByUserId(admin._id);
      let adminrechtliches = await getRechtlichesByID(admin._id);

      let obj = {

        title: this.hauptgast.anrede,
        first_name: this.hauptgast.vorname,
        last_name: this.hauptgast.nachname,
        email: admin.email,
        phone: adminuserinfo.kontakt.mobile,
        notes: this.notes,
        country_code: lang,

        stages_attributes: [{
          checkin_on: this.checkin,
          checkout_on: this.checkout,
          place_id: this.ownPlace.place_id,


          accommodations: accommodations

        }],
        payment_attributes: {
          currency: getCurrency()
        },
      };


      let delay = false;

      this.selectedRoom.map(item => {
        if (item.confirmation === "delayed") {
          delay = true;
        }
      })
      let confirmed = "";
      if (this.order_approval === false || delay) {
        confirmed = "notConfirmed";
      } else {
        confirmed = "isConfirmed"
      }


      let rooms = [];
      let rateplans = [];
      this.selectedRoom.map((item, index) => {
        rooms.push({
          room_name_de: item.name_de,
          room_name_tr: item.name_tr,
          room_name_en: item.name_en,
          quantity: item.anzahl_zimmer,
          cancellation_de: item.cancellation_de,
          cancellation_tr: item.cancellation_tr,
          cancellation_en: item.cancellation_en,
        });
      });

      this.selectedRatePlan.map((it, n) => {
        rateplans.push({
          rateplan_name: it.name,
          rateplan_text: getRatePlanByID(it.name),
          rateplan_id: it.rate_id,
          room_price: it.preis.toString(),
          // room_cancellation: it.stornierung

        })
      });


      let countPersons = [];
      let adults = 0;
      let childs = 0;
      this.gruppe.map(item => {
        countPersons.push({adults: item.erwachsene, childs: item.kinder})
        adults += item.erwachsene;
        childs += item.kinder;
      });


      // ---------- EUR ---------------
      let total_price_eur = 0;
      this.price_per_group_eur = [];
      this.selectedRatePlan.map(it => {
        this.price_per_group_eur.push(calculateCurrency(this.ownPlace.currency, "EUR", this.pCurrencyValues, parseFloat(it.preis)));

      })
      let price_per_group_eur = this.price_per_group_eur;
      this.price_per_group_eur.map(pr => {
        total_price_eur += pr.price;
      })


      // ---------- TRY ---------------
      let total_price_try = 0;
      this.price_per_group_try = [];
      this.selectedRatePlan.map(it => {
        this.price_per_group_try.push(calculateCurrency(this.ownPlace.currency, "TRY", this.pCurrencyValues, parseFloat(it.preis)));
      })

      let price_per_group_try = this.price_per_group_try;
      this.price_per_group_try.map(pr => {
        total_price_try += pr.price;
      })


      // ---------- USD ---------------
      let total_price_usd = 0;
      this.price_per_group_usd = [];
      this.selectedRatePlan.map(it => {
        this.price_per_group_usd.push(calculateCurrency(this.ownPlace.currency, "USD", this.pCurrencyValues, parseFloat(it.preis)));
      })

      let price_per_group_usd = this.price_per_group_usd;
      this.price_per_group_usd.map(pr => {
        total_price_usd += pr.price;
      })


      let opp = this.ownPlace.provision.toString();


      let hotelinfos = {
        place_id: this.ownPlace.place_id,
        hotelname: this.ownPlace.place_name_de,
        hotelname_de: this.ownPlace.place_name_de,
        hotelname_tr: this.ownPlace.place_name_tr,
        hotelname_en: this.ownPlace.place_name_en,
        hotellocation: this.ownPlace.location,

        checkin: this.checkin,
        checkout: this.checkout,
        checkin_time: this.ownPlace.checkin_time,
        checkout_time: this.ownPlace.checkout_time,
        countPersons: countPersons,
        adults: adults,
        childs: childs,
        gruppe: this.gruppe,
        rooms: rooms,
        rateplans: rateplans,
        total_price: this.gesamtpreis.toString(),
        // total_price: calculateCurrency(this.ownPlace.currency, this.pCurrency, this.pCurrencyValues, parseFloat(this.gesamtpreis)),

        own_currency: this.ownPlace.currency,
        currency: this.pCurrency,

        changed_price: "0",

        total_price_eur: calculateCurrency(this.ownPlace.currency, "EUR", this.pCurrencyValues, parseFloat(this.gesamtpreis)),
        price_per_group_eur: price_per_group_eur,
        total_price_try: calculateCurrency(this.ownPlace.currency, "TRY", this.pCurrencyValues, parseFloat(this.gesamtpreis)),
        price_per_group_try: price_per_group_try,
        total_price_usd: calculateCurrency(this.ownPlace.currency, "USD", this.pCurrencyValues, parseFloat(this.gesamtpreis)),
        price_per_group_usd: price_per_group_usd,

        kunden_email: this.hauptgast.email,

        isConfirmed: confirmed,
        delay: delay,
        changed_currency: {
          currency: this.pCurrency,
          currency_values: {EUR: this.pCurrencyValues.EUR, TRY: this.pCurrencyValues.TRY}
        },


        OHP: {
          ohp_provision: opp,
          ohp_email: this.ownPlace.contact.email
        }

      }

      let order_by = "0";
      // Reisebüro daten hinzugefügt
      if (this.pRolle === "reiseburo") {

        let provisions = await getpclssSetting();
        let p = provisions.find(item => item.classname === this.pUser.provisionsklasse);

        let reiseburo = {

          firma: this.pUserinfo.kontakt.firma,
          firma_id: this.pUser.firma_id,
          provisionsklasse: this.pUser.provisionsklasse,
          provisionRB: p.provision,
          email: this.pUserinfo.kontakt.email,
          telefon: this.pUserinfo.kontakt.tel,
          strnr: this.pUserinfo.kontakt.strnr,
          plz: this.pUserinfo.kontakt.plz,
          ort: this.pUserinfo.kontakt.ort,
        }

        hotelinfos.reiseburo = reiseburo;

        order_by = "2";
      }
      // Reisebüro daten hinzugefügt ENDE


      let or = {
        firma_id: this.pUser.firma_id,
        order_id: this.order_id,
        // provision: "0",
        order_by: order_by,
        order_state: "0",
        comment: [],
        part_payment: [],

        // payed_by: "4" => Barzahlung
        payed_by: payed_by,
        rechnungsadresse: {
          email: this.hauptgast.email,
          str: this.hauptgast.str,
          nr: this.hauptgast.strnr,
          adressz: this.hauptgast.addresszusatz,
          plz: this.hauptgast.plz,
          ort: this.hauptgast.ort,
          tel: this.hauptgast.tel
        },
        hotelinfos: hotelinfos,

        order: obj,
        paypal_response: paypalresponse,
        hotel_owner: this.hotel_owner,
      };

      let logo = this.pLogo;
      logo = logo.replace("//", "");
      logo = logo.split("/")[1];
      or.logo = this.pLogo;
      or.filename = logo;

      or.rechtliches = adminrechtliches;
      or.domain = this.pDomain;
      or.userinfo = adminuserinfo;


      if (this.order_approval === false) {
        // Auf der Admin Seite wird keine Mail 7 an den Kunden verschickt
        //await MailTocustomerbevorconfirmation7(or);
      } else {


        if (this.hotel_owner === "2") {
          await MailTohotelbyall4(or);
          await MailTocustomerbevorconfirmation7(or);

        } else {

          if (confirmed === "notConfirmed") {
            or.order_state = "7";
            await MailTocustomerbevorconfirmation7(or);
          } else {

            await MailTocustomerbycustomer1(or);
          }
        }
      }

      or.admin = admin;

      await MailToagencybyagency3(or);

      let order = await postOrder(or);

      this.checkinputs = false;

      localStorage.removeItem("selectedroom");
      localStorage.removeItem("gruppe");
      localStorage.removeItem("__paypal_storage__");
      localStorage.removeItem('c-in');
      localStorage.removeItem('c-out');

      await this.$router.push("/successfulbooking");


    }
  },


  async created() {
    window.scrollTo(0, 2);


    let id = this.$route.query.id;
    this.groups = this.$route.query.groups;


    // ------------------------------------ DATUM anzeigen ------------------------------------
    if (localStorage.getItem('c-in') !== null) {
      let c_in = new Date(localStorage.getItem('c-in'));

      this.checkin = moment(c_in).format("YYYY-MM-DD");
      this.selectedstartdate = c_in;

    } else {

      let d = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 16);
      this.checkin = moment(d).format("YYYY-MM-DD");
      this.selectedstartdate = d;
    }

    if (localStorage.getItem('c-out') !== null
    ) {
      let c_out = new Date(localStorage.getItem('c-out'));
      this.checkout = moment(c_out).format("YYYY-MM-DD");
      this.selectedenddate = c_out;
    } else {

      let d1 = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 18);
      this.checkout = moment(d1).format("YYYY-MM-DD");
      this.selectedenddate = d1;
    }

    // ------------------------------------ DATUM anzeigen ENDE ------------------------------------


    // Berechnung der Nächte
    let days = null;
    let miliseconds = this.selectedenddate.getTime() - this.selectedstartdate.getTime();
    let total_seconds = parseInt(Math.floor(miliseconds / 1000));
    let total_minutes = parseInt(Math.floor(total_seconds / 60));
    let total_hours = parseInt(Math.floor(total_minutes / 60));
    days = parseInt(Math.floor(total_hours / 24));
    this.anzahlNaechte = days;


    // let place = await onePlaceWithParameter(id, this.checkin, this.checkout, '&groups[]=' + this.groups);
    let place = await getOwnPlaceByPlaceID("cy-" + id);
    this.ownPlace = place;

    // if (getCurrency() !== 'EUR') {
    //   this.place_eur = await onePlaceWithParameterLang(id, this.checkin, this.checkout, '&groups[]=' + this.groups, 'EUR');
    // }


    // this.ownPlace = await place;

    // if (getCurrency() !== 'TRY') {
    //   if (this.ownPlace.place.location.country === "Türkei" || this.ownPlace.place.location.country === "Türkiye" || this.ownPlace.place.location.country === "Turkey") {
    //     this.place_try = await onePlaceWithParameterLang(id, this.checkin, this.checkout, '&groups[]=' + this.groups, 'TRY');
    //   }
    // }


    this.selectedRoom = JSON.parse(localStorage.getItem("selectedroom"));


    this.gruppe = JSON.parse(localStorage.getItem("gruppe"));

    let anrede = getAllAnrede(this.pLang);
    anrede.map(item => this.anrede.push(item));


    for (let i = 0; this.gruppe.length > i; i++) {
      this.anzahlPersonen = this.anzahlPersonen + this.gruppe[i].erwachsene;
      this.anzahlPersonen = this.anzahlPersonen + this.gruppe[i].kinder;
    }
    this.gruppe.map(it => {
      delete it.rate_plans;
      delete it.children_price;
      delete it.baby_price;
      delete it.price;
      delete it.rooms;
    })


    this.selectedRoom.map((item, index) => {

      let ppT = getDayPrice(this.ownPlace.price, new Date(this.checkin), new Date(this.checkout), item.min_nights);

      item.rate_plans = [];
      this.ownPlace.rate_plans.map(it => {
        let o = checkChildrenAge(this.gruppe, index, this.ownPlace.baby_price, this.ownPlace.children_price);
        let p = calculatePrice(o.erwachsene, o.children, o.babies, ppT, it.price, item.aufpreis, item.min_persons, item.max_persons, item.baby_bett, this.ownPlace.children_price.rabatt, this.ownPlace.baby_price.rabatt, this.anzahlNaechte, item.min_nights);


        let ob = {
          ...it,
          price: p
        }
        item.rate_plans.push(ob);
      })


    })

    this.selectedRoom.map((it) => {
      this.ratePlan.push(it.rate_plans[0].rate_id);

    })


    this.$root.$on("selectedroom", (val, index) => {
      this.selectedRoom = val;
    })

    this.calculatePrice();
    // this.calculatePriceTRY();


    this.selectedRatePlan = [];
    let arr = [];
    this.gruppe.map((it, index) => {
      arr.push({
        name: this.selectedRoom[index].rate_plans[0].rate_plan_id,
        rate_id: this.ratePlan[index],
        preis: this.selectedRoom[index].rate_plans[0].price,
        stornierung: this.selectedRoom[index]['cancellation_' + this.pLang.toLowerCase()]
      });
    });
    this.selectedRatePlan = arr;


    //Paypal Order_id generieren
    this.order_id = generateReservierungsnr("0000");
    this.orid = this.order_id.split('-')[2];

    this.$root.$on("paypal_response", async (order) => {
      await this.setbooking(order, "0");
    })


    let setting = await getApprovalSettings();

    this.order_approval = setting.order_approval;

    this.loadingb = false;

  }

}
</script>

<style scoped>
@import "../css/Booking.css";
</style>