import axios from "axios";

export const createInvoice = async (invoice) => {
    let res = await axios.post(process.env.VUE_APP_DB + "/api2/invoice/", invoice, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});

    return res;
}

export const getInvoiceByInvNr = async (invoicenr) => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/invoicebyInvnr/" + invoicenr, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});

    return res.data.data;
}