<template>
  <div v-if="showOverlay">

    <v-overlay :value="showOverlay" opacity="0.9">
      <v-progress-circular
          v-if="loading"
          indeterminate
          color="primary"
          :size="50"
          :width="7"
      />
      <div class="div-asktoSave-overlay-orders">

        <div class="div-title-overlayorderstate">
          <label>
            <b>{{ title }} <span v-if="!checkPaypal">?</span></b>
          </label>


          <v-btn
              class="button-close-asktosave-overlayorderstate"
              icon
              color="black"
              @click="closeOverlay"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <div class="div-status-orders1" style="grid-template-columns: unset">
          <div v-if="!checkPaypal">
            <div class="div-checksendto-orders" v-if="item.order_state === '0' && item.hotel_owner === '0' ">
              <v-checkbox
                  color="blue"
                  v-model="checkSendToHalal"
                  :label="checkSendToHalal ? 'Reservierung wird an halalbooking gesendet': 'Reservierung wird nicht an halalbooking gesendet'"
              ></v-checkbox>
            </div>


            <v-btn class="button-state-orders" :color="buttonColor" @click="changeState(newState,item)">
              {{ button }}
            </v-btn>

            <v-btn class="button-state-orders" color="grey" @click="closeOverlay">
              {{ $t("Button.Abbrechen") }}
            </v-btn>
          </div>
          <div v-else>


            <v-btn class="button-state-orders" :color="buttonColor" @click="confirmPaypal(item)">
              {{ button }}
            </v-btn>

            <v-btn class="button-state-orders" color="grey" @click="closeOverlay">
              {{ $t("Button.Abbrechen") }}
            </v-btn>

          </div>
        </div>
      </div>
    </v-overlay>

  </div>

</template>

<script>

import {postBooking} from "@/util/bookingUtil";
import {changeState, updateOrder} from "@/util/orderUtil";
import {getNextState} from "@/util/OrderState";
import {
  MailTocustomeratcancel5,
  MailTocustomerbevorconfirmation7,
  MailTocustomerbycustomer1,
  MailTohotelatcancel6,
  MailTohotelbyall4
} from "@/util/mailSend";
import {getLogo} from "@/util/userinfoDBUtil";
import {getIDFromAdminUser} from "@/util/userUtil";

export default {
  name: "OverlayOrderState",
  props: [
    'showOverlay',
    'title',
    'newState',
    'item',
    // 'index',
    'checkPaypal',

    'button',
    'buttonColor',
    'pLogo',

    'pUserinfo',
    'pRechtliches',
    'pDomain',

  ],

  data() {
    return {


      loading: false,
      logo: "",
      checkSendToHalal: true
    }
  },

  methods: {

    //state = Neuer Status, Item= Order
    async changeState(state, item) {
      this.loading = true;

      let halalerror = "";

      // Wir packen Logo in item rein, damit wir es in der Mail anzeigen lassen können.
      item.logo = process.env.VUE_APP_IMAGES + this.logo.logo.picture;

      item.rechtliches = this.pRechtliches;
      item.domain = this.pDomain;
      item.userinfo = this.pUserinfo;


      let or = {
        ...item
      }
      if (state !== "6") {


        if (item.order_state === "0" && (state === "1" || state === "2")) {
          if (item.hotel_owner !== "1" && item.hotel_owner !== "2") {

            if (or.order.stages_attributes) {
              let booked = "";
              if (this.checkSendToHalal) {
                booked = await postBooking(item.order);

                if (!booked.error) {
                  delete booked.stage.place.photos;
                  booked.stage.rooms.map(item => {
                    delete item.photos;
                  });
                  item.halalerror = "0";

                  or = {
                    ...item,
                    order: booked
                  }
                } else {
                  item.halalerror = "1";
                  booked = item.order;

                  this.$root.$emit("fehlerbeisendenanhalal", false);

                  localStorage.setItem('halalerror', "true");

                }
              } else {
                booked = item.order;
              }

            }


          } else {

            if (item.halalerror !== "1") {
              await MailTohotelbyall4(item);
              or.order_state = "7"
            }

          }
          if (item.halalerror !== "1") {
            localStorage.setItem('stateto1', "true");
            await MailTocustomerbycustomer1(item);
          }

        }


        if (item.order_state === "0" && state === "7") {
          if (item.hotel_owner !== "1" && item.hotel_owner !== "2") {

            if (or.order.stages_attributes) {

              let booked = "";
              if (this.checkSendToHalal) {


                let booked = await postBooking(item.order);
                if (!booked.error) {
                  delete booked.stage.place.photos;
                  booked.stage.rooms.map(item => {
                    delete item.photos;
                  });

                  item.halalerror = "0";
                  or = {
                    ...item,
                    order: booked
                  }
                } else {
                  halalerror = "1";
                  booked = item.order;

                }

              } else {
                booked = item.order;
              }
            }


            await MailTocustomerbevorconfirmation7(item);

          } else {

            await MailTohotelbyall4(item);
            // Keine Bestätigungsmail an Kunde, da der Kunde schon auf der Kundenseite die Mail_7 erhält
            // await MailTocustomerbevorconfirmation7(item);
            //or.order_state = "7"

          }


        }

        if (item.order_state === "7" && (state === "1" || state === "2")) {

          localStorage.setItem('stateto1', "true");
          await MailTocustomerbycustomer1(item);
        }


        if (item.halalerror !== "1") {
          let paypal = false;
          let s = "";
          if (item.paypal_response !== '') {
            paypal = item.paypal_response.captures[0].status === 'COMPLETED';
          }
          if (or.order_state !== "7") {


            s = getNextState(item.order_state, paypal, '');


            if (state === "6" || state === "4" || state === "7") {
              s = state;
            }

            // if (item.payed_by === "0" && state !== "7" && state !== "4") {
            if (item.payed_by === "0" && state !== "7") {
              s = "2"
            }

          } else {
            s = state;
          }


          if (item.order_state === "7" && state !== "4") {
            or.hotelinfos.isConfirmed = "isConfirmed";
          }

          if (state === "1" || state === "2") {
            or.hotelinfos.isConfirmed = "isConfirmed";
          }

          if (state === "4") {
            s = state;
            localStorage.setItem('canOr', "true");

            await MailTocustomeratcancel5(item);

            if (item.hotel_owner === "1") {
              if (item.hotelinfos.isConfirmed === "isConfirmed") {
                await MailTohotelatcancel6(item)
              }
            }

          }
          await changeState(s, or);
        }

      } else {

        await changeState(state, item);
      }
      if (state === "6") {
        localStorage.setItem('closeOr', "true");

      }

      this.showOverlay = false;
      location.reload();

      
    },

    closeOverlay() {
      this.showOverlay = false;
      this.$root.$emit("showOverlay", false);


      // this.$destroy();
      // this.$el.parentNode.removeChild(this.$el);
    },
    async confirmPaypal(item) {

      item.paypal_response.captures[0].status = "COMPLETED";
      let status = await updateOrder(item);


      if (status === 200) {
        this.$root.$emit("bestaetigungPaypal", true);
      } else {
        this.$root.$emit("fehlerPaypal", true);
      }

      this.showOverlay = false;


    }

  },
  async created() {
    let admin = await getIDFromAdminUser();
    this.logo = await getLogo(admin._id);


  }
}
</script>

<style scoped>
@import "../../css/Overlay.css";
</style>