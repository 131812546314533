<template>
  <div class="div-successfulbooking">

    <h2>
      <v-icon color="green" large>mdi-check</v-icon>
      Ihre Buchung war erfolgreich!
    </h2>
    <br>
    <h3>Sie werden jetzt automatisch weitergeleitet!</h3>

    <v-progress-circular
        indeterminate
        color="primary"
        :size="50"
        :width="7"

    />


  </div>
</template>

<script>

export default {
  name: "SuccessfulBooking",
  methods: {
    async gotoHome() {

      await new Promise(resolve => setTimeout(resolve, 1500));
      await this.$router.push("/");
    }

  },
  async created() {
    window.scrollTo(0, 0);
    await this.gotoHome();
  }
}
</script>
<style scoped>
@import "../css/SuccessfulBooking.css";
</style>