import axios from "axios";
import {getUserToken} from "@/util/userCredentials";
import {getCurrency} from "@/util/currency";


const getAPI = () => {
    let lang = localStorage.getItem("lang");
    if (lang.toLowerCase() === "tr") {
        return "/api/"
        // process.env.VUE_APP_API_TR
    }
    if (lang.toLowerCase() === "de") {
        return "/api3/"
        // process.env.VUE_APP_API
    }
    if (lang.toLowerCase() === "en") {
        return "/api4/"
        // process.env.VUE_APP_API_EN
    }

    return process.env.VUE_APP_API;
}

const getAPIProd = () => {
    let lang = localStorage.getItem("lang");
    if (lang.toLowerCase() === "tr") {
        return process.env.VUE_APP_API_TR
        // process.env.VUE_APP_API_TR
    }
    if (lang.toLowerCase() === "de") {
        return process.env.VUE_APP_API
        // process.env.VUE_APP_API
    }
    if (lang.toLowerCase() === "en") {
        return process.env.VUE_APP_API_EN
        // process.env.VUE_APP_API_EN
    }

    return process.env.VUE_APP_API;
}

export const getInitPlaces = async (p) => {

    const token = await getUserToken()


    let currency = getCurrency();


    let d = {
        l: localStorage.getItem('lang'),
        currency: currency,
        page: p,
        token: token
    }

    let res = await axios.post(
        process.env.VUE_APP_DB + "/api2/halal", d, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}}
    );

    let data = res.data;

    return data.data;
}


export const onePlace = async (id, groups) => {
    const token = await getUserToken()


    let currency = getCurrency();

    let d = {
        currency: currency,
        l: localStorage.getItem('lang'),
        token: token,
        groups: groups,
        id: id
    }
    let res = await axios.post(
        process.env.VUE_APP_DB + "/api2/halalone", d, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}}
    );

    let data = res.data;
    return data;
}

export const onePlaceByID = async (id, lang) => {
    const token = await getUserToken()
    let currency = getCurrency();

    let d = {
        currency: currency,
        // l: localStorage.getItem('lang'),
        l: lang,
        token: token,
        groups: "&groups[]=2",
        id: id
    }
    let res = await axios.post(
        process.env.VUE_APP_DB + "/api2/halalone", d, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}}
    );

    let data = res.data;
    return data.data;
}

export const onePlaceWithParameter = async (id, checkin, checkout, groups) => {


    const token = await getUserToken();
    let currency = getCurrency();
    let res = "";
    let d = {
        token: token,
        currency: currency,
        id: id,
        checkin: checkin,
        checkout: checkout,
        groups: groups,
        l: localStorage.getItem('lang')
    }
    try {
        res = await axios.post(
            process.env.VUE_APP_DB + "/api2/halalonepara", d, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}}
        );
    } catch (e) {
        return "";
    }
    let data = res.data;

    return data.data;
}

export const onePlaceWithParameterLang = async (id, checkin, checkout, groups, currency) => {


    const token = await getUserToken();
    //let currency = getCurrency();
    let res = "";
    let d = {
        token: token,
        currency: currency,
        id: id,
        checkin: checkin,
        checkout: checkout,
        groups: groups,
        l: localStorage.getItem('lang')
    }
    try {
        res = await axios.post(
            process.env.VUE_APP_DB + "/api2/halalonepara", d, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}}
        );
    } catch (e) {
        return "";
    }
    let data = res.data;

    return data.data;
}
//-----------------------------------------------------------------------------------------------------------------
// wird bei der suche verwendet
export const callapi = async (url, page) => {
    const token = await getUserToken()

    let d = {
        token: token,
        l: localStorage.getItem('lang'),
        url: url,
        page: page,
        currency: getCurrency()
    }

    let res = await axios.post(
        process.env.VUE_APP_DB + "/api2/halalcall", d, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}}
    );

    let data = res.data;
    return data.data;
}
export const getHalalAllForOwnDB = async (page) => {
    const token = await getUserToken()

    let d = {
        token: token,
        lang: localStorage.getItem('lang'),
        location: "Turkey",
        page: page,
        currency: getCurrency()
    }

    let res = await axios.post(
        process.env.VUE_APP_DB + "/api2/halalcallall", d, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}}
    );

    let data = res.data;
    return data.data;
}


//-----------------------------------------------------------------------------------------------------------------
//Mehtode zum ziehen der einzelnen Seiten um in die DB zu speichern
/*
export const getALLHalalPlaces = async (location, page) => {

    const token = await getUserToken()

    let holiday_type = "xx";
    let currency = await getCurrency();
    let checkin = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 14);
    let checkout = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 16);
    let groups = "2";


    let res = await axios.get(
        getAPIProd() + 'v2/places?holiday_type=' + holiday_type + '&currency=' + currency + '&checkin=' + checkin + '&checkout=' + checkout + '&groups[]=' + groups + "&location=" + location + '&page=' + page,

        {
            headers: {
                "Authorization": 'Basic ' + token,
                "Accept": "application/json",
                'Access-Control-Allow-Origin': '*',
                "Access-Control-Allow-Headers": "Origin,Content-Type,X-Requested-With,Accept,Authorization",
                'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PATCH, PUT',
                 "authorization": 'Bearer ' + localStorage.getItem('token')
            }
        });

    let data = res.data;
    return data;
}*/
