<template>
  <div style="margin: 20px 0; border: 1px solid grey; border-radius: 5px; padding: 10px;">

    <h3>{{ title }}</h3>
    <template v-if="title ==='Pools' || title ==='Beach'">
      <div>Datum {{ title }}</div>


      <div>Uhrzeiten {{ title }}</div>


    </template>

    <div class="div-checkboxes">
      <template v-for="(items,index) in fac">
        <v-checkbox
            v-model="selecteditems"
            :label="items.text"
            color="var(--Primary)"
            :value="items.f_id"
            hide-details
            @change="checkitem"
        />
      </template>

    </div>


    <template v-if="title ==='Pools'">
      <h3>Ausstattungen</h3>
      <h3>Temperatursteuerung</h3>

    </template>

    <template v-if="title ==='Beach'">
      <h3>Sonnenbadbereich</h3>
      <h3>Badestrand</h3>
      <h3>Strandannehmlichkeiten</h3>
      <h3>Strandeinrichtungen</h3>
    </template>

  </div>
</template>
<script>


export default {
  name: "Checkboxes",
  props: [
    'list',
    'title',
    'selecteditems',
    'cid',
    'index'
  ],
  data() {
    return {
      fac: [],
      fac_id: [],
      startdate: "",
      enddate: "",
      starttime: "",
      endtime: ""
    }
  },
  methods: {
    checkitem() {
      this.$root.$emit("checkboxes", this.selecteditems, this.cid, this.index);
    }
  },
  created() {

    let lang = localStorage.getItem('lang').toLowerCase();

    if (lang === "de") {
      //this.fac = this.list.text_de;

      this.list.map(item => {
        this.fac.push({f_id: item.f_id, text: item.text_de});
      })
    }
    if (lang === "tr") {
      this.list.map(item => {
        this.fac.push({f_id: item.f_id, text: item.text_tr});
      })
    }
    if (lang === "en") {
      this.list.map(item => {
        this.fac.push({f_id: item.f_id, text: item.text_en});
      })
    }
  }
}
</script>

<style scoped>
@import "../../css/Checkboxes.css";
</style>