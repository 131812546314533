<template>
  <div class="home" v-if="pRolle==='reiseburo' ||pRolle==='admin' || pRolle==='superadmin'">
    <v-snackbar
        v-model="notificationsnackbar"
        :timeout="6000"
        :color="notificationcolor"
        :top="true"
        :right="true"
        :vertical="true"
    >
      {{ notificationtext }}
      <v-btn
          color="white"
          text
          @click="notificationsnackbar = false"
      >
        {{ $t("Notification.Schliessen") }}
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>


    <div class="bodyheader-home">
      <label class="label-bodyheader-home">
        <v-icon class="icon-home">mdi-home</v-icon>

        {{ $t("Home.Hotels") }}
      </label>

    </div>

    <div class="div-body-home">
      <div>
        <Search
            :halalHotels="halalHotels"
            from="home"
            :pLang="pLang"
        />

      </div>

      <div class="div-card-home">
        <template v-for="(info) in this.ownPlaces">
          <div v-if="info.min_price !== 0.00" :key="intOwnPlaces">
            <CY_CardPlace
                :hotelinfos="info"
                :checkin="checkin"
                :checkout="checkout"
                :groups="groups"
                :pLang="pLang"
                :pGruppen="gruppen.length"
                from="home"
            />
          </div>
        </template>

      </div>

      <h3>
        {{ $t("Home.Urlaubsziele") }}
      </h3>


      <div v-if="loading">
        <v-progress-circular
            indeterminate
            color="primary"
            :size="50"
            :width="7"

        />
        <SkeletonCardHorizontal/>
      </div>

      <div class="div-card-home">
        <div v-for="(info, index) in this.halalHotels.offers">
          <CardPlace
              :hotelinfos="info"
              :checkin="checkin"
              :checkout="checkout"
              :groups="groups"
              :pLang="pLang"
              from="home"
          />
        </div>
      </div>

      <div v-if="moreloading">
        <v-progress-circular
            indeterminate
            color="primary"
            :size="50"
            :width="7"

        />

        <SkeletonCardHorizontal/>
      </div>


      <div class="div-paging-home">
        <v-btn @click="weitereErgebnisse" color="var(--Primary)" class="vbtn-more-home">
          <v-icon>mdi-chevron-down</v-icon>
          {{ $t("Button.WeitereErgebnisse") }}
        </v-btn>

      </div>
    </div>
  </div>
</template>

<script>
import {callapi, getInitPlaces} from "@/util/halalapi";
import CardPlace from "@/components/card/CardPlace";
import CY_CardPlace from "@/components/cy_card/CY_CardPlace";
import Search from "@/components/search/Search";
import SkeletonCardHorizontal from "@/components/skeletons/SkeletonCardHorizontal";
import {getOwnPlaces} from "@/util/OwnPlace";
import {checkChildrenAge, getLowestPrice} from "@/util/PriceOwnPlaceUtil";
import moment from "moment";
import {getGruppe} from "@/util/groupsUtil";
import {getCountry} from "@/util/Countries";
import {calculateCurrency} from "@/util/currencyUtil";

export default {
  name: "Home",
  props: [
    'pRolle',
    'pLang',
    'pCurrencyValues',
    'pCurrency'
  ],
  components: {
    CardPlace,
    CY_CardPlace,
    Search,
    SkeletonCardHorizontal
  },
  data() {
    return {

      user: null,
      isadmin: true,
      ishotel: false,
      isreiseburo: false,

      notificationsnackbar: false,
      notificationtext: "",
      notificationcolor: "grey",

      loading: true,
      ownHalalPlaces: {},
      halalHotels: [],
      page: 1,
      moreloading: false,
      intHalalHotels: 0,

      selectedholidayType: "xx",
      // holidayTypes: "thermal",

      checkin: "",
      checkout: "",
      groups: "&groups[]=2",
      url: "",

      gruppen: 1,

      //  Eigene Places
      ownPlaces: [],
      selectedcheckin: "",
      selectedcheckout: "",
      selectedstartdate: "",
      selectedenddate: "",
      anzahlNaechte: 0,
      intOwnPlaces: 0


    };
  },
  methods: {
    checkChildrenAge,
    getGruppe,
    getLowestPrice,
    async weitereErgebnisse() {

      this.moreloading = true;

      this.page = this.page + 1;

      if (this.url === "") {
        let erg = await getInitPlaces(this.page);
        this.halalHotels.offers.push(...erg.offers);

      } else {

        let data = await callapi(this.url, this.page);

        this.halalHotels.offers.push(...data.offers);

      }


      this.moreloading = false;
    },


  },

  mounted() {

    // this.$root.$on("selectedholidayType", async (val) => {
    //   this.selectedholidayType = val;
    // })

    this.$root.$on("urlxx", async (val) => {

      if (localStorage.getItem("ht")) {
        // if (localStorage.getItem('ht') !== 'xx') {
        this.selectedholidayType = localStorage.getItem('ht')
        // } else {
        //   this.selectedholidayType = "xx"
        // }
      }
      this.loading = true;
      this.ownPlaces = "";
      this.halalHotels = "";
      try {
        this.halalHotels = await callapi(val);
      } catch (e) {

      }


      this.url = val;
      let p = 1;
      if (p !== undefined) {
        this.page = p;
      }


      this.ownPlaces = await getOwnPlaces();
      if (localStorage.getItem('desP')) {
        this.ownPlaces = this.ownPlaces.filter(item =>
            item.location.city.toLowerCase().includes(localStorage.getItem('desP').toLocaleLowerCase()) ||
            (localStorage.getItem('desP').toLowerCase() === getCountry("EN", item.location.country).toLowerCase())
        )

      }


      let g = JSON.parse(localStorage.getItem("gruppe"));

      this.ownPlaces.map((item, index) => {

        if (item.rooms) {
          let p = 0.00;
          if (g !== undefined && g !== null) {

            g.map((it, i) => {
              let o = checkChildrenAge(g, i, item.baby_price, item.children_price);
              p += getLowestPrice(o.erwachsene, o.children, o.babies, this.selectedstartdate, this.selectedenddate, item, this.anzahlNaechte);


            })

          } else {


            p = getLowestPrice(2, 0, 0, this.selectedstartdate, this.selectedenddate, item, this.anzahlNaechte);

          }

          let x = calculateCurrency(this.ownPlaces[index].currency, this.pCurrency, this.pCurrencyValues, p);

          this.ownPlaces[index].min_price = x;
        }
      })
      if (this.selectedholidayType !== "xx") {

        this.ownPlaces = this.ownPlaces.filter(item => item.holiday_type.includes(this.selectedholidayType))
      }
      this.loading = false;
    });

    this.$root.$on("loading", (val) => {

      this.loading = val;
    });

    this.$root.$on("checkin", (val) => {
      this.checkin = val;
      let c_in = new Date(localStorage.getItem('c-in'));

      this.checkin = moment(c_in).format("YYYY-MM-DD");
      this.selectedstartdate = c_in;
    });

    this.$root.$on("checkout", (val) => {
      this.checkout = val;
      let c_out = new Date(localStorage.getItem('c-out'));
      this.checkout = moment(c_out).format("YYYY-MM-DD");
      this.selectedenddate = c_out;
    });

    this.$root.$on("groups", (val) => {
      this.groups = val;
    });

    this.$root.$on("cysearch", (val) => {

      this.gruppen = val;


      // let gruppen = getGruppe(this.groups);
      // console.log("M this.gruppen: ", this.gruppen);

      this.ownPlaces.map((item, index) => {
        let p = 0.00;
        this.gruppen.map((it, i) => {
          if (item.rooms) {
            let o = checkChildrenAge(this.gruppen, i, item.baby_price, item.children_price);
            p += getLowestPrice(o.erwachsene, o.children, o.babies, this.selectedstartdate, this.selectedenddate, item, this.anzahlNaechte);

          }
        })

        this.ownPlaces[index].min_price = p;
        this.intOwnPlaces++;


      })
    });


  },


  async created() {

    this.loading = true;

    if (localStorage.getItem("nlo")) {
      localStorage.removeItem("nlo");
      location.reload();
    }

    if (localStorage.getItem("ht")) {
      this.selectedholidayType = localStorage.getItem("ht")
    }


    //localStorage.removeItem('gruppe');
    window.scrollTo(0, 0);

    if (localStorage.getItem('url')) {
      this.halalHotels = await callapi(localStorage.getItem('url'));
      if (this.halalHotels !== {}) {
        this.loading = false;
      }

    } else {
      let page = 1;
      this.halalHotels = await getInitPlaces(page);

      if (this.halalHotels !== {}) {
        this.loading = false;
      }
    }


    // ------------------------------------ DATUM anzeigen ------------------------------------
    if (localStorage.getItem('c-in') !== null) {
      let c_in = new Date(localStorage.getItem('c-in'));

      this.checkin = moment(c_in).format("YYYY-MM-DD");
      this.selectedstartdate = c_in;

    } else {

      let d = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 16);
      this.checkin = moment(d).format("YYYY-MM-DD");
      this.selectedstartdate = d;
    }

    if (localStorage.getItem('c-out') !== null
    ) {
      let c_out = new Date(localStorage.getItem('c-out'));
      this.checkout = moment(c_out).format("YYYY-MM-DD");
      this.selectedenddate = c_out;
    } else {

      let d1 = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 18);
      this.checkout = moment(d1).format("YYYY-MM-DD");
      this.selectedenddate = d1;
    }

    // ------------------------------------ DATUM anzeigen ENDE ------------------------------------

    // Berechnung der Nächte
    let days = null;
    let miliseconds = this.selectedenddate.getTime() - this.selectedstartdate.getTime();
    let total_seconds = parseInt(Math.floor(miliseconds / 1000));
    let total_minutes = parseInt(Math.floor(total_seconds / 60));
    let total_hours = parseInt(Math.floor(total_minutes / 60));
    days = parseInt(Math.floor(total_hours / 24));
    this.anzahlNaechte = days;


    this.ownPlaces = await getOwnPlaces();


    let gruppen = getGruppe(this.groups);
    let g = JSON.parse(localStorage.getItem("gruppe"));

    if (localStorage.getItem('desP')) {
      this.ownPlaces = this.ownPlaces.filter(item => item.location.city.toLowerCase().includes(localStorage.getItem('desP').toLocaleLowerCase()) ||
          (localStorage.getItem('desP').toLowerCase() === getCountry("EN", item.location.country).toLowerCase())
      )
    }

    this.ownPlaces.map((item, index) => {
      if (item.rooms) {
        let p = 0.00;
        if (g !== undefined && g !== null) {

          g.map((it, i) => {
            let o = checkChildrenAge(g, i, item.baby_price, item.children_price);
            p += getLowestPrice(o.erwachsene, o.children, o.babies, this.selectedstartdate, this.selectedenddate, item, this.anzahlNaechte);
          })

        } else {
          p = getLowestPrice(2, 0, 0, this.selectedstartdate, this.selectedenddate, item, this.anzahlNaechte);

        }
        // this.ownPlaces[index].min_price = p;

        let x = calculateCurrency(this.ownPlaces[index].currency, this.pCurrency, this.pCurrencyValues, p);
        this.ownPlaces[index].min_price = x;

      }
    })


    if (this.selectedholidayType !== "xx") {
      this.ownPlaces = this.ownPlaces.filter(item => item.holiday_type.includes(this.selectedholidayType))
    }
  }

};
</script>
<style scoped>
@import "../css/Home.css";
</style>