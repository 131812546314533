import {getHeader, getHeaderonlyLogo, getPDFHeaderAgency} from "@/util/PDFDokumente/PDFHeader";
import {getPDFFooterAgency} from "@/util/PDFDokumente/PDFFooter";
import {getStyles} from "@/util/PDFDokumente/PDFStyles";
import moment from "moment";
import {formatPrice} from "@/util/currency";

export const pdfRechnung = (order, index, inv, userinfo, pDomain, logo) => {
    let docDefinition = {

        pageMargins: [50, 80, 40, 100],
        info: {
            title: pDomain,
            author: pDomain,
            subject: 'Rechnung Kunde',
            keywords: 'Rechnung Kunde',
            creator: pDomain,
            producer: pDomain,
        },

        header: getHeaderonlyLogo(logo),

        footer: getPDFFooterAgency(userinfo),

        content: getPDFContentRechnung(order, index, inv, userinfo),

        styles: getStyles()


    }
    return docDefinition;
}

export const
    getPDFContentRechnung = (order, index, inv, userinfo) => {
        let res = order.order_id.split("-");
        let reservierungsnr = +res[2];
        let year = new Date().getFullYear();
        let date = moment(new Date()).format("DD.MM.YYYY");

        let rechnungReise = [


                {
                    style: "AnschriftMargin",
                    columns: [
                        [
                            // Anschrift Admin
                            {
                                text: userinfo.kontakt.firma + " - " + userinfo.kontakt.strnr + " - " + userinfo.kontakt.plz + " " + userinfo.kontakt.ort,
                                style: 'AdminAdress'
                            },

                            // Rechnungsanschrift
                            {text: order.order.first_name + " " + order.order.last_name},
                            {text: order.rechnungsadresse.str + " " + order.rechnungsadresse.nr + " " + order.rechnungsadresse.adressz},
                            {text: order.rechnungsadresse.plz + " " + order.rechnungsadresse.ort},
                        ],
                        [

                            {
                                text: 'Rechnungs-Nr.: ' + inv.invoicenr,
                                lineHeight: 1.2,
                                margin: [0, 40, 0, 0],
                                alignment: 'right'
                            },
                            {
                                text: 'Rechnungs-Datum: ' + moment(inv.invoicedate).format("DD.MM.YYYY"),
                                alignment: 'right'
                            },
                            {
                                text: 'Reservierungs-Nr.: ' + reservierungsnr,
                                alignment: 'right',
                                margin: [0, 15, 0, 0],
                            },
                            {
                                text: 'Reservierungs-Datum.: ' + moment(order.date).format("DD.MM.YYYY"),
                                alignment: 'right'
                            }

                        ]

                    ]
                },


                {text: 'Rechnung', style: 'Betreff'},

                // {
                //     text: userinfo.kontakt.ort + ", den " + date, lineHeight: 1.2,
                //     alignment: 'right',
                // },


                {
                    style: 'tabelleBeschreibung',
                    table: {
                        headerRows: 1,
                        widths: ['*', 90],

                        body: [
                            [
                                //---------------------------- Tabellenkopf -------------------------------
                                {text: 'Beschreibung'},
                                {text: 'Betrag', alignment: 'right'}
                            ],

                            [

                                //---------------------------- Zeile 1 (bleibt immer gleich) -------------------------------
                                [
                                    {
                                        text: 'Unterkunft: ' + order.hotelinfos.hotelname,
                                        margin: [0, 8, 0, 0]
                                    },
                                    {
                                        text: 'Adresse: ' + order.hotelinfos.hoteladress,
                                        margin: [0, 3, 0, 0]
                                    }
                                ],
                                [
                                    {
                                        text: (formatPrice(inv.payment) + ' €'),
                                        alignment: 'right',
                                        margin: [0, 8, 0, 0],

                                    },


                                    // {
                                    //     text: (order.hotelinfos.total_price_eur > 0 ? '(' + formatPrice(order.hotelinfos.total_price) + ' ' + order.hotelinfos.currency + ')' : ""),
                                    //     alignment: 'right', fontSize: 9,
                                    //     margin: [0, 2, 0, 0]
                                    // }
                                ],
                            ], [

                                //---------------------------- Zeile 2 (Zimmer und rate_plan) -------------------------------
                                [

                                    {
                                        text:
                                            [
                                                {
                                                    text: 'Gruppe #1: ' + order.hotelinfos.rooms[0].room_name + '\n',
                                                    bold: true
                                                },
                                                order.hotelinfos.gruppe[0].erwachsene + ' Erwachsene' + (order.hotelinfos.gruppe[0].kinder > 0 ? ', ' + order.hotelinfos.gruppe[0].kinder + ' Kinder' : ""),
                                            ],
                                        margin: [0, 8, 0, 0]
                                    }
                                ],
                                [
                                    // [
                                    // {
                                    //     text: (order.hotelinfos.total_price_eur > 0 ? formatPrice(order.hotelinfos.price_per_group[0].price) + ' €' : formatPrice(order.hotelinfos.rateplans[0].room_price) + ' €'),
                                    //     alignment: 'right',
                                    //     margin: [0, 8, 0, 0]
                                    // },
                                    // {
                                    //     text: (order.hotelinfos.total_price_eur > 0 ? '(' + formatPrice(order.hotelinfos.rateplans[0].room_price) + ' ' + order.hotelinfos.currency + ')' : ""),
                                    //     alignment: 'right', fontSize: 9
                                    //
                                    // }
                                    // ]

                                ]
                            ],

                        ]
                    },
                    layout: 'headerLineOnly',
                },


                //---------------------------- Gesamtpreis -------------------------------

                {

                    table: {
                        headerRows: 1,
                        widths: ['*', 200],
                        body: [
                            [
                                // Damit Header im Tabel leer bleibt
                                '', ''
                            ],

                            [
                                '',
                                [
                                    {
                                        text: 'Rechnungsbetrag: ' + (formatPrice(inv.payment) + ' €'),
                                        alignment: 'right', bold: true,
                                        margin: [0, 8, 0, 0],

                                    },


                                    // {
                                    //     text: (order.hotelinfos.total_price_eur > 0 ? '(' + formatPrice(order.hotelinfos.total_price) + ' ' + order.hotelinfos.currency + ')' : ""),
                                    //     alignment: 'right', fontSize: 9, bold: true,
                                    //     margin: [0, 2, 0, 0]
                                    // }
                                ]
                            ],


                        ]
                    },
                    layout: 'headerLineOnly',

                },

                {
                    text: "Bitte überweisen Sie den Rechnungsbetrag auf das unten genannte Konto.",
                    alignment: 'center',
                    margin: [0, 30, 0, 50],
                    fontStyle: 'italic'
                },


            ]
        ;

        return rechnungReise;
    }
