<template>
  <div class="div-login">
    <v-snackbar
        v-model="notificationsnackbar"
        :timeout="6000"
        :color="notificationcolor"
        :top="true"
        :right="true"
        :vertical="true"
    >
      {{ notificationtext }}
      <v-btn
          color="white"
          text
          @click="notificationsnackbar = false"
      >
        {{ $t("Notification.Schliessen") }}
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <span></span>

    <div class="div-login1" v-if="checktoken">
      <img class="img-logo-login" :src="logo" alt="Logo"/>
      <label class="label-login">
        Passwort zurücksetzen
      </label>
      <!--      <v-text-field-->
      <!--          :rules="[rules.required, rules.email]"-->
      <!--          v-model="email"-->
      <!--          :label="$t('Login.Benutzername')"-->
      <!--          outlined-->
      <!--          color="var(&#45;&#45;Secondary)"-->

      <!--      />-->

      <v-text-field
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show2 ? 'text' : 'password'"
          name="input-10-2"
          v-model="password"
          class="input-group--focused"
          @click:append="show2 = !show2"
          :label="$t('Login.Passwort')"
          outlined
          color="var(--Secondary)"
      />
      <v-text-field
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show2 ? 'text' : 'password'"
          name="input-10-2"
          v-model="password2"
          class="input-group--focused"
          @click:append="show2 = !show2"
          :label="$t('Login.Passwort')+' wiederholen'"
          outlined
          color="var(--Secondary)"
          @keyup.enter="resetPW"
      />

      <v-btn class="button-login" large color="var(--Secondary)" @click="resetPW">
        speichern
      </v-btn>


    </div>
    <div class="div-login1" v-if="!checktoken">
      Ihr Link ist abgelaufen, bitte klicken Sie erneut auf Passwort vergessen!
    </div>

  </div>
</template>

<script>
import logo from "../assets/CeeYuu_Logo_100x100.png";
import {deleteResetpwToken, getresetpwBygenId} from "@/util/resetpwUtil";
import {getUser} from "@/util/Auth";
import {updateUserForResetPW} from "@/util/userUtil";

export default {
  name: "ResetPW",
  data() {
    return {
      logo: logo,

      notificationsnackbar: false,
      notificationtext: "",
      notificationcolor: "grey",

      checktoken: false,
      res: "",

      user: "",
      show2: false,
      email: "",
      password: "",
      password2: "",
      rules: {
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value);
        }
      }
    };
  },
  methods: {

    notification(text, color) {
      this.notificationsnackbar = true;
      this.notificationtext = text;
      this.notificationcolor = color;

    },
    async resetPW() {


      if (this.password === this.password2) {

        //update user
        let user = {
          ...this.user,
          password: this.password
        }
        await updateUserForResetPW(user);

        await deleteResetpwToken(this.res._id);

        this.notification("Passwort wurde gespeichert", "green");

        await new Promise(resolve => setTimeout(resolve, 500));
        this.$router.push("/login");

      } else {

        this.notification("Passwörter stimmen nicht überein", "error");
      }


    }
  },
  async created() {
    let id = this.$route.query.id;

    this.res = await getresetpwBygenId(id);

    try {
      let user = await getUser(this.res.token);
      if (user !== undefined) {

        this.user = user;
        this.checktoken = true;
      } else {
        this.checktoken = false;
        await deleteResetpwToken(this.res._id);
      }


    } catch (e) {
      this.checktoken = false;
      await deleteResetpwToken(this.res._id);
    }


  }
};
</script>

<style scoped>
@import "../css/Login.css";

</style>
