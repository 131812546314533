import axios from "axios";


export const getReiseburoStatistic = async () => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/st", {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});

    let users = res.data.data;

    return users;
}
