<template>
  <div class="home" v-if="pRolle==='hotel' ||pRolle==='admin' || pRolle==='superadmin'">
    <v-snackbar
        v-model="notificationsnackbar"
        :timeout="6000"
        :color="notificationcolor"
        :top="true"
        :right="true"
        :vertical="true"
    >
      {{ notificationtext }}
      <v-btn
          color="white"
          text
          @click="notificationsnackbar = false"
      >
        {{ $t("Notification.Schliessen") }}
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

    <div class="bodyheader-home">
      <label class="label-bodyheader-home">
        <v-icon class="icon-home">mdi-home-plus</v-icon>
        Hotel anlegen
        <!--        {{ $t("home.Hotels") }}-->
      </label>

    </div>

    <div class="div-body-createhotel">


      <div
          style="display: flex; justify-content: space-around; margin: 50px 0 300px 0;"
          v-if="(hotelart==='' && !checkHotelexist) "
      >

        <v-btn
            color="var(--Primary)"
            style="color: white"
            @click="hotelart = 'halalbooking'"
            v-if="pRolle ==='admin'|| pRolle ==='superadmin'"
        >
          {{ $t("CreateHotel.HotelvonHalalbooking") }}
        </v-btn>
        <v-btn color="var(--Primary)" style="color: white" @click="hotelart = 'newhotel'">
          {{ $t("CreateHotel.NeuesHotelanlegen") }}
        </v-btn>
      </div>

      <template v-if="hotelart !== ''">
        <div>
          <h3 v-if="hotelart==='halalbooking'">
            {{ $t("CreateHotel.WertevonHalalbooking") }}
          </h3>

        </div>
      </template>

      <div v-if="hotelart === 'halalbooking'" class="div-halalplace-createhotel">

        <div v-if="checkExist" style="color: red; font-size: 18px; font-weight: bolder;">
          {{ $t("CreateHotel.CheckHotelexist1") }} {{ searchHotelID }} {{ $t("CreateHotel.CheckHotelexist2") }}
        </div>

        <div style="color: red; margin-top: 30px;" v-if="checkPlace">
          {{ $t("CreateHotel.Hotelnichtgefunden") }}
        </div>
        <div v-else>
          {{ $t("CreateHotel.BitteHotelIDeingeben") }}
        </div>


        <v-text-field
            label="Hotel-ID"
            v-model="halalplaceID"
            outlined
            color=var(--Primary)
            type="number"
            :error="checkPlace"
            v-on:keyup.enter="getHalalPlace"
        />
        <v-icon large color="green" v-if="halalPlace.place !== undefined">mdi-check</v-icon>

        <v-btn @click="getHalalPlace" color="var(--Secondary)" style="color: white">
          {{ $t("CreateHotel.IDprüfen") }}
        </v-btn>

        <div v-if="loading">
          <v-progress-circular
              indeterminate
              color="primary"
          />
        </div>


        <div v-if="halalPlace.place !== undefined">
          <div style="margin-top: 20px; font-size: 18px; font-weight: bolder">
            {{ $t("Orders.Hotel") }}: {{ halalPlace.place.name }}
          </div>

          <div>
            {{ $t("CreateHotel.Lage") }}: {{ halalPlace.place.location.name }}, {{ halalPlace.place.location.city }},
            {{ halalPlace.place.location.country }}
          </div>

          <div class="div-input-halal-creaethotel" v-if="!checkExist">


            <div style="margin-top: 20px">
              {{ $t("CreateHotel.ZustehendeProvision") }}
            </div>
            <v-text-field
                :label="$t('Dashboard.Provision')"
                v-model="halalPlaceProvision"
                outlined
                color=var(--Primary)
                prepend-inner-icon="mdi-percent"
                type="number"
                @change="validateFields"
            />

            <div style="margin-top: 20px">
              {{ $t("CreateHotel.MailReservierungsanfrage") }}
            </div>
            <v-text-field
                :label="$t('Booking.E-Mail')"
                v-model="halalPlaceEMail"
                outlined
                color=var(--Primary)
                prepend-inner-icon="mdi-email"
                @change="validateFields"
                :rules="[rules.required, rules.email]"
            />
          </div>


          <div style="text-align: end" v-if="checkinputs">

            <v-btn @click="saveHalalPlace" color="var(--Primary)" style="color: white;">
              {{ $t("Button.Speichern") }}
            </v-btn>

          </div>
          <div v-if="!checkinputs">
            {{ $t("CreateHotel.AlleFelder") }}
          </div>

        </div>
      </div>


      <!--  ------------------------------------------------ NEUES HOTEL ------------------------------------------------    -->


      <!--  ------------------------------------------------ NEUES HOTEL ------------------------------------------------    -->


      <div v-if="hotelart==='newhotel' || checkHotelexist" id="newHotel">

        <v-card>
          <v-tabs
              v-model="tab"
              background-color="var(--Primary)"
          >
            <v-tabs-slider color="yellow"></v-tabs-slider>

            <v-tab
                v-for="item in createPlaceHeaders"
                :key="item"
                style="color: white"
            >
              {{ item }}
            </v-tab>
          </v-tabs>
        </v-card>

        <v-tabs-items v-model="tab">

          <!-- --------------------------------------- TAB 1 Hotelinfos --------------------------------- -->
          <v-tab-item
              :key="1"
          >

            <div class="div-tab-createhotel">
              <div v-if="pRolle === 'admin'||pRolle === 'superadmin'">
                {{ $t("Acc.HotelAccount") }}
                <v-select
                    :label="$t('Acc.HotelAccount')"
                    :items="hotelAccounts"
                    v-model="hotelAccount"
                    item-text="kontakt.firma"
                    item-value="userid"
                    outlined
                    color=var(--Primary)
                />

              </div>
              <div v-if="status_place !=='' && (pRolle === 'admin'||pRolle === 'superadmin') ">
                <h3>{{ $t("Orders.Status") }}</h3>

                <div>
                  <div>
                    <div v-if="status_place ==='1'">
                      <v-icon color="green" large>mdi-check</v-icon>
                      {{ $t("CreateHotel.HotelistAktiv") }}
                    </div>
                    <div v-if="status_place ==='0'">
                      <v-icon color="error" large>mdi-alert-outline</v-icon>
                      {{ $t("CreateHotel.HotelistnichtAktiv") }}
                    </div>
                  </div>
                  <v-btn v-if="status_place ==='0'" @click="activatePlace" color="green">
                    {{ $t("CreateHotel.Hotelaktivieren") }}
                  </v-btn>
                  <v-btn v-if="status_place ==='1'" @click="deactivatePlace" color="error">
                    {{ $t("CreateHotel.Hoteldeaktivieren") }}
                  </v-btn>
                </div>

                <br>
                <v-divider></v-divider>
                <br>
              </div>

              <h3>{{ $t("CreateHotel.Infos") }}</h3>


              <v-text-field
                  :label="$t('CreateHotel.HotelID')"
                  v-model="place_id"
                  outlined
                  color=var(--Primary)
                  disabled
              />

              <v-text-field
                  :label="$t('CreateHotel.HotelNameDE')"
                  v-model="place_name_de"
                  outlined
                  color=var(--Primary)
              />

              <v-text-field
                  :label="$t('CreateHotel.HotelNameTR')"
                  v-model="place_name_tr"
                  outlined
                  color=var(--Primary)
              />

              <v-text-field
                  :label="$t('CreateHotel.HotelNameEN')"
                  v-model="place_name_en"
                  outlined
                  color=var(--Primary)
              />
              <br>
              <v-select
                  :label="$t('CreateHotel.HotelSterne')"
                  :items="stars_items"
                  v-model="stars"
                  outlined
                  color=var(--Primary)
              />

              <v-text-field
                  :label="$t('CreateHotel.UhrzeitCheckin')"
                  v-model="checkin_time"
                  outlined
                  color=var(--Primary)
                  placeholder="hh:mm"
              />

              <v-text-field
                  :label="$t('CreateHotel.UhrzeitCheckout')"
                  v-model="checkout_time"
                  outlined
                  color=var(--Primary)
                  placeholder="hh:mm"
              />

              <v-select
                  :label="$t('CreateHotel.Urlaubsthema')"
                  :items="holiday_type_items"
                  item-text="text"
                  item-value="id"
                  v-model="holiday_type"
                  outlined
                  multiple
                  color=var(--Primary)
              />


              <br>
              <v-divider></v-divider>
              <br>
              <div>
                <div class="div-hoteldatepicker-createhotel">
                  <h3>{{ $t("CreateHotel.Preise") }}</h3>
                  <div style="max-width: 300px">

                    <v-select
                        :label="$t('CreateHotel.Waehrung')"
                        :items="currency_items"
                        v-model="currency"
                        outlined
                        color=var(--Primary)

                    />
                  </div>

                  <div style="text-align: start; margin-top: 15px">

                    <h4>{{ $t("CreateHotel.Datum") }}</h4>

                    <div class="div-prices-createhotel">
                      <template v-for="(item,index) in price">

                        <div style="display: flex; gap: 15px">
                          <HotelDatePickerWrapper :price="item" from="createhotel"></HotelDatePickerWrapper>
                          <v-text-field
                              :label="$t('CreateHotel.PreisPerson')"
                              v-model="item.price"
                              outlined
                              color=var(--Primary)
                              type="number"
                              :append-icon="getCurrencyIcon(currency)"
                              class="vtextfield-createhotel"
                          />
                          <span>

                            <v-tooltip right>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    class="mx-2"
                                    fab
                                    dark
                                    small
                                    color="primary"
                                    @click="deletePrice(index)"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                  <v-icon dark>
                                    mdi-delete
                                  </v-icon>
                                </v-btn>

                              </template>
                              <span> {{ $t('Button.Preisloeschen') }}</span>
                            </v-tooltip>
                          </span>

                          <div v-if="price.length-1 !== index">

                          </div>

                          <div v-if="price.length-1 === index">


                            <v-tooltip right>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    class="mx-2"
                                    fab
                                    dark
                                    small
                                    color="primary"
                                    @click="addNewDatePrice"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                  <v-icon dark>
                                    mdi-plus
                                  </v-icon>
                                </v-btn>

                              </template>
                              <span>{{ $t("CreateHotel.Preishinzufuegen") }}</span>
                            </v-tooltip>
                          </div>
                        </div>

                      </template>

                    </div>
                  </div>
                </div>

                <div style="text-align: start; margin-top: 15px">

                  <h4>{{ $t("CreateHotel.Verpflegung") }}</h4>

                  <div v-for="(item,index) in rate_plans">
                    <div class="div-rateplans-createhotel">

                      <v-select
                          :label="$t('CreateHotel.Verpflegung')"
                          :items="rate_plan_items"
                          v-model="item.rate_plan_id"
                          item-value="id"
                          item-text="text"
                          outlined
                          color=var(--Primary)
                      />


                      <v-text-field
                          :label="$t('CreateHotel.Aufpreis')"
                          v-model="item.price"
                          outlined
                          color=var(--Primary)
                          :append-icon="getCurrencyIcon(currency)"
                      />
                      <span>

                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                class="mx-2"
                                fab
                                dark
                                small
                                color="primary"
                                @click="deleteRatePlan(index)"
                                v-bind="attrs"
                                v-on="on"
                            >
                              <v-icon dark>
                                mdi-delete
                              </v-icon>
                            </v-btn>

                          </template>
                          <span> {{ $t('Button.Loeschen') }}</span>
                        </v-tooltip>
                      </span>


                      <div v-if="rate_plans.length-1 === index">
                        <!--                        <v-btn outlined @click="addNewRatePlan" style="height: 54px; width: 100%"-->
                        <!--                               color="var(&#45;&#45;Primary)">-->
                        <!--                          <v-icon>mdi-plus</v-icon>-->
                        <!--                        </v-btn>-->
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                class="mx-2"
                                fab
                                dark
                                small
                                color="primary"
                                @click="addNewRatePlan" v-bind="attrs"
                                v-on="on"
                            >
                              <v-icon dark>
                                mdi-plus
                              </v-icon>
                            </v-btn>

                          </template>
                          <span>{{ $t("CreateHotel.Verpflegunghinzufuegen") }}</span>
                        </v-tooltip>
                      </div>
                    </div>

                  </div>


                </div>


              </div>

              <br>
              <v-divider></v-divider>
              <br>


              <h3>{{ $t("CreateHotel.PreiseKinder") }}</h3>
              <div style="display: flex; gap:10px;">

                <v-text-field
                    :label="$t('CreateHotel.Kindaltervon')"
                    v-model="children_price.age_start"
                    outlined
                    color=var(--Primary)
                    type="number"
                />
                <v-text-field
                    :label="$t('CreateHotel.Kindalterbiseinschließlich')"
                    v-model="children_price.age_end"
                    outlined
                    color=var(--Primary)
                    type="number"
                />
                <v-text-field
                    :label="$t('CreateHotel.RabattKind')"
                    v-model="children_price.rabatt"
                    outlined
                    color=var(--Primary)
                    type="number"
                    append-icon="mdi-percent"
                />
              </div>

              <div style="display: flex; gap:10px;">

                <v-text-field
                    :label="$t('CreateHotel.Babyaltervon')"
                    v-model="baby_price.age_start"
                    outlined
                    color=var(--Primary)
                    type="number"
                />
                <v-text-field
                    :label="$t('CreateHotel.Babyalterbiseinschließlich')"
                    v-model="baby_price.age_end"
                    outlined
                    color=var(--Primary)
                    type="number"
                />
                <v-text-field
                    :label="$t('CreateHotel.RabattBaby')"
                    v-model="baby_price.rabatt"
                    outlined
                    color=var(--Primary)
                    type="number"
                    append-icon="mdi-percent"
                />
              </div>

              <br>
              <v-divider></v-divider>
              <br>

              <v-text-field
                  :label="$t('Dashboard.Provision')"
                  v-model="provision"
                  outlined
                  placeholder="%"
                  color=var(--Primary)
                  append-icon="mdi-percent"
              />


              <br>
              <v-divider></v-divider>
              <br>

              <v-select
                  :label="$t('CreateHotel.GesprocheneSprachen')"
                  :items="getLanguages()"
                  v-model="selectedlanguages"
                  item-value="lang_id"
                  item-text="lang"
                  outlined
                  multiple
                  color=var(--Primary)
              />

              <br>
              <v-divider></v-divider>
              <br>

              <v-textarea
                  :label="$t('CreateHotel.KurzbeschreibungDE')"
                  v-model="place_desc_de"
                  auto-grow
                  outlined
                  rows="3"
                  row-height="35"
                  color=var(--Primary)
                  v-on:keyup="overview_countdown_de"
              />
              <p class='text-right text-small' v-bind:class="{'text-danger': hasError_de }">{{ remainingCount_de }}</p>


              <v-textarea
                  :label="$t('CreateHotel.KurzbeschreibungTR')"
                  v-model="place_desc_tr"
                  auto-grow
                  outlined
                  rows="3"
                  row-height="35"
                  color=var(--Primary)
                  v-on:keyup="overview_countdown_tr"
              />
              <p class='text-right text-small' v-bind:class="{'text-danger': hasError_tr }">{{ remainingCount_tr }}</p>


              <v-textarea
                  :label="$t('CreateHotel.KurzbeschreibungEN')"
                  v-model="place_desc_en"
                  auto-grow
                  outlined
                  rows="3"
                  row-height="35"
                  color=var(--Primary)
                  v-on:keyup="overview_countdown_en"
              />
              <p class='text-right text-small' v-bind:class="{'text-danger': hasError_en }">{{ remainingCount_en }}</p>

              <br>
              <v-divider/>
              <br>

              <h3>{{ $t("CreateHotel.Lage") }}</h3>
              <v-autocomplete
                  :label="$t('CreateHotel.Land')"
                  v-model="location.country"
                  :items="countryItems"
                  outlined
                  color=var(--Primary)
                  item-value="countryid"
                  @input="changeCountry"
              />
              <!--              <v-text-field-->
              <!--                  label="Region"-->
              <!--                  v-model="location.region"-->
              <!--                  outlined-->
              <!--                  color=var(&#45;&#45;Primary)-->
              <!--              />-->
              <v-text-field
                  :label="$t('CreateHotel.Stadt')"
                  v-model="location.city"
                  outlined
                  color=var(--Primary)
              />

              <v-text-field
                  label="Subregion"
                  v-model="location.subregion"
                  outlined
                  color=var(--Primary)
              />
              <v-text-field
                  :label="$t('CreateHotel.PLZ')"
                  v-model="location.plz"
                  outlined
                  color=var(--Primary)
              />
              <v-text-field
                  :label="$t('CreateHotel.StrNr')"
                  v-model="location.strnr"
                  outlined
                  color=var(--Primary)
              />

              <br>
              <v-divider/>
              <br>

              <!-- ------------------------------------ Picture Pool für Place ------------------------------------------ -->


              <PicturePool
                  :pUserinfo="pUserinfo"
                  :pictures_place="pictures_place"

              ></PicturePool>


              <!-- ------------------------------------ Picture Pool für Place ENDE ------------------------------------------ -->
            </div>
          </v-tab-item>


          <!-- --------------------------------------- TAB 2 FACILITIES --------------------------------- -->


          <v-tab-item
              :key="2"
          >
            <div class="div-tab-createhotel">

              <Checkboxes
                  :list="gen"
                  :title="$t('CreateHotel.Generell')"
                  :selecteditems="facilities.general"
                  cid="general"
              />
              <v-divider/>
              <Checkboxes
                  :list="act" title="Aktivitäten" :selecteditems="facilities.activities"
                  cid="activity"
              />
              <v-divider/>
              <Checkboxes :list="foo" title="Essen & Trinken" :selecteditems="facilities.food" cid="food"/>
              <v-divider/>
              <Checkboxes :list="chi" title="Kinder" :selecteditems="facilities.children" cid="children"/>
              <v-divider/>
              <Checkboxes
                  :list="ent" title="Unterhaltung" :selecteditems="facilities.entertainment"
                  cid="entertainment"
              />
              <v-divider/>
              <Checkboxes :list="int" title="Internet" :selecteditems="facilities.internet" cid="internet"/>
              <v-divider/>
              <Checkboxes :list="par" title="Parken" :selecteditems="facilities.parking" cid="parking"/>
              <v-divider/>
              <Checkboxes :list="sho" title="Shops" :selecteditems="facilities.shops" cid="shops"/>
              <v-divider/>
              <Checkboxes :list="rec" title="Reception" :selecteditems="facilities.reception" cid="reception"/>
              <v-divider/>
              <Checkboxes :list="bus" title="Business" :selecteditems="facilities.business" cid="business"/>
              <v-divider/>
              <Checkboxes :list="cle" title="Cleaning" :selecteditems="facilities.cleaning" cid="cleaning"/>
              <v-divider/>
              <Checkboxes :list="tra" title="Transport" :selecteditems="facilities.transport" cid="transport"/>

            </div>
          </v-tab-item>


          <!-- --------------------------------------- TAB 3 Beach/Pools/Spa --------------------------------- -->


          <v-tab-item
              :key="3"
          >
            <div class="div-tab-createhotel">


              <!-- ---------------------------------------------------------- Spa ----------------------------------------------- -->
              <h2>Wellness & Spa</h2>

              <div v-for="(item, index) in facilities.well_spa">
                <br>
                <div style="display: flex; justify-content: center; align-items: center; margin: 0 0 10px 0;">
                  <h3>Spa #{{ index + 1 }} </h3>
                  <span v-if="index !==0">

                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="mx-2"
                            fab
                            dark
                            small
                            color="primary"
                            @click="deleteSpa(index)"
                            v-bind="attrs"
                            v-on="on"
                        >
                          <v-icon dark>
                            mdi-delete
                          </v-icon>

                        </v-btn>

                      </template>
                      <span> {{ $t('Button.Loeschen') }}</span>
                    </v-tooltip>
                  </span>

                </div>
                <v-text-field
                    label="Wellness & Spa Startuhrzeit"
                    v-model="item.starttime"
                    outlined
                    placeholder="hh:mm"
                    color=var(--Primary)
                />
                <v-text-field
                    label="Wellness & Spa Enduhrzeit"
                    v-model="item.endtime"
                    outlined
                    placeholder="hh:mm"
                    color=var(--Primary)
                />

                <Checkboxes
                    :list="spa" title="Spa & Wellness" :selecteditems="item.facilities" cid="well_spa"
                    :index="index"
                />

                <div v-if="index !== facilities.well_spa.length-1">
                  <br>
                  <v-divider/>
                  <br>
                </div>

              </div>

              <div>

                <v-btn outlined color="var(--Primary)" @click="addWellSpa">
                  Weiteres Wellness & Spa anlegen
                  <v-icon>mdi-plus</v-icon>
                </v-btn>

              </div>


              <!-- ---------------------------------------------------------- Spa ENDE ----------------------------------------------- -->

              <br>
              <v-divider/>
              <br>

              <!-- ---------------------------------------------------------- Pool ----------------------------------------------- -->
              <h2>Pools</h2>

              <div v-for="(item, index) in facilities.pools">

                <br>
                <div style="display: flex; justify-content: center; align-items: center; margin: 0 0 10px 0;">

                  <h3>Pool #{{ index + 1 }}</h3>
                  <span v-if="index !==0">

                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="mx-2"
                            fab
                            dark
                            small
                            color="primary"
                            @click="deletePool(index)"
                            v-bind="attrs"
                            v-on="on"
                        >
                          <v-icon dark>
                            mdi-delete
                          </v-icon>

                        </v-btn>

                      </template>
                      <span> {{ $t('Button.Loeschen') }}</span>
                    </v-tooltip>
                  </span>
                </div>


                <v-text-field
                    label="Pools Startdatum"
                    v-model="item.pool.startdate"
                    outlined
                    placeholder="DD.MM"
                    color=var(--Primary)
                />
                <v-text-field
                    label="Pools Enddatum"
                    v-model="item.pool.enddate"
                    outlined
                    placeholder="DD.MM"
                    color=var(--Primary)
                />

                <v-text-field
                    label="Pools Startuhrzeit"
                    v-model="item.pool.starttime"
                    outlined
                    placeholder="hh:mm"
                    color=var(--Primary)

                />
                <v-text-field
                    label="Pools Enduhrzeit"
                    v-model="item.pool.endtime"
                    outlined
                    placeholder="hh:mm"
                    color=var(--Primary)
                />

                <v-text-field
                    label="Länge"
                    v-model="item.pool.length"
                    outlined
                    placeholder="30m"
                    color=var(--Primary)
                    type="number"
                />
                <v-text-field
                    label="Breite"
                    v-model="item.pool.width"
                    outlined
                    placeholder="20m"
                    color=var(--Primary)
                    type="number"
                />
                <v-text-field
                    label="m²"
                    v-model="item.pool.square_meter"
                    outlined
                    placeholder="211 m²"
                    color=var(--Primary)
                    type="number"
                />
                <v-text-field
                    label="Min. Tiefe"
                    v-model="item.pool.depth_min"
                    outlined
                    placeholder="5m"
                    color=var(--Primary)
                    type="number"
                />
                <v-text-field
                    label="Max. Tiefe"
                    v-model="item.pool.depth_max"
                    outlined
                    placeholder="2m"
                    color=var(--Primary)
                    type="number"
                />

                <Checkboxes
                    :list="pool_art"
                    title="Pool Art"
                    :selecteditems="item.pool.pool_art"
                    cid="pool_art"
                    :index="index"
                />

                <Checkboxes
                    :list="pool_facilities"
                    title="Pool Facilities"
                    :selecteditems="item.pool.pool_facilities"
                    cid="pool_facilities"
                    :index="index"
                />


                <Checkboxes
                    :list="pool_temperature"
                    title="Pool Temperature"
                    :selecteditems="item.pool.pool_temperature"
                    cid="pool_temperature"
                    :index="index"
                />
                <div v-if="index !== facilities.pools.length-1">
                  <br>
                  <v-divider/>
                  <br>
                </div>
              </div>
              <div>

                <v-btn outlined color="var(--Primary)" @click="addPool">
                  Weiteren Pool anlegen
                  <v-icon>mdi-plus</v-icon>
                </v-btn>

              </div>

              <!-- ---------------------------------------------------------- Pool ENDE ----------------------------------------------- -->

              <br>
              <v-divider/>
              <br>

              <!-- ---------------------------------------------------------- Beach ----------------------------------------------- -->

              <h2>Beach</h2>

              <v-text-field
                  label="Beach Startdatum"
                  v-model="facilities.beach.startdate"
                  outlined
                  placeholder="DD.MM"
                  color=var(--Primary)
              />
              <v-text-field
                  label="Beach Enddatum"
                  v-model="facilities.beach.enddate"
                  outlined
                  placeholder="DD.MM"
                  color=var(--Primary)
              />

              <Checkboxes
                  :list="beach_sunbathing"
                  title="Beach Sunbathing"
                  :selecteditems="facilities.beach.beach_sunbathing"
                  cid="beach_sunbathing"
              />

              <Checkboxes
                  :list="beach_swimming"
                  title="Beach Swimming"
                  :selecteditems="facilities.beach.beach_swimming"
                  cid="beach_swimming"
              />

              <Checkboxes
                  :list="beach_facilities"
                  title="Beach Facilities"
                  :selecteditems="facilities.beach.beach_facilities"
                  cid="beach_facilities"
              />

              <Checkboxes
                  :list="beach_amenities"
                  title="Beach Amenities"
                  :selecteditems="facilities.beach.beach_amenities"
                  cid="beach_amenities"
              />


            </div>
          </v-tab-item>


          <!-- --------------------------------------- TAB 4 Nicht vergeben --------------------------------- -->


          <!-- --------------------------------------- TAB 5 Notizen Wichtige Infos --------------------------------- -->


          <v-tab-item
              :key="5"
          >

            <div class="div-tab-createhotel">


              <v-textarea
                  label="Wichtige Hinweise DE"
                  v-model="facilities.notes_de"
                  auto-grow
                  outlined
                  rows="3"
                  row-height="35"
                  color=var(--Primary)
              />

              <v-textarea
                  label="Wichtige Hinweise TR"
                  v-model="facilities.notes_tr"
                  auto-grow
                  outlined
                  rows="3"
                  row-height="35"
                  color=var(--Primary)
              />

              <v-textarea
                  label="Wichtige Hinweise EN"
                  v-model="facilities.notes_en"
                  auto-grow
                  outlined
                  rows="3"
                  row-height="35"
                  color=var(--Primary)
              />
            </div>
          </v-tab-item>


          <!-- --------------------------------------- TAB 6 Contact --------------------------------- -->


          <v-tab-item
              :key="6"
          >

            <div class="div-tab-createhotel" style="max-width: 800px">

              <v-text-field
                  label="Ansprechpartner"
                  v-model="contact.name"
                  outlined
                  placeholder="Vorname Nachname"
                  color=var(--Primary)
              />
              <v-text-field
                  label="E-Mail"
                  v-model="contact.email"
                  outlined
                  placeholder="exampel@hotel.tr"
                  color=var(--Primary)
              />
              <v-text-field
                  label="Telefon 1"
                  v-model="contact.tel1"
                  outlined
                  type="number"
                  placeholder="+49123456666"
                  color=var(--Primary)
              />
              <v-text-field
                  label="Telefon 2"
                  v-model="contact.tel2"
                  outlined
                  type="number"
                  placeholder="+49123456777"
                  color=var(--Primary)
              />
              <v-text-field
                  label="Fax"
                  v-model="contact.fax"
                  outlined
                  type="number"
                  placeholder="+49123456888"
                  color=var(--Primary)
              />


            </div>
          </v-tab-item>


        </v-tabs-items>


        <br>
        <v-divider/>
        <br>

        <div style="margin: 50px 0">
          <v-btn @click="saveNewHotel" color="var(--Primary)" style="color: white" v-if="!checkupdateOwnPlace">
            {{ $t("Button.Speichern") }}
          </v-btn>
          <v-btn @click="updateOwnHotel" color="var(--Primary)" style="color: white" v-else>
            <!--            {{ $t("Button.Speichern") }}-->
            Update Hotel
          </v-btn>
        </div>

      </div>


    </div>

  </div>
</template>

<script>
import {onePlaceWithParameter} from "@/util/halalapi";
import {addOwnHalalProvision, getOwnHalalProvisionByHalalid} from "@/util/halalplaceUtil";
import {getCountries} from "@/util/Countries";
import {getAllSettings} from "../util/settingsUtil";
import Checkboxes from "../components/checkboxes/Checkboxes";
import {getUser} from "@/util/Auth";
import PicturePool from "@/components/picture_pool/PicturePool";
import CreateRoom from "@/components/createPlace/CreateRoomWrapper";
import {generatePlaceID, generateRatePlanID} from "@/util/GenerateId";
import {createOwnPlace, updateOwnPlace} from "@/util/OwnPlace";
import CardRoom from "@/components/card/CardRoom";
import HotelDatePicker from 'vue-hotel-datepicker';
import HotelDatePickerWrapper from "@/components/DatePicker/HotelDatePickerWrapper";
import {getLanguages} from "@/util/LanguageUtil";
import {getCurrencyIcon} from "@/util/currency";
import {getAllHotelUser} from "@/util/userUtil";
import {getUserinfoByUserId} from "@/util/userinfoDBUtil";
import {getRatePlanArray} from "@/util/rateplansUtil";


export default {
  name: "CreateHotel",
  props: [
    'pLang',
    'pRolle',
    'pSetting',
    'pUserinfo'
  ],
  components: {
    CardRoom,
    PicturePool,
    Checkboxes,
    CreateRoom,
    HotelDatePicker,
    HotelDatePickerWrapper
  },
  data() {
    return {
      notificationsnackbar: false,
      notificationtext: "",
      notificationcolor: "grey",

      checkupdateOwnPlace: false,
      id: "",

      checkinputs: false,
      checkExist: false,

      loading: false,
      // hotelart: "halalbooking",
      hotelart: "",
      halalplaceID: "",
      searchHotelID: "",
      halalPlace: {},
      checkPlace: false,
      halalPlaceProvision: "",
      halalPlaceEMail: "",
      rules: {
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value);
        }
      },


      set: [],
      generallist: [],
      activitieslist: [],
      foodlist: [],
      childrenlist: [],
      entertainmentlist: [],
      internetlist: [],
      parkinglist: [],
      shopslist: [],
      receptionlist: [],
      businesslist: [],
      cleaninglist: [],
      transportlist: [],
      beachlist: [],
      spalist: [],
      poolslist: [],


      gen: [],
      act: [],
      foo: [],
      chi: [],
      ent: [],
      int: [],
      par: [],
      sho: [],
      rec: [],
      bus: [],
      cle: [],
      tra: [],

      spa: [],
      pool_facilities: [],
      pool_temperature: [],
      pool_art: [],


      beach_sunbathing: [],
      beach_swimming: [],
      beach_facilities: [],
      beach_amenities: [],


      // Eigenes neues Hotel anlgegen  --- ANFANG -------------------
      // Tabs Headers
      checkHotelexist: false,
      tab: null,
      createPlaceHeaders: [
        'Hotel', 'Facilities', 'Pools/Beach/Spa', 'Notes', 'Contact'
      ],
      roomindex: -1,
      newRoom: true,


      selecteditems: [],
      oplace: "",
      status_place: "",
      place_id: "",
      place_name_de: "",
      place_name_tr: "",
      place_name_en: "",
      stars_items: [0, 1, 2, 3, 4, 5],
      stars: "",
      checkin_time: "",
      checkout_time: "",
      holiday_type_items: [
        {id: "resort", text: "Resorts"},
        {id: "hotel", text: "Städtereise"},
        {id: "villa", text: "Villen"},
        {id: "thermal", text: "Wellness"}
      ],
      holiday_type: null,
      place_desc_de: "",
      place_desc_tr: "",
      place_desc_en: "",


      price: [{
        startdate: "",
        enddate: "",
        price: "",
        // currency: ""
      }],

      children_price: {
        age_start: "",
        age_end: "",
        rabatt: ""
      },

      baby_price: {
        age_start: "",
        age_end: "",
        rabatt: ""
      },

      currency: "",
      currency_items: ['EUR', 'TRY', 'USD'],
      provision: "",
      selectedlanguages: [],


      maxCount_de: 500,
      remainingCount_de: 500,
      message_de: '',
      hasError_de: false,

      maxCount_tr: 500,
      remainingCount_tr: 500,
      message_tr: '',
      hasError_tr: false,

      maxCount_en: 500,
      remainingCount_en: 500,
      message_en: '',
      hasError_en: false,


      countryItems: getCountries(localStorage.getItem('lang')),
      location: {
        country: "",
        region: "",
        subregion: "",
        city: "",
        locality: "",
        name: "",
        plz: "",
        strnr: "",
      },


      facilities: {
        general: [],
        activities: [],
        food: [],
        children: [],
        entertainment: [],
        internet: [],
        parking: [],
        shops: [],
        reception: [],
        business: [],
        cleaning: [],
        transport: [],


        well_spa: [{
          starttime: "",
          endtime: "",
          facilities: [],
        }],


        pools: [{

          pool: {

            startdate: "",
            enddate: "",
            starttime: "",
            endtime: "",
            depth_min: "",
            depth_max: "",
            length: "",
            width: "",
            square_meter: "",

            pool_facilities: [],
            pool_temperature: [],
            pool_art: [],

          },

        }],


        beach: {
          startdate: "",
          enddate: "",
          beach_sunbathing: [],
          beach_swimming: [],
          beach_facilities: [],
          beach_amenities: []
        },

        notes_de: "",
        notes_tr: "",
        notes_en: "",
      },


      rate_plan_items: getRatePlanArray(),
      // rate_plan_items_de: [
      //   {id: "room_only", text: "Nur Zimmer"},
      //   {id: "Self-catering", text: "Selbstverpfl."},
      //   {id: "breakfast", text: "Frühstück inklusive"},
      //   {id: "conti_breakfast", text: "Kontinentales Frühstück"},
      //   {id: "half_board", text: "Halbpension"},
      //   {id: "half_board_plus", text: "Halbpension Plus"},
      //   {id: "full_board", text: "Vollpension"},
      //   {id: "full_board_plus", text: "Vollpension Plus"},
      //   {id: "all_inclusive", text: "All-inclusive"},
      //   {id: "ultra_all_inclusive", text: "Ultra All-Inclusive"}
      // ],

      rooms_counter: 0,
      rooms: [],

      rate_plans: [],

      pictures_place: [],


      contact: {
        name: "",
        email: "",
        tel1: "",
        tel2: "",
        fax: ""
      },

      hotelAccounts: [{
        kontakt: {
          firma: "Kein Hotel-Account!"
        }
      }],
      hotelAccount: "",

      // Eigenes neues Hotel anlgegen  --- ENDE -------------------

      selectedstartdate: "",
      selectedenddate: "",


      SearchDatePickerDE: {
        night: 'Nacht',
        nights: 'Nächste',
        'day-names': ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        'check-in': 'Start-Datum',
        'check-out': 'End-Datum',
        'month-names': ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
        "tooltip": {
          "minimumRequiredPeriod": "%{minNightInPeriod} %{night} minimum"
        },
      },
      SearchDatePickerTR: {
        night: 'Gece',
        nights: 'Geceler',
        'day-names': ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt'],
        'check-in': 'Giriş',
        'check-out': 'Çıkış',
        'month-names': ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
        "tooltip": {
          "minimumRequiredPeriod": "%{minNightInPeriod} %{night} minimum"
        },
      },
      SearchDatePickerEN: {
        night: 'Night',
        nights: 'Nights',
        'day-names': ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        'check-in': 'Start-Date',
        'check-out': 'End-Date',
        'month-names': ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        "tooltip": {
          "minimumRequiredPeriod": "%{minNightInPeriod} %{night} minimum"
        },
      }


    };
  },
  methods: {
    getLanguages,
    getCurrencyIcon,
    notification(text, color) {
      this.notificationsnackbar = true;
      this.notificationtext = text;
      this.notificationcolor = color;
    },
    async activatePlace() {
      this.status_place = "1";
      await this.updateOwnHotel();
    },
    async deactivatePlace() {
      this.status_place = "0";
      await this.updateOwnHotel();
    },

    addNewDatePrice() {

      this.price.push({
        startdate: "",
        enddate: "",
        price: "",
        // currency: ""
      })
    },


    deleteSpa(index) {

      this.facilities.well_spa = this.facilities.well_spa.filter((item, i) => i !== index);
      this.notification("Spa gelöscht", "green");

    },

    deletePool(index) {

      this.facilities.pools = this.facilities.pools.filter((item, i) => i !== index);

      this.notification("Pool gelöscht", "green");
    },

    deleteRatePlan(index) {

      this.rate_plans = this.rate_plans.filter((item, i) => i !== index);

      this.notification("Verpflegung gelöscht", "green");
    },


    async deletePrice(index) {

      let price = this.price.filter((it, i) => i !== index);
      this.price = price;
      // let p = {
      //   ...this.oplace,
      //   price: this.price
      // }

      // let status = await updateOwnPlace(p);
      // if (status === 200) {
      //   this.notification("Preis gelöscht", "green");
      //   await new Promise(resolve => setTimeout(resolve, 500));
      //
      //   // location.reload();
      //
      // } else {
      //   this.notification("Fehler beim Speichern", "error");
      // }

    },

    addNewRatePlan() {
      let rp = this.rate_plans[this.rate_plans.length - 1];

      this.rate_plans.push({
        price: "",
        rate_id: generateRatePlanID(),
        rate_plan_id: ""
      })

    },


    addWellSpa() {
      this.facilities.well_spa.push({
        starttime: "",
        endtime: "",
        facilities: [],
      });
    },


    addPool() {

      this.facilities.pools.push({
        pool: {
          startdate: "",
          enddate: "",
          starttime: "",
          endtime: "",

          depth_min: "",
          depth_max: "",
          length: "",
          width: "",
          square_meter: "",

          pool_facilities: [],
          pool_temperature: [],
          pool_art: [],
        }
      });
    },


    overview_countdown_de() {
      this.remainingCount_de = this.maxCount_de - this.place_desc_de.length;
      this.hasError_de = this.remainingCount_de < 0;
    },
    overview_countdown_tr() {
      this.remainingCount_tr = this.maxCount_tr - this.place_desc_tr.length;
      this.hasError_tr = this.remainingCount_tr < 0;
    },
    overview_countdown_en() {
      this.remainingCount_en = this.maxCount_en - this.place_desc_en.length;
      this.hasError_en = this.remainingCount_en < 0;
    },
    changeCountry() {

    },
    async getHalalPlace() {
      this.searchHotelID = this.halalplaceID;
      this.loading = true;
      this.halalPlace = {};
      if (this.halalplaceID !== "") {


        let id = this.halalplaceID;
        let checkin = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 16);
        let checkout = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 40);
        let groups = "2";


        let place = await onePlaceWithParameter(id, checkin, checkout, '&groups[]=' + groups);

        this.halalPlace = place;
        this.checkPlace = false;


      } else {
        if (this.halalplaceID === "") {
          this.notification("Bitte geben Sie eine ID ein!", "error");
        }

        this.checkPlace = true;
      }

      if (this.halalPlace !== "") {
        let provision = await getOwnHalalProvisionByHalalid(this.halalplaceID);

        if (provision !== "") {
          this.checkExist = true;
          this.checkPlace = false;
        }

        if (provision === "") {
          this.checkExist = false;
        }
      } else {
        this.checkPlace = true;
      }

      this.loading = false;


    },
    validateFields() {
      if (this.halalPlaceEMail !== "" && this.halalPlaceProvision !== "" && this.halalplaceID !== "") {
        this.checkinputs = true;
      }
    },
    async saveHalalPlace() {
      let user = await getUser(localStorage.getItem("token"));

      let halalPlace = {
        halal_place_id: this.halalplaceID,
        halal_place_name: this.halalPlace.place.name,
        provision: this.halalPlaceProvision,
        email: this.halalPlaceEMail,
        firma_id: user.firma_id
      }

      let status = await addOwnHalalProvision(halalPlace);

      if (status === 201) {
        localStorage.setItem("notiy", "Speichern erfolgreich")
        this.$router.push("/hotellist");

      }


    },


    async saveNewHotel() {

      this.rate_plans = this.rate_plans.filter(item => item.price !== "" && item.price !== null);

      let data = {

        status_place: "0",
        place_id: this.place_id,
        place_name_de: this.place_name_de,
        place_name_tr: this.place_name_tr,
        place_name_en: this.place_name_en,
        stars: this.stars,
        checkin_time: this.checkin_time,
        checkout_time: this.checkout_time,

        price: this.price,
        children_price: this.children_price,
        baby_price: this.baby_price,
        currency: this.currency,
        provision: this.provision,


        holiday_type: this.holiday_type,
        place_desc_de: this.place_desc_de,
        place_desc_tr: this.place_desc_tr,
        place_desc_en: this.place_desc_en,

        location: this.location,


        facilities: this.facilities,

        rate_plans: this.rate_plans,
        rooms_counter: 0,
        rooms: this.rooms,

        contact: this.contact,
        cy_hotelowner: this.hotelAccount

      }

      let status = await createOwnPlace(data);

      if (status === 201) {
        this.notification("Erfolgreich abgespeichert", "green");
      }
      this.checkupdateOwnPlace = true;

    },
    async updateOwnHotel() {

      this.rate_plans = this.rate_plans.filter(item => item.price !== "" && item.price !== null);

      let data = {
        _id: this.id,
        status_place: this.status_place,
        place_id: this.place_id,
        place_name_de: this.place_name_de,
        place_name_tr: this.place_name_tr,
        place_name_en: this.place_name_en,
        stars: this.stars,
        checkin_time: this.checkin_time,
        checkout_time: this.checkout_time,

        holiday_type: this.holiday_type,
        place_desc_de: this.place_desc_de,
        place_desc_tr: this.place_desc_tr,
        place_desc_en: this.place_desc_en,

        price: this.price,
        children_price: this.children_price,
        baby_price: this.baby_price,
        currency: this.currency,
        provision: this.provision,
        languages: this.selectedlanguages,

        location: this.location,


        facilities: this.facilities,

        rate_plans: this.rate_plans,
        rooms_counter: this.rooms_counter,
        rooms: this.rooms,

        pictures_place: this.pictures_place,

        contact: this.contact,
        cy_hotelowner: this.hotelAccount

      }
      console.log("data.price ", data.price);
      console.log("this.price ", this.price);

      let status = await updateOwnPlace(data);

      if (status === 200) {
        this.notification("Erfolgreich abgespeichert", "green");
        await new Promise(resolve => setTimeout(resolve, 500));
        this.$router.push('/hotellist');
      } else {

        this.notification("Fehler beim Speichern", "error");
      }


    },
    updateCheckIn(date) {

      this.$root.$on("check-in-changed", val => {

      })

      // item.startdate = date;
    },

    updateCheckOut(date) {
      // item.enddate = date;
    },
  },

  computed: {
    getLangDatePicker() {

      if (localStorage.getItem('lang')) {
        if (localStorage.getItem('lang') === "EN") {
          return this.SearchDatePickerEN;
        }
        if (localStorage.getItem('lang') === "TR") {
          return this.SearchDatePickerTR;
        }
        if (localStorage.getItem('lang') === "DE") {
          return this.SearchDatePickerDE;
        }
      }
      return this.SearchDatePickerDE;
    }
  },

  async created() {
    if (localStorage.getItem("ownp")) {
      let item = JSON.parse(localStorage.getItem("ownp"));
      this.oplace = item;
      this.id = item._id;
      this.status_place = item.status_place;
      this.place_id = item.place_id;
      this.place_name_de = item.place_name_de;
      this.place_name_tr = item.place_name_tr;
      this.place_name_en = item.place_name_en;
      this.stars = item.stars;
      this.checkin_time = item.checkin_time;
      this.checkout_time = item.checkout_time;

      this.holiday_type = item.holiday_type;
      this.place_desc_de = item.place_desc_de;
      this.place_desc_tr = item.place_desc_tr;
      this.place_desc_en = item.place_desc_en;

      this.price = item.price;
      if (this.price === undefined || this.price === null || this.price.length === 0) {
        this.price = [{
          startdate: "",
          enddate: "",
          price: "",
          // currency: ""
        }]


      }

      let a = this.price;
      a.sort(function (a, b) {
        const bandA = new Date(a.startdate);
        const bandB = new Date(b.startdate);

        let comparison = 0;
        if (bandA > bandB) {
          comparison = 1;
        } else if (bandA < bandB) {
          comparison = -1;
        }
        return comparison;
      });
      this.price = a;


      this.children_price = item.children_price;
      this.baby_price = item.baby_price;
      this.currency = item.currency;
      this.provision = item.provision;

      this.selectedlanguages = item.languages;
      this.location = item.location;


      this.facilities = item.facilities;

      this.rate_plans = item.rate_plans;
      if (this.rate_plans === undefined || this.rate_plans.length === 0) {
        this.rate_plans = [{
          price: "",
          rate_id: generateRatePlanID(),
          rate_plan_id: "",
        }]
      } else {

      }

      if (item.pictures_place !== undefined) {
        this.pictures_place = item.pictures_place;
      }
      this.rooms_counter = item.rooms_counter;
      this.rooms = item.rooms;
      this.contact = item.contact;
      this.hotelAccount = item.cy_hotelowner;


      this.checkupdateOwnPlace = true;
      this.checkHotelexist = true;

      localStorage.removeItem("ownp");

    } else {

      this.place_id = generatePlaceID();
      this.rate_plans.push({
        price: "",
        rate_id: generateRatePlanID(),
        rate_plan_id: ""

      });
    }


    this.set = await getAllSettings();
    let gen = [];
    let act = [];
    let foo = [];
    let chi = [];
    let ent = [];
    let int = [];
    let par = [];
    let sho = [];
    let rec = [];
    let bus = [];
    let cle = [];
    let tra = [];
    let spa = [];
    let pool_facilities = [];
    let pool_temperature = [];
    let pool_art = [];
    let beach_sunbathing = [];
    let beach_swimming = [];
    let beach_facilities = [];
    let beach_amenities = [];


    this.set.facilities_de.general.map(item => {

      let f_id = item.f_id;
      let en = this.set.facilities_en.general.find(it => it.f_id === item.f_id);
      let tr = this.set.facilities_tr.general.find(it => it.f_id === item.f_id);
      gen.push({f_id: f_id, text_de: item.text, text_tr: tr.text, text_en: en.text});

    })
    this.gen = gen;


    this.set.facilities_de.activities.map(item => {
      let f_id = item.f_id;
      let en = this.set.facilities_en.activities.find(it => it.f_id === item.f_id);
      let tr = this.set.facilities_tr.activities.find(it => it.f_id === item.f_id);
      act.push({f_id: f_id, text_de: item.text, text_tr: tr.text, text_en: en.text});
    })
    this.act = act;


    this.set.facilities_de.food.map(item => {
      let f_id = item.f_id;
      let en = this.set.facilities_en.food.find(it => it.f_id === item.f_id);
      let tr = this.set.facilities_tr.food.find(it => it.f_id === item.f_id);
      foo.push({f_id: f_id, text_de: item.text, text_tr: tr.text, text_en: en.text});
    })
    this.foo = foo;


    this.set.facilities_de.children.map(item => {
      let f_id = item.f_id;
      let en = this.set.facilities_en.children.find(it => it.f_id === item.f_id);
      let tr = this.set.facilities_tr.children.find(it => it.f_id === item.f_id);
      chi.push({f_id: f_id, text_de: item.text, text_tr: tr.text, text_en: en.text});
    })
    this.chi = chi;


    this.set.facilities_de.entertainment.map(item => {
      let f_id = item.f_id;
      let en = this.set.facilities_en.entertainment.find(it => it.f_id === item.f_id);
      let tr = this.set.facilities_tr.entertainment.find(it => it.f_id === item.f_id);
      ent.push({f_id: f_id, text_de: item.text, text_tr: tr.text, text_en: en.text});
    })
    this.ent = ent;

    this.set.facilities_de.internet.map(item => {
      let f_id = item.f_id;
      let en = this.set.facilities_en.internet.find(it => it.f_id === item.f_id);
      let tr = this.set.facilities_tr.internet.find(it => it.f_id === item.f_id);
      int.push({f_id: f_id, text_de: item.text, text_tr: tr.text, text_en: en.text});
    })
    this.int = int;


    this.set.facilities_de.parking.map(item => {
      let f_id = item.f_id;
      let en = this.set.facilities_en.parking.find(it => it.f_id === item.f_id);
      let tr = this.set.facilities_tr.parking.find(it => it.f_id === item.f_id);
      par.push({f_id: f_id, text_de: item.text, text_tr: tr.text, text_en: en.text});
    })
    this.par = par;


    this.set.facilities_de.shops.map(item => {
      let f_id = item.f_id;
      let en = this.set.facilities_en.shops.find(it => it.f_id === item.f_id);
      let tr = this.set.facilities_tr.shops.find(it => it.f_id === item.f_id);
      sho.push({f_id: f_id, text_de: item.text, text_tr: tr.text, text_en: en.text});
    })
    this.sho = sho;


    this.set.facilities_de.reception.map(item => {
      let f_id = item.f_id;
      let en = this.set.facilities_en.reception.find(it => it.f_id === item.f_id);
      let tr = this.set.facilities_tr.reception.find(it => it.f_id === item.f_id);
      rec.push({f_id: f_id, text_de: item.text, text_tr: tr.text, text_en: en.text});
    })
    this.rec = rec;


    this.set.facilities_de.business.map(item => {
      let f_id = item.f_id;
      let en = this.set.facilities_en.business.find(it => it.f_id === item.f_id);
      let tr = this.set.facilities_tr.business.find(it => it.f_id === item.f_id);
      bus.push({f_id: f_id, text_de: item.text, text_tr: tr.text, text_en: en.text});
    })
    this.bus = bus;


    this.set.facilities_de.cleaning.map(item => {
      let f_id = item.f_id;
      let en = this.set.facilities_en.cleaning.find(it => it.f_id === item.f_id);
      let tr = this.set.facilities_tr.cleaning.find(it => it.f_id === item.f_id);
      cle.push({f_id: f_id, text_de: item.text, text_tr: tr.text, text_en: en.text});
    })
    this.cle = cle;


    this.set.facilities_de.transport.map(item => {
      let f_id = item.f_id;
      let en = this.set.facilities_en.transport.find(it => it.f_id === item.f_id);
      let tr = this.set.facilities_tr.transport.find(it => it.f_id === item.f_id);
      tra.push({f_id: f_id, text_de: item.text, text_tr: tr.text, text_en: en.text});
    })
    this.tra = tra;


    this.set.facilities_de.spa.map(item => {
      let f_id = item.f_id;
      let en = this.set.facilities_en.spa.find(it => it.f_id === item.f_id);
      let tr = this.set.facilities_tr.spa.find(it => it.f_id === item.f_id);
      spa.push({f_id: f_id, text_de: item.text, text_tr: tr.text, text_en: en.text});
    })
    this.spa = spa;

    this.set.facilities_de.pool_facilities.map(item => {
      let f_id = item.f_id;
      let en = this.set.facilities_en.pool_facilities.find(it => it.f_id === item.f_id);
      let tr = this.set.facilities_tr.pool_facilities.find(it => it.f_id === item.f_id);
      pool_facilities.push({f_id: f_id, text_de: item.text, text_tr: tr.text, text_en: en.text});
    })
    this.pool_facilities = pool_facilities;


    this.set.facilities_de.pool_temperature.map(item => {
      let f_id = item.f_id;
      let en = this.set.facilities_en.pool_temperature.find(it => it.f_id === item.f_id);
      let tr = this.set.facilities_tr.pool_temperature.find(it => it.f_id === item.f_id);
      pool_temperature.push({f_id: f_id, text_de: item.text, text_tr: tr.text, text_en: en.text});
    })
    this.pool_temperature = pool_temperature;


    this.set.facilities_de.pool_art.map(item => {
      let f_id = item.f_id;
      let en = this.set.facilities_en.pool_art.find(it => it.f_id === item.f_id);
      let tr = this.set.facilities_tr.pool_art.find(it => it.f_id === item.f_id);
      pool_art.push({f_id: f_id, text_de: item.text, text_tr: tr.text, text_en: en.text});
    })
    this.pool_art = pool_art;


    this.set.facilities_de.beach_sunbathing.map(item => {
      let f_id = item.f_id;
      let en = this.set.facilities_en.beach_sunbathing.find(it => it.f_id === item.f_id);
      let tr = this.set.facilities_tr.beach_sunbathing.find(it => it.f_id === item.f_id);
      beach_sunbathing.push({f_id: f_id, text_de: item.text, text_tr: tr.text, text_en: en.text});
    })
    this.beach_sunbathing = beach_sunbathing;

    this.set.facilities_de.beach_swimming.map(item => {
      let f_id = item.f_id;
      let en = this.set.facilities_en.beach_swimming.find(it => it.f_id === item.f_id);
      let tr = this.set.facilities_tr.beach_swimming.find(it => it.f_id === item.f_id);
      beach_swimming.push({f_id: f_id, text_de: item.text, text_tr: tr.text, text_en: en.text});
    })
    this.beach_swimming = beach_swimming;

    this.set.facilities_de.beach_facilities.map(item => {
      let f_id = item.f_id;
      let en = this.set.facilities_en.beach_facilities.find(it => it.f_id === item.f_id);
      let tr = this.set.facilities_tr.beach_facilities.find(it => it.f_id === item.f_id);
      beach_facilities.push({f_id: f_id, text_de: item.text, text_tr: tr.text, text_en: en.text});
    })
    this.beach_facilities = beach_facilities;

    this.set.facilities_de.beach_amenities.map(item => {
      let f_id = item.f_id;
      let en = this.set.facilities_en.beach_amenities.find(it => it.f_id === item.f_id);
      let tr = this.set.facilities_tr.beach_amenities.find(it => it.f_id === item.f_id);
      beach_amenities.push({f_id: f_id, text_de: item.text, text_tr: tr.text, text_en: en.text});
    })
    this.beach_amenities = beach_amenities;


    this.$root.$on("Roominfos", (val, index) => {
      this.rooms[index] = val;

    });

    this.$root.$on("checkboxes", (val, cid, index) => {
      if (cid === "general") {
        this.facilities.general = val;
      }
      if (cid === "activity") {
        this.facilities.activities = val;
      }
      if (cid === "food") {
        this.facilities.food = val;
      }
      if (cid === "children") {
        this.facilities.children = val;
      }
      if (cid === "entertainment") {
        this.facilities.entertainment = val;
      }
      if (cid === "internet") {
        this.facilities.internet = val;
      }
      if (cid === "parking") {
        this.facilities.parking = val;
      }
      if (cid === "shops") {
        this.facilities.shops = val;
      }
      if (cid === "reception") {
        this.facilities.reception = val;
      }
      if (cid === "business") {
        this.facilities.business = val;
      }

      if (cid === "cleaning") {
        this.facilities.cleaning = val;
      }

      if (cid === "transport") {
        this.facilities.transport = val;
      }

      if (cid === "well_spa") {
        this.facilities.well_spa[index].facilities = val;
      }


      if (cid === "pool_facilities") {
        this.facilities.pools[index].pool.pool_facilities = val;
      }

      if (cid === "pool_temperature") {
        this.facilities.pools[index].pool.pool_temperature = val;
      }

      if (cid === "pool_art") {
        this.facilities.pools[index].pool.pool_art = val;
      }

      if (cid === "beach_sunbathing") {
        this.facilities.beach.beach_sunbathing = val;
      }
      if (cid === "beach_swimming") {
        this.facilities.beach.beach_swimming = val;
      }
      if (cid === "beach_facilities") {
        this.facilities.beach.beach_facilities = val;
      }
      if (cid === "beach_amenities") {
        this.facilities.beach.beach_amenities = val;
      }

    });

    this.$root.$on("pictureplace", val => {
      this.pictures_place = val;
    })

    let hotels = await getAllHotelUser();

    hotels.map(async item => {
      let ui = await getUserinfoByUserId(item._id)
      this.hotelAccounts.push(ui);

    })

    if (this.pRolle === 'hotel') {
      this.hotelAccount = this.pUserinfo.userid;
    }
  }
};
</script>

<style scoped>
@import "../css/CreateHotel.css";
</style>