<template>
  <div class="div-place">
    <!--    <div class="div-place" v-bind:class="stopScrolling ? 'no-scroll':''">-->
    <v-snackbar
        v-model="notificationsnackbar"
        :timeout="6000"
        :color="notificationcolor"
        :top="true"
        :right="true"
        :vertical="true"
    >
      {{ notificationtext }}
      <v-btn
          color="white"
          text
          @click="notificationsnackbar = false"
      >
        {{ $t("Notification.Schliessen") }}
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

    <div class="div-body-place">

      <v-overlay :value="loading">
        <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
            :size="50"
            :width="7"
        />
      </v-overlay>

      <div class="div-body-grid-place">
        <div class="div-body-left-place">

          <CY_Gallery v-if="ownPlace.pictures_place" :pics="ownPlace.pictures_place"></CY_Gallery>


          <div class="div-grid-title-place">
            <div class="div-header-location-place">
              <label v-if="ownPlace" class="label-placename-place">

                {{ ownPlace['place_name_' + pLang.toLowerCase()] }}
              </label>
              <v-rating
                  v-if="ownPlace"
                  v-model="ownPlace.stars"
                  background-color="transparent"
                  color="#FFDF00"
                  small
                  readonly
              />

              <div v-if="ownPlace.location" class="div-location-place">
                <v-icon color="black" small>mdi-map-marker-radius</v-icon>
                <label>
                  {{ ownPlace.location.city }},
                  <!--                {{ ownPlace.location.subregion }},-->
                  {{ ownPlace.location.country }}
                </label>
              </div>
            </div>

            <div class="div-social-share-place">


              <!--   -----------------------------------   Social Media ----------------------------------- -->
              <div class="div-social-share-trip">
                <label style="font-size: 11px;">Teilen Sie die Unterkunft</label>
                <div class="div-social-share-trip1">
                  <ShareNetwork
                      network="facebook"
                      :url="'https://'+pDomain+'/cy-place?id='+ownPlace.place_id.split('-')[1]"
                      :title="pDomain+', Finden Sie Jetzt Ihre Traumreise'"
                      :description="ownPlace['place_name_' + pLang.toLowerCase()]"
                      :quote=" 'Siehe dir folgendes Hotel an: '+  ownPlace['place_name_' + pLang.toLowerCase()]"
                      :hashtags="pDomain"

                  >
                    <v-icon color="#4267B2">mdi-facebook</v-icon>
                  </ShareNetwork>

                  <ShareNetwork
                      network="twitter"
                      :url="'https://'+pDomain+'/cy-place?id='+ownPlace.place_id.split('-')[1]"
                      :title="pDomain+', Finden Sie Jetzt Ihre Traumreise'"
                      :description=" ownPlace['place_name_' + pLang.toLowerCase()]"
                      :quote=" 'Siehe dir folgendes Hotel an: '+  ownPlace['place_name_' + pLang.toLowerCase()]"
                      hashtags="MyTourTravel"
                  >
                    <v-icon color="#1da1f2">mdi-twitter</v-icon>
                  </ShareNetwork>

                  <ShareNetwork
                      network="whatsapp"
                      :url="'https://'+pDomain+'/cy-place?id='+ownPlace.place_id.split('-')[1]"
                      title="Siehe dir das Hotel an:"
                      :description=" ownPlace['place_name_' + pLang.toLowerCase()]"
                  >
                    <v-icon color="#4ac959">mdi-whatsapp</v-icon>
                  </ShareNetwork>


                  <ShareNetwork
                      network="email"
                      :title="'Hotel auf '+ pDomain"
                      :description="'Hier ist eine sehr schönes Hotel auf '+pDomain+', schau es dir doch mal an: '+  ownPlace['place_name_' + pLang.toLowerCase()]"
                      :url="'https://'+pDomain+'/cy-place?id='+ownPlace.place_id.split('-')[1]"
                  >
                    <v-icon color="black">mdi-email-outline</v-icon>
                  </ShareNetwork>
                </div>

              </div>

              <!--   -----------------------------------   Social Media ENDE ----------------------------------- -->


            </div>
          </div>


          <v-divider style="margin: 10px 0;"></v-divider>

          <div
              v-if="ownPlace" class="div-overview-place"
              style="border-right: unset"
          >{{ place_desc }}
          </div>

          <v-divider style="margin: 10px 0;"></v-divider>


          <div v-if="ownPlace">
            <SearchSmall
                :propsPlaceID="ownPlace.place_id"
                :checkin="checkin"
                :checkout="checkout"
                from="cy-place"
            />
          </div>


          <v-divider></v-divider>
          <!-- ------------------------------- Card Room Anfang ------------------------------- -->


          <div style="margin: 20px 5px 30px 5px" id="title-place">

            <div v-if="ownPlace.rooms">
              <div class="div-title-price-outer-place">
                <div class="div-contentinfo-place">
                  <div class="div-contentinfo-title-place">
                    <div class="line"/>
                    <div class="div-contentinfo-title-place1">
                      <span></span>
                      <div class="div-title-price-place">

                        <label class="label-title-price-place">
                          <span v-if="pLang !== 'TR'">
                            {{ $t("Place.Preisefur") }}
                            <br/>
                          </span>

                          <span>
                            {{ ownPlace['place_name_' + pLang.toLowerCase()] }}
                          </span>

                          <span v-if="pLang === 'TR'">
                            <br>
                            {{ $t("Place.Preisefur") }}
                          </span>
                        </label>
                      </div>
                    </div>
                    <div class="line"/>
                  </div>
                </div>
              </div>

              <div style="z-index: 2; position: relative">
                <v-expansion-panels multiple v-model="panel">

                  <template v-for="(gruppen,i) in gruppe">

                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <template v-slot:default="{ open }">
                          <v-row no-gutters>
                            <span v-if="gruppe.length-1 >0">

                              {{ $t("Place.Gruppe") }} {{ i + 1 }}
                            </span>
                            <span v-else></span>
                            <v-fade-transition leave-absolute>

                              <span v-if="open" key="0" style="margin: auto">
                                <div v-if="selectedRoom[i].name === 'unset'" style="font-weight: bold;">
                                  {{ $t("Place.BitteZimmerwahlen") }}
                                </div>

                                <div v-else style="font-weight: bold; text-align: center">
                                  <v-icon color="green">mdi-check-circle-outline</v-icon>
                                  <span>
                                    {{ selectedRoom[i]['name_' + pLang.toLowerCase()] }}
                                  </span>
                                </div>

                              </span>


                              <span v-else key="1" style="margin: auto">
                                <div v-if="selectedRoom[i].name === 'unset'">
                                  <v-icon color="error">mdi-alert</v-icon>
                                  {{ $t("Place.KeinZimmer") }}
                                </div>

                                <div v-else style="font-weight: bold; text-align: center">
                                  <v-icon color="green">mdi-check-circle-outline</v-icon>
                                  <span>
                                    {{ selectedRoom[i]['name_' + pLang.toLowerCase()] }}
                                  </span>
                                </div>
                              </span>
                            </v-fade-transition>
                          </v-row>
                        </template>
                      </v-expansion-panel-header>


                      <v-expansion-panel-content>
                        <div class="div-rooms-place">

                          <template v-for="(info,index) in ownPlace.rooms">

                            <CY_CardRoom
                                v-if="info.status_room === 1"
                                :roominfo="info"
                                :rate_plans="ownPlace.rate_plans"
                                :index="index"
                                :groupindex="i"
                                :pLang="pLang"
                                :pGruppen="ownPlace.gruppen"
                                :key="index+keyroom"
                                :pCurrency="pCurrency"
                                :pCurrencyValues="pCurrencyValues"
                                :pPlaceCurrency="ownPlace.currency"
                            />
                            <!--                            :place="ownPlace"-->


                            <!--                            <CY_CardRoom-->
                            <!--                                v-if="cardDesign ==='1'"-->
                            <!--                                :roominfo="info"-->
                            <!--                                :place="ownPlace"-->
                            <!--                                :index="index"-->
                            <!--                                :groupindex="i"-->
                            <!--                            />-->
                            <!--                            <CardRoom2-->
                            <!--                                v-if="cardDesign ==='2'"-->
                            <!--                                :roominfo="info"-->
                            <!--                                :place="ownPlace"-->
                            <!--                                :index="index"-->
                            <!--                                :groupindex="i"-->
                            <!--                            />-->

                            <!--                            <CardRoom3-->
                            <!--                                v-if="cardDesign ==='3'"-->
                            <!--                                :roominfo="info"-->
                            <!--                                :place="ownPlace"-->
                            <!--                                :index="index"-->
                            <!--                                :groupindex="i"-->
                            <!--                            />-->

                            <!--                            <CardRoom4-->
                            <!--                                v-if="cardDesign ==='4'"-->
                            <!--                                :roominfo="info"-->
                            <!--                                :place="ownPlace"-->
                            <!--                                :index="index"-->
                            <!--                                :groupindex="i"-->
                            <!--                            />-->

                          </template>

                        </div>


                      </v-expansion-panel-content>
                    </v-expansion-panel>


                  </template>

                </v-expansion-panels>
              </div>
            </div>

            <!--            <div v-if="this.ownPlace.groups[0].offers.length < 1" class="div-norooms-place">-->
            <!--              <v-icon large color="error">mdi-calendar-search</v-icon>-->
            <!--              <label>-->
            <!--                {{ $t("Place.KeineErgebnisse") }}-->
            <!--              </label>-->
            <!--            </div>-->
          </div>
          <!-- ------------------------------- Card Room ENDE ------------------------------- -->


          <hr class="hr-horizontale-linie" style="margin: 20px 0;">


          <div class="div-infos-desk-place">


            <!-- ------------------------------------- Hotel Infos Anfang ------------------------------------- -->

            <div class="div-contentinfo-place">
              <div class="div-contentinfo-title-place">
                <div class="line"/>
                <div class="div-contentinfo-title-place1">
                  <v-icon color="var(--Primary)">mdi-fireplace-off</v-icon>
                  <label v-if="ownPlace.place_id" class="label-placename-place">
                    {{ ownPlace['place_name_' + pLang.toLowerCase()] }}
                  </label>
                </div>
                <div class="line"/>
              </div>

              <div class="div-overview-outer-place">
                <div v-if="ownPlace.place_id" class="div-overview-place">{{ place_desc }}</div>

                <div>
                  <div class="div-facs-place" v-if="ownPlace.checkin_time">
                    <v-icon color="var(--Primary)" style="margin-right: 8px">mdi-calendar-clock-outline</v-icon>
                    <div style="font-weight: bolder; margin-right: 5px;">
                      {{ $t('Place.Check-in') }}:
                    </div>
                    <div style="white-space: nowrap"><span v-if="pLang!=='TR'">
                      {{ $t('Place.Check-in-nach') }}
                    </span>{{ ownPlace.checkin_time }}<span v-if="pLang==='TR'">
                      {{ $t('Place.Check-in-nach') }}
                    </span>
                    </div>

                    <v-icon color="var(--Primary)" style="transform: rotate(90deg)">mdi-minus</v-icon>
                    <div style="font-weight: bolder; margin-right: 5px;">
                      {{ $t('Place.Check-out') }}:
                    </div>
                    <div style="white-space: nowrap"><span v-if="pLang!=='TR'">
                      {{ $t('Place.Check-out-vor') }}
                    </span>{{ ownPlace.checkout_time }}<span v-if="pLang==='TR'">
                      {{ $t('Place.Check-out-vor') }}
                    </span>
                    </div>
                  </div>

                  <div class="div-facs-place" v-if="ownPlace.holiday_type">
                    <v-icon color="var(--Primary)" style="margin-right: 8px">mdi-home-group</v-icon>
                    <div style="font-weight: bolder; margin-right: 5px;">
                      {{ $t('Place.Unterkunftstyp') }}:
                    </div>
                    <template v-for="(item,index) in ownPlace.holiday_type">
                      <span>{{ getHolidayType(pLang, item) }}</span><span
                        v-if="index < ownPlace.holiday_type.length-1"
                    >, </span>
                    </template>
                  </div>

                  <div class="div-facs-place" v-if="ownPlace.languages">
                    <v-icon color="var(--Primary)" style="margin-right: 8px">mdi-account-tie-voice</v-icon>
                    <div>
                      <span style="font-weight: bolder; margin-right: 5px;">
                        {{ $t('Place.GesprocheneSprachen') }}:
                      </span>
                      <template v-for="(items,index) in ownPlace.languages">
                        <span>{{ getLanguageByid(items) }}</span><span
                          v-if="index < ownPlace.languages.length-1"
                      >, </span>
                      </template>
                    </div>
                  </div>
                </div>
              </div>

            </div>


            <!-- ------------------------------------- Hotel Infos ENDE ------------------------------------- -->
            <div class="div-infos-grid-place">
              <div id="div-left-place">

                <!-- ------------------------------------- Strand Infos Anfang ------------------------------------- -->
                <div
                    class="div-contentinfo-place"
                    v-if="ownPlace.facilities !== undefined &&
                     (
                         ownPlace.facilities.beach.beach_sunbathing.length>0 ||
                         ownPlace.facilities.beach.beach_swimming.length>0 ||
                         ownPlace.facilities.beach.beach_amenities.length>0 ||
                         ownPlace.facilities.beach.beach_facilities.length>0
                     ) "
                >

                  <div class="div-contentinfo-title-place">
                    <div class="line"/>
                    <div class="div-contentinfo-title-place1">
                      <v-icon color="var(--Primary)">mdi-beach</v-icon>
                      <label class="label-placename-place">{{ $t('Place.STRÄNDE') }}</label>
                    </div>
                    <div class="line"/>
                  </div>


                  <div v-if="ownPlace.facilities.beach.startdate !== ''" style="margin-bottom: 10px">
                    <label style="font-weight: bold">{{ $t('Place.Öffnungszeit') }}: </label><label
                  >{{ ownPlace.facilities.beach.startdate }} - {{ ownPlace.facilities.beach.enddate }}</label>
                  </div>


                  <div class="div-beach-titles-place" v-if="ownPlace.facilities.beach.beach_sunbathing.length>0">
                    {{ $t('Place.StrandSonnenbadbereich') }}
                  </div>
                  <template v-for="item in ownPlace.facilities.beach.beach_sunbathing">
                    <div style="display: flex">
                      <v-icon color="var(--Primary)" small style="margin-right: 5px;">mdi-check</v-icon>
                      <div>{{ getValue(pLang, pSetting, "beach_sunbathing", item) }}</div>
                    </div>
                  </template>
                  <div class="div-beach-titles-place" v-if="ownPlace.facilities.beach.beach_swimming.length>0">
                    {{ $t('Place.Badestrand') }}
                  </div>
                  <template v-for="item in ownPlace.facilities.beach.beach_swimming">
                    <div style="display: flex">
                      <v-icon color="var(--Primary)" small style="margin-right: 5px;">mdi-check</v-icon>
                      <div>{{ getValue(pLang, pSetting, "beach_swimming", item) }}</div>
                    </div>
                  </template>
                  <div class="div-beach-titles-place" v-if="ownPlace.facilities.beach.beach_amenities.length>0">
                    {{ $t('Place.Strandannehmlichkeiten') }}
                  </div>
                  <template v-for="item in ownPlace.facilities.beach.beach_amenities">
                    <div style="display: flex">
                      <v-icon color="var(--Primary)" small style="margin-right: 5px;">mdi-check</v-icon>
                      <div>{{ getValue(pLang, pSetting, "beach_amenities", item) }}</div>
                    </div>
                  </template>
                  <div class="div-beach-titles-place" v-if="ownPlace.facilities.beach.beach_facilities.length>0">
                    {{ $t('Place.Strandeinrichtungen') }}
                  </div>
                  <template v-for="item in ownPlace.facilities.beach.beach_facilities">
                    <div style="display: flex">
                      <v-icon color="var(--Primary)" small style="margin-right: 5px;">mdi-check</v-icon>
                      <div>{{ getValue(pLang, pSetting, "beach_facilities", item) }}</div>
                    </div>
                  </template>

                </div>

                <!-- ------------------------------------- Strand Infos ENDE ------------------------------------- -->


                <!-- ------------------------------------- Wellness & Spa Infos Anfang ------------------------------------- -->
                <div
                    class="div-contentinfo-place"
                    v-if="ownPlace.facilities !== undefined && ownPlace.facilities.well_spa[0].facilities.length>0"
                >

                  <div class="div-contentinfo-title-place">
                    <div class="line"/>
                    <div class="div-contentinfo-title-place1">
                      <v-icon color="var(--Primary)">mdi-hand-heart-outline</v-icon>
                      <label class="label-placename-place">
                        {{ $t('Place.Wellness&Spa').toUpperCase() }}
                      </label>
                    </div>
                    <div class="line"/>
                  </div>


                  <template v-for="(items, index) in ownPlace.facilities.well_spa">
                    <div class="div-beach-titles-place">{{ $t('Place.Wellness&Spa') }} {{ index + 1 }}</div>
                    <div style="margin-bottom: 10px">
                      {{ $t('Place.Zeitplan') }}: {{ $t('Place.ab') }} {{ items.starttime }} {{ $t('Place.bis') }}
                      {{ items.endtime }}
                    </div>

                    <div v-for="item in items.facilities">
                      <div style="display: flex">
                        <v-icon color="var(--Primary)" small style="margin-right: 5px;">mdi-check</v-icon>
                        <div>{{ getValue(pLang, pSetting, "spa", item) }}</div>
                      </div>
                    </div>
                  </template>
                </div>
                <!-- ------------------------------------- Wellness & Spa Infos ENDE ------------------------------------- -->


                <!-- ------------------------------------- Pools Infos Anfang ------------------------------------- -->
                <div
                    class="div-contentinfo-place"
                    v-if="ownPlace.facilities !== undefined && ownPlace.facilities.pools[0].pool.pool_art.length>0"
                >

                  <div class="div-contentinfo-title-place">
                    <div class="line"/>
                    <div class="div-contentinfo-title-place1">
                      <v-icon color="var(--Primary)">mdi-pool</v-icon>
                      <label class="label-placename-place">{{ $t('Place.Pools') }}</label>
                    </div>
                    <div class="line"/>
                  </div>


                  <template v-for="(item, index) in ownPlace.facilities.pools">

                    <div class="div-beach-titles-place">{{ $t('Place.Pool') }} {{ index + 1 }}</div>
                    <div>{{ $t('Place.Öffnungszeit') }}: {{ item.pool.startdate }} - {{ item.pool.startdate }}</div>
                    <div style="margin-bottom: 10px"> {{ $t('Place.Zeitplan') }}:
                                                      {{ $t('Place.ab') }} {{ item.pool.starttime }} {{
                        $t('Place.bis')
                                                      }} {{ item.pool.endtime }}
                    </div>


                    <div class="div-pool-desc-place">
                      <div style="margin-bottom: 10px">
                        <template v-for="(it,n) in item.pool.pool_art">
                          <span>
                            <v-chip
                                class="vchip-pool-place"
                                color="var(--Primary)"
                                outlined
                            >
                              <span>{{ getValue(pLang, pSetting, "pool_art", it) }}</span>
                            </v-chip>
                          </span>

                        </template>
                        <span v-if="item.pool.depth_min !=='' && item.pool.depth_max !==''">
                          <v-chip
                              class="vchip-pool-place"
                              color="var(--Primary)"
                              outlined
                          >
                            <span>
                              {{ item.pool.depth_min }}cm - {{ item.pool.depth_max }}cm {{ $t('Place.Tiefe') }}
                            </span>
                          </v-chip>
                        </span>
                        <span v-if="item.pool.width !=='' && item.pool.length !==''">
                          <v-chip
                              class="vchip-pool-place"
                              color="var(--Primary)"
                              outlined
                          >
                            <span>{{ item.pool.width }}cm x {{ item.pool.length }}cm</span>
                          </v-chip>
                        </span>

                        <span v-if="item.pool.square_meter !==''">
                          <v-chip
                              class="vchip-pool-place"
                              color="var(--Primary)"
                              outlined
                          >
                            <span>{{ item.pool.square_meter }}m²</span>
                          </v-chip>
                        </span>
                      </div>

                      <div style="font-weight: bolder">{{ $t('Place.PoolAusstattungen') }}</div>
                      <template v-for="(it) in item.pool.pool_facilities">
                        <div>
                          <v-icon color="var(--Primary)">mdi-circle-small</v-icon>
                          <span>{{ getValue(pLang, pSetting, "pool_facilities", it) }}</span>
                        </div>
                      </template>


                      <div style="font-weight: bolder">{{ $t('Place.PoolTemperatur') }}</div>
                      <template v-for="(it) in item.pool.pool_temperature">
                        <div>
                          <v-icon color="var(--Primary)">mdi-circle-small</v-icon>
                          <span>{{ getValue(pLang, pSetting, "pool_temperature", it) }}</span>
                        </div>
                      </template>


                    </div>

                  </template>
                </div>
                <!-- ------------------------------------- Pools Infos ENDE ------------------------------------- -->

              </div>


              <div id="div-right-place">

                <!-- ------------------------------------- Location Infos Anfang ------------------------------------- -->
                <div class="div-contentinfo-place" v-if="ownPlace.facilities !== undefined">

                  <div class="div-contentinfo-title-place">
                    <div class="line"/>
                    <div class="div-contentinfo-title-place1">
                      <v-icon color="var(--Primary)">mdi-map</v-icon>
                      <label class="label-placename-place">{{ $t('Place.Lage') }}</label>
                    </div>
                    <div class="line"/>

                  </div>

                  <div class="div-facs-place" style="align-items: self-start">
                    <v-icon color="var(--Primary)" style="margin-right: 8px">mdi-map-marker-radius</v-icon>
                    <div>
                      {{ ownPlace.location.strnr }}, {{ ownPlace.location.plz }} {{ ownPlace.location.city }},
                      {{ ownPlace.location.country }}
                    </div>
                  </div>

                  <div v-if="googlemaps.key !== ''">
                    <iframe
                        :width="googlemaps.width"
                        :height="googlemaps.height"
                        style="border:0"
                        loading="lazy"
                        allowfullscreen
                        :src="this.googlemaps.src+this.googlemaps.key+this.googlemaps.place"
                    >
                    </iframe>
                  </div>

                </div>

                <!-- ------------------------------------- Location Infos ENDE ------------------------------------- -->

                <!-- ------------------------------------- Aktivitäten Infos Anfang ------------------------------------- -->
                <div
                    class="div-contentinfo-place"
                    v-if="ownPlace.facilities !== undefined && ownPlace.facilities.activities.length >0"
                >

                  <div class="div-contentinfo-title-place">
                    <div class="line"/>
                    <div class="div-contentinfo-title-place1">
                      <v-icon color="var(--Primary)">mdi-handball</v-icon>
                      <label class="label-placename-place">{{ $t('Place.Aktivitäten') }}</label>
                    </div>
                    <div class="line"/>
                  </div>


                  <template v-for="item in ownPlace.facilities.activities ">
                    <div style="display: flex; align-items: center;">
                      <v-icon color="var(--Primary)" large>mdi-circle-small</v-icon>
                      <div>{{ getValue(pLang, pSetting, "activities", item) }}</div>
                    </div>
                  </template>

                </div>
                <!-- ------------------------------------- Aktivitäten Infos ENDE ------------------------------------- -->


                <!-- ------------------------------------- Essen & Trinken Infos Anfang ------------------------------------- -->

                <div
                    class="div-contentinfo-place"
                    v-if="ownPlace.facilities !== undefined && ownPlace.facilities.food.length >0"
                >

                  <div class="div-contentinfo-title-place">
                    <div class="line"/>
                    <div class="div-contentinfo-title-place1">
                      <v-icon color="var(--Primary)">mdi-food-fork-drink</v-icon>
                      <label class="label-placename-place">{{ $t('Place.Speisen&Getränke') }}</label>
                    </div>
                    <div class="line"/>
                  </div>

                  <template v-for="item in ownPlace.facilities.food ">
                    <div style="display: flex; align-items: center;">
                      <v-icon color="var(--Primary)" large>mdi-circle-small</v-icon>
                      <div>{{ getValue(pLang, pSetting, "food", item) }}</div>
                    </div>
                  </template>
                </div>
                <!-- ------------------------------------- Essen & Trinken Infos ENDE ------------------------------------- -->


                <!-- ------------------------------------- Kinder Infos Anfang ------------------------------------- -->
                <div
                    class="div-contentinfo-place"
                    v-if="ownPlace.facilities !== undefined && ownPlace.facilities.children.length >0"
                >

                  <div class="div-contentinfo-title-place">
                    <div class="line"/>
                    <div class="div-contentinfo-title-place1">
                      <v-icon color="var(--Primary)">mdi-baby-face-outline</v-icon>
                      <label class="label-placename-place">{{ $t('Place.FürKinder') }}</label>
                    </div>
                    <div class="line"/>
                  </div>

                  <template v-for="item in ownPlace.facilities.children ">
                    <div style="display: flex; align-items: center;">
                      <v-icon color="var(--Primary)" large>mdi-circle-small</v-icon>
                      <div>{{ getValue(pLang, pSetting, "children", item) }}</div>
                    </div>
                  </template>
                </div>
                <!-- ------------------------------------- Kinder Infos ENDE ------------------------------------- -->


                <!-- ------------------------------------- UNTERHALTUNG Infos Anfang ------------------------------------- -->
                <div
                    class="div-contentinfo-place"
                    v-if="ownPlace.facilities !== undefined && ownPlace.facilities.entertainment.length >0"
                >

                  <div class="div-contentinfo-title-place">
                    <div class="line"/>
                    <div class="div-contentinfo-title-place1">
                      <v-icon color="var(--Primary)">mdi-drama-masks</v-icon>
                      <label class="label-placename-place">{{ $t('Place.Unterhaltung') }}</label>
                    </div>
                    <div class="line"/>
                  </div>

                  <template v-for="item in ownPlace.facilities.entertainment ">
                    <div style="display: flex; align-items: center;">
                      <v-icon color="var(--Primary)" large>mdi-circle-small</v-icon>
                      <div>{{ getValue(pLang, pSetting, "entertainment", item) }}</div>
                    </div>
                  </template>
                </div>
                <!-- ------------------------------------- UNTERHALTUNG Infos ENDE ------------------------------------- -->

              </div>
            </div>

            <!-- ------------------------------------- Ausstattungen Infos Anfang ------------------------------------- -->
            <div class="div-contentinfo-place" v-if="ownPlace.facilities !== undefined">

              <div class="div-contentinfo-title-place">
                <div class="line"/>
                <div class="div-contentinfo-title-place1">
                  <v-icon color="var(--Primary)">mdi-home-city</v-icon>
                  <label class="label-placename-place">{{ $t("Place.Ausstattungen") }}</label>
                </div>
                <div class="line"/>
              </div>


              <div class="div-general-outer-place">
                <div v-if="ownPlace.facilities.general.length >0">
                  <div class="div-titleinfo-place">
                    <v-icon color="var(--Primary)">mdi-home-city</v-icon>
                    <label class="label-infotitle-inner-place">{{ $t("Place.Allgemein") }}</label>
                  </div>

                  <div style="margin-top: 15px !important;">
                    <span v-for="item in ownPlace.facilities.general">
                      <span>{{ getValue(pLang, pSetting, "general", item) }}, </span>
                    </span>
                  </div>

                  <br>
                  <v-divider></v-divider>
                  <br>
                </div>


                <div v-if="ownPlace.facilities.internet.length >0">
                  <div class="div-titleinfo-place">
                    <v-icon color="var(--Primary)">mdi-wifi</v-icon>
                    <label class="label-infotitle-inner-place">{{ $t("Place.INTERNET") }}</label>
                  </div>
                  <div style="margin-top: 15px !important;">
                    <span v-for="item in ownPlace.facilities.internet">
                      <span>{{ getValue(pLang, pSetting, "internet", item) }}, </span>
                    </span>
                  </div>

                  <br>
                  <v-divider></v-divider>
                  <br>
                </div>

                <div v-if="ownPlace.facilities.parking.length >0">
                  <div class="div-titleinfo-place">
                    <v-icon color="var(--Primary)">mdi-car-brake-parking</v-icon>
                    <label class="label-infotitle-inner-place">{{ $t("Place.PARKPLATZ") }}</label>
                  </div>
                  <div style="margin-top: 15px !important;">
                    <span v-for="item in ownPlace.facilities.parking">
                      <span>{{ getValue(pLang, pSetting, "parking", item) }}, </span>
                    </span>
                  </div>

                  <br>
                  <v-divider></v-divider>
                  <br>
                </div>

                <div v-if="ownPlace.facilities.shops.length >0">
                  <div class="div-titleinfo-place">
                    <v-icon color="var(--Primary)">mdi-cart</v-icon>
                    <label class="label-infotitle-inner-place">{{ $t("Place.GESCHÄFTE") }}</label>
                  </div>
                  <div style="margin-top: 15px !important;">
                    <span v-for="item in ownPlace.facilities.shops">
                      <span>{{ getValue(pLang, pSetting, "shops", item) }}, </span>
                    </span>
                  </div>

                  <br>
                  <v-divider></v-divider>
                  <br>
                </div>

                <div v-if="ownPlace.facilities.reception.length >0">
                  <div class="div-titleinfo-place">
                    <v-icon color="var(--Primary)">mdi-account-tie</v-icon>
                    <label class="label-infotitle-inner-place">{{ $t("Place.REZEPTIONSSERVICE") }}</label>
                  </div>
                  <div style="margin-top: 15px !important;">
                    <span v-for="item in ownPlace.facilities.reception">
                      <span>{{ getValue(pLang, pSetting, "reception", item) }}, </span>
                    </span>
                  </div>

                  <br>
                  <v-divider></v-divider>
                  <br>
                </div>

                <div v-if="ownPlace.facilities.business.length >0">
                  <div class="div-titleinfo-place">
                    <v-icon color="var(--Primary)">mdi-briefcase-variant</v-icon>
                    <label class="label-infotitle-inner-place">{{ $t("Place.BUSINESS-EINRICHTUNGEN") }}</label>
                  </div>
                  <div style="margin-top: 15px !important;">
                    <span v-for="item in ownPlace.facilities.business">
                      <span>{{ getValue(pLang, pSetting, "business", item) }}, </span>
                    </span>
                  </div>

                  <br>
                  <v-divider></v-divider>
                  <br>
                </div>

                <div v-if="ownPlace.facilities.cleaning.length >0">
                  <div class="div-titleinfo-place">
                    <v-icon color="var(--Primary)">mdi-washing-machine</v-icon>
                    <label class="label-infotitle-inner-place">{{ $t("Place.REINIGUNGSSERVICE") }}</label>
                  </div>
                  <div style="margin-top: 15px !important;">
                    <span v-for="item in ownPlace.facilities.cleaning">
                      <span>{{ getValue(pLang, pSetting, "cleaning", item) }}, </span>
                    </span>
                  </div>

                  <br>
                  <v-divider></v-divider>
                  <br>
                </div>

                <div v-if="ownPlace.facilities.transport.length >0">
                  <div class="div-titleinfo-place">
                    <v-icon color="var(--Primary)">mdi-taxi</v-icon>
                    <label class="label-infotitle-inner-place">{{ $t("Place.TRANSPORT") }}</label>
                  </div>
                  <div style="margin-top: 15px !important;">
                    <span v-for="item in ownPlace.facilities.transport">
                      <span>{{ getValue(pLang, pSetting, "transport", item) }}, </span>
                    </span>
                  </div>

                  <br>
                  <v-divider></v-divider>
                  <br>
                </div>


              </div>

            </div>
            <!-- ------------------------------------- Ausstattungen Infos ENDE ------------------------------------- -->


            <!-- ------------------------------------- Wichtige Infos Anfang ------------------------------------- -->
            <div class="div-contentinfo-place" v-if="ownPlace.facilities !== undefined">

              <div class="div-contentinfo-title-place">
                <div class="line"/>
                <div class="div-contentinfo-title-place1">
                  <v-icon color="var(--Primary)">mdi-note-text-outline</v-icon>
                  <label class="label-placename-place">{{ $t("Place.WICHTIGEHINWEISE") }}</label>
                </div>
                <div class="line"/>
              </div>

              <div
                  v-html="ownPlace.facilities['notes_'+pLang.toLowerCase()]"
                  style="white-space: pre-line; text-align: justify"
              />
            </div>
            <!-- ------------------------------------- Wichtige Infos ENDE ------------------------------------- -->

          </div>

          <!-- --------------------------------------------------- Mobile Anfang --------------------------------------------------- -->

          <div class="div-infos-mobile-place">
            <v-expansion-panels>

              <!-- ------------------------------------- Hotel Infos Anfang ------------------------------------- -->
              <v-expansion-panel v-if="ownPlace.place_id">
                <v-expansion-panel-header>
                  <template v-slot:default="{ open }">
                    <div class="div-titleinfo-place">
                      <v-icon color="var(--Primary)">mdi-fireplace-off</v-icon>
                      <label class="label-infotitle-place">
                        {{ ownPlace['place_name_' + pLang.toLowerCase()] }}
                      </label>
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content v-if="ownPlace.place_id">
                  <div class="div-contentinfo-place">
                    <label class="label-placename-place">{{ ownPlace['place_name_' + pLang.toLowerCase()] }}</label>
                    <div class="div-overview-place" style="border-bottom: 1px solid lightgray; padding-bottom: 15px">
                      {{ place_desc }}
                    </div>
                    <div>
                      <div>
                        <div class="div-facs-place" v-if="ownPlace.checkin_time">
                          <v-icon color="var(--Primary)" style="margin-right: 8px">mdi-calendar-clock-outline</v-icon>
                          <div style="font-weight: bolder; margin-right: 5px;">
                            {{ $t('Place.Check-in') }}:
                          </div>
                          <div style="white-space: nowrap"><span v-if="pLang!=='TR'">
                            {{ $t('Place.Check-in-nach') }}
                          </span>{{ ownPlace.checkin_time }}<span v-if="pLang==='TR'">
                            {{ $t('Place.Check-in-nach') }}
                          </span>
                          </div>

                          <v-icon color="var(--Primary)" style="transform: rotate(90deg)">mdi-minus</v-icon>
                          <div style="font-weight: bolder; margin-right: 5px;">
                            {{ $t('Place.Check-out') }}:
                          </div>
                          <div style="white-space: nowrap"><span v-if="pLang!=='TR'">
                            {{ $t('Place.Check-out-vor') }}
                          </span>{{ ownPlace.checkout_time }}<span v-if="pLang==='TR'">
                            {{ $t('Place.Check-out-vor') }}
                          </span>
                          </div>
                        </div>


                        <div class="div-facs-place" v-if="ownPlace.holiday_type">
                          <v-icon color="var(--Primary)" style="margin-right: 8px">mdi-home-group</v-icon>
                          <div style="font-weight: bolder; margin-right: 5px;">
                            {{ $t('Place.Unterkunftstyp') }}:
                          </div>
                          <template v-for="(item,index) in ownPlace.holiday_type">
                            <span>{{ getHolidayType(pLang, item) }}</span><span
                              v-if="index < ownPlace.holiday_type.length-1"
                          >, </span>
                          </template>
                        </div>

                        <div class="div-facs-place" v-if="ownPlace.languages">
                          <v-icon color="var(--Primary)" style="margin-right: 8px">mdi-account-tie-voice</v-icon>
                          <div>
                            <span style="font-weight: bolder; margin-right: 5px;">
                              {{ $t('Place.GesprocheneSprachen') }}:
                            </span>
                            <template v-for="(items,index) in ownPlace.languages">
                              <span>{{ getLanguageByid(items) }}</span><span
                                v-if="index < ownPlace.languages.length-1"
                            >, </span>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- ------------------------------------- Hotel Infos Ende ------------------------------------- -->


              <!-- ------------------------------------- Strand Infos Anfang ------------------------------------- -->
              <v-expansion-panel
                  v-if="ownPlace.facilities !== undefined &&
                  (
                      ownPlace.facilities.beach.beach_sunbathing.length>0 ||
                      ownPlace.facilities.beach.beach_swimming.length>0 ||
                      ownPlace.facilities.beach.beach_amenities.length>0 ||
                      ownPlace.facilities.beach.beach_facilities.length>0
                  )"
              >

                <v-expansion-panel-header>
                  <template v-slot:default="{ open }">
                    <div class="div-titleinfo-place">
                      <v-icon color="var(--Primary)">mdi-beach</v-icon>
                      <label class="label-infotitle-place">
                        <div>{{ $t('Place.STRÄNDE') }}</div>
                      </label>
                    </div>

                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="div-contentinfo-place">

                    <div v-if="ownPlace.facilities.beach.startdate !== ''" style="margin-bottom: 10px">
                      <label style="font-weight: bold">{{ $t('Place.Öffnungszeit') }}: </label><label
                    >{{ ownPlace.facilities.beach.startdate }} - {{ ownPlace.facilities.beach.enddate }} </label>
                    </div>


                    <div class="div-beach-titles-place" v-if="ownPlace.facilities.beach.beach_sunbathing.length>0">
                      {{ $t('Place.StrandSonnenbadbereich') }}
                    </div>
                    <template v-for="item in ownPlace.facilities.beach.beach_sunbathing">
                      <div style="display: flex">
                        <v-icon color="var(--Primary)" small style="margin-right: 5px;">mdi-check</v-icon>
                        <div>{{ getValue(pLang, pSetting, "beach_sunbathing", item) }}</div>
                      </div>
                    </template>
                    <div class="div-beach-titles-place" v-if="ownPlace.facilities.beach.beach_swimming.length>0">
                      {{ $t('Place.Badestrand') }}
                    </div>
                    <template v-for="item in ownPlace.facilities.beach.beach_swimming">
                      <div style="display: flex">
                        <v-icon color="var(--Primary)" small style="margin-right: 5px;">mdi-check</v-icon>
                        <div>{{ getValue(pLang, pSetting, "beach_swimming", item) }}</div>
                      </div>
                    </template>
                    <div class="div-beach-titles-place" v-if="ownPlace.facilities.beach.beach_amenities.length>0">
                      {{ $t('Place.Strandannehmlichkeiten') }}
                    </div>
                    <template v-for="item in ownPlace.facilities.beach.beach_amenities">
                      <div style="display: flex">
                        <v-icon color="var(--Primary)" small style="margin-right: 5px;">mdi-check</v-icon>
                        <div>{{ getValue(pLang, pSetting, "beach_amenities", item) }}</div>
                      </div>
                    </template>
                    <div class="div-beach-titles-place" v-if="ownPlace.facilities.beach.beach_facilities.length>0">
                      {{ $t('Place.Strandeinrichtungen') }}
                    </div>
                    <template v-for="item in ownPlace.facilities.beach.beach_facilities">
                      <div style="display: flex">
                        <v-icon color="var(--Primary)" small style="margin-right: 5px;">mdi-check</v-icon>
                        <div>{{ getValue(pLang, pSetting, "beach_facilities", item) }}</div>
                      </div>
                    </template>

                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- ------------------------------------- Strand Infos Ende ------------------------------------- -->


              <!-- ------------------------------------- Wellness & Spa Infos Anfang ------------------------------------- -->
              <v-expansion-panel v-if="ownPlace.facilities !== undefined && ownPlace.facilities.well_spa[0].facilities.length>0">
                <v-expansion-panel-header>
                  <template v-slot:default="{ open }">
                    <div class="div-titleinfo-place">
                      <v-icon color="var(--Primary)">mdi-hand-heart-outline</v-icon>
                      <label class="label-infotitle-place">
                        <div>{{ $t('Place.Wellness&Spa').toUpperCase() }}</div>
                      </label>
                    </div>
                  </template>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <div class="div-contentinfo-place">
                    <template v-for="(items, index) in ownPlace.facilities.well_spa">

                      <div class="div-beach-titles-place">{{ $t('Place.Wellness&Spa') }} {{ index + 1 }}</div>
                      <div style="margin-bottom: 10px">
                        {{ $t('Place.Zeitplan') }}: {{ $t('Place.ab') }} {{ items.starttime }} {{ $t('Place.bis') }}
                        {{ items.endtime }}
                      </div>


                      <template v-for="item in items.facilities">
                        <div style="display: flex">
                          <v-icon color="var(--Primary)" small style="margin-right: 5px;">mdi-check</v-icon>
                          <div>{{ getValue(pLang, pSetting, "spa", item) }}</div>
                        </div>
                      </template>
                    </template>

                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- ------------------------------------- Wellness & Spa Infos Ende ------------------------------------- -->


              <!-- ------------------------------------- Pools Infos Anfang ------------------------------------- -->
              <v-expansion-panel v-if="ownPlace.facilities !== undefined && ownPlace.facilities.pools[0].pool.pool_art.length>0">
                <v-expansion-panel-header>
                  <template v-slot:default="{ open }">
                    <div class="div-titleinfo-place">
                      <v-icon color="var(--Primary)">mdi-pool</v-icon>
                      <label class="label-infotitle-place">
                        <div>{{ $t('Place.Pools') }}</div>
                      </label>
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="div-contentinfo-place">


                    <template v-for="(item, index) in ownPlace.facilities.pools">

                      <div class="div-beach-titles-place">{{ $t('Place.Pool') }} {{ index + 1 }}</div>
                      <div>{{ $t('Place.Öffnungszeit') }}: {{ item.pool.startdate }} - {{ item.pool.startdate }}</div>
                      <div style="margin-bottom: 10px"> {{ $t('Place.Zeitplan') }}:
                                                        {{ $t('Place.ab') }} {{ item.pool.starttime }}
                                                        {{ $t('Place.bis') }} {{ item.pool.endtime }}
                      </div>


                      <div class="div-pool-desc-place">
                        <template v-for="(it,n) in item.pool.pool_art">
                          <span>
                            <v-chip
                                class="vchip-pool-place"
                                color="var(--Primary)"
                                outlined
                            >
                              <span>{{ getValue(pLang, pSetting, "pool_art", it) }}</span>
                            </v-chip>
                          </span>

                        </template>
                        <span>
                          <v-chip
                              class="vchip-pool-place"
                              color="var(--Primary)"
                              outlined
                          >
                            <span>
                              {{ item.pool.depth_min }}cm - {{ item.pool.depth_max }}cm {{ $t('Place.Tiefe') }}
                            </span>
                          </v-chip>
                        </span>
                        <span>
                          <v-chip
                              class="vchip-pool-place"
                              color="var(--Primary)"
                              outlined
                          >
                            <span>{{ item.pool.width }}cm x {{ item.pool.length }}cm</span>
                          </v-chip>
                        </span>

                        <span>
                          <v-chip
                              class="vchip-pool-place"
                              color="var(--Primary)"
                              outlined
                          >
                            <span>{{ item.pool.square_meter }}m²</span>
                          </v-chip>
                        </span>


                        <div style="font-weight: bolder">{{ $t('Place.PoolAusstattungen') }}</div>
                        <template v-for="(it) in item.pool.pool_facilities">
                          <div>
                            <v-icon color="var(--Primary)">mdi-circle-small</v-icon>
                            <span>{{ getValue(pLang, pSetting, "pool_facilities", it) }}</span>
                          </div>
                        </template>

                        <div style="font-weight: bolder">{{ $t('Place.PoolTemperatur') }}</div>
                        <template v-for="(it) in item.pool.pool_temperature">
                          <div>
                            <v-icon color="var(--Primary)">mdi-circle-small</v-icon>
                            <span>{{ getValue(pLang, pSetting, "pool_temperature", it) }}</span>
                          </div>
                        </template>


                      </div>

                    </template>

                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- ------------------------------------- Pools Infos Ende ------------------------------------- -->


              <!-- ------------------------------------- Location Infos Anfang ------------------------------------- -->
              <v-expansion-panel v-if="ownPlace.facilities !== undefined">
                <v-expansion-panel-header>
                  <template v-slot:default="{ open }">
                    <div class="div-titleinfo-place">
                      <v-icon color="var(--Primary)">mdi-map</v-icon>
                      <label class="label-infotitle-place">
                        <div>{{ $t('Place.Lage') }}</div>
                      </label>
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                  <div class="div-contentinfo-place">

                    <div class="div-facs-place" style="align-items: self-start">
                      <v-icon color="var(--Primary)" style="margin-right: 8px">mdi-map-marker-radius</v-icon>
                      <div>
                        {{ ownPlace.location.strnr }}, {{ ownPlace.location.plz }} {{ ownPlace.location.city }},
                        {{ ownPlace.location.country }}
                      </div>
                    </div>
                    <div v-if="googlemaps.key !== ''">
                      <iframe
                          :width="googlemaps.width"
                          :height="googlemaps.height"
                          style="border:0"
                          loading="lazy"
                          allowfullscreen
                          :src="this.googlemaps.src+this.googlemaps.key+this.googlemaps.place"
                      >
                      </iframe>
                    </div>
                  </div>

                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- ------------------------------------- Location Infos Ende ------------------------------------- -->


              <!-- ------------------------------------- Aktivitäten Infos Anfang ------------------------------------- -->
              <v-expansion-panel v-if="ownPlace.facilities !== undefined && ownPlace.facilities.activities.length >0">
                <v-expansion-panel-header>
                  <template v-slot:default="{ open }">
                    <div class="div-titleinfo-place">
                      <v-icon color="var(--Primary)">mdi-handball</v-icon>
                      <label class="label-infotitle-place">
                        <div>{{ $t('Place.Aktivitäten') }}</div>
                      </label>
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                  <div class="div-contentinfo-place">

                    <template v-for="item in ownPlace.facilities.activities ">
                      <div style="display: flex; align-items: center;">
                        <v-icon color="var(--Primary)" large>mdi-circle-small</v-icon>
                        <div>{{ getValue(pLang, pSetting, "activities", item) }}</div>
                      </div>
                    </template>

                  </div>

                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- ------------------------------------- Aktivitäten Infos Ende ------------------------------------- -->


              <!-- ------------------------------------- Essen & Trinken Infos Anfang ------------------------------------- -->
              <v-expansion-panel v-if="ownPlace.facilities !== undefined && ownPlace.facilities.food.length >0">
                <v-expansion-panel-header>
                  <template v-slot:default="{ open }">
                    <div class="div-titleinfo-place">
                      <v-icon color="var(--Primary)">mdi-food-fork-drink</v-icon>
                      <label class="label-infotitle-place">
                        <div>{{ $t('Place.Speisen&Getränke') }}</div>
                      </label>
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                  <div class="div-contentinfo-place">
                    <template v-for="item in ownPlace.facilities.food ">
                      <div style="display: flex; align-items: center;">
                        <v-icon color="var(--Primary)" large>mdi-circle-small</v-icon>
                        <div>{{ getValue(pLang, pSetting, "food", item) }}</div>
                      </div>
                    </template>
                  </div>

                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- ------------------------------------- Essen & Trinken Infos Ende ------------------------------------- -->


              <!-- ------------------------------------- Kinder Infos Anfang ------------------------------------- -->
              <v-expansion-panel v-if="ownPlace.facilities !== undefined && ownPlace.facilities.children.length >0">
                <v-expansion-panel-header>
                  <template v-slot:default="{ open }">
                    <div class="div-titleinfo-place">
                      <v-icon color="var(--Primary)">mdi-baby-face-outline</v-icon>
                      <label class="label-infotitle-place">
                        <div>{{ $t('Place.FürKinder') }}</div>
                      </label>
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                  <div class="div-contentinfo-place">
                    <template v-for="item in ownPlace.facilities.children ">
                      <div style="display: flex; align-items: center;">
                        <v-icon color="var(--Primary)" large>mdi-circle-small</v-icon>
                        <div>{{ getValue(pLang, pSetting, "children", item) }}</div>
                      </div>
                    </template>
                  </div>

                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- ------------------------------------- Kinder Infos Ende ------------------------------------- -->


              <!-- ------------------------------------- UNTERHALTUNG Infos Anfang ------------------------------------- -->
              <v-expansion-panel v-if="ownPlace.facilities !== undefined && ownPlace.facilities.entertainment.length >0">
                <v-expansion-panel-header>
                  <template v-slot:default="{ open }">
                    <div class="div-titleinfo-place">
                      <v-icon color="var(--Primary)">mdi-drama-masks</v-icon>
                      <label class="label-infotitle-place">
                        <div>{{ $t('Place.Unterhaltung') }}</div>
                      </label>
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                  <div class="div-contentinfo-place">
                    <template v-for="item in ownPlace.facilities.entertainment ">
                      <div style="display: flex; align-items: center;">
                        <v-icon color="var(--Primary)" large>mdi-circle-small</v-icon>
                        <div>{{ getValue(pLang, pSetting, "entertainment", item) }}</div>
                      </div>
                    </template>
                  </div>

                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- ------------------------------------- UNTERHALTUNG Infos Ende ------------------------------------- -->


              <!-- ------------------------------------- Ausstattungen Infos Anfang ------------------------------------- -->
              <v-expansion-panel v-if="ownPlace.facilities !== undefined">
                <v-expansion-panel-header>
                  <template v-slot:default="{ open }">
                    <div class="div-titleinfo-place">
                      <v-icon color="var(--Primary)">mdi-home-city-outline</v-icon>
                      <label class="label-infotitle-place">
                        <div>{{ $t("Place.Ausstattungen") }}</div>
                      </label>
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                  <div class="div-contentinfo-place">

                    <div v-if="ownPlace.facilities.activities.general >0">
                      <div class="div-titleinfo-place">
                        <v-icon color="var(--Primary)">mdi-home-city</v-icon>
                        <label class="label-infotitle-inner-place">{{ $t("Place.Allgemein") }}</label>
                      </div>

                      <div style="margin-top: 15px !important;">
                        <span v-for="item in ownPlace.facilities.general">
                          <span>{{ getValue(pLang, pSetting, "general", item) }}, </span>
                        </span>
                      </div>

                      <br>
                      <v-divider></v-divider>
                      <br>
                    </div>

                    <div v-if="ownPlace.facilities.activities.internet >0">
                      <div class="div-titleinfo-place">
                        <v-icon color="var(--Primary)">mdi-wifi</v-icon>
                        <label class="label-infotitle-inner-place">{{ $t("Place.INTERNET") }}</label>
                      </div>
                      <div style="margin-top: 15px !important;">
                        <span v-for="item in ownPlace.facilities.internet">
                          <span>{{ getValue(pLang, pSetting, "internet", item) }}, </span>
                        </span>
                      </div>

                      <br>
                      <v-divider></v-divider>
                      <br>
                    </div>

                    <div v-if="ownPlace.facilities.activities.parking >0">
                      <div class="div-titleinfo-place">
                        <v-icon color="var(--Primary)">mdi-car-brake-parking</v-icon>
                        <label class="label-infotitle-inner-place">{{ $t("Place.PARKPLATZ") }}</label>
                      </div>
                      <div style="margin-top: 15px !important;">
                        <span v-for="item in ownPlace.facilities.parking">
                          <span>{{ getValue(pLang, pSetting, "parking", item) }}, </span>
                        </span>
                      </div>

                      <br>
                      <v-divider></v-divider>
                      <br>
                    </div>

                    <div v-if="ownPlace.facilities.activities.shops >0">
                      <div class="div-titleinfo-place">
                        <v-icon color="var(--Primary)">mdi-cart</v-icon>
                        <label class="label-infotitle-inner-place">{{ $t("Place.GESCHÄFTE") }}</label>
                      </div>
                      <div style="margin-top: 15px !important;">
                        <span v-for="item in ownPlace.facilities.shops">
                          <span>{{ getValue(pLang, pSetting, "shops", item) }}, </span>
                        </span>
                      </div>

                      <br>
                      <v-divider></v-divider>
                      <br>
                    </div>

                    <div v-if="ownPlace.facilities.activities.reception >0">
                      <div class="div-titleinfo-place">
                        <v-icon color="var(--Primary)">mdi-account-tie</v-icon>
                        <label class="label-infotitle-inner-place">{{ $t("Place.REZEPTIONSSERVICE") }}</label>
                      </div>
                      <div style="margin-top: 15px !important;">
                        <span v-for="item in ownPlace.facilities.reception">
                          <span>{{ getValue(pLang, pSetting, "reception", item) }}, </span>
                        </span>
                      </div>

                      <br>
                      <v-divider></v-divider>
                      <br>
                    </div>

                    <div v-if="ownPlace.facilities.activities.business >0">
                      <div class="div-titleinfo-place">
                        <v-icon color="var(--Primary)">mdi-briefcase-variant</v-icon>
                        <label class="label-infotitle-inner-place">{{ $t("Place.BUSINESS-EINRICHTUNGEN") }}</label>
                      </div>
                      <div style="margin-top: 15px !important;">
                        <span v-for="item in ownPlace.facilities.business">
                          <span>{{ getValue(pLang, pSetting, "business", item) }}, </span>
                        </span>
                      </div>

                      <br>
                      <v-divider></v-divider>
                      <br>
                    </div>

                    <div v-if="ownPlace.facilities.activities.cleaning >0">
                      <div class="div-titleinfo-place">
                        <v-icon color="var(--Primary)">mdi-washing-machine</v-icon>
                        <label class="label-infotitle-inner-place">{{ $t("Place.REINIGUNGSSERVICE") }}</label>
                      </div>
                      <div style="margin-top: 15px !important;">
                        <span v-for="item in ownPlace.facilities.cleaning">
                          <span>{{ getValue(pLang, pSetting, "cleaning", item) }}, </span>
                        </span>
                      </div>

                      <br>
                      <v-divider></v-divider>
                      <br>
                    </div>

                    <div v-if="ownPlace.facilities.activities.transport >0">
                      <div class="div-titleinfo-place">
                        <v-icon color="var(--Primary)">mdi-taxi</v-icon>
                        <label class="label-infotitle-inner-place">{{ $t("Place.TRANSPORT") }}</label>
                      </div>
                      <div style="margin-top: 15px !important;">
                        <span v-for="item in ownPlace.facilities.transport">
                          <span>{{ getValue(pLang, pSetting, "transport", item) }}, </span>
                        </span>
                      </div>

                      <br>
                      <v-divider></v-divider>
                      <br>
                    </div>


                  </div>

                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- ------------------------------------- Ausstattungen Infos Ende ------------------------------------- -->

              <!-- ------------------------------------- Wichtige Infos Anfang ------------------------------------- -->
              <v-expansion-panel v-if="ownPlace.facilities !== undefined">
                <v-expansion-panel-header>
                  <template v-slot:default="{ open }">
                    <div class="div-titleinfo-place">
                      <v-icon color="var(--Primary)">mdi-note-text-outline</v-icon>
                      <label class="label-infotitle-place">
                        <div>{{ $t("Place.WICHTIGEHINWEISE") }}</div>
                      </label>
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                  <div class="div-contentinfo-place">

                    <div
                        v-html="ownPlace.facilities['notes_'+pLang.toLowerCase()]"
                        style="white-space: pre-line; text-align: justify"
                    />
                  </div>

                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- ------------------------------------- Wichtige Infos Ende ------------------------------------- -->


            </v-expansion-panels>


          </div>

        </div>

        <!-- ----------------------------------- Links ENDE ----------------------------------------------------  -->

        <!-- ----------------------------------- Rechts Anfang----------------------------------------------------  -->


        <div class="div-body-right-place">
          <CY_TotalPrice
              :checkin="checkin"
              :checkout="checkout"
              :ownPlace="ownPlace"
              :gruppe="gruppe"
              :selectedRoom="selectedRoom"
              :gesamtpreis="gesamtpreis"
              :checkinput="checkinput"
              :selectedRatePlan="selectedRatePlan"
              :showbutton="true"
              :pLang="pLang"
              :pCurrency="pCurrency"
              :pCurrencyValues="pCurrencyValues"
              :pPlaceCurrency="ownPlace.currency"
          />


        </div>
      </div>
      <div class="div-btn-totalprice-place">
        <v-btn
            @click="zuBooking"
            class="vbtn-totalprice"
            :disabled="!checkinput"
            :color="!checkinput ? 'grey':'green'"
        >
          {{ $t('Button.WeiterZurBuchung') }}
        </v-btn>
      </div>
    </div>
  </div>

</template>

<script>
import Search from "@/components/search/Search";
import SearchSmall from "@/components/search/SearchSmall";
import CY_CardRoom from "@/components/cy_card/CY_CardRoom";
import {getCurrencyIcon} from "@/util/currency";
import {getgoogleSetting} from "@/util/settingsUtil";
import moment from "moment";
import {getOwnPlaceByPlaceID} from "@/util/OwnPlace";
import CY_Gallery from "@/components/cy_gallery/CY_Gallery";
import {getLanguageByid, getValue} from "@/util/LanguageUtil";
import {getHolidayType} from "@/util/HolidayTypeUtil";
import {checkChildrenAge, getLowestPrice} from "@/util/PriceOwnPlaceUtil";
import CY_TotalPrice from "@/components/cy_card/CY_TotalPrice";


export default {
  name: "CY_Place",
  props: [
    'pLang',
    'pRolle',
    'pUser',
    'pUserinfo',
    'pSetting',
    'pCurrency',
    'pCurrencyValues',
    'pDomain'
  ],
  components: {
    SearchSmall,
    Search,
    CY_Gallery,
    CY_CardRoom,
    // CardRoom2,
    // CardRoom3,
    // CardRoom4,
    CY_TotalPrice
  },
  data() {
    return {
      currencyicon: getCurrencyIcon(),

      userinfo: null,
      isadmin: true,
      ishotel: false,
      isreiseburo: false,


      panel: [0],
      gruppe: "",
      checkinput: false,

      selectedstartdate: "",
      selectedenddate: "",


      selectedRoom: [{text: this.$t("Place.KeinZimmer"), name: undefined}],
      gesamtpreis: 0,
      selectedRatePlan: [{name: "", preis: 0}],

      notificationsnackbar: false,
      notificationtext: "",
      notificationcolor: "grey",

      showGalleryPopup: false,
      loading: true,


      ownPlace: {},

      cardDesign: 1,

      checkin: "",
      checkout: "",
      groups: "",
      place_desc: "",

      googlemaps: {
        key: "",
        place: "&q=",
        width: "100%",
        height: "450",
        style: "border:0",
        src: "https://www.google.com/maps/embed/v1/place?key="

      },


      keyroom: 0,
      anzahlNaechte: 0


    };
  },
  methods: {
    getCurrencyIcon,
    getValue,
    getLanguageByid,
    getHolidayType,

    zuBooking() {

      localStorage.setItem("gruppe", JSON.stringify(this.gruppe));
      localStorage.setItem("selectedroom", JSON.stringify(this.selectedRoom));
      let cyid = this.ownPlace.place_id.split("-");
      let query = {
        ...this.$route.query,
        id: cyid[1]
      }
      this.$router.push({path: "/cy-booking", query: query});

    },


    calculatePrice() {
      let gprice = 0;
      this.selectedRoom.map(it => {
        if (it.price !== undefined) {
          gprice += it.price;
        }
      });
      this.gesamtpreis = gprice;
    },

    checkGroupRooms() {
      this.checkinput = false;
      let selRoom = this.selectedRoom.filter(item => item.room_id === undefined);
      if (selRoom.length === 0) {
        this.checkinput = true;
      }

      /*this.selectedRoom.map(it => {
        if (it.room === undefined) {
          this.checkinput = true;
        }
      });*/

    },


    googleMaps() {

      //Google Maps Iframe
      this.googlemaps.place = "&q=";
      this.googlemaps.place += this.ownPlace.location.city.replace('&', '%26');
      this.googlemaps.place += ", " + this.ownPlace.location.country;

    }
  },

  async created() {

    window.scrollTo(0, 2);

    let id = this.$route.query.id;
    this.groups = this.$route.query.groups;


    // ------------------------------------ DATUM anzeigen ------------------------------------
    if (localStorage.getItem('c-in') !== null) {
      let c_in = new Date(localStorage.getItem('c-in'));

      this.checkin = moment(c_in).format("YYYY-MM-DD");
      this.selectedstartdate = c_in;

    } else {

      let d = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 16);
      this.checkin = moment(d).format("YYYY-MM-DD");
      this.selectedstartdate = d;
    }

    if (localStorage.getItem('c-out') !== null
    ) {
      let c_out = new Date(localStorage.getItem('c-out'));
      this.checkout = moment(c_out).format("YYYY-MM-DD");
      this.selectedenddate = c_out;
    } else {

      let d1 = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 18);
      this.checkout = moment(d1).format("YYYY-MM-DD");
      this.selectedenddate = d1;
    }

    // ------------------------------------ DATUM anzeigen ENDE ------------------------------------


    // let place = await onePlaceWithParameter(id, this.checkin, this.checkout, '&groups[]=' + this.groups);

    let place = await getOwnPlaceByPlaceID("cy-" + id);
    this.ownPlace = place;

    this.place_desc = this.ownPlace["place_desc_" + this.pLang.toLowerCase()];

    // this.$root.$on("place", (val) => {
    //   this.ownPlace = val;
    //
    //   this.gruppe = JSON.parse(localStorage.getItem("gruppe"));
    //
    //   let tmp = [];
    //   this.gruppe.map((it, index) => {
    //     tmp.push({text: this.$t("Place.KeinZimmer"), name: "unset"})
    //   });
    //   this.selectedRoom = tmp;
    //
    //   // this.checkGroupRooms();
    // });


    //-----------------------------------------------------------------Google Maps ----------------------------------
    // let user = await getUser(localStorage.getItem("token"));
    let settings = await getgoogleSetting();
    this.googlemaps.key = settings.googleMapsKey;

    this.googleMaps();


    this.gruppe = JSON.parse(localStorage.getItem("gruppe"));
    if (this.gruppe === null) {
      this.gruppe = [{name: "Gruppe 1", erwachsene: 2, kinder: 0, kinderalter: []}];
    }
    let tmp = [];
    this.gruppe.map((it, index) => {
      tmp.push({text: this.$t("Place.KeinZimmer"), name: "unset"})
    });
    this.selectedRoom = tmp;


    if (this.pUserinfo.roomcard) {
      this.cardDesign = this.pUserinfo.roomcard;
    } else {
      this.cardDesign = '1'
    }


    this.$root.$on("selectedroom", (val, grpindex, index) => {
      this.selectedRoom[grpindex] = val[grpindex].rooms[index];

      // console.log(this.selectedRoom[grpindex]);
      if (this.selectedRoom.length > 1) {
        if ((grpindex + 1) < this.selectedRoom.length) {
          this.panel = [grpindex + 1];
        } else {
          this.panel = [];
        }
      } else {
        this.panel = [0];
      }

      this.calculatePrice();
      this.checkGroupRooms();
      this.$forceUpdate();
    });


    this.selectedRatePlan = [];
    let arr = [];
    this.gruppe.map((it, index) => {
      arr.push({name: "", preis: 0});
    });
    this.selectedRatePlan = arr;


    this.$root.$on("stopScrolling", (val) => {
      this.stopScrolling = val;
    });


    // Berechnung der Nächte
    let days = null;
    let miliseconds = this.selectedenddate.getTime() - this.selectedstartdate.getTime();
    let total_seconds = parseInt(Math.floor(miliseconds / 1000));
    let total_minutes = parseInt(Math.floor(total_seconds / 60));
    let total_hours = parseInt(Math.floor(total_minutes / 60));
    days = parseInt(Math.floor(total_hours / 24));
    this.anzahlNaechte = days;

    this.ownPlace.gruppen = this.gruppe;
    this.ownPlace.gruppen.rooms = [];
    // let p = 0.00;
    this.gruppe.map((it, i) => {

      if (this.ownPlace.rooms) {
        this.ownPlace.gruppen[i].rooms = [];
        this.ownPlace.rooms.map(it => {
          let o = {
            ...it
          }
          this.ownPlace.gruppen[i].rooms.push(o);
        })

        this.ownPlace.gruppen[i].rate_plans = this.ownPlace.rate_plans;
        this.ownPlace.gruppen[i].children_price = this.ownPlace.children_price
        this.ownPlace.gruppen[i].baby_price = this.ownPlace.baby_price
        this.ownPlace.gruppen[i].price = this.ownPlace.price

        // console.log(this.ownPlace.gruppen);

        let o = checkChildrenAge(this.gruppe, i, this.ownPlace.baby_price, this.ownPlace.children_price);
        // console.log("o: ", o);
        getLowestPrice(o.erwachsene, o.children, o.babies, this.selectedstartdate, this.selectedenddate, this.ownPlace.gruppen[i], this.anzahlNaechte);


      }
    })


    // this.keyroom++;

    this.loading = false;


  },
};
</script>
<style scoped>
@import "../css/Place.css";
</style>