import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: localStorage.getItem("token") !== undefined ? localStorage.getItem("token") : null,
        user: "",
        place: {},
        rolle: "",
        auth: false
    },
    getters: {
        getToken: (state) => {
            return state.token;
        },
        getUser: (state) => {
            return state.user;
        },
        getPlace: (state) => {
            return state.place;
        },
        getRolle: state => state.rolle,
        getAuth: state => state.auth
    },
    mutations: {
        setToken: (state, payload) => {
            state.token = payload;

        },
        user: (state, payload) => {
            state.user = payload;
        },
        setRolle: (state, payload) => {
            state.rolle = payload

        },
        setAuth: (state, payload) => {
            state.auth = payload
        },
        logout: (state) => {
            state.token = null;
            localStorage.removeItem("token");
            state.auth = false;
        },
        place: (state, payload) => {
            state.place = payload;
        }
    },
    actions: {
        setToken: (context, payload) => {
            context.commit('setToken', payload);
        },
        user: (context, payload) => {
            context.commit('user', payload);
        },
        setRolle: (context, payload) => {
            context.commit('setRolle', payload);
        },
        setAuth: (context, payload) => {
            context.commit('setAuth', payload);
        },
        logout: (context) => {
            context.commit('logout');
        },
        place: (context, payload) => {
            context.commit('place', payload);
        }
    },
    modules: {}
})
