<template>
  <div class="div-cardsmallroom">
    <img :src="item[0].url" alt="Photo" class="img-cardsmallroom">
  </div>
</template>


<script>

export default {
  name: "CardSmallRoom",
  props: [
    'item',
  ],

  data() {
    return {}
  },


}

</script>

<style lang="css" scoped>
@import "../../css/CardSmallRoom.css";
</style>
