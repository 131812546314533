<template>
  <div>
    <v-snackbar
        v-model="notificationsnackbar"
        :timeout="6000"
        :color="notificationcolor"
        :top=true
        :right=true
        :vertical=true
    >
      {{ notificationtext }}
      <v-btn
          color="white"
          text
          @click="notificationsnackbar = false"
      >
        {{ $t("Notification.Schliessen") }}
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>


    <div class="bodyheader-createuser">

      <div class="bodyheader-createuser1">
        <v-icon class="icon-start" color="white">mdi-account-plus</v-icon>
        <label class="label-bodyheader-createuser">
          {{ $t("Acc.NeuerBenutzer") }}
        </label>

      </div>
    </div>

    <div class="div-body-createuser">
      <span id="radio-step2-create" v-if="!existHotel">
        <v-radio-group v-model="benutzerart" :mandatory="false">
          <v-radio color="var(--Primary)" :label="$t('Acc.Reisebüro')" value="reiseburo"/>
          <v-radio color="var(--Primary)" :label="$t('Acc.HotelAccount')" value="hotel"/>
          <v-radio color="var(--Primary)" label="Admin" value="admin" v-if="pRolle === 'superadmin'"/>
        </v-radio-group>
      </span>

      <template v-if="benutzerart !== ''">
        <div>
          <div v-if="existHotel" style="margin: 20px 0">

            <h3 v-if="benutzerart==='hotel'">
              Hotel-Account bearbeiten
            </h3>

          </div>
          <div v-else>


            <h3 v-if="benutzerart==='reiseburo'">
              {{ $t("Acc.Reisebüroanlegen") }}
            </h3>
            <h3 v-if="benutzerart==='hotel'">
              {{ $t("Acc.Hotelanlegen") }}
            </h3>
            <h3 v-if="benutzerart==='admin'">
              Admin
            </h3>
          </div>


          <v-text-field
              v-if="benutzerart==='reiseburo'"
              :label="$t('Acc.FirmaReiseburo')+ '*'"
              v-model="firma"
              outlined
              color=var(--Primary)
          />
          <v-text-field
              v-if="benutzerart==='hotel'"
              :label="$t('Acc.Hotel')+'*'"
              v-model="firma"
              outlined
              color=var(--Primary)
          />


          <v-text-field
              :label="$t('Setting.Email') +'*'"
              :rules="[rules.required, rules.email]"
              v-model="email"
              outlined
              color="var(--Secondary)"

          />


          <v-select
              v-if="benutzerart==='reiseburo'"
              :label="$t('Acc.Provisionsklasse')"
              :items="provision_classes"
              v-model="provisionsklasse"
              outlined
              color=var(--Primary)
              :item-text="text"
          />


          <br>
          <v-divider></v-divider>
          <br>


          <v-text-field
              v-if="benutzerart!=='admin'"
              :label="$t('Setting.Vorname') +'*'"
              v-model="vorname"
              outlined
              color=var(--Primary)
              @change=""
          />
          <v-text-field
              v-if="benutzerart!=='admin'"
              :label="$t('Setting.Nachname') +'*'"
              v-model="nachname"
              outlined
              color=var(--Primary)
              @change=""
          />


          <br>
          <v-divider></v-divider>
          <br>


          <v-text-field
              v-if="benutzerart!=='admin'"
              :label="$t('Setting.Telefon')+ ' 1'"
              v-model="telefon1"
              outlined
              color=var(--Primary)
              @change=""
          />
          <v-text-field
              v-if="benutzerart!=='admin'"
              label="Mobile"
              v-model="telefon2"
              outlined
              color=var(--Primary)
              @change=""
          />
          <v-text-field
              v-if="benutzerart!=='admin'"
              :label="$t('Setting.Fax')"
              v-model="fax"
              outlined
              color=var(--Primary)
              @change=""
          />


          <br>
          <v-divider></v-divider>
          <br>


          <v-text-field
              v-if="benutzerart!=='admin'"
              :label="$t('Setting.StrNR')"
              v-model="strnr"
              outlined
              color=var(--Primary)
          />
          <v-text-field
              v-if="benutzerart!=='admin'"
              :label="$t('Setting.Adresszusatz')"
              v-model="adressz"
              outlined
              color=var(--Primary)
          />
          <v-text-field
              v-if="benutzerart!=='admin'"
              :label="$t('Setting.PLZ')"
              v-model="plz"
              outlined
              color=var(--Primary)
          />
          <v-text-field
              v-if="benutzerart!=='admin'"
              :label="$t('Setting.Stadt')"
              v-model="ort"
              outlined
              color=var(--Primary)
          />
          <!--          <v-text-field-->
          <!--              v-if="benutzerart!=='admin'"-->
          <!--              :label="$t('Setting.Land')"-->
          <!--              v-model="land"-->
          <!--              outlined-->
          <!--              color=var(&#45;&#45;Primary)-->
          <!--          />-->

          <v-autocomplete
              :label="$t('Setting.Land')"
              v-model="land"
              :items="countryItems"
              outlined
              color=var(--Primary)
              item-value="countryid"
          />


          <br>
          <v-divider></v-divider>
          <br>

          <v-text-field
              :label="$t('Setting.NeuesPasswort') +'*'"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              name="input-10-2"
              v-model="password"
              class="input-group--focused"
              @click:append="show1 = !show1"
              outlined
              color="var(--Secondary)"
              :error="passwortError"
          />

          <v-text-field
              :label="$t('Setting.NeuesPasswortWiederholen') +'*'"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"
              name="input-10-2"
              v-model="password2"
              class="input-group--focused"
              @click:append="show2 = !show2"
              outlined
              color="var(--Secondary)"
              :error="passwortError"
          />


          <v-divider></v-divider>
          <br>


        </div>

        <div class="div-img-upload-createuser" v-if="benutzerart !=='admin'">

          <label>
            {{ $t("Acc.Logohochladen") }}
          </label>

          <label>max. 500 KB</label>
          <input type="file" name="file" @change="this.onFileHandler"/>
          <br/>
          <div className="form-group">
            <Progress
                max="100" color="success"
                :value="this.loaded"
            >{{ Math.round(this.loaded, 2) }}%
            </Progress>

          </div>
          <br/>
        </div>

        <v-divider></v-divider>
        <br>


        <div class="div-button-save-createuser">

          <v-btn
              class="ma-2"
              color=var(--Primary)
              style="color: white"
              @click="createUser"
          >
            {{ $t("Button.Speichern") }}
          </v-btn>
        </div>
      </template>
    </div>


  </div>
</template>

<script>
import {createUser, getUserByEmail, updateUser} from "@/util/userUtil";
import {getUser} from "@/util/Auth";
import {getAllSettings} from "@/util/settingsUtil";
import {generateID} from "@/util/GenerateId";
import {createUserInfo, updateUserInfo} from "@/util/userinfoDBUtil";
import {fileupload} from "@/util/fileUtil";
import {getCountries} from "@/util/Countries";

export default {
  name: "CreateUser",
  props: [
    'pLang',
    'pRolle',
  ],
  data() {
    return {
      existHotel: false,
      hotelUser: "",
      hotelUserInfo: "",
      firma: "",
      hotel: "",
      email: "",
      provisionsklasse: "",
      rolle: "",
      vorname: "",
      nachname: "",
      telefon1: "",
      telefon2: "",
      fax: "",
      strnr: "",
      adressz: "",
      plz: "",
      ort: "",
      land: "",
      password: "",
      password2: "",

      benutzerart: "reiseburo",

      admin_Email: "",


      notificationsnackbar: false,
      notificationtext: "",
      notificationcolor: "",

      setting: [],
      provision_classes: [],
      selectedFile: "",
      loaded: "",
      logo: "",
      passwortError: false,
      show1: false,
      show2: false,
      rules: {
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value);
        }
      },

      countryItems: getCountries(localStorage.getItem('lang')),


    };
  },
  methods: {
    onFileHandler(event) {
      let file = event.target.files[0]
      if (this.checkMimeType(event) && this.checkFileSize(event)) {
        // if return true allow to setState
        this.selectedFile = file;
      }
    },

    checkMimeType(event) {
      //getting file object
      let file = event.target.files[0];
      //define message container
      let err = ''
      // list allow mime type
      const types = ['image/png', 'image/jpeg', 'image/gif']
      // loop access array

      // compare file type find doesn't matach
      if (types.every(type => file.type !== type)) {
        // create error message and assign to container
        err += file.type + ' is not a supported format\n';

        this.notificationsnackbar = true;
        this.notificationtext = this.$t("NotificationFormatfalsch");
        this.notificationcolor = "error";

      }


      if (err !== '') { // if message not same old that mean has error
        event.target.value = null // discard selected file
        return false;
      }
      return true;

    },

    checkFileSize(event) {
      let file = event.target.files[0]
      let size = 500000
      let err = "";

      if (file.size > size) {
        err += file.type + 'Datei zu groß, bitte die maximale größe beachten.\n';

        this.notificationsnackbar = true;
        this.notificationtext = this.$t("NotificationBildzugross500kb");
        this.notificationcolor = "error";

      } else {
        this.error = "";
      }

      if (err !== '') {
        event.target.value = null
        this.error = err;
        return false
      }
      return true;
    },

    async onClickHandler() {
      if (this.error === "" && this.selectedFile !== null) {

        let fileUploadData = await fileupload(this.selectedFile);

        this.logo = {picture: fileUploadData.filename, pictureinfo: fileUploadData.originalname};

      }
    },
    notification(text, color) {
      this.notificationsnackbar = true;
      this.notificationtext = text;
      this.notificationcolor = color;
    },

    async createUser() {
      if (this.benutzerart === "") {
        this.notification(this.notificationtext = this.$t("NotificationReisebürooderHotel"), this.notificationcolor = "error");


      } else {
        if (this.existHotel) {

          let createuser = {
            ...this.hotelUser,
            email: this.email,
            password: this.password,

          }
          if (this.password.trim() === "" && this.password2.trim() === "") {
            delete createuser.password;
          }

          await updateUser(createuser);

          let userinfo = {
            ...this.hotelUserInfo,
            kontakt: {
              firma: this.firma,
              vname: this.vorname,
              nname: this.nachname,
              strnr: this.strnr,
              adressz: this.adressz,
              plz: this.plz,
              ort: this.ort,
              land: this.land,
              tel: this.telefon1,
              mobile: this.telefon2,
              fax: this.fax,
              email: this.email,
            },
            logo: this.logo
          }
          await updateUserInfo(userinfo);


        } else {


          if (this.password.trim() !== "" && this.password.trim() === this.password2.trim()) {
            await this.onClickHandler()
            let firma_id = "";
            if (this.benutzerart !== "admin") {
              let u = await getUser(localStorage.getItem('token'));
              firma_id = u.firma_id;
            }
            let pro = this.provisionsklasse;
            if (this.benutzerart === "hotel" || this.benutzerart === "admin") {
              pro = "0";
            }
            let createuser = {
              email: this.email,
              password: this.password,
              provisionsklasse: pro,
              rolle: this.benutzerart,
              firma_id: generateID(),
              abstract_firma_id: firma_id
            }

            
            let token = "";

            try {
              token = await createUser(createuser);
            } catch (e) {
            }
            let user = "";
            if (token != "")
              user = await getUserByEmail(createuser.email);

            let userinfo = {
              kontakt: {
                firma: this.firma,
                vname: this.vorname,
                nname: this.nachname,
                strnr: this.strnr,
                adressz: "",
                plz: this.plz,
                ort: this.ort,
                land: this.land,
                tel: this.telefon1,
                mobile: this.telefon2,
                fax: this.fax,
                email: this.email,
              },
              abstractid: firma_id,
              userid: user._id,
              zahlungsart: [],
              rechtliches: {
                geschaftsfuhrer: "",
                handelsregister: "",
                umsatzsteuerid: "",
                impressum: "",
                agb: "",
                disclaimer: "",
                kontoinhaber: "",
                iban: "",
                bic: ""

              },
              hotelcard: "",
              roomcard: "",
              logo: this.logo,
            }

            try {
              if (token !== "") {
                await createUserInfo(userinfo);
              }
            } catch (e) {

            }


            this.$router.push("/accounts");
          } else {

            this.notification(this.$t('Acc.Passwortnichtgleich'), "error");
            this.passwortError = true;

          }
        }
      }
    }
  },
  async created() {
    let hotel = JSON.parse(localStorage.getItem('husinfo'));
    this.hotelUserInfo = hotel;
    this.hotelUser = JSON.parse(localStorage.getItem('hus'));

    if (hotel !== undefined && hotel !== null && hotel !== '') {
      this.existHotel = true;

      this.benutzerart = 'hotel';
      this.firma = hotel.kontakt.firma;
      this.email = hotel.kontakt.email;
      this.vorname = hotel.kontakt.vname;
      this.nachname = hotel.kontakt.nname;
      this.telefon1 = hotel.kontakt.tel;
      this.telefon2 = hotel.kontakt.mobile;
      this.fax = hotel.kontakt.fax;
      this.strnr = hotel.kontakt.strnr;
      this.plz = hotel.kontakt.plz;
      this.ort = hotel.kontakt.ort;
      this.land = hotel.kontakt.land;

      localStorage.removeItem('husinfo');
      localStorage.removeItem('hus');


    } else {


      let setting = await getAllSettings();
      this.setting = setting;
      let a = this.setting.provisionclasses;
      a.sort(function (a, b) {
        const bandA = a.classname;
        const bandB = b.classname;
        let comparison = 0;
        if (bandA > bandB) {
          comparison = 1;
        } else if (bandA < bandB) {
          comparison = -1;
        }
        return comparison;
      });
      this.setting.provisionclasses = a;
      let arr = []
      this.setting.provisionclasses.map(item => {
        arr.push({text: item.classname + " - " + item.provision + " %", value: item.classname});

      });
      this.provision_classes = arr;
      this.provisionsklasse = this.provision_classes[0].value;
    }
  }

}

</script>

<style scoped>
@import "../css/CreateUser.css";
</style>