<template>
  <div class="div-hotelDate" :key="salekey">
    <HotelDatePicker
        :i18n="getLangDatePicker"
        format='DD/MM/YYYY'
        checkInDate="YYYY-MM-DD"
        :minNights="1"
        @check-in-changed="updateCheckIn"
        @check-out-changed="updateCheckOut"
        :firstDayOfWeek=1
        :startingDateValue="selectedstartdate ==='' ? undefined : selectedstartdate"
        :endingDateValue="selectedenddate ==='' ? undefined : selectedenddate"


    />

  </div>

</template>

<script>
import HotelDatePicker from "vue-hotel-datepicker";
import moment from "moment";

export default {
  name: "HotelDatePickerWrapper",
  props: [
    'from',
    'pLang',
    'price',
    'salestops',
    'saleindex',
    'salekey',

  ],
  components: {
    HotelDatePicker
  },
  data() {
    return {

      selectedstartdate: "",
      selectedenddate: "",


      SearchDatePickerDE: {
        night: 'Nacht',
        nights: 'Nächste',
        'day-names': ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        'check-in': 'Start-Datum',
        'check-out': 'End-Datum',
        'month-names': ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
        "tooltip": {
          "minimumRequiredPeriod": "%{minNightInPeriod} %{night} minimum"
        },
      },
      SearchDatePickerTR: {
        night: 'Gece',
        nights: 'Geceler',
        'day-names': ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt'],
        'check-in': 'Giriş',
        'check-out': 'Çıkış',
        'month-names': ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
        "tooltip": {
          "minimumRequiredPeriod": "%{minNightInPeriod} %{night} minimum"
        },
      },
      SearchDatePickerEN: {
        night: 'Night',
        nights: 'Nights',
        'day-names': ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        'check-in': 'Start-Date',
        'check-out': 'End-Date',
        'month-names': ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        "tooltip": {
          "minimumRequiredPeriod": "%{minNightInPeriod} %{night} minimum"
        },
      }

    }
  },
  methods: {

    updateCheckIn(date) {

      if (this.from === 'createhotel') {
        this.selectedstartdate = date;
      }

      if (this.from === 'salestop') {
        this.salestops[this.saleindex].start = date.toString();
      }

    },

    updateCheckOut(date) {


      if (this.from === 'createhotel') {

        this.selectedenddate = date;
        this.selectedenddate1;
      }

      if (this.from === 'salestop') {
        this.salestops[this.saleindex].end = date.toString();
      }


    },

  },

  computed: {
    selectedenddate1() {
      // this.price.startdate = moment(new Date(this.selectedstartdate)).format("YYYY-MM-DD");
      // this.price.enddate = moment(new Date(this.selectedenddate)).format("YYYY-MM-DD");
      this.price.startdate = new Date(this.selectedstartdate).toString();
      this.price.enddate = new Date(this.selectedenddate).toString();
    },

    getLangDatePicker() {

      if (localStorage.getItem('lang')) {
        if (localStorage.getItem('lang') === "EN") {
          return this.SearchDatePickerEN;
        }
        if (localStorage.getItem('lang') === "TR") {
          return this.SearchDatePickerTR;
        }
        if (localStorage.getItem('lang') === "DE") {
          return this.SearchDatePickerDE;
        }
      }
      return this.SearchDatePickerDE;
    }
  },
  created() {
    if (this.price !== undefined && this.price !== null && this.price.startdate !== "") {

      this.selectedstartdate = new Date(this.price.startdate);
      this.selectedenddate = new Date(this.price.enddate);
    } else {

    }

    if (this.salestops !== undefined && this.salestops.length > 0) {
      if (this.salestops[this.saleindex].start !== "") {
        this.selectedstartdate = new Date(this.salestops[this.saleindex].start);
        this.selectedenddate = new Date(this.salestops[this.saleindex].end);
      }
    }

  }
}
</script>

<style scoped>
@import "../../css/HotelDatePickerWrapper.css";
</style>