<template>
  <div v-if="show" class="div-picslider-gallery">

    <div style="max-width: 1100px; margin: 0 auto;">
      <div style="text-align: end; height: 50px;">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn @click="show" v-on="on">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Schließen</span>
        </v-tooltip>
      </div>
      <vueper-slides
          class="vueperslide-gallery"
          ref="vueperslides1"
          :touchable="false"
          fade
          :autoplay="false"
          :bullets="false"
          @slide="$refs.vueperslides2.goToSlide($event.currentSlide.index, { emit: false })"
      >
        <vueper-slide
            v-for="(slide, i) in pics"
            :key="i"
            :image="slide.url">
        </vueper-slide>
      </vueper-slides>

      <vueper-slides
          class="no-shadow thumbnails"
          ref="vueperslides2"
          @slide="$refs.vueperslides1.goToSlide($event.currentSlide.index, { emit: false })"
          :visible-slides="7"
          fixed-height="75px"
          :bullets="false"
          :touchable="false"
          :gap="2.5"
          :arrows="true"
          :breakpoints="{
            1000: { visibleSlides: 7 },
            850: { visibleSlides: 5 },
            650: { visibleSlides: 3}}"
      >
        <vueper-slide
            v-for="(slide, i) in pics"
            :key="i"
            :image="slide.url"
            @click.native="$refs.vueperslides2.goToSlide(i)">
        </vueper-slide>
      </vueper-slides>
    </div>


  </div>

</template>

<script>
import {VueperSlides, VueperSlide} from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';


export default {
  name: "CY_GalleryPopup",
  props: [
    'pics',
    'show'
  ],
  components: {
    VueperSlides,
    VueperSlide
  },
  data() {
    return {
      showPicSlider: false,
    }
  },
  methods: {}
}
</script>

<style scoped>
@import "../../css/Gallery.css";
</style>