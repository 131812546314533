<template>

  <div>
    <template>
      <div style="margin: 5px;">
        <Container
            @drop="onDrop(favPlace, $event)" lock-axis="y"
            drag-handle-selector=".column-drag-handle"
        >
          <Draggable v-for="(item,index) in favPlace">
            <div class="draggable-item column-drag-handle " v-if="index >0">


              <div class="div-hor-cards-search-favrow" v-if="favCategory === 'search'">
                <div style="display: grid; height: 100%; justify-items: center; align-content: center">
                  <div style="height: 20px">{{ index }}</div>
                  <v-icon style="width: 30px; cursor: move">mdi-drag-horizontal-variant</v-icon>
                </div>

                <v-divider vertical></v-divider>


                <div style="margin: 5px; padding: 10px; width: 350px;">
                  {{ getTitle(index) }}
                </div>


                <div>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">

                      <v-btn v-on="on" outlined color="grey" @click="deleteFavElement(item,index)">
                        <v-icon>mdi-delete-forever</v-icon>
                      </v-btn>
                    </template>
                    <span> löschen </span>
                  </v-tooltip>
                </div>
              </div>


              <div class="div-hor-cards-favrow" v-if="favCategory !== 'search'">
                <div style="display: grid; height: 100%; justify-items: center; align-content: center">
                  <div style="height: 20px">{{ index + 1 }}</div>
                  <v-icon style="width: 30px; cursor: move">mdi-drag-horizontal-variant</v-icon>
                </div>

                <v-divider vertical></v-divider>

                <!--                  </v-btn>-->
                <!--                  <CardSmallLocation :item="item"/>-->
                <div class="div-hor-cards-favrow1">
                  <img
                      :src="item.photo"
                      alt="Kein Foto, bitte Foto hochladen"
                      class="img-favrow"
                      v-if="favCategory !== 'search'"
                  >
                  <div>
                    {{ getTitle(index) }}
                  </div>
                </div>


                <v-divider vertical></v-divider>


                <!-- --------------------------------------  Country ------------------------------------------------------  -->


                <template v-if="favCategory !=='hotel' && favCategory !=='landingpage' && favCategory !== 'search'">
                  <div style="display: grid; gap: 10px; align-self: center; ">
                    <img class="img-flag-favrow" :src="flagDE" alt="flag" @click="changeDescLang(index, 'de')"/>
                    <img class="img-flag-favrow" :src="flagTR" alt="flag" @click="changeDescLang(index, 'tr')"/>
                    <img class="img-flag-favrow" :src="flagEN" alt="flag" @click="changeDescLang(index, 'en')"/>
                  </div>
                  <div>
                    <v-textarea
                        v-if="selectedLang[index] === 'de'"
                        v-model="item.desc_de"
                        label="Kurzbeschreibung DE"
                        outlined
                        rows="3"
                        no-resize
                        counter
                        maxlength="200"
                    />
                    <v-textarea
                        v-if="selectedLang[index] === 'tr'"
                        v-model="item.desc_tr"
                        label="Kurzbeschreibung TR"
                        outlined
                        rows="3"
                        no-resize
                        counter
                        maxlength="200"
                    />
                    <v-textarea
                        v-if="selectedLang[index] === 'en'"
                        v-model="item.desc_en"
                        label="Kurzbeschreibung EN"
                        outlined
                        rows="3"
                        no-resize
                        counter
                        maxlength="200"
                    />
                  </div>
                  <v-divider vertical></v-divider>
                </template>

                <!-- --------------------------------------  Country ENDE ------------------------------------------------------  -->


                <div style="height: 103px; display: grid; align-content: center; gap:10px;">
                  <div v-if="favCategory !=='hotel' && favCategory !=='landingpage' && favCategory !== 'search'">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">

                        <v-btn
                            v-on="on" outlined color="var(--Primary)"
                            @click="openPhotoOverlay(item)"
                        >
                          <v-icon>mdi-file-upload</v-icon>
                        </v-btn>
                      </template>
                      <span>Foto hochladen</span>
                    </v-tooltip>
                  </div>

                  <div>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">

                        <v-btn v-on="on" outlined color="grey" @click="deleteFavElement(item,index)">
                          <v-icon>mdi-delete-forever</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ getCategory() }}</span>
                    </v-tooltip>
                  </div>
                </div>

              </div>


            </div>
          </Draggable>
        </Container>
      </div>
    </template>
  </div>


</template>


<script>
import {Container, Draggable} from 'vue-dndrop';
import flagDE from '../../assets/Flags/de.png';
import flagTR from '../../assets/Flags/tr.png';
import flagEN from '../../assets/Flags/en.png';


export default {
  name: "FavRow",
  props: [
    'favPlace',
    'favCategory',
    'pLang',
  ],

  components: {
    Container,
    Draggable
  },


  data() {
    return {
      flagDE: flagDE,
      flagTR: flagTR,
      flagEN: flagEN,
      shortDescr: {textDE: "", textTR: "", textEN: ""},
      hotelMinPrice: [],
      selectedLang: [],
      place: "",
      int: 0,
      load: false

    }
  },

  methods: {

    getTitle(index) {
      if (this.pLang.toLowerCase() === 'de') {
        // return this.favPlace[index].name_de.includes(",") ? this.favPlace[index].name_de.split(",")[0] : this.favPlace[index].name_de;
        if (this.favPlace[index].fav_type === "city") {
          return this.favPlace[index].name_de.includes(",") ? this.favPlace[index].name_de.split(",")[0] : this.favPlace[index].name_de;
        }
        return this.favPlace[index].name_de;
      }

      if (this.pLang.toLowerCase() === 'tr') {
        // return this.favPlace[index].name_tr.includes(",") ? this.favPlace[index].name_tr.split(",")[0] : this.favPlace[index].name_tr;
        if (this.favPlace[index].fav_type === "city") {
          return this.favPlace[index].name_tr.includes(",") ? this.favPlace[index].name_tr.split(",")[0] : this.favPlace[index].name_tr;
        }
        return this.favPlace[index].name_tr;

      }
      if (this.pLang.toLowerCase() === 'en') {
        // return this.favPlace[index].name_en.includes(",") ? this.favPlace[index].name_en.split(",")[0] : this.favPlace[index].name_en;
        if (this.favPlace[index].fav_type === "city") {
          return this.favPlace[index].name_en.includes(",") ? this.favPlace[index].name_en.split(",")[0] : this.favPlace[index].name_de;
        }
        return this.favPlace[index].name_en;
      }
    },
    getCategory() {
      let cat = this.favCategory;

      if (this.favCategory === 'hotel') {
        return this.$t("Setting.Hotelloeschen");
      }
      if (this.favCategory === 'country') {
        return this.$t("Setting.Landloeschen");
      }
      if (this.favCategory === 'region') {
        return this.$t("Setting.Regionloeschen");
      }
      if (this.favCategory === 'city') {
        return this.$t("Setting.Stadtloeschen");
      }


    },

    onDrop(source, dropResult) {
      let removedIndex = dropResult["removedIndex"];
      let addedIndex = dropResult["addedIndex"];

      if (removedIndex !== null && addedIndex !== null) {
        let itemToAdd = source.splice(removedIndex, 1)[0];
        source.splice(addedIndex, 0, itemToAdd);
      }
    },


    changeDescLang(index, lang) {
      this.selectedLang[index] = lang;
      this.int++;
    },


    async deleteFavElement(item) {
      if (this.favCategory === 'search') {
        this.$root.$emit('deleteFavSearch', item);
      }
      if (this.favCategory === 'hotel') {
        this.$root.$emit('deleteFavHotel', item);
      }
      if (this.favCategory === 'country') {
        this.$root.$emit('deleteFavCountry', item);
      }
      if (this.favCategory === 'region') {
        this.$root.$emit('deleteFavlocation', item);
      }
      if (this.favCategory === 'city') {
        this.$root.$emit('deleteFavCity', item);
      }
      if (this.favCategory === 'landingpage') {
        this.$root.$emit('deletelandingpagePhoto', item);
      }
    },


    openPhotoOverlay(item) {
      this.overlayPhotoUpload = true;
      this.$root.$emit('openOverlayPhotoUpload', item);
    },
    init() {
      if (!this.load) {
        this.place = this.favPlace;
        this.place.map(item => {
          this.selectedLang.push("de");
        })
        this.load = true;
      }
    }

  },


  async created() {

  },
  watch: {
    favPlace(newValue) {
      this.init();
    }
  }

}

</script>

<style lang="css" scoped>
@import "../../css/FavRow.css";
</style>
