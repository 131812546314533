const holiday_type_de = [
    {id: "resort", text: "Resorts"},
    {id: "hotel", text: "Städtereise"},
    {id: "villa", text: "Villen"},
    {id: "thermal", text: "Wellness"}
]

const holiday_type_tr = [
    {id: "resort", text: "Tatil köyleri"},
    {id: "hotel", text: "Şehir tatili"},
    {id: "villa", text: "Villalar"},
    {id: "thermal", text: "Termal"}
]

const holiday_type_en = [
    {id: "resort", text: "Resorts"},
    {id: "hotel", text: "City Break"},
    {id: "villa", text: "Villas"},
    {id: "thermal", text: "Wellness"}
]




export const getHolidayType = (lang, id) => {

    if(lang !== undefined) {

        if (lang.toLowerCase() === "de") {
            return holiday_type_de.find(item => id === item.id).text;
        }

        if (lang.toLowerCase() === "en") {
            return holiday_type_en.find(item => id === item.id).text;
        }

        if (lang.toLowerCase() === "tr") {
            return holiday_type_tr.find(item => id === item.id).text;
        }
    }
}