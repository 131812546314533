import axios from "axios";


export const createUserInfo = async (userinfo) => {
    try {
        let res = await axios.post(process.env.VUE_APP_DB + "/api2/userinfo/", userinfo, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});
        return res.data.data;
    } catch (e) {
    }
}

export const updateUserInfo = async (userinfo) => {
    await axios.put(process.env.VUE_APP_DB + "/api2/userinfo/" + userinfo._id, userinfo, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});
}


export const getUserinfoByID = async (id) => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/userinfo/" + id, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});
    return res.data.data;
}


export const getUserinfoByUserId = async (id) => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/userinfobyuid/" + id, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});
    return res.data.data;
}


export const getKontaktFromUserinfoByUserId = async (id) => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/userk/" + id, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});
    return res.data.data;
}


export const getRoomAndHotel = async (id) => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/roomandhotel/" + id, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});
    return res.data.data;
}


export const deleteFile = async (file) => {
    let res = "";
    try {
        let apiBaseUrl = process.env.VUE_APP_DB + "/api2/";
        let headers = {
            headers: {
                "Accept": "application/json",
                'Access-Control-Allow-Origin': '*',
                "Access-Control-Allow-Headers": "Origin,Content-Type,X-Requested-With,Accept,Authorization",
                'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PATCH, PUT',
                "authorization": 'Bearer ' + localStorage.getItem('token')
            }
        }

        res = await axios.post(apiBaseUrl + "deletepic", {"file": file}, headers);

    } catch (e) {
        // console.log(e);
    }
    return res;
}


export const getLogo = async (id) => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/asdfr/" + id, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});
    let user = res.data.data;

    return user;
}


export const getRechtlichesByID = async (id) => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/rbid/" + id, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});

    return res.data.data.rechtliches;
}
