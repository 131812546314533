<template>
  <div class="home" v-if="pRolle==='admin' || pRolle==='superadmin'">
    <v-snackbar
        v-model="notificationsnackbar"
        :timeout="6000"
        :color="notificationcolor"
        :top="true"
        :right="true"
        :vertical="true"
    >
      {{ notificationtext }}
      <v-btn
          color="white"
          text
          @click="notificationsnackbar = false"
      >
        {{ $t("Notification.Schliessen") }}
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>


    <div class="bodyheader-home">
      <label class="label-bodyheader-home">
        <v-icon class="icon-home">mdi-home-plus</v-icon>
        Transfer anlegen
        <!--        {{ $t("home.Hotels") }}-->
      </label>

    </div>
    <h1>
      Create Transfer
    </h1>

  </div>
</template>

<script>

export default {
  name: "CreateTransfer",
  props: [
    'pLang',
    'pRolle',
  ],
  data() {
    return {
      notificationsnackbar: false,
      notificationtext: "",
      notificationcolor: "grey",
    };
  },
  methods: {
    zuStart() {
      this.$router.push('/login');
    }
  },

  async created() {

  }
};
</script>

<style scoped>
@import "../css/CreateTransfer.css";
</style>