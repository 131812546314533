const rate_plan_items_de = [
    {id: "room_only", text: "Nur Zimmer"},
    {id: "Self-catering", text: "Selbstverpfl."},
    {id: "breakfast", text: "Frühstück inklusive"},
    {id: "conti_breakfast", text: "Kontinentales Frühstück"},
    {id: "half_board", text: "Halbpension"},
    {id: "half_board_plus", text: "Halbpension Plus"},
    {id: "full_board", text: "Vollpension"},
    {id: "full_board_plus", text: "Vollpension Plus"},
    {id: "all_inclusive", text: "All-inclusive"},
    {id: "ultra_all_inclusive", text: "Ultra All-Inclusive"}
];

const rate_plan_items_tr = [
    {id: "room_only", text: "Sadece Oda"},
    {id: "Self-catering", text: "Mutfaklı Konaklama"},
    {id: "breakfast", text: "Kahvaltı Dâhil"},
    {id: "conti_breakfast", text: "Kontinental Kahvaltı"},
    {id: "half_board", text: "Yarım Pansiyon"},
    {id: "half_board_plus", text: "Yarım Pansiyon Ekstra"},
    {id: "full_board", text: "Tam Pansiyon"},
    {id: "full_board_plus", text: "Tam Pansiyon Ekstra"},
    {id: "all_inclusive", text: "Herşey Dâhil"},
    {id: "ultra_all_inclusive", text: "Ultra Herşey Dâhil"}
];


const rate_plan_items_en = [
    {id: "room_only", text: "Room Only"},
    {id: "Self-catering", text: "Self-catering"},
    {id: "breakfast", text: "Breakfast Included"},
    {id: "conti_breakfast", text: "Continental Breakfast"},
    {id: "half_board", text: "Half Board"},
    {id: "half_board_plus", text: "Half Board Plus"},
    {id: "full_board", text: "Full Board"},
    {id: "full_board_plus", text: "Full Board Plus"},
    {id: "all_inclusive", text: "All Inclusive"},
    {id: "ultra_all_inclusive", text: "Ultra all-inclusive"}
];


export const getRatePlanArray = () => {
    let lang = localStorage.getItem("lang");
    lang = lang.toLowerCase();

    if (lang === "de") {
        return rate_plan_items_de;
    }
    if (lang === "tr") {
        return rate_plan_items_tr;
    }
    if (lang === "en") {
        return rate_plan_items_en;
    }
    return rate_plan_items_de;
}

export const getRatePlanByID = (id) => {

    return getRatePlanArray().find(item => id === item.id).text;
}