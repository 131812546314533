<template>
  <div class="div-totalprice" :key="totalprice_int">

    <div style="border-bottom: 1px solid lightgray; margin-bottom: 15px;">
      <h3>
        {{ $t("Card.Uebersicht") }}
      </h3>
    </div>

    <div>
      <b>{{ $t("Card.Checkin") }}:</b> {{ moment(checkin, "YYYY-MM-DD").format('ddd, DD. MMMM') }}
    </div>
    <div>
      <b>{{ $t("Card.Checkout") }}:</b> {{ moment(checkout, "YYYY-MM-DD").format('ddd, DD. MMMM') }}
    </div>


    <template v-for="(item, index) in gruppe">
      <div style="margin-top: 15px">

        <b>{{ $t('Card.Gruppe') }} {{ index + 1 }} </b> <br>
        <span>{{ $t('Card.Personen') }}: </span>
        <span v-if="item.erwachsene !== undefined"> {{ item.erwachsene }} {{ $t('Card.Erwachsene') }}</span>
        <span v-if="item.kinder === 1">, {{ item.kinder }} {{ $t('Card.Kind') }}</span>
        <span v-if="item.kinder >1">, {{ item.kinder }} {{ $t('Card.Kinder') }}</span>


        <div v-if="selectedRoom[index].text !== undefined" style="color: red;">
          <!--          {{ selectedRoom[index]['name_' + pLang.toLowerCase()] }}-->
          {{ selectedRoom[index].text }}
        </div>

        <div class="div-room-price-totalprice" v-if="selectedRoom[index].room_id !== undefined">
          <div class="div-room-price-totalprice1">
            {{ selectedRoom[index].anzahl_zimmer }} x {{ selectedRoom[index]['name_' + pLang.toLowerCase()] }}
          </div>

          <div class="div-room-price-totalprice2">
            <span style="display: flex; align-items: baseline; justify-content: flex-end">
              <span v-if="selectedRatePlan[index].preis === 0">
                {{
                  formatPrice(calculateCurrency(pPlaceCurrency, pCurrency, pCurrencyValues, selectedRoom[index].price))
                }}
              </span>
              <span v-else>
                {{
                  formatPrice(calculateCurrency(pPlaceCurrency, pCurrency, pCurrencyValues, selectedRatePlan[index].preis))
                }}
              </span>
              <v-icon color="black" small>{{ currencyicon }}</v-icon>
            </span>

            <span
                v-if="pCurrency !== 'EUR' && from ==='CY_Booking'"
                class="div-total-price-totalprice3"
            >
              <span>
                ({{
                  formatPrice(calculateCurrency(pPlaceCurrency, "EUR", pCurrencyValues, selectedRatePlan[index].preis))
                }} €)
              </span>
            </span>
            <span
                v-if="trypreisprogruppe !== undefined && trypreisprogruppe !== 0"
                class="div-total-price-totalprice3"
            >
              <span>
                (
                {{
                  formatPrice(calculateCurrency(pPlaceCurrency, "TRY", pCurrencyValues, trypreisprogruppe[index].price))
                }}
                <v-icon small>{{ getCurrencyIcon('TRY') }}</v-icon>
                )
              </span>
            </span>
          </div>

        </div>

        <div v-if="selectedRatePlan[index].name !== '' && selectedRatePlan[index].length !== 1">
          {{ getRatePlanByID(selectedRatePlan[index].name) }}
        </div>

      </div>

    </template>

    <div class="div-total-price-totalprice">

      <div>
        {{ $t('Card.Gesamtpreis') }}:
      </div>
      <div class="div-total-price-totalprice2">
        {{ formatPrice(calculateCurrency(pPlaceCurrency, pCurrency, pCurrencyValues, gesamtpreis)) }}
        <v-icon color="black" small>{{ currencyicon }}</v-icon>
      </div>
      <span></span>
      <div v-if="pCurrency!=='EUR' && from ==='CY_Booking'" class="div-total-price-totalprice3">
        ({{ formatPrice(calculateCurrency(pPlaceCurrency, "EUR", pCurrencyValues, gesamtpreis)) }} €)
      </div>
      <div v-if="trygesamtpreis !== undefined && trygesamtpreis !== 0" class="div-total-price-totalprice3">
        (
        {{ formatPrice(calculateCurrency(pPlaceCurrency, "TRY", pCurrencyValues, trygesamtpreis)) }}
        <v-icon small>{{ getCurrencyIcon('TRY') }}</v-icon>
        )
      </div>

    </div>

    <div class="div-btn-totalprice" v-if="showbutton">
      <v-btn
          @click="zuBooking"
          class="vbtn-totalprice"
          :disabled="!checkinput"
          :color="!checkinput ? 'grey':'green'"
      >
        {{ $t('Button.WeiterZurBuchung') }}
      </v-btn>

    </div>

  </div>

</template>

<script>
import {formatPrice, getCurrencyIcon} from "@/util/currency";
import {getRatePlanByID} from "@/util/rateplansUtil";
import {calculateCurrency} from "@/util/currencyUtil";

export default {
  props: [
    'checkin',
    'checkout',
    'ownPlace',
    'halalPlaceGroups',
    'gruppe',
    'selectedRoom',
    'gesamtpreis',
    'checkinput',
    'selectedRatePlan',
    'showbutton',
    'eurogesamtpreis',
    'europreisprogruppe',
    'usdpreisprogruppe',
    'trygesamtpreis',
    'trypreisprogruppe',
    'totalprice_int',
    'pLang',
    'pCurrency',
    'pCurrencyValues',
    'pPlaceCurrency',
    'from'
  ],
  name: "CY_TotalPrice",
  data() {
    return {
      currencyicon: getCurrencyIcon(),

    }
  },
  methods: {
    getRatePlanByID,
    getCurrencyIcon,
    formatPrice,
    calculateCurrency,
    zuBooking() {
      localStorage.setItem("gruppe", JSON.stringify(this.gruppe));
      localStorage.setItem("selectedroom", JSON.stringify(this.selectedRoom));
      let cyid = this.ownPlace.place_id.split("-");
      let query = {
        ...this.$route.query,
        id: cyid[1]
      }
      this.$router.push({path: "/cy-booking", query: query});
    },
  },
  created() {
    this.$moment.locale(localStorage.getItem('lang'));
  }
}
</script>

<style scoped>
@import "../../css/TotalPrice.css";
</style>