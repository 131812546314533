<template>
  <div id="app">
    <v-app>
      <div class="div-body">
        <!--      <NavLeft page="Start" v-if="this.$store.getters.getToken"/>-->
        <NavLeft
            page="Start"
            :pLogo="logo"
            :pLang="lang"
            :pRolle="rolle"
        />
        <!--      <BurgerMenu page="Start"/>-->
        <div class="div-body-1">
          <!--        <Header v-if="this.$store.getters.getToken"/>-->
          <Header
              :pLogo="logo"
              :pLang="lang"
              :pRolle="rolle"
              :pDomain="domain"
          />
          <router-view
              :pLogo="logo"
              :pLang="lang"
              :pRolle="rolle"
              :pUser="user"
              :pUserinfo="userinfo"
              :pSetting="setting"
              :pRechtliches="rechtliches"
              :pDomain="domain"
              :pCurrencyValues="currencyValues"
              :pCurrency="currency"
          />
          <CeeYuuFooter2/>

        </div>
      </div>
    </v-app>
  </div>
</template>

<script>
import Header from "./components/headerfooter/Header";
import NavLeft from "./components/nav/NavLeft";
import CeeYuuFooter from "@/components/headerfooter/CeeYuuFooter";
import CeeYuuFooter2 from "@/components/headerfooter/CeeYuuFooter2";
import {
  getKontaktFromUserinfoByUserId,
  getLogo,
  getRechtlichesByID,
  getUserinfoByID,
  getUserinfoByUserId
} from "@/util/userinfoDBUtil";
import {getUser} from "@/util/Auth";
import {getAllSettings, getCurrencyKey, getdomain} from "@/util/settingsUtil";
import {getAdminUser} from "@/util/userUtil";
import {getCurrencyValues} from "@/util/currencyUtil";
import * as auth from "@/util/Auth";


export default {
  components: {
    Header,
    NavLeft,
    CeeYuuFooter,
    CeeYuuFooter2,
    // BurgerMenu
  },
  data() {
    return {
      lang: "DE",
      logo: "",
      user: "",
      userinfo: "",
      rolle: "",
      setting: [],
      rechtliches: "",
      domain: "",
      admin: "",
      currencyValues: "",
      currency: "EUR"
    }
  },


  async created() {
    this.domain = await getdomain();

    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    } else {
      localStorage.setItem("lang", this.lang);
    }

    if (localStorage.getItem("token"))
      this.user = await getUser(localStorage.getItem("token"));
    if (this.user !== "") {


      let logo = await getLogo(this.user._id);

      if (logo.logo !== undefined) {
        this.logo = process.env.VUE_APP_IMAGES + logo.logo.picture;
      }

      this.userinfo = await getUserinfoByUserId(this.user._id);


      this.rolle = await this.user.rolle;
      await this.$store.dispatch("setRolle", this.rolle);

      this.setting = await getAllSettings();
      this.rechtliches = this.userinfo.rechtliches;
      this.domain = this.setting.domain;

      if (localStorage.getItem("currency")) {
        this.currency = localStorage.getItem("currency");
      } else {
        localStorage.setItem("currency", this.currency);
      }

      let ckey = await getCurrencyKey();
      this.currencyValues = await getCurrencyValues(ckey.currencyKey);
    }

  }

};
</script>

<style scoped>
@import "./index.css";
</style>
