<template>
  <div class="div-place">
    <!--    <div class="div-place" v-bind:class="stopScrolling ? 'no-scroll':''">-->
    <v-snackbar
        v-model="notificationsnackbar"
        :timeout="6000"
        :color="notificationcolor"
        :top="true"
        :right="true"
        :vertical="true"
    >
      {{ notificationtext }}
      <v-btn
          color="white"
          text
          @click="notificationsnackbar = false"
      >
        {{ $t("Notification.Schliessen") }}
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

    <div class="div-body-place">

      <v-overlay :value="loading">
        <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
            :size="50"
            :width="7"
        />
      </v-overlay>

      <div class="div-body-grid-place" v-if="halalPlace !== '' && halalPlace !== undefined && halalPlace !== null">
        <div class="div-body-left-place">

          <Gallery v-if="halalPlace.place" :pics="halalPlace.place.photos"></Gallery>


          <div class="div-grid-title-place">
            <div
                class="div-header-location-place"
                v-if="halalPlace !== '' && halalPlace !== undefined && halalPlace !== null"
            >
              <label v-if="halalPlace.place" class="label-placename-place">{{ halalPlace.place.name }}</label>
              <v-rating
                  v-if="halalPlace.place "
                  v-model="halalPlace.place.stars"
                  background-color="transparent"
                  color="#FFDF00"
                  small
                  readonly
              />

              <div v-if="halalPlace.place" class="div-location-place">
                <v-icon color="black" small>mdi-map-marker-radius</v-icon>
                <label>{{ halalPlace.place.location.city }}, {{ halalPlace.place.location.subregion }},
                       {{ halalPlace.place.location.country }}</label>
              </div>
            </div>
            <div class="div-social-share-place">


              <!--   -----------------------------------   Social Media ----------------------------------- -->
              <div class="div-social-share-trip">
                <label style="font-size: 11px;">Teilen Sie die Unterkunft</label>
                <div class="div-social-share-trip1">
                  <ShareNetwork
                      network="facebook"
                      :url="'https://'+pDomain+'/place?id='+halalPlace.place.id"
                      :title="pDomain+', Finden Sie Jetzt Ihre Traumreise'"
                      :description="halalPlace.place.name"
                      :quote=" 'Siehe dir folgendes Hotel an: '+ halalPlace.place.name"
                      :hashtags="pDomain"
                      :image="halalPlace.place.photo"
                  >
                    <v-icon color="#4267B2">mdi-facebook</v-icon>
                  </ShareNetwork>

                  <ShareNetwork
                      network="twitter"
                      :url="'https://'+pDomain+'/place?id='+halalPlace.place.id"
                      :title="pDomain+', Finden Sie Jetzt Ihre Traumreise'"
                      :description=" halalPlace.place.name"
                      :quote=" 'Siehe dir folgendes Hotel an: '+ halalPlace.place.name"
                      hashtags="MyTourTravel"
                  >
                    <v-icon color="#1da1f2">mdi-twitter</v-icon>
                  </ShareNetwork>

                  <ShareNetwork
                      network="whatsapp"
                      :url="'https://'+pDomain+'/place?id='+halalPlace.place.id"
                      title="Siehe dir das Hotel an:"
                      :description=" halalPlace.place.name"
                  >
                    <v-icon color="#4ac959">mdi-whatsapp</v-icon>
                  </ShareNetwork>


                  <ShareNetwork
                      network="email"
                      :title="'Hotel auf '+ pDomain"
                      :description="'Hier ist eine sehr schönes Hotel auf '+pDomain+', schau es dir doch mal an: '+  halalPlace.place.name"
                      :url="'https://'+pDomain+'/place?id='+halalPlace.place.id"
                  >
                    <v-icon color="black">mdi-email-outline</v-icon>
                  </ShareNetwork>
                </div>

              </div>

              <!--   -----------------------------------   Social Media ENDE ----------------------------------- -->


            </div>
          </div>


          <v-divider style="margin: 10px 0;"></v-divider>

          <div
              v-if="halalPlace.place" class="div-overview-place" v-html="halalPlace.place.overview"
              style="border-right: unset"
          ></div>

          <v-divider style="margin: 10px 0;"></v-divider>


          <div v-if="halalPlace.place">
            <SearchSmall
                :propsPlaceID="halalPlace.place.id"
                :checkin="checkin"
                :checkout="checkout"
                from="place"
            />
          </div>


          <v-divider></v-divider>
          <!-- ------------------------------- Card Room Anfang ------------------------------- -->


          <div
              style="margin: 20px 5px 30px 5px" id="title-place"
              v-if="this.halalPlace !== undefined && this.halalPlace.groups !== undefined"
          >

            <div v-if="this.halalPlace.groups[0].offers.length >0">
              <div class="div-title-price-outer-place">
                <div class="div-contentinfo-place">
                  <div class="div-contentinfo-title-place">
                    <div class="line"/>
                    <div class="div-contentinfo-title-place1">
                      <span></span>
                      <div class="div-title-price-place">

                        <label class="label-title-price-place">
                          <span v-if="pLang !== 'TR'">
                            {{ $t("Place.Preisefur") }}
                            <br/>
                          </span>

                          <span v-html="halalPlace.place.name"></span>

                          <span v-if="pLang === 'TR'">
                            <br>
                            {{ $t("Place.Preisefur") }}
                          </span>
                        </label>
                      </div>
                    </div>
                    <div class="line"/>
                  </div>
                </div>
              </div>

              <div style="z-index: 2; position: relative">
                <v-expansion-panels multiple v-model="panel">

                  <template v-for="(gruppen,i) in halalPlace.groups">

                    <v-expansion-panel v-if="halalPlace.place">
                      <v-expansion-panel-header>
                        <template v-slot:default="{ open }" v-if="selectedRoom[i].name !== undefined">
                          <v-row no-gutters>
                            <span v-if="halalPlace.groups.length-1 >0">
                              {{ $t("Place.Zimmer") }} {{ i + 1 }}
                            </span>
                            <span v-else></span>
                            <v-fade-transition leave-absolute>
                              <span v-if="open && selectedRoom[i].name " key="0" style="margin: auto">
                                <div v-if="selectedRoom[i].name === 'unset'" style="font-weight: bold;">
                                  {{ $t("Place.BitteZimmerwahlen") }}
                                </div>
                                <div v-else style="font-weight: bold; text-align: center">
                                  <v-icon color="green">mdi-check-circle-outline</v-icon>
                                  <span v-if="selectedRoom[i].room" v-html="selectedRoom[i].room.name"></span>
                                </div>

                              </span>


                              <span v-else key="1" style="margin: auto">
                                <div v-if="selectedRoom[i].name === 'unset'">
                                  <v-icon color="error">mdi-alert</v-icon>
                                  {{ $t("Place.KeinZimmer") }}
                                </div>
                                <div v-else style="font-weight: bold; text-align: center">
                                  <v-icon color="green">mdi-check-circle-outline</v-icon>
                                  <span v-if="selectedRoom[i].room" v-html="selectedRoom[i].room.name"></span>
                                </div>
                              </span>
                            </v-fade-transition>
                          </v-row>
                        </template>
                      </v-expansion-panel-header>


                      <v-expansion-panel-content>
                        <div class="div-rooms-place">
                          <template v-for="(info,index) in halalPlace.groups[i].offers">

                            <CardRoom
                                v-if="cardDesign ==='1'"
                                :roominfo="info"
                                :place="halalPlace"
                                :index="index"
                                :groupindex="i"
                            />


                            <CardRoom2
                                v-if="cardDesign ==='2'"
                                :roominfo="info"
                                :place="halalPlace"
                                :index="index"
                                :groupindex="i"
                            />

                            <CardRoom3
                                v-if="cardDesign ==='3'"
                                :roominfo="info"
                                :place="halalPlace"
                                :index="index"
                                :groupindex="i"
                            />

                            <CardRoom4
                                v-if="cardDesign ==='4'"
                                :roominfo="info"
                                :place="halalPlace"
                                :index="index"
                                :groupindex="i"
                            />

                          </template>

                        </div>


                      </v-expansion-panel-content>
                    </v-expansion-panel>


                  </template>

                </v-expansion-panels>
              </div>
            </div>

            <div v-if="this.halalPlace.groups[0].offers.length < 1" class="div-norooms-place">
              <v-icon large color="error">mdi-calendar-search</v-icon>
              <label>
                {{ $t("Place.KeineErgebnisse") }}
              </label>
            </div>
          </div>
          <!-- ------------------------------- Card Room ENDE ------------------------------- -->


          <hr class="hr-horizontale-linie" style="margin: 20px 0;">


          <div class="div-infos-desk-place">


            <!-- ------------------------------------- Hotel Infos Anfang ------------------------------------- -->

            <div class="div-contentinfo-place">
              <div class="div-contentinfo-title-place">
                <div class="line"/>
                <div class="div-contentinfo-title-place1">
                  <v-icon color="var(--Primary)">mdi-fireplace-off</v-icon>
                  <label v-if="halalPlace.place" class="label-placename-place" v-html="halalPlace.place.name"></label>
                </div>
                <div class="line"/>
              </div>

              <div class="div-overview-outer-place">
                <div v-if="halalPlace.place" class="div-overview-place" v-html="halalPlace.place.overview"></div>

                <div>
                  <div class="div-facs-place" v-if="check_facts[2].checkin">
                    <v-icon color="var(--Primary)" style="margin-right: 8px">mdi-calendar-clock-outline</v-icon>
                    <div style="font-weight: bolder; margin-right: 5px;">
                      {{ check_facts[2].title }}:
                    </div>
                    <div style="white-space: nowrap" v-html="check_facts[2].text"></div>

                    <v-icon color="var(--Primary)" style="transform: rotate(90deg)">mdi-minus</v-icon>
                    <div style="font-weight: bolder; margin-right: 5px;">
                      {{ check_facts[3].title }}:
                    </div>
                    <div style="white-space: nowrap"> {{ check_facts[3].text }}</div>
                  </div>

                  <div class="div-facs-place" v-if="check_facts[0].propertyType">
                    <v-icon color="var(--Primary)" style="margin-right: 8px">mdi-home-group</v-icon>
                    <div style="font-weight: bolder; margin-right: 5px;">
                      {{ check_facts[0].title }}:
                    </div>
                    <div>{{ check_facts[0].text }}</div>
                  </div>

                  <div class="div-facs-place" v-if="check_facts[4].language">
                    <v-icon color="var(--Primary)" style="margin-right: 8px">mdi-account-tie-voice</v-icon>
                    <div>
                      <span style="font-weight: bolder; margin-right: 5px;">{{ check_facts[4].title }}:</span>
                      <span>{{ check_facts[4].text }}</span>
                    </div>
                  </div>
                </div>
              </div>

            </div>


            <!-- ------------------------------------- Hotel Infos ENDE ------------------------------------- -->
            <div class="div-infos-grid-place">
              <div id="div-left-place">

                <!-- ------------------------------------- Strand Infos Anfang ------------------------------------- -->
                <div class="div-contentinfo-place" v-if="check_facilities[12].beach">

                  <div class="div-contentinfo-title-place">
                    <div class="line"/>
                    <div class="div-contentinfo-title-place1">
                      <v-icon color="var(--Primary)">mdi-beach</v-icon>
                      <label class="label-placename-place" v-html="check_facilities[12].title"></label>
                    </div>
                    <div class="line"/>
                  </div>


                  <div v-if="hb_beach[0].desc[0].includes('-')" style="margin-bottom: 10px">
                    <label style="font-weight: bold">{{ $t('Place.Öffnungszeit') }}: </label><label
                      v-html="hb_beach[0].desc[0]"
                  ></label>
                  </div>
                  <div v-else style="margin-bottom: 10px">
                    <label style="font-weight: bold" v-html="hb_beach[0].title.replace(':','')"></label><br>
                    <template v-for="it in hb_beach[0].desc">
                      <div style="display: flex">
                        <v-icon color="var(--Primary)" small style="margin-right: 5px;">mdi-check</v-icon>
                        <label v-html="it"></label>
                      </div>
                    </template>
                  </div>


                  <template v-for="(item, index) in hb_beach">
                    <div v-html="item.title.replace(':','')" class="div-beach-titles-place" v-if="index>0"></div>
                    <template v-for="it in hb_beach[index].desc" v-if="index>0">
                      <div style="display: flex">
                        <v-icon color="var(--Primary)" small style="margin-right: 5px;">mdi-check</v-icon>
                        <div v-html="it"></div>
                      </div>
                    </template>
                  </template>

                </div>

                <!-- ------------------------------------- Strand Infos ENDE ------------------------------------- -->


                <!-- ------------------------------------- Wellness & Spa Infos Anfang ------------------------------------- -->
                <div class="div-contentinfo-place" v-if="check_facilities[13].spa">

                  <div class="div-contentinfo-title-place">
                    <div class="line"/>
                    <div class="div-contentinfo-title-place1">
                      <v-icon color="var(--Primary)">mdi-hand-heart-outline</v-icon>
                      <label class="label-placename-place" v-html="check_facilities[13].title"></label>
                    </div>
                    <div class="line"/>
                  </div>


                  <template v-for="(item, index) in hb_spa">
                    <div v-html="item.title.replace(':','')" class="div-beach-titles-place"></div>
                    <template v-for="it in hb_spa[index].desc">
                      <div style="display: flex">
                        <v-icon color="var(--Primary)" small style="margin-right: 5px;">mdi-check</v-icon>
                        <div v-html="it"></div>
                      </div>
                    </template>
                  </template>
                </div>
                <!-- ------------------------------------- Wellness & Spa Infos ENDE ------------------------------------- -->


                <!-- ------------------------------------- Pools Infos Anfang ------------------------------------- -->
                <div class="div-contentinfo-place" v-if="check_facilities[14].pools">

                  <div class="div-contentinfo-title-place">
                    <div class="line"/>
                    <div class="div-contentinfo-title-place1">
                      <v-icon color="var(--Primary)">mdi-pool</v-icon>
                      <label class="label-placename-place" v-html="check_facilities[14].title"></label>
                    </div>
                    <div class="line"/>
                  </div>


                  <template v-for="(item, index) in hb_pools">
                    <template v-if="!Array.isArray(hb_pools[0])">
                      <div v-html="item.nontitle" v-if="item.nontitle !==''"></div>
                      <div v-html="item[0].title.replace(':','')" class="div-beach-titles-place" v-if="index>0"></div>


                      <div v-if="index > 0" class="div-pool-desc-place">
                        <template v-for="(it,n) in item">
                          <div v-html="it.title.replace(':','')" style="font-weight: bolder" v-if="n>0"></div>
                          <div v-if="!Array.isArray(it.desc) && it.desc !== undefined">
                            <v-icon color="var(--Primary)">mdi-circle-small</v-icon>
                            <span v-html="it.desc"></span>
                            <!--                            {{ it.desc }}-->
                          </div>
                          <span v-if="Array.isArray(it.desc)" v-for="(desc, m) in it.desc" id="span1">

                            <div v-if="n>0">
                              <v-icon color="var(--Primary)">mdi-circle-small</v-icon>

                              <span v-html="desc"></span>
                              <!--                          {{ desc }}-->
                            </div>

                            <span v-if="n===0">
                              <v-chip
                                  class="vchip-pool-place"
                                  color="var(--Primary)"
                                  outlined
                              >
                                <!--                            {{ desc }}-->

                                <span v-html="desc"></span>
                              </v-chip>
                            </span>
                          </span>
                        </template>
                      </div>
                    </template>

                    <div class="div-pool-desc-place" v-if="Array.isArray(hb_pools[0])">
                      <template v-if="Array.isArray(hb_pools[0])" v-for="(it,b) in item">
                        <div v-html="it.title.replace(':','')" class="div-beach-titles-place"></div>
                        <div v-if="b>0">
                          <v-icon color="var(--Primary)">mdi-circle-small</v-icon>
                          {{ it.desc }}
                        </div>


                        <span v-if="b===0">
                          <v-chip
                              class="vchip-pool-place"
                              color="var(--Primary)"
                              outlined
                          >
                            <!--                        {{ it.desc }}-->
                            <span v-html="it.desc"></span>
                          </v-chip>
                        </span>
                      </template>
                    </div>


                  </template>
                </div>
                <!-- ------------------------------------- Pools Infos ENDE ------------------------------------- -->

              </div>


              <div id="div-right-place">

                <!-- ------------------------------------- Location Infos Anfang ------------------------------------- -->
                <div class="div-contentinfo-place" v-if="check_facts[5].location">

                  <div class="div-contentinfo-title-place">
                    <div class="line"/>
                    <div class="div-contentinfo-title-place1">
                      <v-icon color="var(--Primary)">mdi-map</v-icon>
                      <label class="label-placename-place">{{ (check_facts[5].title).toUpperCase() }}</label>
                    </div>
                    <div class="line"/>

                  </div>

                  <div class="div-facs-place" style="align-items: self-start">
                    <v-icon color="var(--Primary)" style="margin-right: 8px">mdi-map-marker-radius</v-icon>
                    <div v-html="check_facts[5].text"></div>
                  </div>

                  <div v-if="googlemaps.key !== ''">
                    <iframe
                        :width="googlemaps.width"
                        :height="googlemaps.height"
                        style="border:0"
                        loading="lazy"
                        allowfullscreen
                        :src="this.googlemaps.src+this.googlemaps.key+this.googlemaps.place"
                    >
                    </iframe>
                  </div>

                </div>

                <!-- ------------------------------------- Location Infos ENDE ------------------------------------- -->

                <!-- ------------------------------------- Aktivitäten Infos Anfang ------------------------------------- -->
                <div class="div-contentinfo-place" v-if="check_facilities[1].acitivities">

                  <div class="div-contentinfo-title-place">
                    <div class="line"/>
                    <div class="div-contentinfo-title-place1">
                      <v-icon color="var(--Primary)">mdi-handball</v-icon>
                      <label class="label-placename-place" v-html="check_facilities[1].title"></label>
                    </div>
                    <div class="line"/>
                  </div>


                  <template v-for="item in check_facilities[1].text ">
                    <div style="display: flex; align-items: center;">
                      <v-icon color="var(--Primary)" large>mdi-circle-small</v-icon>
                      <div>{{ item }}</div>
                    </div>
                  </template>

                </div>
                <!-- ------------------------------------- Aktivitäten Infos ENDE ------------------------------------- -->


                <!-- ------------------------------------- Essen & Trinken Infos Anfang ------------------------------------- -->

                <div class="div-contentinfo-place" v-if="check_facilities[2].food">

                  <div class="div-contentinfo-title-place">
                    <div class="line"/>
                    <div class="div-contentinfo-title-place1">
                      <v-icon color="var(--Primary)">mdi-food-fork-drink</v-icon>
                      <label class="label-placename-place" v-html="check_facilities[2].title"></label>
                    </div>
                    <div class="line"/>
                  </div>


                  <template v-for="item in check_facilities[2].text ">
                    <div style="display: flex; align-items: center;">
                      <v-icon color="var(--Primary)" large>mdi-circle-small</v-icon>
                      <div v-html="item"></div>
                    </div>
                  </template>
                </div>
                <!-- ------------------------------------- Essen & Trinken Infos ENDE ------------------------------------- -->


                <!-- ------------------------------------- Kinder Infos Anfang ------------------------------------- -->
                <div class="div-contentinfo-place" v-if="check_facilities[3].children">

                  <div class="div-contentinfo-title-place">
                    <div class="line"/>
                    <div class="div-contentinfo-title-place1">
                      <v-icon color="var(--Primary)">mdi-baby-face-outline</v-icon>
                      <label class="label-placename-place" v-html="check_facilities[3].title"></label>
                    </div>
                    <div class="line"/>
                  </div>


                  <template v-for="item in check_facilities[3].text ">
                    <div style="display: flex; align-items: center;">
                      <v-icon color="var(--Primary)" large>mdi-circle-small</v-icon>
                      <div v-html="item"></div>
                    </div>
                  </template>
                </div>
                <!-- ------------------------------------- Kinder Infos ENDE ------------------------------------- -->


                <!-- ------------------------------------- UNTERHALTUNG Infos Anfang ------------------------------------- -->

                <div class="div-contentinfo-place" v-if="check_facilities[4].entertainment">

                  <div class="div-contentinfo-title-place">
                    <div class="line"/>
                    <div class="div-contentinfo-title-place1">
                      <v-icon color="var(--Primary)">mdi-drama-masks</v-icon>
                      <label class="label-placename-place" v-html="check_facilities[4].title"></label>
                    </div>
                    <div class="line"/>
                  </div>

                  <template v-for="item in check_facilities[4].text ">
                    <div style="display: flex; align-items: center;">
                      <v-icon color="var(--Primary)" large>mdi-circle-small</v-icon>
                      <div v-html="item"></div>
                    </div>
                  </template>
                </div>
                <!-- ------------------------------------- UNTERHALTUNG Infos ENDE ------------------------------------- -->

              </div>
            </div>

            <!-- ------------------------------------- Ausstattungen Infos Anfang ------------------------------------- -->
            <div class="div-contentinfo-place">

              <div class="div-contentinfo-title-place">
                <div class="line"/>
                <div class="div-contentinfo-title-place1">
                  <v-icon color="var(--Primary)">mdi-home-city</v-icon>
                  <label class="label-placename-place">{{ $t("Place.Ausstattungen") }}</label>
                </div>
                <div class="line"/>
              </div>


              <div class="div-general-outer-place">
                <div v-if="check_facilities[0].general">
                  <div class="div-titleinfo-place">
                    <v-icon color="var(--Primary)">mdi-home-city</v-icon>
                    <label class="label-infotitle-inner-place" v-html="check_facilities[0].title"></label>
                  </div>
                  <div v-html="check_facilities[0].text" style="margin-top: 15px !important;"></div>
                  <hr class="hr-horizontale-linie" style="margin: 20px 0;">
                </div>


                <div v-if="check_facilities[5].internet">
                  <div class="div-titleinfo-place">
                    <v-icon color="var(--Primary)">mdi-wifi</v-icon>
                    <label class="label-infotitle-inner-place" v-html="check_facilities[5].title"></label>
                  </div>
                  <div v-html="check_facilities[5].text" style="margin-top: 15px !important;"></div>

                  <hr class="hr-horizontale-linie" style="margin: 20px 0;">
                </div>


                <div v-if="check_facilities[6].parking">
                  <div class="div-titleinfo-place">
                    <v-icon color="var(--Primary)">mdi-car-brake-parking</v-icon>
                    <label class="label-infotitle-inner-place" v-html="check_facilities[6].title"></label>
                  </div>
                  <div v-html="check_facilities[6].text" style="margin-top: 15px !important;"></div>
                  <hr class="hr-horizontale-linie" style="margin: 20px 0;">
                </div>


                <div v-if="check_facilities[7].shops">
                  <div class="div-titleinfo-place">
                    <v-icon color="var(--Primary)">mdi-cart</v-icon>
                    <label class="label-infotitle-inner-place" v-html="check_facilities[7].title"></label>
                  </div>
                  <div v-html="check_facilities[7].text" style="margin-top: 15px !important;"></div>
                  <hr class="hr-horizontale-linie" style="margin: 20px 0;">
                </div>


                <div v-if="check_facilities[8].reception">
                  <div class="div-titleinfo-place">
                    <v-icon color="var(--Primary)">mdi-account-tie</v-icon>
                    <label class="label-infotitle-inner-place" v-html="check_facilities[8].title"></label>
                  </div>
                  <div v-html="check_facilities[8].text" style="margin-top: 15px !important;"></div>
                  <hr class="hr-horizontale-linie" style="margin: 20px 0;">
                </div>


                <div v-if="check_facilities[9].business">
                  <div class="div-titleinfo-place">
                    <v-icon color="var(--Primary)">mdi-briefcase-variant</v-icon>
                    <label class="label-infotitle-inner-place" v-html="check_facilities[9].title"></label>
                  </div>
                  <div v-html="check_facilities[9].text" style="margin-top: 15px !important;"></div>
                  <hr class="hr-horizontale-linie" style="margin: 20px 0;">
                </div>


                <div v-if="check_facilities[10].cleaning">
                  <div class="div-titleinfo-place">
                    <v-icon color="var(--Primary)">mdi-washing-machine</v-icon>
                    <label class="label-infotitle-inner-place" v-html="check_facilities[10].title"></label>
                  </div>
                  <div v-html="check_facilities[10].text" style="margin-top: 15px !important;"></div>
                  <hr class="hr-horizontale-linie" style="margin: 20px 0;">
                </div>


                <div v-if="check_facilities[11].transport">
                  <div class="div-titleinfo-place">
                    <v-icon color="var(--Primary)">mdi-taxi</v-icon>
                    <label class="label-infotitle-inner-place" v-html="check_facilities[11].title"></label>
                  </div>
                  <div v-html="check_facilities[11].text" style="margin-top: 15px !important;"></div>
                  <hr class="hr-horizontale-linie" style="margin: 20px 0;">
                </div>
              </div>

            </div>
            <!-- ------------------------------------- Ausstattungen Infos ENDE ------------------------------------- -->


            <!-- ------------------------------------- Wichtige Infos Anfang ------------------------------------- -->

            <div class="div-contentinfo-place" v-if="check_facilities[15].notes">

              <div class="div-contentinfo-title-place">
                <div class="line"/>
                <div class="div-contentinfo-title-place1">
                  <v-icon color="var(--Primary)">mdi-note-text-outline</v-icon>
                  <label class="label-placename-place" v-html="check_facilities[15].title"></label>
                </div>
                <div class="line"/>
              </div>


              <div v-html="check_facilities[15].text" style="white-space: pre-line; text-align: justify"></div>
            </div>
            <!-- ------------------------------------- Wichtige Infos ENDE ------------------------------------- -->

          </div>

          <!-- --------------------------------------------------- Mobile Anfang --------------------------------------------------- -->

          <div class="div-infos-mobile-place">
            <v-expansion-panels>

              <!-- ------------------------------------- Hotel Infos Anfang ------------------------------------- -->
              <v-expansion-panel v-if="halalPlace.place">
                <v-expansion-panel-header>
                  <template v-slot:default="{ open }">
                    <div class="div-titleinfo-place">
                      <v-icon color="var(--Primary)">mdi-fireplace-off</v-icon>
                      <label class="label-infotitle-place">Hotel Info</label>
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content v-if="halalPlace.place">
                  <div class="div-contentinfo-place">
                    <label class="label-placename-place" v-html="halalPlace.place.name"></label>
                    <div
                        class="div-overview-place" style="border-bottom: 1px solid lightgray; padding-bottom: 15px"
                        v-html="halalPlace.place.overview"
                    ></div>
                    <div>
                      <div>
                        <div class="div-facs-place" v-if="check_facts[2].checkin">
                          <v-icon color="var(--Primary)" style="margin-right: 8px">mdi-calendar-clock-outline</v-icon>
                          <div style="font-weight: bolder; margin-right: 5px;">
                            {{ check_facts[2].title }}:
                          </div>
                          <div style="white-space: nowrap"> {{ check_facts[2].text }}</div>

                          <v-icon color="var(--Primary)" style="transform: rotate(90deg)">mdi-minus</v-icon>
                          <div style="font-weight: bolder; margin-right: 5px;">
                            {{ check_facts[3].title }}:
                          </div>
                          <div style="white-space: nowrap"> {{ check_facts[3].text }}</div>
                        </div>

                        <div class="div-facs-place" v-if="check_facts[0].propertyType">
                          <v-icon color="var(--Primary)" style="margin-right: 8px">mdi-home-group</v-icon>
                          <div style="font-weight: bolder; margin-right: 5px;">
                            {{ check_facts[0].title }}:
                          </div>
                          <div>{{ check_facts[0].text }}</div>
                        </div>

                        <div class="div-facs-place" v-if="check_facts[4].language">
                          <v-icon color="var(--Primary)" style="margin-right: 8px">mdi-account-tie-voice</v-icon>
                          <div>
                            <span style="font-weight: bolder; margin-right: 5px;">
                              {{ check_facts[4].title }}:
                            </span>
                            <span>
                              {{ check_facts[4].text }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- ------------------------------------- Hotel Infos Ende ------------------------------------- -->


              <!-- ------------------------------------- Strand Infos Anfang ------------------------------------- -->
              <v-expansion-panel v-if="check_facilities[12].beach">
                <v-expansion-panel-header>
                  <template v-slot:default="{ open }">
                    <div class="div-titleinfo-place">
                      <v-icon color="var(--Primary)">mdi-beach</v-icon>
                      <label class="label-infotitle-place">
                        <div v-html="check_facilities[12].title"></div>
                      </label>
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="div-contentinfo-place">

                    <div v-if="hb_beach[0].desc[0].includes('-')" style="margin-bottom: 10px">
                      <label style="font-weight: bold">Öffnungszeit: </label><label
                        v-html="hb_beach[0].desc[0]"
                    ></label>
                    </div>
                    <div v-else style="margin-bottom: 10px">
                      <label style="font-weight: bold" v-html="hb_beach[0].title.replace(':','')"></label><br>
                      <template v-for="it in hb_beach[0].desc">
                        <div style="display: flex">
                          <v-icon color="var(--Primary)" small style="margin-right: 5px;">mdi-check</v-icon>
                          <label v-html="it"></label>
                        </div>
                      </template>
                    </div>


                    <template v-for="(item, index) in hb_beach">
                      <div v-html="item.title.replace(':','')" class="div-beach-titles-place" v-if="index>0"></div>
                      <template v-for="it in hb_beach[index].desc" v-if="index>0">

                        <div style="display: flex">
                          <v-icon small color="var(--Primary)" style="margin-right: 5px;">mdi-check</v-icon>
                          <div v-html="it"></div>
                        </div>
                      </template>
                    </template>

                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- ------------------------------------- Strand Infos Ende ------------------------------------- -->


              <!-- ------------------------------------- Wellness & Spa Infos Anfang ------------------------------------- -->
              <v-expansion-panel v-if="check_facilities[13].spa">
                <v-expansion-panel-header>
                  <template v-slot:default="{ open }">
                    <div class="div-titleinfo-place">
                      <v-icon color="var(--Primary)">mdi-hand-heart-outline</v-icon>
                      <label class="label-infotitle-place">
                        <div v-html="check_facilities[13].title"></div>
                      </label>
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="div-contentinfo-place">

                    <template v-for="(item, index) in hb_spa">
                      <div v-html="item.title.replace(':','')" class="div-beach-titles-place"></div>
                      <template v-for="it in hb_spa[index].desc">

                        <div style="display: flex">
                          <v-icon small color="var(--Primary)" style="margin-right: 5px;">mdi-check</v-icon>
                          <div v-html="it"></div>
                        </div>
                      </template>
                    </template>

                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- ------------------------------------- Wellness & Spa Infos Ende ------------------------------------- -->


              <!-- ------------------------------------- Pools Infos Anfang ------------------------------------- -->
              <v-expansion-panel v-if="check_facilities[14].pools">
                <v-expansion-panel-header>
                  <template v-slot:default="{ open }">
                    <div class="div-titleinfo-place">
                      <v-icon color="var(--Primary)">mdi-pool</v-icon>
                      <label class="label-infotitle-place">
                        <div v-html="check_facilities[14].title"></div>
                      </label>
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="div-contentinfo-place">


                    <template v-for="(item, index) in hb_pools">
                      <template v-if="!Array.isArray(hb_pools[0])">
                        <div v-html="item.nontitle" v-if="item.nontitle !==''"></div>
                        <div
                            v-html="item[0].title.replace(':','')" class="div-beach-titles-place"
                            v-if="index>0"
                        ></div>


                        <div v-if="index > 0" class="div-pool-desc-place">
                          <template v-for="(it,n) in item">
                            <div v-html="it.title.replace(':','')" style="font-weight: bolder" v-if="n>0"></div>
                            <div v-if="!Array.isArray(it.desc) && it.desc !== undefined">
                              <v-icon color="var(--Primary)">mdi-circle-small</v-icon>
                              <span v-html="it.desc"></span>
                            </div>
                            <span v-if="Array.isArray(it.desc)" v-for="(desc, m) in it.desc" id="span">

                              <div v-if="n>0">
                                <v-icon color="var(--Primary)">mdi-circle-small</v-icon>
                                <span v-html="desc"></span>
                              </div>

                              <span v-if="n===0">
                                <v-chip
                                    class="vchip-pool-place"
                                    color="var(--Primary)"
                                    outlined
                                >
                                  <span v-html="desc"></span>
                                </v-chip>
                              </span>
                            </span>
                          </template>
                        </div>
                      </template>

                      <div class="div-pool-desc-place" v-if="Array.isArray(hb_pools[0])">
                        <template v-if="Array.isArray(hb_pools[0])" v-for="(it,b) in item">
                          <div v-html="it.title.replace(':','')" class="div-beach-titles-place"></div>
                          <div v-if="b>0">
                            <v-icon color="var(--Primary)">mdi-circle-small</v-icon>
                            <span v-html="it.desc"></span>
                          </div>


                          <span v-if="b===0">
                            <v-chip
                                class="vchip-pool-place"
                                color="var(--Primary)"
                                outlined
                            >
                              <span v-html="it.desc"></span>
                            </v-chip>
                          </span>
                        </template>
                      </div>


                    </template>

                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- ------------------------------------- Pools Infos Ende ------------------------------------- -->


              <!-- ------------------------------------- Location Infos Anfang ------------------------------------- -->


              <v-expansion-panel v-if="check_facts[5].location">
                <v-expansion-panel-header>
                  <template v-slot:default="{ open }">
                    <div class="div-titleinfo-place">
                      <v-icon color="var(--Primary)">mdi-map</v-icon>
                      <label class="label-infotitle-place">
                        <div>{{ (check_facts[5].title).toUpperCase() }}</div>
                      </label>
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                  <div class="div-contentinfo-place">

                    <div class="div-facs-place" style="align-items: self-start">
                      <v-icon color="var(--Primary)" style="margin-right: 8px">mdi-map-marker-radius</v-icon>
                      <div v-html="check_facts[5].text"></div>
                    </div>

                    <div v-if="googlemaps.key !== ''">
                      <iframe
                          :width="googlemaps.width"
                          :height="googlemaps.height"
                          style="border:0"
                          loading="lazy"
                          allowfullscreen
                          :src="this.googlemaps.src+this.googlemaps.key+this.googlemaps.place"
                      >
                      </iframe>
                    </div>
                  </div>

                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- ------------------------------------- Location Infos Ende ------------------------------------- -->


              <!-- ------------------------------------- Aktivitäten Infos Anfang ------------------------------------- -->
              <v-expansion-panel v-if="check_facilities[1].acitivities">
                <v-expansion-panel-header>
                  <template v-slot:default="{ open }">
                    <div class="div-titleinfo-place">
                      <v-icon color="var(--Primary)">mdi-handball</v-icon>
                      <label class="label-infotitle-place">
                        <div v-html="check_facilities[1].title"></div>
                      </label>
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                  <div class="div-contentinfo-place">

                    <template v-for="item in check_facilities[1].text ">

                      <div style="display: flex; align-items: center;">
                        <v-icon color="var(--Primary)" large>mdi-circle-small</v-icon>
                        <div v-html="item"></div>
                      </div>
                    </template>

                  </div>

                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- ------------------------------------- Aktivitäten Infos Ende ------------------------------------- -->


              <!-- ------------------------------------- Essen & Trinken Infos Anfang ------------------------------------- -->
              <v-expansion-panel v-if="check_facilities[2].food">
                <v-expansion-panel-header>
                  <template v-slot:default="{ open }">
                    <div class="div-titleinfo-place">
                      <v-icon color="var(--Primary)">mdi-food-fork-drink</v-icon>
                      <label class="label-infotitle-place">
                        <div v-html="check_facilities[2].title"></div>
                      </label>
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                  <div class="div-contentinfo-place">
                    <template v-for="item in check_facilities[2].text ">
                      <div style="display: flex; align-items: center;">
                        <v-icon color="var(--Primary)" large>mdi-circle-small</v-icon>
                        <div v-html="item"></div>
                      </div>
                    </template>
                  </div>

                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- ------------------------------------- Essen & Trinken Infos Ende ------------------------------------- -->


              <!-- ------------------------------------- Kinder Infos Anfang ------------------------------------- -->
              <v-expansion-panel v-if="check_facilities[3].children">
                <v-expansion-panel-header>
                  <template v-slot:default="{ open }">
                    <div class="div-titleinfo-place">
                      <v-icon color="var(--Primary)">mdi-baby-face-outline</v-icon>
                      <label class="label-infotitle-place">
                        <div v-html="check_facilities[3].title"></div>
                      </label>
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                  <div class="div-contentinfo-place">
                    <template v-for="item in check_facilities[3].text ">
                      <div style="display: flex; align-items: center;">
                        <v-icon color="var(--Primary)" large>mdi-circle-small</v-icon>
                        <div v-html="item"></div>
                      </div>
                    </template>
                  </div>

                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- ------------------------------------- Kinder Infos Ende ------------------------------------- -->


              <!-- ------------------------------------- UNTERHALTUNG Infos Anfang ------------------------------------- -->
              <v-expansion-panel v-if="check_facilities[4].entertainment">
                <v-expansion-panel-header>
                  <template v-slot:default="{ open }">
                    <div class="div-titleinfo-place">
                      <v-icon color="var(--Primary)">mdi-drama-masks</v-icon>
                      <label class="label-infotitle-place">
                        <div v-html="check_facilities[4].title"></div>
                      </label>
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                  <div class="div-contentinfo-place">
                    <template v-for="item in check_facilities[4].text ">
                      <div style="display: flex; align-items: center;">
                        <v-icon color="var(--Primary)" large>mdi-circle-small</v-icon>
                        <div v-html="item"></div>
                      </div>
                    </template>
                  </div>

                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- ------------------------------------- UNTERHALTUNG Infos Ende ------------------------------------- -->


              <!-- ------------------------------------- Ausstattungen Infos Anfang ------------------------------------- -->
              <v-expansion-panel v-if="check_facilities[4].entertainment">
                <v-expansion-panel-header>
                  <template v-slot:default="{ open }">
                    <div class="div-titleinfo-place">
                      <v-icon color="var(--Primary)">mdi-home-city-outline</v-icon>
                      <label class="label-infotitle-place">
                        <div>{{ $t("Place.Ausstattungen") }}</div>
                      </label>
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                  <div class="div-contentinfo-place">


                    <div v-if="check_facilities[0].general">
                      <div class="div-titleinfo-place">
                        <v-icon color="var(--Primary)">mdi-home-city</v-icon>
                        <label class="label-infotitle-inner-place" v-html="check_facilities[0].title"></label>
                      </div>
                      <div v-html="check_facilities[0].text" style="margin-top: 15px !important;"></div>
                      <hr class="hr-horizontale-linie" style="margin: 20px 0;">
                    </div>


                    <div v-if="check_facilities[5].internet">
                      <div class="div-titleinfo-place">
                        <v-icon color="var(--Primary)">mdi-wifi</v-icon>
                        <label class="label-infotitle-inner-place" v-html="check_facilities[5].title"></label>
                      </div>
                      <div v-html="check_facilities[5].text" style="margin-top: 15px !important;"></div>

                      <hr class="hr-horizontale-linie" style="margin: 20px 0;">
                    </div>


                    <div v-if="check_facilities[6].parking">
                      <div class="div-titleinfo-place">
                        <v-icon color="var(--Primary)">mdi-car-brake-parking</v-icon>
                        <label class="label-infotitle-inner-place" v-html="check_facilities[6].title"></label>
                      </div>
                      <div v-html="check_facilities[6].text" style="margin-top: 15px !important;"></div>
                      <hr class="hr-horizontale-linie" style="margin: 20px 0;">
                    </div>


                    <div v-if="check_facilities[7].shops">
                      <div class="div-titleinfo-place">
                        <v-icon color="var(--Primary)">mdi-cart</v-icon>
                        <label class="label-infotitle-inner-place" v-html="check_facilities[7].title"></label>
                      </div>
                      <div v-html="check_facilities[7].text" style="margin-top: 15px !important;"></div>
                      <hr class="hr-horizontale-linie" style="margin: 20px 0;">
                    </div>


                    <div v-if="check_facilities[8].reception">
                      <div class="div-titleinfo-place">
                        <v-icon color="var(--Primary)">mdi-account-tie</v-icon>
                        <label class="label-infotitle-inner-place" v-html="check_facilities[8].title"></label>
                      </div>
                      <div v-html="check_facilities[8].text" style="margin-top: 15px !important;"></div>
                      <hr class="hr-horizontale-linie" style="margin: 20px 0;">
                    </div>


                    <div v-if="check_facilities[9].business">
                      <div class="div-titleinfo-place">
                        <v-icon color="var(--Primary)">mdi-briefcase-variant</v-icon>
                        <label class="label-infotitle-inner-place" v-html="check_facilities[9].title"></label>
                      </div>
                      <div v-html="check_facilities[9].text" style="margin-top: 15px !important;"></div>
                      <hr class="hr-horizontale-linie" style="margin: 20px 0;">
                    </div>


                    <div v-if="check_facilities[10].cleaning">
                      <div class="div-titleinfo-place">
                        <v-icon color="var(--Primary)">mdi-washing-machine</v-icon>
                        <label class="label-infotitle-inner-place" v-html="check_facilities[10].title"></label>
                      </div>
                      <div v-html="check_facilities[10].text" style="margin-top: 15px !important;"></div>
                      <hr class="hr-horizontale-linie" style="margin: 20px 0;">
                    </div>


                    <div v-if="check_facilities[11].transport">
                      <div class="div-titleinfo-place">
                        <v-icon color="var(--Primary)">mdi-taxi</v-icon>
                        <label class="label-infotitle-inner-place" v-html="check_facilities[11].title"></label>
                      </div>
                      <div v-html="check_facilities[11].text" style="margin-top: 15px !important;"></div>
                      <hr class="hr-horizontale-linie" style="margin: 20px 0;">
                    </div>


                  </div>

                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- ------------------------------------- Ausstattungen Infos Ende ------------------------------------- -->

              <!-- ------------------------------------- Wichtige Infos Anfang ------------------------------------- -->
              <v-expansion-panel v-if="check_facilities[15].notes">
                <v-expansion-panel-header>
                  <template v-slot:default="{ open }">
                    <div class="div-titleinfo-place">
                      <v-icon color="var(--Primary)">mdi-note-text-outline</v-icon>
                      <label class="label-infotitle-place">
                        <div v-html="check_facilities[15].title"></div>
                      </label>
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                  <div class="div-contentinfo-place">
                    <div v-html="check_facilities[15].text" style="white-space: pre-line; text-align: justify;"></div>
                  </div>

                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- ------------------------------------- Wichtige Infos Ende ------------------------------------- -->


            </v-expansion-panels>


          </div>

        </div>

        <!-- ----------------------------------- Links ENDE ----------------------------------------------------  -->

        <!-- ----------------------------------- Rechts Anfang----------------------------------------------------  -->


        <div class="div-body-right-place">
          <TotalPrice
              :checkin="checkin"
              :checkout="checkout"
              :halalPlace="halalPlace"
              :halalPlaceGroups="halalPlace.groups"
              :gruppe="gruppe"
              :selectedRoom="selectedRoom"
              :gesamtpreis="gesamtpreis"
              :checkinput="checkinput"
              :showbutton="true"
              :selectedRatePlan="selectedRatePlan"
          />


        </div>
      </div>
      <div class="div-btn-totalprice-place">
        <v-btn
            @click="zuBooking"
            class="vbtn-totalprice"
            :disabled="!checkinput"
            :color="!checkinput ? 'grey':'green'"
        >
          {{ $t('Button.WeiterZurBuchung') }}
        </v-btn>
      </div>
    </div>
  </div>

</template>

<script>
import Search from "@/components/search/Search";
import SearchSmall from "@/components/search/SearchSmall";
import Gallery from "@/components/gallery/Gallery";
import CardRoom from "@/components/card/CardRoom";
import CardRoom2 from "@/components/card/CardRoom2";
import CardRoom3 from "@/components/card/CardRoom3";
import CardRoom4 from "@/components/card/CardRoom4";
import {onePlaceWithParameter} from "@/util/halalapi";
import {getCurrencyIcon} from "@/util/currency";
import TotalPrice from "@/components/card/TotalPrice";
import {beachParse, beachSplit, parseFacilities, parsePool, spa} from "../util/placeParsingUtil";
import {getgoogleSetting} from "@/util/settingsUtil";
import {parseFacilities_DE} from "@/util/placeParsingUtil_DE";
import {parseFacilities_EN} from "@/util/placeParsingUtil_EN";
import moment from "moment";
import {getGruppe} from "@/util/groupsUtil";
import {getGroups} from "@/util/util";


export default {
  name: "Place",
  props: [
    'pLang',
    'pRolle',
    'pUser',
    'pUserinfo',
    'pDomain'
  ],
  components: {
    SearchSmall,
    Search,
    Gallery,
    CardRoom,
    CardRoom2,
    CardRoom3,
    CardRoom4,
    TotalPrice
  },
  data() {
    return {
      currencyicon: getCurrencyIcon(),

      userinfo: null,
      isadmin: true,
      ishotel: false,
      isreiseburo: false,


      panel: [0],
      gruppe: "",
      checkinput: false,

      selectedstartdate: "",
      selectedenddate: "",


      selectedRoom: [{text: this.$t("Place.KeinZimmer"), name: 'unset'}],
      gesamtpreis: 0,
      selectedRatePlan: [{name: "", preis: 0}],

      notificationsnackbar: false,
      notificationtext: "",
      notificationcolor: "grey",

      showGalleryPopup: false,
      loading: true,


      halalPlace: '',

      cardDesign: 1,

      checkin: "",
      checkout: "",
      groups: "",
      hb_facilities: [],
      hb_facts: [],
      hb_pools: [],
      hb_beach: [],
      hb_spa: [],


      googlemaps: {
        key: "",
        place: "&q=",
        width: "100%",
        height: "450",
        style: "border:0",
        src: "https://www.google.com/maps/embed/v1/place?key="

      },


      check_facilities: [
        {general: false, title: "", text: ""},
        {acitivities: false, title: "", text: []},
        {food: false, title: "", text: []},
        {children: false, title: "", text: []},
        {entertainment: false, title: "", text: []},
        {internet: false, title: "", text: ""},
        {parking: false, title: "", text: ""},
        {shops: false, title: "", text: ""},
        {reception: false, title: "", text: ""},
        {business: false, title: "", text: ""},
        {cleaning: false, title: "", text: ""},
        {transport: false, title: "", text: ""},
        {beach: false, title: "", text: ""},
        {spa: false, title: "", text: ""},
        {pools: false, title: "", text: ""},
        {notes: false, title: "", text: ""},
      ],

      check_facts: [
        {propertyType: false, title: "", text: ""},
        {stars: false, title: "", text: ""},
        {checkin: false, title: "", text: ""},
        {checkout: false, title: "", text: ""},
        {language: false, title: "", text: ""},
        {location: false, title: "", text: ""},
      ]


    };
  },
  methods: {
    getCurrencyIcon,
    zuBooking() {
      localStorage.setItem("gruppe", JSON.stringify(this.gruppe));
      localStorage.setItem("selectedroom", JSON.stringify(this.selectedRoom));
      let query = {
        ...this.$route.query,
        id: this.halalPlace.place.id
      }
      this.$router.push({path: "/booking", query: query});
    },

    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },

    checkFacts() {
      this.hb_facts.map(((item, index) => {
        let text = item.split(": ");

        if (text[0] === "Tesis Tipi" || text[0] === "Unterkunftstyp" || text[0] === "Property type") {
          this.check_facts[0].propertyType = true;
          this.check_facts[0].title = text[0];
          this.check_facts[0].text = text[1];
        }

        if (text[0] === "Yıldız sayısı" || text[0] === "Sterne" || text[0] === "Stars") {
          this.check_facts[1].stars = true;
          this.check_facts[1].title = text[0];
          this.check_facts[1].text = text[1];
        }

        if (text[0] === "Giriş" || text[0] === "Check-in") {
          this.check_facts[2].checkin = true;
          this.check_facts[2].title = text[0];
          this.check_facts[2].text = text[1];
        }
        if (text[0] === "Çıkış" || text[0] === "Check-out") {
          this.check_facts[3].checkout = true;
          this.check_facts[3].title = text[0];
          this.check_facts[3].text = text[1];
        }
        if (text[0] === "Konuşulan di̇ller" || text[0] === "Gesprochene Sprachen" || text[0] === "Languages spoken") {
          this.check_facts[4].language = true;
          this.check_facts[4].title = text[0];
          this.check_facts[4].text = text[1];
        }
        if (text[0] === "Konum" || text[0] === "Lage" || text[0] === "Location") {
          this.check_facts[5].location = true;
          this.check_facts[5].title = text[0];
          this.check_facts[5].text = text[1];
        }


      }));
    },

    checkFacilities() {

      this.hb_facilities.map((item) => {
        if (item.title.includes("GENERAL FACILITIES") || item.title.includes("ALLGEMEINE EINRICHTUNGEN") || item.title.includes("GENEL TESİS OLANAKLARI")) {

          this.check_facilities[0].general = true;
          item.title = item.title.replace(":", "");
          this.check_facilities[0].title = item.title;
          this.check_facilities[0].text = item.text;
        }

        if (item.title.includes("ACTIVITIES:") || item.title.includes("AKTIVITÄTEN:") || item.title.includes("ETKİNLİKLER:")) {
          this.check_facilities[1].acitivities = true;
          item.title = item.title.replace(":", "");
          this.check_facilities[1].title = item.title;

          let splittedText = item.text.split(",");
          this.check_facilities[1].text = splittedText;
        }

        if (item.title.includes("FOOD &amp; DRINKS:") || item.title.includes("SPEISEN &amp; GETRÄNKE:") || item.title.includes("YİYECEK &amp; İÇECEK:")) {
          this.check_facilities[2].food = true;
          item.title = item.title.replace(":", "");
          this.check_facilities[2].title = item.title;

          let splittedText = item.text.split(",");
          this.check_facilities[2].text = splittedText;
        }

        if (item.title.includes("FOR CHILDREN:") || item.title.includes("FÜR KINDER:") || item.title.includes("ÇOCUKLAR İÇİN:")) {
          this.check_facilities[3].children = true;
          item.title = item.title.replace(":", "");
          this.check_facilities[3].title = item.title;

          let splittedText = item.text.split(",");
          this.check_facilities[3].text = splittedText;
        }

        if (item.title.includes("ENTERTAINMENT:") || item.title.includes("UNTERHALTUNG:") || item.title.includes("EĞLENCELER:")) {
          this.check_facilities[4].entertainment = true;
          item.title = item.title.replace(":", "");
          this.check_facilities[4].title = item.title;

          let splittedText = item.text.split(",");
          this.check_facilities[4].text = splittedText;
        }

        if (item.title.includes("INTERNET:") || item.title.includes("INTERNET:") || item.title.includes("İNTERNET:")) {
          this.check_facilities[5].internet = true;
          item.title = item.title.replace(":", "");
          this.check_facilities[5].title = item.title;
          this.check_facilities[5].text = item.text;
        }

        if (item.title.includes("PARKING:") || item.title.includes("PARKPLATZ:") || item.title.includes("PARK YERİ:")) {
          this.check_facilities[6].parking = true;
          item.title = item.title.replace(":", "");
          this.check_facilities[6].title = item.title;
          this.check_facilities[6].text = item.text;
        }

        if (item.title.includes("SHOPS:") || item.title.includes("GESCHÄFTE:") || item.title.includes("DÜKKANLAR:")) {
          this.check_facilities[7].shops = true;
          item.title = item.title.replace(":", "");
          this.check_facilities[7].title = item.title;
          this.check_facilities[7].text = item.text;
        }

        if (item.title.includes("RECEPTION SERVICES:") || item.title.includes("REZEPTIONSSERVICE:") || item.title.includes("RESEPSİYON HİZMETLERİ:")) {
          this.check_facilities[8].reception = true;
          item.title = item.title.replace(":", "");
          this.check_facilities[8].title = item.title;
          this.check_facilities[8].text = item.text;
        }

        if (item.title.includes("BUSINESS FACILITIES:") || item.title.includes("BUSINESS-EINRICHTUNGEN:") || item.title.includes("ÇALIŞMA ALANLARI:")) {
          this.check_facilities[9].business = true;
          item.title = item.title.replace(":", "");
          this.check_facilities[9].title = item.title;
          this.check_facilities[9].text = item.text;
        }

        if (item.title.includes("CLEANING SERVICES:") || item.title.includes("REINIGUNGSSERVICE:") || item.title.includes("TEMİZLİK HİZMETLERİ:")) {
          this.check_facilities[10].cleaning = true;
          item.title = item.title.replace(":", "");
          this.check_facilities[10].title = item.title;
          this.check_facilities[10].text = item.text;
        }

        if (item.title.includes("TRANSPORT:") || item.title.includes("TRANSPORT:") || item.title.includes("ULAŞIM:")) {
          this.check_facilities[11].transport = true;
          item.title = item.title.replace(":", "");
          this.check_facilities[11].title = item.title;
          this.check_facilities[11].text = item.text;
        }

        if (item.title.includes("BEACHES:") || item.title.includes("STRÄNDE:") || item.title.includes("PLAJLAR:")) {
          this.check_facilities[12].beach = true;
          item.title = item.title.replace(":", "");
          this.check_facilities[12].title = item.title;
          this.check_facilities[12].text = item.text;

        }

        if (item.title.includes("WELLNESS & SPA:") || item.title.includes("SAĞLIK & SPA:")) {
          this.check_facilities[13].spa = true;
          item.title = item.title.replace(":", "");
          this.check_facilities[13].title = item.title;
          this.check_facilities[13].text = item.text;
        }

        if (item.title.includes("POOLS:") || item.title.includes("HAVUZLAR:")) {
          this.check_facilities[14].pools = true;
          item.title = item.title.replace(":", "");
          this.check_facilities[14].title = item.title;
          this.check_facilities[14].text = item.text;
        }

        if (item.title.includes("IMPORTANT NOTES:") || item.title.includes("WICHTIGE HINWEISE:") || item.title.includes("ÖNEMLİ NOT VE KURALLAR:")) {
          this.check_facilities[15].notes = true;
          item.title = item.title.replaceAll(":", "");
          this.check_facilities[15].title = item.title;
          this.check_facilities[15].text = item.text;


        }

      });

    },
    calculatePrice() {
      let gprice = 0;
      this.selectedRoom.map(it => {
        if (it.price !== undefined) {
          gprice += it.price;
        }
      });
      this.gesamtpreis = gprice;
    },

    checkGroupRooms() {
      this.checkinput = false;
      let selRoom = this.selectedRoom.filter(item => item.room === undefined);
      if (selRoom.length === 0) {
        this.checkinput = true;
      }

      /*this.selectedRoom.map(it => {
        if (it.room === undefined) {
          this.checkinput = true;
        }
      });*/

    },


    googleMaps() {

      //Google Maps Iframe
      this.googlemaps.place = "&q=";
      this.googlemaps.place += this.halalPlace.place.name.replace('&', '%26');
      this.googlemaps.place += ", " + this.halalPlace.place.location.country;

    }
  },


  async created() {
    window.scrollTo(0, 2);

    let id = this.$route.query.id;

    if (localStorage.getItem('groups') !== null) {
      this.groups = localStorage.getItem('groups');
    } else {
      this.groups = '&groups[]=2';
    }

    // ------------------------------------ DATUM anzeigen ------------------------------------


    if (localStorage.getItem('c-in') !== null) {
      let c_in = new Date(localStorage.getItem('c-in'));
      if (c_in < new Date()) {

        let d = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 16);
        this.checkin = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
        localStorage.removeItem('c-in');
        localStorage.removeItem('c-out');
      } else {
        this.checkin = moment(c_in).format("YYYY-MM-DD");
      }

    } else {
      let d = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 16);
      this.checkin = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();

    }

    if (localStorage.getItem('c-out') !== null) {
      let c_out = new Date(localStorage.getItem('c-out'));

      this.checkout = moment(c_out).format("YYYY-MM-DD");
    } else {
      let d1 = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 18);
      this.checkout = d1.getFullYear() + "-" + (d1.getMonth() + 1) + "-" + d1.getDate();

    }


    let place = await onePlaceWithParameter(id, this.checkin, this.checkout, "&" + this.groups);
    this.halalPlace = place;
    this.$root.$on("place", (val) => {
      this.halalPlace = val;

      this.gruppe = JSON.parse(localStorage.getItem("gruppe"));

      let tmp = [];
      this.gruppe.map((it, index) => {
        tmp.push({text: this.$t("Place.KeinZimmer"), name: "unset"})
      });
      this.selectedRoom = tmp;

      // this.checkGroupRooms();
    });


    //-----------------------------------------------------------------Google Maps ----------------------------------
    // let user = await getUser(localStorage.getItem("token"));
    let settings = await getgoogleSetting();
    this.googlemaps.key = settings.googleMapsKey;

    this.googleMaps();


    if (this.pLang === "TR") {
      this.hb_facilities = await parseFacilities(this.halalPlace.place.facilities);
    }
    if (this.pLang === "DE") {
      this.hb_facilities = await parseFacilities_DE(this.halalPlace.place.facilities);
    }
    if (this.pLang === "EN") {
      this.hb_facilities = await parseFacilities_EN(this.halalPlace.place.facilities);
    }

    this.hb_facts = this.halalPlace.place.facts.split("\n");
    let pooIndex = -1;
    let beachIndex = -1;
    let spaIndex = -1;
    this.hb_facilities.map((item, index) => {
      if (item.title.includes("HAVUZLAR:") || item.title.includes("POOLS:")) {
        pooIndex = index;
      }
      if (item.title.includes("PLAJLAR:") || item.title.includes("STRÄNDE:") || item.title.includes("BEACHES:")) {
        beachIndex = index;
      }
      if (item.title.includes("SAĞLIK & SPA:") || item.title.includes("WELLNESS & SPA:")) {
        spaIndex = index;
      }

    });
    if (pooIndex !== -1) {
      this.hb_pools = await parsePool(this.hb_facilities[pooIndex].text);

    }
    if (beachIndex !== -1) {
      let beach = await beachParse(this.hb_facilities[beachIndex].text);
      this.hb_beach = await beachSplit(beach);
    }
    if (spaIndex !== -1) {
      this.hb_spa = await spa(this.hb_facilities[spaIndex].text);
    }

    await this.checkFacilities();
    await this.checkFacts();


    this.gruppe = JSON.parse(localStorage.getItem("gruppe"));
    if (this.gruppe === null) {
      this.gruppe = [{name: "Gruppe 1", erwachsene: 2, kinder: 0, kinderalter: []}];
    }
    let tmp = [];
    this.gruppe.map((it, index) => {
      tmp.push({text: this.$t("Place.KeinZimmer"), name: "unset"})
    });
    this.selectedRoom = tmp;


    if (this.pUserinfo.roomcard) {
      this.cardDesign = this.pUserinfo.roomcard;
    } else {
      this.cardDesign = '1'
    }


    this.$root.$on("selectedroom", (val, grpindex) => {
      this.selectedRoom[grpindex] = val;

      if (this.selectedRoom.length > 1) {
        if ((grpindex + 1) < this.selectedRoom.length) {
          this.panel = [grpindex + 1];
        } else {
          this.panel = [];
        }
      } else {
        this.panel = [0];
      }

      this.calculatePrice();
      this.checkGroupRooms();
      this.$forceUpdate();
    });
    // this.checkGroupRooms();


    this.selectedRatePlan = [];
    let arr = [];
    this.gruppe.map((it, index) => {
      arr.push({name: "", preis: 0});
    });
    this.selectedRatePlan = arr;


    this.$root.$on("stopScrolling", (val) => {
      this.stopScrolling = val;
    });

    this.loading = false;


  }
};
</script>
<style scoped>
@import "../css/Place.css";
</style>