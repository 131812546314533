import axios from "axios";
import moment from "moment";


export const getCurrencyValues = async (key) => {
    let today = moment().format("YYYY-MM-DD");
    let values = {
        key: key
    }
    let res = await axios.post(process.env.VUE_APP_DB + "/api2/cvalues/", values, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});


    return res.data.data.data;

    // Wenn Efe bei Currency meckert
    // return res.data.data.rates[today]
}

export const calculateCurrency = (currency, newcurrency, currencyValues, price) => {

    if (price === undefined || currencyValues === "" || newcurrency === "" || currency === "" ||
        currencyValues === undefined || newcurrency === undefined || currency === undefined) {
        return 0;
    }

    let cvalue = currencyValues[newcurrency.toUpperCase()].value;
    let oldvalue = currencyValues[currency.toUpperCase()].value;


    // Wenn Efe bei Currency meckert
    // let cvalue = currencyValues[newcurrency.toUpperCase()];
    // let oldvalue = currencyValues[currency.toUpperCase()];

    //

    if (currency === "USD") {

        if (newcurrency !== "USD") {
            return price * cvalue;
        } else {
            return price;
        }
    }


    if (currency === "EUR") {
        if (newcurrency === "TRY") {
            return calculatePrice(price, oldvalue, cvalue);
        }

        if (newcurrency === "USD") {
            return price / oldvalue;

        }
        return price;

    }

    if (currency === "TRY") {
        if (newcurrency === "EUR") {
            return calculatePrice(price, oldvalue, cvalue);
        }

        if (newcurrency === "USD") {
            return price / oldvalue;

        }
        return price;

    }

    return price;


}

const calculatePrice = (price, oldvalue, cvalue) => {

    let zp = price / oldvalue;
    return zp * cvalue;
}