import {getAllPlaces, getPlaceBylang} from "./placeUtil";
import {parseFacilities} from "./placeParsingUtil";
import Facility from "./FacilityClass";
import {parseFacilities_DE} from "@/util/placeParsingUtil_DE";
import {parseFacilities_EN} from "@/util/placeParsingUtil_EN";


export const getValues = async () => {
    //let places = await getAllPlaces();
    let places = await getPlaceBylang();
    return places;


}

export const create = async (places) => {

    let allFacilities = [];
    places.map(p => {

            allFacilities.push(p.place.place.facilities);

        }
    )

    let util = new Facility();

    await allFacilities.map(async aFac => {
            let allFac = await parseFacilities_DE(aFac)

            allFac.map(a => {

                util.add(a);
            })


        }
    )


    return util;
}


export const parseOnlybyComma = (items) => {

    let tmp = [];
    items.map(it => {

        let sp = it.text.split(",");
        sp.map(i => {
            if (!tmp.some(e => e.text === i.trim())) {
                tmp.push({text: i.trim()});
            }
        })
    })

    return tmp;
}