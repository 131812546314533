<template>
  <div>
    <v-snackbar
        v-model="notificationsnackbar"
        :timeout="6000"
        :color="notificationcolor"
        :top=true
        :right=true
        :vertical=true
    >
      {{ notificationtext }}
      <v-btn
          color="white"
          text
          @click="notificationsnackbar = false"
      >
        {{ $t("NotificationSchliessen") }}
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>


    <div class="div-setting1" id="SettingsContact">

      <v-overlay :value="loading">
        <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
            :size="50"
            :width="7"
        />
      </v-overlay>
      <h2>{{ $t("Setting.Kontakt") }}</h2>


      <label><b>{{ $t("Setting.Firma") }}</b></label>

      <v-text-field
          :label=" $t('Setting.Unternehmensname')"
          v-model="firma"
          outlined
          color=var(--Primary)
          @change="settingsObject"
      />

      <br>
      <v-divider></v-divider>
      <br>

      <label><b>{{ $t("Setting.Ansprechpartner") }}</b></label>
      <v-text-field
          :label=" $t('Setting.Vorname')"
          v-model="vname"
          outlined
          color=var(--Primary)
          @change="settingsObject"
      />
      <v-text-field
          :label=" $t('Setting.Nachname')"
          v-model="nname"
          outlined
          color=var(--Primary)
          @change="settingsObject"
      />

      <br>
      <v-divider></v-divider>
      <br>

      <label><b>{{ $t("Setting.Postadresse") }}</b></label>

      <v-text-field
          :label=" $t('Setting.StrNR')"
          v-model="strnr"
          outlined
          color=var(--Primary)
          @change="settingsObject"
      />
      <v-text-field
          :label=" $t('Setting.Adresszusatz')"
          v-model="adressz"
          outlined
          color=var(--Primary)
          @change="settingsObject"
      />

      <v-text-field
          :label=" $t('Setting.PLZ')"
          v-model="plz"
          outlined
          color=var(--Primary)
          @change="settingsObject"
      />
      <v-text-field
          :label=" $t('Setting.Stadt')"
          v-model="ort"
          outlined
          color=var(--Primary)
          @change="settingsObject"
      />
      <v-text-field
          :label=" $t('Setting.Land')"
          v-model="land"
          outlined
          color=var(--Primary)
          @change="settingsObject"
      />

      <br>
      <v-divider></v-divider>
      <br>


      <label><b>{{ $t("Setting.Kontakt") }}</b></label>
      <v-text-field
          :label=" $t('Setting.Telefon')"
          v-model="tel"
          outlined
          color=var(--Primary)
          @change="settingsObject"
      />
      <v-text-field
          :label=" $t('Setting.Mobile')"
          v-model="mobile"
          outlined
          color=var(--Primary)
          @change="settingsObject"
      />
      <v-text-field
          :label=" $t('Setting.Fax')"
          v-model="fax"
          outlined
          color=var(--Primary)
          @change="settingsObject"
      />
      <v-text-field
          :label=" $t('Setting.Email')"
          v-model="email"
          outlined
          color=var(--Primary)
          @change="settingsObject"
      />


      <template v-if="pRolle === 'admin' || pRolle ==='superadmin'">


        <br>
        <v-divider></v-divider>

        <label><b>Social-Media (Footer)</b></label>

        <div class="div-social-grid-settingscontact">
          <label>www.facebook.com/</label>
          <v-text-field
              label="Facebook"
              placeholder="url"
              v-model="facebook"
              outlined
              color=var(--Primary)
              @change="settingsObject"
          />
        </div>

        <div class="div-social-grid-settingscontact">
          <label>www.instagram.com/</label>
          <v-text-field
              label="Instagram"
              placeholder="url"
              v-model="instagram"
              outlined
              color=var(--Primary)
              @change="settingsObject"
          />
        </div>

        <div class="div-social-grid-settingscontact">
          <label>www.youtube.com/</label>
          <v-text-field
              label="Youtube"
              placeholder="url"
              v-model="youtube"
              outlined
              color=var(--Primary)
              @change="settingsObject"
          />
        </div>

        <div class="div-social-grid-settingscontact">
          <label>www.twitter.com/</label>
          <v-text-field
              label="Twitter"
              placeholder="url"
              v-model="twitter"
              outlined
              color=var(--Primary)
              @change="settingsObject"
          />
        </div>

      </template>

      <br>
      <v-divider></v-divider>
      <br>

      <label><b>Logo</b></label>
      <img class="img-logo-nav" :src="logoanzeigen" alt="Logo"/>
      <v-btn @click="deleteLogo" outlined color="var(--Primary)">Neues Logo</v-btn>
      <div class="div-img-upload-createuser" v-if="showNeuesLogo">

        <label>max. 500 KB</label>
        <input type="file" name="file" @change="this.onFileHandler"/>
        <br/>
        <div className="form-group">
          <Progress
              max="100" color="success"
              :value="this.loaded"
          >{{ Math.round(this.loaded, 2) }}%
          </Progress>

        </div>
        <br/>
        <v-btn
            class="ma-2"
            color=var(--Primary)
            style="color: white"
            @click="onClickHandler"
            :disabled="disableupload"
        >
          Upload
        </v-btn>
      </div>
      <v-divider></v-divider>
      <br>

      <div class="div-button-save-createuser">

        <v-btn
            class="ma-2"
            color=var(--Primary)
            style="color: white"
            @click="savecontact"
        >
          {{ $t("Button.Speichern") }}
        </v-btn>
      </div>


    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {getUser} from '../../util/Auth';
import {deleteFile, getUserinfoByUserId, updateUserInfo} from "../../util/userinfoDBUtil";
import {getCountries} from "@/util/Countries";

export default {
  name: "SettingsContact",
  props: [
    'pUserinfo',
    'pRolle',
  ],
  data() {
    return {
      notificationsnackbar: false,
      notificationtext: "",
      notificationcolor: "",

      loading: false,
      userinfo: {},
      firma: "",
      vname: "",
      nname: "",
      strnr: "",
      adressz: "",
      plz: "",
      ort: "",
      land: "",
      tel: "",
      mobile: "",
      fax: "",
      email: "",

      facebook: "",
      instagram: "",
      youtube: "",
      twitter: "",


      loaded: 0,


      selectedFile: "",
      logo: "",
      logoanzeigen: "",
      showNeuesLogo: false,
      disableupload: false,

    }
  },
  methods: {
    notification(text, color) {
      this.notificationsnackbar = true;
      this.notificationtext = text;
      this.notificationcolor = color;
    },
    settingsObject(set) {

      let settings = {
        ...set,
        kontakt: {
          firma: this.firma,
          vname: this.vname,
          nname: this.nname,
          strnr: this.strnr,
          adressz: this.adressz,
          plz: this.plz,
          ort: this.ort,
          land: this.land,
          tel: this.tel,
          mobile: this.mobile,
          fax: this.fax,
          email: this.email,
          facebook: this.facebook,
          instagram: this.instagram,
          youtube: this.youtube,
          twitter: this.twitter,

        },
        logo: this.logo
      }

      this.$store.dispatch('settings', settings);
      return settings;
    },


    onFileHandler(event) {

      // if (this.selectedFile === "") {
      this.loading = true;
      // }

      let file = event.target.files[0]
      if (this.checkMimeType(event) && this.checkFileSize(event)) {
        // if return true allow to setState
        this.selectedFile = file;
      }
    },

    checkMimeType(event) {
      //getting file object
      let file = event.target.files[0];
      //define message container
      let err = ''
      // list allow mime type
      const types = ['image/png', 'image/jpeg', 'image/gif']
      // loop access array

      // compare file type find doesn't matach
      if (types.every(type => file.type !== type)) {
        // create error message and assign to container
        err += file.type + ' is not a supported format\n';

        this.notificationsnackbar = true;
        this.notificationtext = this.$t("NotificationFormatfalsch");
        this.notificationcolor = "error";

      }


      if (err !== '') { // if message not same old that mean has error
        event.target.value = null // discard selected file
        return false;
      }
      return true;

    },

    checkFileSize(event) {
      let file = event.target.files[0]
      let size = 500000
      let err = "";

      if (file.size > size) {
        err += file.type + 'Datei zu groß, bitte die maximale größe beachten.\n';

        this.notificationsnackbar = true;
        this.notificationtext = this.$t("NotificationBildzugross500kb");
        this.notificationcolor = "error";

      } else {
        this.error = "";
      }

      this.loading = false;

      if (err !== '') {
        event.target.value = null
        this.error = err;
        return false
      }
      return true;
    },

    async onClickHandler() {
      if (this.error === "" && this.selectedFile !== null) {
        // let fileUploadData = await fileupload(this.selectedFile, this.loaded);

        const data = new FormData()
        let fileUploadData = {};
        data.append('file', this.selectedFile)
        await axios.post(process.env.VUE_APP_DB + "/api2/uploads", data, {

          headers: {
            "Accept": "application/json",
            'Access-Control-Allow-Origin': '*',
            "Access-Control-Allow-Headers": "Origin,Content-Type,X-Requested-With,Accept,Authorization",
            'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PATCH, PUT',
            "authorization": 'Bearer ' + localStorage.getItem('token')
          },

          onUploadProgress: ProgressEvent => {
            this.loaded = (ProgressEvent.loaded / ProgressEvent.total * 100);
          },

        }).then(res => { // then print response status
          fileUploadData = res.data;
        })

        this.logo = {picture: fileUploadData.filename, pictureinfo: fileUploadData.originalname};
        if (fileUploadData.filename !== "") {
          this.disableupload = true;
        }

      }
    },
    async savecontact() {


      let user = await getUser(localStorage.getItem("token"));
      let userinfo = await getUserinfoByUserId(user._id);
      let ui = this.settingsObject(userinfo);
      await updateUserInfo(ui);
      // this.userinfo = ui;


      this.notification(this.$t("NotificationSpeichernErfolgreich"), "green");

      await new Promise(resolve => setTimeout(resolve, 500));
      location.reload();

    },

    async deleteLogo() {
      if (this.logo !== undefined) {
        let res = await deleteFile(this.logo.picture);

        if (res.status === 200) {
          this.logo = "";
          this.notificationsnackbar = true;
          this.notificationtext = this.$t("NotificationFotoGeloescht");
          this.notificationcolor = "green";

        }
        // this.showNeuesLogo = true;
      }

      this.showNeuesLogo = true;
    }

  },
  async mounted() {
    // async created() {
    // let user = await getUser(localStorage.getItem("token"));

    await new Promise(resolve => setTimeout(resolve, 200));

    let userinfo = this.pUserinfo;


    this.firma = userinfo.kontakt.firma;
    this.vname = userinfo.kontakt.vname;
    this.nname = userinfo.kontakt.nname;
    this.strnr = userinfo.kontakt.strnr;
    this.adressz = userinfo.kontakt.adressz;
    this.plz = userinfo.kontakt.plz;
    this.ort = userinfo.kontakt.ort;
    this.land = userinfo.kontakt.land;
    this.tel = userinfo.kontakt.tel;
    this.mobile = userinfo.kontakt.mobile;
    this.fax = userinfo.kontakt.fax;
    this.email = userinfo.kontakt.email;

    if (userinfo.kontakt.facebook !== '' && userinfo.kontakt.facebook !== undefined && userinfo.kontakt.facebook !== null) {
      this.facebook = userinfo.kontakt.facebook;
    }
    if (userinfo.kontakt.instagram !== '' && userinfo.kontakt.instagram !== undefined && userinfo.kontakt.instagram !== null) {
      this.instagram = userinfo.kontakt.instagram;
    }
    if (userinfo.kontakt.youtube !== '' && userinfo.kontakt.youtube !== undefined && userinfo.kontakt.youtube !== null) {
      this.youtube = userinfo.kontakt.youtube;
    }
    if (userinfo.kontakt.twitter !== '' && userinfo.kontakt.twitter !== undefined && userinfo.kontakt.twitter !== null) {
      this.twitter = userinfo.kontakt.twitter;
    }


    this.logo = userinfo.logo;
    if (this.logo !== undefined) {
      this.logoanzeigen = process.env.VUE_APP_IMAGES + this.logo.picture;
    }
  }
}
</script>

<style scoped>


</style>
