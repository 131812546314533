<template>
  <div>
    <v-overlay v-if="overlayPhotoUpload">
      <div class="div-overlay-photo-settingsfavorites">

        <v-btn text color="black" style="position: absolute; right: 2px; top: 2px; z-index: 1"
               @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <h3 class="label-overlay-settingsfavorites">Bild hochladen</h3>
        <div class="div-overlay-photo-settingsfavorites1">
          <div>max. 200 KB</div>
          <input type="file" name="file" @change="this.onFileHandler"/>
          <br/>
          <div className="form-group">
            <Progress max="100" color="success"
                      :value="this.loaded">{{ Math.round(this.loaded, 2) }}%
            </Progress>

            <div class="div-savebtns-settingsfavorites">
              <v-btn @click="overlayPhotoUpload = false" color="error" outlined>Abbrechen</v-btn>
              <v-btn @click="savePhoto" color="var(--Primary)">Speichern</v-btn>
            </div>

          </div>
        </div>
      </div>

    </v-overlay>
  </div>
</template>

<script>

import {deleteFile} from "@/util/userinfoDBUtil";
import {fileupload} from "@/util/fileUtil";
import {updateSettings} from "@/util/settingsUtil";

export default {
  name: "OverlayPhotoUpload",
  props: [
    'overlayPhotoUpload',
    'from',
    'pSetting'
  ],

  data() {
    return {
      loading: false,
      logo: "",
      photo: "",

      selectedFile: "",
      loaded: "",

    }
  },

  methods: {
    close() {

      this.overlayPhotoUpload = false;
      this.$root.$emit('closePhotoUpload', false);
    },

    onFileHandler(event) {
      let file = event.target.files[0]
      if (this.checkMimeType(event) && this.checkFileSize(event)) {
        // if return true allow to setState
        this.selectedFile = file;
      }
    },

    checkMimeType(event) {
      //getting file object
      let file = event.target.files[0];
      //define message container
      let err = ''
      // list allow mime type
      const types = ['image/png', 'image/jpeg', 'image/gif']
      // loop access array

      // compare file type find doesn't matach
      if (types.every(type => file.type !== type)) {
        // create error message and assign to container
        err += file.type + ' is not a supported format\n';


        this.notification(this.$t("NotificationFormatfalsch"), 'error');


      }


      if (err !== '') { // if message not same old that mean has error
        event.target.value = null // discard selected file
        return false;
      }
      return true;

    },

    checkFileSize(event) {
      let file = event.target.files[0]
      let size = 200000
      let err = "";

      if (file.size > size) {
        err += file.type + 'Datei zu groß, bitte die maximale größe beachten.\n';

        this.notification(this.$t("NotificationBildzugross") + 'max 200 KB', 'error');


      } else {
        this.error = "";
      }

      if (err !== '') {
        event.target.value = null
        this.error = err;
        return false
      }
      return true;
    },

    async onClickHandler() {
      if (this.error === "" && this.selectedFile !== null) {
        // await this.deletePhoto();
        let fileUploadData = await fileupload(this.selectedFile);
        this.photo = {picture: fileUploadData.filename, pictureinfo: fileUploadData.originalname};
      }

    },

    async deletePhoto(item) {
      if (item === undefined) {
        item = this.item;
      }

      if (item !== undefined) {

        if (item !== null) {
          let foto = item.photo;

          foto = foto.replace("//", "");
          foto = foto.split("/")[1];

          let res = await deleteFile(foto);

          if (res.status === 200) {
            this.notification("Foto gelöscht", "green");

            await new Promise(resolve => setTimeout(resolve, 500));
            // location.reload();

          }
        } else {
          this.notification("Fehler", "error");
        }


      }


    },


    async savePhoto() {
      await this.onClickHandler();

      let setting = this.pSetting;
      let s = {...setting}
      if (this.from === 'settingsdesign') {
        let landingpagephotos = s.landingpagephotos;
        if (landingpagephotos === undefined) {
          landingpagephotos = [];

        }

        this.photo.photo = process.env.VUE_APP_IMAGES + this.photo.picture;
        landingpagephotos.push(this.photo);
        s = {
          ...setting,
          landingpagephotos: landingpagephotos
        }
      } else {
        // await this.onClickHandler();

        this.settingFavLoc.map(location => {
          if (location._id === this.item._id) {
            location.photo = process.env.VUE_APP_IMAGES + this.photo.picture;
          }
        })

        this.settingFavcountry.map(location => {
          if (location._id === this.item._id) {
            location.photo = process.env.VUE_APP_IMAGES + this.photo.picture;
          }
        })
        this.settingFavcity.map(location => {
          if (location._id === this.item._id) {
            location.photo = process.env.VUE_APP_IMAGES + this.photo.picture;
          }
        })


        s = {
          ...setting,
          favorite_location: this.settingFavLoc,
          favorite_country: this.settingFavcountry,
          favorite_city: this.settingFavcity
        }
      }
      await updateSettings(s);
      // this.photo = "";
      // this.item = "";
      // this.overlayPhotoUpload = false;
      location.reload();
    }


  }
}
</script>

<style scoped>
@import "../../css/Overlay.css";
</style>