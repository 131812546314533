<template>
  <div>
    Test
    <div>
      <PicturePool></PicturePool>
    </div>
    <div style="margin: 20px">

      <h2>Hotels in die DB Speichern</h2>

      <br>
      <v-btn @click="getHalalHotel">Speichern</v-btn>
      <br>
      <v-btn @click="addFacalities">facalities save</v-btn>


    </div>

  </div>
</template>

<script>
import {getHalalAllForOwnDB} from "@/util/halalapi";
import {addPlace} from "@/util/placeUtil";
import json_de from "@/json/facilities_de.json";
import json_tr from "@/json/facilities_tr.json";
import json_en from "@/json/facilities_en.json";
import {getAllSettings, updateSettings} from "@/util/settingsUtil";
import PicturePool from "@/components/picture_pool/PicturePool";

export default {
  name: "Test",
  components: {
    PicturePool
  },

  data() {
    return {
      allData: [],
      total_pages: 0,
      facilities_de: {},
      facilities_tr: {},
      facilities_en: {},

    }
  },
  methods: {

    async getHalalHotel() {
      // Daten in die DB speichern
      for (let i = 1; i <= (this.total_pages / 3); i++) {
        // for (let i = 1; i <= 5; i++) {


        let arr = await getHalalAllForOwnDB(i);

        //this.allData.push(arr);
        // console.log("Seite: " + i + " Anzahl: " + arr.offers.length);
        for (let k = 0; k < arr.offers.length; k++) {
          let obj = arr.offers[k];
          delete obj.place.photos;
          delete obj.place.board_basis;
          delete obj.place.cancellation_policy;
          delete obj.place.checkin_time;
          delete obj.place.checkout_time;
          // delete obj.place.facilities;
          delete obj.place.facts;
          delete obj.place.overview;
          delete obj.place.rate_plan;
          delete obj.place.photo;
          delete obj.href;
          delete obj.min_price;
          delete obj.quantity;
          delete obj.groups;


          await addPlace(obj);

          // console.log(obj);
        }
      }
      // console.log("Daten: ");


      /* let data = await getALLHalalPlaces(1);
   this.total_pages = data.total_pages;

   await addPlace(data.offers[0]);*/
// }


    },
    async addFacalities() {
      let sett = await getAllSettings();
      let s = {
        ...sett,
        facilities_de: this.facilities_de,
        facilities_tr: this.facilities_tr,
        facilities_en: this.facilities_en
      }

      await updateSettings(s);
    }
  },
  async created() {

    let data = await getHalalAllForOwnDB(1);


    this.total_pages = data.total_pages;
    //await this.getHalalHotel();

    //await addPlace(data.offers[0]);
    // await getAllPlaces();
    this.facilities_de = json_de;
    this.facilities_tr = json_tr;
    this.facilities_en = json_en;

  }
}
</script>
<style scoped>

</style>
