import axios from "axios";

export const isHotelname = (searcharray, hotelname) => {
    let place = searcharray.find(item => item.place.name.toUpperCase() === hotelname.toUpperCase());

    return place;
}

export const halalsearch = async (search) => {
    let lang = localStorage.getItem('lang');
    let res = await axios.get("https://" + lang.toLowerCase() + ".halalbooking.com/search/destinations?term=" + search);
    return res.data;
}


export const halalsearchTR = async (search) => {
    let res = await axios.get("https://tr.halalbooking.com/search/destinations?term=" + search);
    return res.data;
}


export const halalsearchDE = async (search) => {
    let res = await axios.get("https://de.halalbooking.com/search/destinations?term=" + search);
    return res.data;
}

export const halalsearchEN = async (search) => {
    let res = await axios.get("https://en.halalbooking.com/search/destinations?term=" + search);
    return res.data;
}