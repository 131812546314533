<template>
  <div>
    <div class="div-serachsmall">

      <v-snackbar
          v-model="notificationsnackbar"
          :timeout="6000"
          :color="notificationcolor"
          :top=true
          :right=true
          :vertical=true
      >
        {{ notificationtext }}
        <v-btn
            color="white"
            text
            @click="notificationsnackbar = false"
        >
          {{ $t("Notification.Schliessen") }}
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-snackbar>


      <!-- ------------------------------ DATES Anfang  ------------------------------ -->

      <div class="div-date-serachsmall" @click="closePersons">
        <HotelDatePicker
            :i18n="getLangDatePicker"
            format='DD/MM/YYYY'
            :startDate="minDate"
            @check-in-changed="updateCheckIn"
            @check-out-changed="updateCheckOut"
            :firstDayOfWeek=1
            :minNights="1"
            :startingDateValue="selectedcheckin ==='' ? undefined:selectedcheckin "
            :endingDateValue="selectedcheckout ==='' ? undefined: selectedcheckout"
        />
      </div>


      <!-- ------------------------------ DATES Ende  ------------------------------ -->


      <!-- ------------------------------ Persons Anfang  ------------------------------ -->

      <div class="div-persons-serachsmall">
        <div @click="openPersons" class="div-labels-persons-serachsmall">

          <v-icon color="var(--Primary)">mdi-account-supervisor</v-icon>
          <div class="div-labels-persons-serachsmall1">
            <label class="label-searchpersonsheader-serachsmall text-align-left">
              {{ anzahlPersonen !== 1 ? anzahlPersonen + ' ' + $t("Search.Personen") : 1 + ' ' + $t("Search.Person") }}
              {{ gruppen.length > 1 ? '(' + gruppen.length + ' ' + $t("Search.Gruppen") + ')' : '' }}
            </label>
          </div>

        </div>

        <transition name="fade">
          <div v-if="showPersons" class="div-dd-persons-serachsmall">

            <template v-for="(item,index) in gruppen">
              <div class="div-grid-persons-serachsmall">
                <div class="div-personsgroup-serachsmall">
                  <label class="label-group-serachsmall">{{ $t("Search.Gruppe") }} {{ index + 1 }}</label>
                  <span></span>
                  <v-btn
                      class="vbtn-entfernen-serachsmall"
                      x-small
                      color="error"
                      @click="removeGroup(index)"
                      :disabled="gruppen.length === 1"
                  >
                    {{ $t("Button.Entfernen") }}
                  </v-btn>
                </div>
                <div class="div-addpersons-serachsmall">
                  <div>
                    {{ $t("Search.Erwachsene") }}
                    <div class="div-addpersons-serachsmall1">

                      <v-btn
                          class="mx-2"
                          text
                          dark
                          x-small
                          color="primary"
                          @click="removeAdult(index)"
                          :disabled="gruppen[index].erwachsene === 1"
                      >
                        <v-icon dark>
                          mdi-minus
                        </v-icon>
                      </v-btn>
                      <label>{{ item.erwachsene }}</label>

                      <v-btn
                          class="mx-2"
                          text
                          dark
                          x-small
                          color="primary"
                          @click="addAdult(index)"
                          :disabled="gruppen[index].erwachsene === 10"
                      >
                        <v-icon dark>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </div>
                  </div>
                  <div>
                    {{ $t("Search.Kinder") }}
                    <div class="div-addpersons-serachsmall1">

                      <v-btn
                          class="mx-2"
                          text
                          dark
                          x-small
                          color="primary"
                          @click="removeChild(index)"
                          :disabled="gruppen[index].kinder < 1"
                      >
                        <v-icon dark>
                          mdi-minus
                        </v-icon>
                      </v-btn>
                      <label>{{ item.kinder }}</label>

                      <v-btn
                          class="mx-2"
                          text
                          dark
                          x-small
                          color="primary"
                          @click="addChild(index)"
                          :disabled="gruppen[index].kinder ===7"
                      >
                        <v-icon dark>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>


                <div>
                  <label v-if="gruppen[index].kinder>0">
                    {{ $t("Search.GebKinder") }}
                  </label>
                  <div class="div-addChild-serachsmall">
                    <template v-for="(info,i) in item.kinderalter">

                      <BirthdayPicker
                          :selecteddate="item.kinderalter[i].date"
                          :gruppe="index"
                          :id="i"
                      />

                    </template>
                  </div>
                </div>


              </div>
            </template>

            <v-btn
                class="vbtn-addgroup-serachsmall"
                small
                color="var(--Secondary)"
                @click="addGroup"
                :disabled="gruppen.length ===10"
            >
              <v-icon color="white" small>mdi-plus</v-icon>
              {{ $t("Search.WeitereGruppe") }}
            </v-btn>

            <div class="div-vbtn-fertig-serachsmall">
              <v-btn class="vbtn-fertig-serachsmall" @click="closePersons" color="var(--Primary)">
                {{ $t("Button.Fertig") }}
              </v-btn>
            </div>
          </div>
        </transition>
      </div>

      <!-- ------------------------------ Persons ENDE  ------------------------------ -->

      <div class="div-button-serachsmall">
        <v-btn class="vbtn-serachsmall" color="var(--Primary)" @click="search">
          {{ $t("Button.Suchen") }}
        </v-btn>
      </div>

    </div>

    <div v-if="loadingsearch" style="margin: 50px auto;">
      <v-progress-circular
          indeterminate
          color="primary"
          :size="50"
          :width="7"

      />
      <SkeletonCardHorizontal/>
    </div>

  </div>
</template>

<script>
import HotelDatePicker from 'vue-hotel-datepicker';
import 'vue-hotel-datepicker/dist/vueHotelDatepicker.css';
import * as UrlUtil from '../../util/UrlUtil';
import {onePlaceWithParameter} from "@/util/halalapi";
import BirthdayPicker from "@/components/DatePicker/BirthdayPicker";
import {getAge} from "@/util/Datez";
import SkeletonCardHorizontal from "@/components/skeletons/SkeletonCardHorizontal";

export default {
  name: "SearchSmall",
  props: [
    'propsPlaceID',
    'checkin',
    'checkout',
    'from'
  ],
  components: {
    HotelDatePicker,
    BirthdayPicker,
    SkeletonCardHorizontal
  },
  data() {
    return {
      loadingsearch: false,
      showDestination: false,
      showPersons: false,
      places: "",


      minDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),


      anzahlGruppen: 1,
      anzahlErwachsene: 2,
      anzahlKinder: 0,
      alterKinder: [],
      anzahlPersonen: 0,


      notificationsnackbar: false,
      notificationtext: "",
      notificationcolor: "",


      gruppen: [{name: "Gruppe 1", erwachsene: 2, kinder: 0, kinderalter: []}],

      selectedstartdate: "",
      selectedenddate: "",

      selectedcheckin: "",
      selectedcheckout: "",


      SearchDatePickerDE: {
        night: 'Nacht',
        nights: 'Nächste',
        'day-names': ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        'check-in': 'Check-in',
        'check-out': 'Check-out',
        'month-names': ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
        "tooltip": {
          "minimumRequiredPeriod": "%{minNightInPeriod} %{night} minimum.",
        },
      },
      SearchDatePickerTR: {
        night: 'Gece',
        nights: 'Geceler',
        'day-names': ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt'],
        'check-in': 'Giriş',
        'check-out': 'Çıkış',
        'month-names': ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
        "tooltip": {
          "minimumRequiredPeriod": "%{minNightInPeriod} %{night} minimum.",
        },
      },
      SearchDatePickerEN: {
        night: 'Night',
        nights: 'Nights',
        'day-names': ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        'check-in': 'Check-in',
        'check-out': 'Check-out',
        'month-names': ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        "tooltip": {
          "minimumRequiredPeriod": "%{minNightInPeriod} %{night} minimum.",
        },
      }


    }

  },
  methods: {


    notification(text, color) {
      this.notificationsnackbar = true;
      this.notificationtext = text;
      this.notificationcolor = color;
    },


    openPersons() {
      this.showDestination = false;
      this.showPersons = !this.showPersons;
    },

    closePersons() {
      this.showDestination = false;
      this.showPersons = false;
    },


    addAdult(i) {
      if (this.gruppen[i].erwachsene < 10) {
        let g = this.gruppen[i];
        g.erwachsene = g.erwachsene + 1;
        this.gruppen[i] = g;
        this.anzahlPersonen = this.anzahlPersonen + 1;
        localStorage.setItem("gruppe", JSON.stringify(this.gruppen));
      }
    },

    removeAdult(i) {
      if (this.gruppen[i].erwachsene > 1) {
        let g = this.gruppen[i];
        g.erwachsene = g.erwachsene - 1;
        this.gruppen[i] = g;

        this.anzahlPersonen = this.anzahlPersonen - 1;
        localStorage.setItem("gruppe", JSON.stringify(this.gruppen));
      }
    },

    addChild(i) {
      if (this.gruppen[i].kinder < 7) {
        let g = this.gruppen[i];
        g.kinder = g.kinder + 1;
        g.kinderalter.push({age: "0", date: ""});
        this.gruppen[i] = g;

        this.anzahlPersonen = this.anzahlPersonen + 1;
        localStorage.setItem("gruppe", JSON.stringify(this.gruppen));
      }
    },

    removeChild(i) {
      if (this.gruppen[i].kinder > 0) {
        let g = this.gruppen[i];

        g.kinder = g.kinder - 1;
        g.kinderalter.pop();
        this.gruppen[i] = g;

        this.anzahlPersonen = this.anzahlPersonen - 1;
        localStorage.setItem("gruppe", JSON.stringify(this.gruppen));
      }
    },

    addGroup() {
      if (this.gruppen.length < 10) {
        let length = this.gruppen.length;
        let v = "Gruppe " + (length + 1);
        this.gruppen.push({name: v, erwachsene: 2, kinder: 0, kinderalter: []});

        this.anzahlPersonen = this.anzahlPersonen + 2;
        localStorage.setItem("gruppe", JSON.stringify(this.gruppen));
      }
    },


    removeGroup(i) {
      if (this.gruppen.length > 1) {
        this.anzahlPersonen -= (this.gruppen[i].erwachsene + this.gruppen[i].kinder);
        this.gruppen = this.gruppen.filter((item, index) => index !== i);
        localStorage.setItem("gruppe", JSON.stringify(this.gruppen));
      }
    },


    zuPlace(id, checkin, checkout, groups) {
      localStorage.setItem("gruppe", JSON.stringify(this.gruppen));

      let idplace = "";
      if (isNaN(id)) {
        if (id.includes("cy")) {
          idplace = id.split("-")[1];
        }
      } else {
        idplace = id;
      }


      this.$router.replace({
        path: this.from,
        query: {id: idplace, checkin, checkout, groups}
      });
      window.scrollTo(0, 0);
    },

    async search() {
      this.loadingsearch = true;
      //let url = process.env.VUE_APP_API;
      let checkin = "";
      let checkout = "";

      let value = "";
      let url = "";

      if (localStorage.getItem('ht') !== null) {

        url = UrlUtil.addHolidayType(url, localStorage.getItem('ht'));
        // url = UrlUtil.addHolidayType(url, "resort");
        url = UrlUtil.bind(url);

      }


      if (localStorage.getItem('c-in') !== null) {
        let d = new Date(localStorage.getItem('c-in'));
        this.selectedcheckin = d;


        checkin = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
        url = UrlUtil.addCheckin(url, checkin);
        url = UrlUtil.bind(url);
      } else {
        let d = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 16);
        checkin = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
        url = UrlUtil.addCheckin(url, checkin);
        url = UrlUtil.bind(url);
      }

      if (localStorage.getItem('c-out') !== null) {
        let d = new Date(localStorage.getItem('c-out'));
        this.selectedcheckout = d;

        checkout = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
        url = UrlUtil.addCheckout(url, checkout);
        url = UrlUtil.bind(url);

      } else {
        let d = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 18);
        checkout = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
        url = UrlUtil.addCheckout(url, checkout);
        url = UrlUtil.bind(url);
      }


      this.gruppen.map((item, index) => {
        let groups = "";
        if (index > 0) {
          value = UrlUtil.binddata(value);
        }


        let erw = item.erwachsene;
        let kinder = item.kinder;

        if (kinder > 0) {
          item.kinderalter.map(it => {
            if (groups !== "")
              groups = groups.concat(",")
            groups = groups.concat(it.age.toString());
          })
        }

        if (kinder > 0) {

          value = UrlUtil.addGroupsData(value, erw + "," + groups);

        } else {

          value = UrlUtil.addGroupsData(value, erw);

        }


      });

      localStorage.setItem('groups', value);
      
      if (this.from === 'place') {

        let place = await onePlaceWithParameter(this.propsPlaceID, checkin, checkout, "&" + value);
        this.$root.$emit("place", place);
      }

      // if (this.from ==='cy-place') {
      //   this.$root.$emit("cy-place", place);
      // }


      localStorage.setItem("url", url + value);
      this.zuPlace(this.propsPlaceID, checkin, checkout, "&" + value);


      this.loadingsearch = false;
      location.reload();
    },

    updateCheckIn(date) {
      this.selectedstartdate = date;
      this.selectedcheckin = date;

      localStorage.setItem('c-in', date);

      if (this.selectedstartdate === null) {
        localStorage.removeItem('c-in');
      }

    },

    updateCheckOut(date) {
      this.selectedenddate = date;
      this.selectedcheckout = date;

      localStorage.setItem('c-out', date);

      if (this.selectedenddate === null) {
        localStorage.removeItem('c-out');
      }

      this.gruppen.map((item, index) => {
        item.kinderalter.map((it, i) => {
          let age = getAge(it.date, this.selectedenddate);

          this.gruppen[index].kinderalter[i].age = age.toString();
        })
      })

    },
  },
  async created() {

    // ------------------------------------ DATUM anzeigen ------------------------------------


    if (localStorage.getItem('c-in') !== null) {
      let c_in = new Date(localStorage.getItem('c-in'));
      this.selectedcheckin = c_in;
    } else {

      let d = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 16);
      this.selectedstartdate = d;
    }

    if (localStorage.getItem('c-out') !== null) {
      let c_out = new Date(localStorage.getItem('c-out'));
      this.selectedcheckout = c_out;
    } else {

      let d1 = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 18);
      this.selectedenddate = d1;
    }

    if (this.selectedstartdate !== "") {
      this.selectedcheckin = this.selectedstartdate;
    }
    if (this.selectedenddate !== "") {
      this.selectedcheckout = this.selectedenddate;
    }


    // ------------------------------------ DATUM anzeigen ENDE ------------------------------------


    let d = new Date().getFullYear();
    let arr = [];
    for (let i = 0; i <= 17; i++) {

      arr.push({text: (d - i).toString(), id: i});
    }
    this.alterKinder = arr;

    if (this.propsSearchtext !== undefined) {
      this.searchtext = this.propsSearchtext;
    }

    if (this.propsHolidayType !== undefined) {
      let type = this.holidayTypes.find(item => item.id === this.propsHolidayType);
      this.selectedholidayType = type.text;
    } else {
      this.selectedholidayType = this.$route.query.holidayType;
    }


    this.$root.$on("geburtstag", (gruppe, id, date) => {


      let age = getAge(date, this.selectedenddate);

      this.gruppen[gruppe].kinderalter[id].age = age.toString();
      this.gruppen[gruppe].kinderalter[id].date = date;
    });

    this.gruppen = JSON.parse(localStorage.getItem("gruppe"));

    if (this.gruppen === null) {
      this.gruppen = [{name: "Gruppe 1", erwachsene: 2, kinder: 0, kinderalter: []}];
    }
    this.gruppen.map(item => {
      this.anzahlPersonen += item.erwachsene;
      this.anzahlPersonen += item.kinderalter.length;
    })

  },
  computed: {
    getLangDatePicker() {

      if (localStorage.getItem('lang')) {
        if (localStorage.getItem('lang') === "EN") {
          return this.SearchDatePickerEN;
        }
        if (localStorage.getItem('lang') === "TR") {
          return this.SearchDatePickerTR;
        }
        if (localStorage.getItem('lang') === "DE") {
          return this.SearchDatePickerDE;
        }
      }
      return this.SearchDatePickerDE;
    }
  }
}
</script>

<style scoped>
@import "../../css/SearchSmall.css";
</style>