<template>
  <div>


    <GalleryPopup :pics="roominfo.room.photos" :show="showGalleryPopup"/>


    <div class="flip-card" v-bind:class="[{selectedroom3 : isActiv},{notselectedroom3 :! isActiv}]">
      <v-icon v-bind:class="[{showChecked3: isActiv},{hideChecked3 :!isActiv}]" color="var(--Primary)" large>
        mdi-check
      </v-icon>

      <div class="flip-card-inner">
        <!-- ------------------------------ Front Anfang ------------------------------ -->
        <div class="flip-card-front">

          <div class="div-img-cardroom3">
            <img :src="roominfo.room.photos[0]" alt="room-photo" class="img-cardroom3">
          </div>


          <div class="div-price-name-cardroom3">

            <div class="div-price-cardroom3">
              {{ formatPrice(roominfo.price) }}
              <v-icon small color="var(--Primary)">{{ currencyicon }}</v-icon>
            </div>

            <label class="label-roomtitel-cardroom3">
              <span v-if="roominfo.quantity !== 1">{{ roominfo.quantity }} X </span>{{ roominfo.room.name }}
            </label>

          </div>


        </div>


        <!-- ------------------------------ Front ENDE ------------------------------ -->


        <!-- ------------------------------ Back Anfang ------------------------------ -->
        <div class="flip-card-back">
          <div class="div-title-card-back">
            <span v-if="roominfo.quantity !== 1">{{ roominfo.quantity }} X </span>{{ roominfo.room.name }}
          </div>


          <div class="div-quickinfos-cardroom3">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div v-on="on" style="cursor: default">
                  <v-icon large color="white">mdi-room-service</v-icon>
                  <br>
                  <div style="font-size: 10px">
                    {{ $t("Card.Optionen") }}
                  </div>
                </div>
              </template>
              <div class="div-tooltip-cardroom3">
                <label style="font-size: 18px; text-align: center">
                  -- {{ $t("Card.ServiceOptionen") }} --
                </label>
                <template v-for="(i,index) in roominfo.rate_plans">
                  <ul>
                    <li>
                      {{ i.rate_plan.meal_plan_name }}
                    </li>
                  </ul>
                </template>
              </div>
            </v-tooltip>


            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div v-on="on" style="cursor: default">
                  <v-icon large v-if="roominfo.confirmation === 'instant'" color="white">mdi-check-decagram</v-icon>
                  <v-icon large v-if="roominfo.confirmation === 'delayed'" color="white">mdi-hours-24</v-icon>
                  <br>
                  <div style="font-size: 10px">
                    {{ $t("Card.Bestaetigung") }}
                  </div>
                </div>
              </template>
              <div class="div-tooltip-cardroom3">
                <label style="font-size: 18px;">
                  -- {{ $t("Card.Buchungsbestaetigung") }} --
                </label>
                <br>
                <label>
                  {{ roominfo.confirmation === "instant" ? $t("Card.instant") : "" }}
                  {{ roominfo.confirmation === "delayed" ? $t("Card.delayed") : "" }}
                </label>
              </div>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div v-on="on" style="cursor: default">
                  <v-icon large color="white">mdi-book-cancel</v-icon>
                  <br>
                  <div style="font-size: 10px">
                    {{ $t("Card.Stornierung") }}
                  </div>
                </div>
              </template>
              <div class="div-tooltip-cardroom3">
                <label style="font-size: 18px;">
                  -- {{ $t("Card.Stornierung") }} --
                </label>
                <br>
                <div
                    style="text-align: start; max-width: 300px;"
                    v-html="roominfo.rate_plan.cancellation_policy_html"
                >
                </div>

              </div>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on }">

                <div v-on="on" style="cursor: default">
                  <v-icon large color="white" style="cursor: pointer" @click="showGalleryPopup= true">
                    mdi-image-multiple
                  </v-icon>
                  <br>
                  <div style="font-size: 10px">
                    {{ $t("Card.Bilder") }}
                  </div>
                </div>
              </template>
              <div class="div-tooltip-cardroom3">
                <label style="font-size: 18px;">
                  {{ $t("Card.Bilderoffnenklicken") }}
                </label>


              </div>
            </v-tooltip>


          </div>


          <div class="div-infos-cardroom3">
            <div class="div-infos-cardroom31">
              <v-icon small color="white">mdi-silverware-fork-knife</v-icon>
              <label style="font-size: 16px">
                {{ roominfo.rate_plan.meal_plan_name }}
              </label>
            </div>


          </div>
          <div class="div-price-back-cardroom3">
            {{ formatPrice(roominfo.price) }}
            <v-icon color="var(--Secondary)">{{ currencyicon }}</v-icon>
          </div>


          <div class="div-btn-cardroom3">

            <template>
              <v-btn v-if="place.groups.length-1 >0" @click="selectRoom" href="#title-place">
                {{ $t("Button.ZimmerAuswaehlen") }}
              </v-btn>
              <v-btn v-else @click="selectRoom">
                {{ $t("Button.ZimmerAuswaehlen") }}
              </v-btn>
            </template>

            <v-btn
                @click="zeigeInfos"
                color="white"
                class="vbtn-zeigInfos-cardroom3"
                outlined
            >
              {{ $t("Button.Infos") }}
            </v-btn>

          </div>

        </div>


        <!-- ------------------------------ Back ENDE ------------------------------ -->
      </div>

    </div>
    <div style="z-index: 5">
      <OverlayRoomInfos
          v-if="showInfos"
          :roominfo="roominfo"
          :show-overlay="showInfos"
          from="halal"
      />
    </div>

  </div>
</template>


<script>
import GalleryPopup from "@/components/gallery/GalleryPopup";
import OverlayRoomInfos from "@/components/overlay/OverlayRoomInfos";
import {formatPrice, getCurrencyIcon} from "@/util/currency";


export default {
  name: "CardRoom3",
  props: [
    'roominfo',
    'place',
    'from',
    'index',
    'groupindex'
  ],
  components: {
    GalleryPopup,
    OverlayRoomInfos
  },

  data() {
    return {
      currencyicon: getCurrencyIcon(),
      showGalleryPopup: false,
      showInfos: false,
      isActiv: false,
    }
  },
  methods: {
    getCurrencyIcon,
    formatPrice,
    selectRoom() {
      this.isActiv = true;
      this.$root.$emit("active", this.index, this.groupindex);
      this.$root.$emit("selectedroom", this.roominfo, this.groupindex);
    },

    zeigeInfos() {
      this.showInfos = !this.showInfos;
      this.$root.$emit("stopScrolling", true);
    }
  },


  async created() {

    this.$root.$on("showGallerie", (val) => {
      this.showGalleryPopup = val;
    });

    this.$root.$on("showOverlayRoomInfos", (val) => {
      this.showInfos = val;

    });

    this.$root.$on("active", (val, grpindex) => {
      if (grpindex === this.groupindex) {
        if (val !== this.index) {
          this.isActiv = false;

        }
      }
    });
  }
}

</script>

<style lang="css" scoped>
@import "../../css/CardRoom3.css";
</style>
