<template>

  <div class="div-cardroom" v-bind:class="[{selectedroom : isActiv},{notselectedroom :! isActiv}]">


    <div class="div-img-cardroom">

      <v-icon v-bind:class="[{showChecked: isActiv},{hideChecked :!isActiv}]" color="var(--Primary)" large>
        mdi-check
      </v-icon>
      <v-icon color="white" class="vicon-gallery-cardroom">mdi-image-multiple</v-icon>
      <img
          :src="roominfo.pictures_rooms[0].url" alt="room-photo" class="img-cardroom"
          @click="showGalleryPopup = !showGalleryPopup"
      >

      <GalleryPopup :pics="roominfo.pictures_rooms" :show="showGalleryPopup" from="CY_Card"/>

    </div>
    <div class="div-price-cardroom" @click="showGalleryPopup = !showGalleryPopup">
      <div
          class="label-price-cardroom"
          v-if="calculateCurrency(pPlaceCurrency, pCurrency, pCurrencyValues, (pGruppen[groupindex].rooms[index].price))"
      >
        {{
          formatPrice(calculateCurrency(pPlaceCurrency, pCurrency, pCurrencyValues, (pGruppen[groupindex].rooms[index].price)))
        }}
        <v-icon
            small color="var(--Primary)"
        >
          {{ currencyicon }}
        </v-icon>
      </div>

      <div class="label-price-cardroom" v-else style="color: red">Ausverkauft</div>
    </div>

    <div class="div-roomtitel-cardroom">
      <label class="label-roomtitel-cardroom">
        <span v-if="pGruppen[groupindex].rooms[index].anzahl_zimmer !== 1">
          {{ pGruppen[groupindex].rooms[index].anzahl_zimmer }} X </span>
        {{ roominfo['name_' + pLang.toLowerCase()] }}
      </label>

    </div>

    <div class="div-quickinfos-cardroom">
      <v-tooltip top>
        <template v-slot:activator="{ on }">

          <div v-on="on" style="cursor: default">
            <v-icon v-on="on" large>mdi-room-service</v-icon>
            <br>
            <div style="font-size: 10px">
              {{ $t("Card.Optionen") }}

            </div>
          </div>
        </template>
        <div class="div-tooltip-cardroom">
          <label style="font-size: 18px; text-align: center">
            -- {{ $t("Card.ServiceOptionen") }} --
          </label>
          <template v-for="(rateplan,index) in rate_plans">
            <ul>
              <li>
                {{ getRatePlanByID(rateplan.rate_plan_id) }}
              </li>
            </ul>
          </template>
        </div>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <!--          <v-icon v-on="on" large v-if="roominfo.confirmation === 'instant'">mdi-check-decagram</v-icon>-->
          <!--          <v-icon v-on="on" large v-if="roominfo.confirmation === 'delayed'">mdi-hours-24</v-icon>-->
          <div v-on="on" style="cursor: default">
            <v-icon large v-if="roominfo.confirmation === 'instant'">mdi-check-decagram</v-icon>
            <v-icon large v-if="roominfo.confirmation === 'delayed'">mdi-hours-24</v-icon>
            <br>
            <div style="font-size: 10px">
              {{ $t("Card.Bestaetigung") }}
            </div>
          </div>
        </template>
        <div class="div-tooltip-cardroom">
          <label style="font-size: 18px;">
            -- {{ $t("Card.Buchungsbestaetigung") }} --
          </label>
          <br>
          <label>
            {{
              roominfo.confirmation === "instant" ? $t("Card.instant") : ""
            }}
            {{
              roominfo.confirmation === "delayed" ? $t("Card.delayed") : ""
            }}
          </label>
        </div>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <div v-on="on" style="cursor: default">
            <v-icon large>mdi-book-cancel</v-icon>
            <br>
            <div style="font-size: 10px">
              {{ $t("Card.Stornierung") }}
            </div>
          </div>
        </template>
        <div class="div-tooltip-cardroom">
          <label style="font-size: 18px;">
            -- {{ $t("Card.Stornierung") }} --
          </label>
          <br>
          <div style="text-align: start; max-width: 300px;" v-html="roominfo['cancellation_'+pLang.toLowerCase()]">
          </div>

        </div>
      </v-tooltip>

    </div>


    <div class="div-infos-cardroom">
      <div class="div-infos-cardroom1">
        <v-icon small>mdi-silverware-fork-knife</v-icon>
        <label style="font-size: 16px">
          {{ getRatePlanByID(rate_plans[0].rate_plan_id) }}
        </label>
      </div>


    </div>

    <div class="div-btn-cardroom">

      <!--      <v-btn-->
      <!--          v-if="place.groups.length-1 >0"-->
      <!--          @click="selectRoom"-->
      <!--          color="var(&#45;&#45;Primary)"-->
      <!--          class="vbtn-cardroom"-->
      <!--          href="#title-place"-->
      <!--      >-->
      <!--        {{ $t("Button.ZimmerAuswaehlen") }}-->
      <!--      </v-btn>-->

      <v-btn
          @click="selectRoom"
          color="var(--Primary)"
          class="vbtn-cardroom"
          :disabled="calculateCurrency(pPlaceCurrency, pCurrency, pCurrencyValues, (pGruppen[groupindex].rooms[index].price)) ===0"
      >
        {{ $t("Button.ZimmerAuswaehlen") }}
      </v-btn>

      <v-btn @click="zeigeInfos" class="vbtn-zeigInfos-cardroom" outlined>
        {{ $t("Button.Infos") }}
      </v-btn>
    </div>

    <div style="z-index: 5">
      <OverlayRoomInfos
          v-if="showInfos"
          :roominfo="roominfo"
          :show-overlay="showInfos"
          from="CY_Card"
          :pLang="pLang"
      />
    </div>

  </div>
</template>


<script>
import GalleryPopup from "@/components/gallery/GalleryPopup";
import {formatPrice, getCurrencyIcon} from "@/util/currency";
import OverlayRoomInfos from "@/components/overlay/OverlayRoomInfos";
import {getRatePlanByID} from "@/util/rateplansUtil";
import {calculateCurrency} from "@/util/currencyUtil";


export default {
  name: "CY_CardRoom",
  props: [
    'roominfo',
    'rate_plans',
    'place',
    'from',
    'index',
    'groupindex',
    'pLang',
    'pGruppen',
    'pCurrency',
    'pCurrencyValues',
    'pPlaceCurrency'
  ],
  components: {
    GalleryPopup,
    OverlayRoomInfos
  },

  data() {
    return {
      currencyicon: getCurrencyIcon(),
      showGalleryPopup: false,
      showInfos: false,
      isActiv: false,
      checkstopsale: false
    }
  },

  methods: {
    getRatePlanByID,
    getCurrencyIcon,
    formatPrice,
    calculateCurrency,

    selectRoom() {
      this.isActiv = true;
      this.$root.$emit("active", this.index, this.groupindex);
      this.$root.$emit("selectedroom", this.pGruppen, this.groupindex, this.index);
    },

    zeigeInfos() {
      this.showInfos = !this.showInfos;
      this.$root.$emit("stopScrolling", true);
    }
  },


  async created() {


    this.$root.$on("showGallerie", (val) => {
      this.showGalleryPopup = val;
    });

    this.$root.$on("showOverlayRoomInfos", (val) => {
      this.showInfos = val;
    });


    this.$root.$on("active", (val, grpindex) => {
      if (grpindex === this.groupindex) {
        if (val !== this.index) {
          this.isActiv = false;

        }
      }
    });


  }


}

</script>

<style lang="css" scoped>
@import "../../css/CardRoom.css";
</style>
