import axios from "axios";

export const addOwnHalalProvision = async (halalPlace) => {

    let res = await axios.post(process.env.VUE_APP_DB + "/api2/ownhalalprovision", halalPlace, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});

    return res.status;

}

export const getOwnHalalProvisionByHalalid = async (id) => {
    let place = "";
    try {
        let res = await axios.get(process.env.VUE_APP_DB + "/api2/ownhalalprovisionbyhid/" + id, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});

        place = res.data.data;
    } catch (e) {
        return "";
    }

    return place;
}

export const getAllOwnHalalProvision = async () => {
    let res = "";
    try {
        res = await axios.get(process.env.VUE_APP_DB + "/api2/ownhalalprovision", {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});
    } catch (e) {
        return [];
    }

    let place = res.data.data;

    return place;
}

export const updateOwnHalalProvision = async (provision) => {
    await axios.put(process.env.VUE_APP_DB + "/api2/ownhalalprovision/" + provision._id, provision, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});
}

export const deleteOwnHalalProvisionByID = async (id) => {
    let status = 0;
    //await axios.delete(process.env.VUE_APP_DB + "/api/order/" + id, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}}).then(res => status = res.status).catch(err => {
    await axios.delete(process.env.VUE_APP_DB + "/api2/ownhalalprovision/" + id, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}}).then(res => status = res.status).catch(err => {

    });
    return status;
}