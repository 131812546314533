<template>
  <div class="div-language-header">
    <img class="img-flag-header" :src="getLang(this.select)" alt="flag"/>

    <!--    append-icon=""-->
    <v-select
        v-model="$i18n.locale"
        :items="items"
        :label="getSelect"
        solo
        style="padding: 0 !important"
        @change="changeLang"
    >
      <template slot="item" slot-scope="data">
        <img :src="getLang(data.item)" alt="flag"/>
        {{ data.item }}
      </template>
    </v-select>
  </div>
</template>
<script>
import DE from "../../assets/Flags/de.png";
import TR from "../../assets/Flags/tr.png";
import EN from "../../assets/Flags/en.png";

export default {
  name: "LanguageSwitcher",

  data() {
    return {
      // langs: ['de', 'en', 'tr'],
      select: "DE",
      de: DE,
      tr: TR,
      en: EN,
      items: ["DE", "TR", "EN"],
      flags: ["DE", "TR", "EN"]
    };
  },
  methods: {
    getLang(lang) {
      switch (lang) {
        case "DE":
          return this.de;
        case "TR":
          return this.tr;
        case "EN":
          return this.en;
        default:
          return this.de;
      }
    },
    changeLang() {
      localStorage.setItem("lang", this.$i18n.locale);

      localStorage.removeItem('des');
      localStorage.removeItem('desP');
      localStorage.removeItem('desID');

      this.$router.go();
    }
  },
  computed: {
    getSelect() {
      localStorage.setItem("lang", this.$i18n.locale);
      this.select = this.$i18n.locale;
      return this.select;
    }
  },
  created() {
    if (localStorage.getItem("lang")) {
      this.select = localStorage.getItem("lang");
    }
  }
};
</script>
<style scoped></style>
