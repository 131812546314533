import axios from "axios";
import {getUserToken} from "@/util/userCredentials";


export const postBooking = async (booking) => {
    const token = await getUserToken();

    let res = "";
    let data = "";
    let d = {
        token: token,

        booking: booking,
        lang: localStorage.getItem('lang')
    }
    try {
        res = await axios.post(
            process.env.VUE_APP_DB + "/api2/halaladd", d, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}}
        );
        data = res.data;
    } catch (e) {
        return {msg: "booking error", error: true, order_state: "-1"};
    }

    return data.data;
}