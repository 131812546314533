<template>

  <div class="div-cardroom4" v-bind:class="[{selectedroom4 : isActiv},{notselectedroom4 :! isActiv}]">
    <div>
      <v-icon v-bind:class="[{showChecked: isActiv},{hideChecked :!isActiv}]" color="var(--Primary)" large>
        mdi-check
      </v-icon>
    </div>
    <div class="div-roomtitel-cardroom4">

      <label class="label-roomtitel-cardroom4">
        <span v-if="roominfo.quantity !== 1">{{ roominfo.quantity }} X </span> {{ roominfo.room.name }}
      </label>

    </div>

    <div class="div-img-cardroom4">


      <v-icon color="white" class="vicon-gallery-cardroom4">mdi-image-multiple</v-icon>
      <img
          :src="roominfo.room.photos[0]" alt="room-photo" class="img-cardroom4"
          @click="showGalleryPopup = !showGalleryPopup"
      >

      <GalleryPopup :pics="roominfo.room.photos" :show="showGalleryPopup"/>

    </div>

    <div class="div-infos0-cardroom4">
      <div class="div-infos-cardroom4">
        <div class="div-infos-cardroom41">
          <v-icon small color="var(--Primary)">mdi-silverware-fork-knife</v-icon>
          <label style="font-size: 16px; font-weight: bolder; color: var(--Primary)">
            {{ roominfo.rate_plan.meal_plan_name }}
          </label>
        </div>


      </div>
      <div class="div-price-cardroom4" @click="showGalleryPopup = !showGalleryPopup">
        <div class="label-price-cardroom4">
          {{ formatPrice(roominfo.price) }}
          <v-icon small color="var(--Primary)">{{ currencyicon }}</v-icon>
        </div>
      </div>


    </div>

    <div class="div-quickinfos-cardroom4">
      <v-tooltip top>
        <template v-slot:activator="{ on }">

          <div v-on="on" style="cursor: default">
            <v-icon v-on="on">mdi-room-service</v-icon>
            <br>
            <div style="font-size: 10px">
              {{ $t("Card.Optionen") }}

            </div>
          </div>
        </template>
        <div class="div-tooltip-cardroom4">
          <label style="font-size: 18px; text-align: center">
            -- {{ $t("Card.ServiceOptionen") }} --
          </label>
          <template v-for="(i,index) in roominfo.rate_plans">
            <ul>
              <li>
                {{ i.rate_plan.meal_plan_name }}
              </li>
            </ul>
          </template>
        </div>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <!--          <v-icon v-on="on" large v-if="roominfo.confirmation === 'instant'">mdi-check-decagram</v-icon>-->
          <!--          <v-icon v-on="on" large v-if="roominfo.confirmation === 'delayed'">mdi-hours-24</v-icon>-->
          <div v-on="on" style="cursor: default">
            <v-icon v-if="roominfo.confirmation === 'instant'">mdi-check-decagram</v-icon>
            <v-icon v-if="roominfo.confirmation === 'delayed'">mdi-hours-24</v-icon>
            <br>
            <div style="font-size: 10px">
              {{ $t("Card.Bestaetigung") }}
            </div>
          </div>
        </template>
        <div class="div-tooltip-cardroom4">
          <label style="font-size: 18px;">
            -- {{ $t("Card.Buchungsbestaetigung") }} --
          </label>
          <br>
          <label>
            {{
              roominfo.confirmation === "instant" ? $t("Card.instant") : ""
            }}
            {{
              roominfo.confirmation === "delayed" ? $t("Card.delayed") : ""
            }}
          </label>
        </div>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <div v-on="on" style="cursor: default">
            <v-icon>mdi-book-cancel</v-icon>
            <br>
            <div style="font-size: 10px">
              {{ $t("Card.Stornierung") }}
            </div>
          </div>
        </template>
        <div class="div-tooltip-cardroom4">
          <label style="font-size: 18px;">
            -- {{ $t("Card.Stornierung") }} --
          </label>
          <br>
          <div style="text-align: start; max-width: 300px;" v-html="roominfo.rate_plan.cancellation_policy_html">
          </div>

        </div>
      </v-tooltip>

    </div>


    <div class="div-btn-cardroom4">
      <v-btn
          v-if="place.groups.length-1 >0"
          @click="selectRoom"
          color="var(--Primary)"
          class="vbtn-cardroom4"
          href="#title-place"
      >
        {{ $t("Button.ZimmerAuswaehlen") }}
      </v-btn>

      <v-btn
          v-else
          @click="selectRoom"
          color="var(--Primary)"
          class="vbtn-cardroom4"
      >
        {{ $t("Button.ZimmerAuswaehlen") }}
      </v-btn>

      <v-btn @click="zeigeInfos" class="vbtn-zeigInfos-cardroom4" outlined>
        {{ $t("Button.Infos") }}
      </v-btn>
    </div>

    <div style="z-index: 5">
      <OverlayRoomInfos
          v-if="showInfos"
          :roominfo="roominfo"
          :show-overlay="showInfos"
          from="halal"
      />
    </div>

  </div>
</template>


<script>
import GalleryPopup from "@/components/gallery/GalleryPopup";
import {formatPrice, getCurrencyIcon} from "@/util/currency";
import OverlayRoomInfos from "@/components/overlay/OverlayRoomInfos";


export default {
  name: "CardRoom4",
  props: [
    'roominfo',
    'place',
    'from',
    'index',
    'groupindex'
  ],
  components: {
    GalleryPopup,
    OverlayRoomInfos
  },

  data() {
    return {
      currencyicon: getCurrencyIcon(),
      showGalleryPopup: false,
      showInfos: false,
      isActiv: false,
    }
  },

  methods: {
    getCurrencyIcon,
    formatPrice,
    selectRoom() {
      this.isActiv = true;
      this.$root.$emit("active", this.index, this.groupindex);
      this.$root.$emit("selectedroom", this.roominfo, this.groupindex);
    },

    zeigeInfos() {
      this.showInfos = !this.showInfos;
      this.$root.$emit("stopScrolling", true);
    }
  },


  async created() {


    this.$root.$on("showGallerie", (val) => {
      this.showGalleryPopup = val;
    });

    this.$root.$on("showOverlayRoomInfos", (val) => {
      this.showInfos = val;
    });


    this.$root.$on("active", (val, grpindex) => {
      if (grpindex === this.groupindex) {
        if (val !== this.index) {
          this.isActiv = false;

        }
      }
    });
  }

}

</script>

<style lang="css" scoped>
@import "../../css/CardRoom4.css";
</style>
