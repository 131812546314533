export default class MyList {
    constructor() {
        this.list = [];
    }

    add(e) {


        // let fac = e.split(',');


        if (!this.isExist(e.trim())) {

            this.list.push({
                text: e.trim(),
                tid: e.trim()
            });
        }


    }

    addBeach(e) {


        // let fac = e.split(',');


        if (!this.isBeachExist(e)) {

            this.list.push({
                title: e.title.trim(),
                desc: e.desc,
                tid: e.title.trim()
            });
        }


    }

    isBeachExist(e) {
        let found = false;
        this.list.map(it => {

            e.desc.map(item => {
                if (it.desc.includes(item)) {
                    found = true;
                }
            })

        })
        return found;

    }

    isExist(e) {

        return this.list.filter(item => item.text === e).length !== 0;

    }

    getList() {
        return this.list;
    }
}