<template>
  <div v-if="pRolle==='admin' || pRolle==='superadmin'">
    <v-snackbar
        v-model="notificationsnackbar"
        :timeout="6000"
        :color="notificationcolor"
        :top=true
        :right=true
        :vertical=true
    >
      {{ notificationtext }}
      <v-btn
          color="white"
          text
          @click="notificationsnackbar = false"
      >
        {{ $t("NotificationSchliessen") }}
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>


    <div class="bodyheader-accounts">

      <label class="label-bodyheader-accounts">

        <v-icon class="icon-start" color="white">mdi-account-multiple</v-icon>
        {{ $t("Nav.Benutzer") }}
      </label>
      <span></span>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn class="ma-2" color=var(--Primary) @click="goToCreateUser" v-on="on">
            {{ $t("Acc.NeuerBenutzer") }}
          </v-btn>
        </template>
        <span>{{ $t("Acc.NeuerBenutzerTT") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
              class="mx-2 button-create-accounts" fab dark color="var(--Primary)" @click="goToCreateUser"
              v-on="on"
          >
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("Acc.NeuerBenutzerTT") }}</span>
      </v-tooltip>
    </div>


    <div class="div-body-accounts">
      <div>
        <v-data-table
            :headers="headers"
            :items="reiseburoInfos"
            item-key="name"
            class="elevation-1"

            :custom-filter="filterOnlyCapsText"
        >

          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Reisebüros</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-dialog
                  v-model="dialog"
                  max-width="600px"
              >
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col
                            cols="12"
                            sm="6"
                            md="4"
                        >

                          <v-text-field
                              v-model="editedItem.kontakt.firma"
                              label="Reisebüro"
                          ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            md="4"
                        >
                          <v-text-field
                              v-model="editedItem.firma_id"
                              label="ID"
                              disabled
                          ></v-text-field>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="6"
                            md="4"
                        >

                          <v-select
                              :label="$t('Acc.Provisionsklasse')"
                              :items="provision_classes"
                              v-model="editedItem.provisionsklasse"
                              outlined
                              color=var(--Primary)

                          />
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            md="4"
                        >
                          <v-text-field
                              v-model="editedItem.email"
                              label="E-Mail"

                          ></v-text-field>
                        </v-col>

                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="close"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="save"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="600px">
                <v-card>
                  <v-card-title class="text-h5" style="word-break: unset">Wollen Sie das Reisebüro entgültig
                                                                          löschen?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deactivateRBConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>

            <v-text-field
                v-model="search"
                label="Suche..."
                class="mx-4"
                prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </template>


          <template v-slot:item.provision="{ item }">

            {{ item.provisionsklasse }} %

          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
                small
                @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>


        </v-data-table>
      </div>

      <div>
        <v-data-table
            :headers="hotelheaders"
            :items="hotelInfos"
            item-key="name"
            class="elevation-1"

            :custom-filter="filterOnlyCapsText"
        >

          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Hotel-Accounts</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-dialog
                  v-model="dialog"
                  max-width="600px"
              >
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ hotelformTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col
                            cols="12"
                            sm="6"
                            md="4"
                        >

                          <v-text-field
                              v-model="editedItem.kontakt.firma"
                              label="Hotel"
                          ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            md="4"
                        >
                          <v-text-field
                              v-model="editedItem.firma_id"
                              label="ID"
                              disabled
                          ></v-text-field>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="6"
                            md="4"
                        >
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            md="4"
                        >
                          <v-text-field
                              v-model="editedItem.email"
                              label="E-Mail"

                          ></v-text-field>
                        </v-col>

                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="close"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="save"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="600px">
                <v-card>
                  <v-card-title class="text-h5" style="word-break: unset">
                    Wollen Sie das Hotel entgültig löschen?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deactivateHotelConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>

            <v-text-field
                v-model="search"
                label="Suche..."
                class="mx-4"
                prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </template>


          <template v-slot:item.provision="{ item }">

            {{ item.provisionsklasse }} %

          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="hoteleditItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
                small
                @click="hoteldeleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>


        </v-data-table>
      </div>
    </div>


  </div>
</template>

<script>
import {getAllUsers, getUserByID, updateUser, updateUserAtAccounts} from "@/util/userUtil";
import {getUserinfoByUserId, updateUserInfo} from "@/util/userinfoDBUtil";
import {deleteOwnHalalProvisionByID} from "@/util/halalplaceUtil";
import {getpclssSetting} from "@/util/settingsUtil";

export default {
  name: "Accounts",
  props: [
    'pLang',
    'pRolle',
  ],
  data() {
    return {
      accounts: [],
      hotels: [],
      reiseburos: [],
      admins: [],
      // rolle: "",

      notificationsnackbar: false,
      notificationtext: "",
      notificationcolor: "",
      search: "",

      // TABS
      tab: null,
      icons: true,
      centered: false,
      grow: true,
      vertical: false,
      prevIcon: false,
      nextIcon: false,
      right: false,
      tabs: 3,


      items: null,
      asktosavestatus: false,
      reiseburoInfos: [],


      hotelitems: null,
      hotelasktosavestatus: false,
      hotelInfos: [],

      setting: "",
      provision_classes: [],
      provisionsklasse: "",


      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        kontakt: {firma: ""},
        firma_id: "",
        provisionsklasse: "",
        email: "",
        _id: ""
      },
      defaultItem: {
        kontakt: {firma: ""},
        firma_id: "",
        provisionsklasse: "",
        email: ""
      },


    }
  },
  methods: {
    notification(text, color) {
      this.notificationsnackbar = true;
      this.notificationtext = text;
      this.notificationcolor = color;
    },
    goToCreateUser() {
      this.$router.push("/createuser");
    },
    image(id) {
      let image = this.reiseburoInfos.find(item => item.userid === id);
      if (image !== undefined) {
        return process.env.VUE_APP_IMAGES + image.logo.picture;
      }
      return "";
    },
    filterOnlyCapsText(value, search, item) {
      return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLocaleLowerCase().indexOf(search.toString().toLocaleLowerCase()) !== -1
    },

    editItem(item) {
      this.editedIndex = this.reiseburoInfos.indexOf(item)
      this.editedItem = Object.assign({}, item)

      this.dialog = true


    },

    hoteleditItem(item) {
      // this.editedIndex = this.hotelInfos.indexOf(item)
      // this.editedItem = Object.assign({}, item)
      //
      // this.dialog = true

      localStorage.setItem("husinfo", JSON.stringify(item));
      localStorage.setItem("hus", JSON.stringify(this.hotels.find(it => it._id === item.userid)));
      this.$router.push({
        path: 'createuser',

      })


    },

    deleteItem(item) {
      this.editedIndex = this.reiseburoInfos.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    hoteldeleteItem(item) {
      this.editedIndex = this.hotelInfos.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    async deactivateRBConfirm() {
      //await deleteOwnHalalProvisionByID(this.editedItem._id);

      this.reiseburoInfos.splice(this.editedIndex, 1)
      let user = await getUserByID(this.editedItem.userid);
      let obj = {
        ...user,
        email: this.editedItem.firma_id + this.editedItem.email,
        isActive: false
      }

      await updateUser(obj);
      this.closeDelete()

      this.notification("Reisebüro erfolgreich gelöscht!", "green");
    },

    async deactivateHotelConfirm() {
      //await deleteOwnHalalProvisionByID(this.editedItem._id);

      this.hotelInfos.splice(this.editedIndex, 1)
      let user = await getUserByID(this.editedItem.userid);
      let obj = {
        ...user,
        email: this.editedItem.firma_id + this.editedItem.email,
        isActive: false
      }

      await updateUser(obj);
      this.closeDelete()

      this.notification("Hotel erfolgreich gelöscht!", "green");
    },

    // async deleteItemConfirm() {
    //   await deleteOwnHalalProvisionByID(this.editedItem._id);
    //   this.reiseburoInfos.splice(this.editedIndex, 1)
    //   this.closeDelete()
    //
    //   this.notification("Hotel erfolgreich gelöscht!", "green");
    // },
    //
    // async hoteldeleteItemConfirm() {
    //   await deleteOwnHalalProvisionByID(this.editedItem._id);
    //   this.reiseburoInfos.splice(this.editedIndex, 1)
    //   this.closeDelete()
    //
    //   this.notification("Hotel erfolgreich gelöscht!", "green");
    // },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async save() {
      if (this.editedIndex > -1) {

        this.reiseburoInfos.map(item => {
          if (item.id === this.editedItem._id) {
            item = this.editedItem;
          }
        })
      } else {

        this.reiseburoInfos.push(this.editedItem)
      }

      let userid = this.reiseburoInfos[this.editedIndex].userid;
      let user = await getUserByID(userid);


      let ui = this.reiseburoInfos[this.editedIndex];

      await updateUserInfo(ui);

      let obj = {
        ...user,
        email: this.editedItem.email,
        provisionsklasse: this.editedItem.provisionsklasse
      }

      await updateUserAtAccounts(obj)
      this.close()

      this.notification(this.$t('NotificationSpeichernErfolgreich'), "green");

      await new Promise(resolve => setTimeout(resolve, 1000));
      location.reload();
    },
  },

  computed: {
    headers() {
      return [
        {
          text: 'Firma',
          align: 'start',
          value: 'kontakt.firma',
        },
        {text: 'ID', value: 'firma_id'},
        {text: 'Provisionsklasse', value: 'provision'},
        {text: 'E-Mail', value: 'email'},
        {text: 'Actions', value: 'actions', sortable: false},


      ]
    },
    hotelheaders() {
      return [
        {
          text: 'Hotel',
          align: 'start',
          value: 'kontakt.firma',
        },
        {text: 'ID', value: 'firma_id'},
        {text: 'E-Mail', value: 'email'},
        {text: 'Actions', value: 'actions', sortable: false},


      ]
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Bearbeiten'
    },
    hotelformTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Bearbeiten'
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  async created() {

    this.accounts = await getAllUsers();
    this.accounts.map(async item => {
      if (item.rolle === "reiseburo" && item.isActive === true) {
        this.reiseburos.push(item);
        let ui = await getUserinfoByUserId(item._id)
        ui = {
          ...ui,
          provisionsklasse: item.provisionsklasse,
          firma_id: item.firma_id,
          email: item.email,
          rolle: item.rolle
        }
        this.reiseburoInfos.push(ui);
      }
      if (item.rolle === "hotel") {
        this.hotels.push(item);
        let ui = await getUserinfoByUserId(item._id)
        ui = {
          ...ui,
          provisionsklasse: item.provisionsklasse,
          firma_id: item.firma_id,
          email: item.email,
          rolle: item.rolle
        }
        this.hotelInfos.push(ui);
      }
      if (item.rolle === "admin") {
        this.admins.push(item);
      }
    })


    // let user = await getUser(localStorage.getItem("token"));
    let setting = await getpclssSetting();

    this.setting = setting;
    let a = this.setting;
    a.sort(function (a, b) {
      const bandA = a.classname;
      const bandB = b.classname;
      let comparison = 0;
      if (bandA > bandB) {
        comparison = 1;
      } else if (bandA < bandB) {
        comparison = -1;
      }
      return comparison;
    });
    this.setting = a;
    let arr = []

    this.setting.map(item => {
      arr.push({text: item.classname + " - " + item.provision + " %", value: item.classname});

    });
    this.provision_classes = arr;
    this.provisionsklasse = this.provision_classes[0].value;

    // this.rolle = user.rolle;

  }
}
</script>

<style scoped>
@import "../css/Accounts.css";
</style>