import axios from "axios";
import jwt from "jsonwebtoken";


export const MailTocustomerbycustomer1 = async (sendValues) => {
// 1. E-Mail an Kunde bei Reservierung durch Kunde/Admin
    let status = 0;
    await axios.post(process.env.VUE_APP_DB + '/api2/mailTocustomerbycustomer1', sendValues, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}}).then(res => status = res.status).catch(err => {
        status = 404;
    });
    return status;
}


export const MailTocustomerbyagency2 = async (sendValues) => {
// 2. E-Mail an Kunde bei Reservierung durch Reisebüro/iFrame
//    WIRD AUF DER KUNDENSEITE NICHT BENÖTIGT!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    //TODO: Wenn ein Reisebüro bucht muss diese mail an den kunden verschickt werden.
    await axios.post(process.env.VUE_APP_DB + '/api2/mailTocustomerbyagency2', sendValues, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});
}


export const MailToagencybyagency3 = async (sendValues) => {
// 3. E-Mail an Reisebüro bei Reservierung durch Reisebüro/iFrame
    await axios.post(process.env.VUE_APP_DB + '/api2/mailToagencybyagency3', sendValues, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});
}

export const MailTohotelbyall4 = async (sendValues) => {
// 4. E-Mail an eigene Hotels bei Reservierung
    await axios.post(process.env.VUE_APP_DB + '/api2/mailTohotelbyall4', sendValues, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});
}

export const MailTocustomeratcancel5 = async (sendValues) => {
// 5. E-Mail an Kunde bei Stornierung durch Admin
    await axios.post(process.env.VUE_APP_DB + '/api2/mailTocustomeratcancel5', sendValues, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});
}

export const MailTohotelatcancel6 = async (sendValues) => {
// 6. E-Mail an eigene Hotels bei Stornierung
    await axios.post(process.env.VUE_APP_DB + '/api2/mailTohotelatcancel6', sendValues, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});
}

export const MailTocustomerbevorconfirmation7 = async (sendValues) => {
// 7. E-Mail an Kunde bei Reservierungseingang bevor Bestätigung (nur wenn keine automatische Reservierung)
    await axios.post(process.env.VUE_APP_DB + '/api2/mailTocustomerbevorconfirmation7', sendValues, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});
}
export const MailPWforgotten = async (sendValues) => {
// Passwort vergessen
    await axios.post(process.env.VUE_APP_DB + '/api2/mailnpw', sendValues, {headers: {"authorization": 'Bearer ' + await getToken()}});
}


export const getToken = async () => {

    let payload = {
        name: "kunde"
    }

    let token = jwt.sign(payload, process.env.VUE_APP_JWTSECRET, {expiresIn: 46800});
    return token;
}
