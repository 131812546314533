import axios from "axios";

export const getAllPlaces = async () => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/places", {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});

    let place = res.data.data;

    return place;
}


export const getAllOwnHalalPlaces = async (lang) => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/places" + lang + "l10", {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});

    let place = res.data.data;

    return place;
}

export const getPlaceByName = async (searchname) => {
    let place = "";
    try {
        let res = await axios.get(process.env.VUE_APP_DB + "/api2/placebyname/" + searchname, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});

        place = res.data.data;
    } catch (e) {
        return "";
    }

    return place;
}
export const getPlaceBylang = async () => {
    let place = "";
    try {
        let res = await axios.get(process.env.VUE_APP_DB + "/api2/placebyLang/" + localStorage.getItem("lang"), {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});

        place = res.data.data;
    } catch (e) {
        return "";
    }

    return place;
}


export const addPlace = async (pl) => {
    let place = {
        place: pl,
        lang: localStorage.getItem("lang")
    }
    let res = await axios.post(process.env.VUE_APP_DB + "/api2/place", place, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});


}

export const addPlacetr = async (pl) => {
    let place = {
        place: pl
    }
    let res = await axios.post(process.env.VUE_APP_DB + "/api2/placetr", place, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});


}