<template>
  <div>
    <v-snackbar
        v-model="notificationsnackbar"
        :timeout="6000"
        :color="notificationcolor"
        :top="true"
        :right="true"
        :vertical="true"
    >
      {{ notificationtext }}
      <v-btn
          color="white"
          text
          @click="notificationsnackbar = false"
      >
        {{ $t("Notification.Schliessen") }}
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

    <h4>{{ $t("Pictures.Bilder") }}</h4><br/>
    <label v-if="pictures_place.length <1">{{ $t("Pictures.KeineBilder") }}</label>
    <div class="div-ppool-galery-picturepool">

      <template v-for="(item,index) in pictures_place">
        <div class="div-used-img-picturepool">
          <img
              class="img-upload-picturepool"
              :src="item.url" alt="Foto"
          />
          <!--          <CardSmallLocation :item="item"></CardSmallLocation>-->
          <div class="div-img-entfernen-picturepool">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon @click="removePicture(item)" v-on="on">mdi-close</v-icon>
              </template>
              <span>{{ $t("Pictures.Button.RemoveBildTT") }}</span>
            </v-tooltip>
          </div>
        </div>
      </template>
    </div>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
            class="ma-2"
            outlined
            color=var(--Primary)
            @click="showppool = !showppool"
            v-on="on"
        >
          {{ $t("Button.BildHinzufuegen") }}
        </v-btn>
      </template>
      <span>{{ $t("Pictures.Button.BilderGalerieTT") }}</span>
    </v-tooltip>

    <v-overlay v-if="showppool" opacity="0.95" class="overlay-picturepool">
      <!--    <v-overlay v-if="showppool" class="div-ppool-picturepool>">-->
      <div class="div-header-ppool-picturepool">
        <label class="label-galerie-picturepool">{{ $t("Pictures.Galerie") }}</label>
        <v-btn class="button-close-overlay-picturepool" outlined @click="showppool = !showppool">
          <v-icon color="black">mdi-close</v-icon>
        </v-btn>
      </div>

      <div class="div-ppool-galery-picturepool">
        <template v-if="picturepool.length >=0" v-for="(item,index) in picturepool">

          <div class="div-used-img-picturepool">
            <div style="height: 85%">


              <input
                  :id="'img-ppool' + index"
                  class="checkbox-ppool-picturepool"
                  type="checkbox"
                  :name="'picture' + index"
                  @click="checkboxChange(index,item.name)"
                  :checked="checkSelectedImages(item.name)"
              />
              <label class="label-forchecked-ppool-picturepool" :for="'img-ppool' + index">
                <img
                    class="img-ppool-picturepool"
                    :src=" item.url"
                    alt="Foto-Reise"
                />
              </label>


            </div>


            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon @click="deletePicturePPool(index)" color="var(--Primary)" v-on="on">
                  mdi-delete-forever-outline
                </v-icon>
              </template>
              <span>{{ $t("Pictures.FotoLoeschen") }}</span>
            </v-tooltip>
          </div>
        </template>
      </div>

      <hr class="hr-horizontale-linie"/>

      <label class="label-weitere-bilder-picturepool">{{ $t("Pictures.WeitereBilder") }}</label>
      <div class="div-img-upload-picturepool">

        <label>max. 100 KB</label>
        <input type="file" name="file" @change="this.onFileHandler"/>
        <br/>
        <div className="form-group">
          <Progress
              max="100" color="success"
              :value="this.loaded"
          >{{ Math.round(this.loaded, 2) }}%
          </Progress>

        </div>
        <!--        <label v-model="error">{{ error }}</label>-->
        <v-btn outlined color="var(--Primary)" @click=savePhoto>
          {{ $t("Button.Upload") }}
        </v-btn>
        <br/>
      </div>

      <hr class="hr-horizontale-linie"/>
      <div class="div-button-bestaetigen-ppool-picturepool">
        <v-btn color="var(--Primary)" @click="showppool = !showppool">{{ $t("Button.Bestaetigen") }}</v-btn>
      </div>

    </v-overlay>


  </div>

</template>

<script>
import axios from "axios";
import logo from "../../assets/CeeYuu_500x127.png";
import logo2 from "../../assets/CeeYuu_500x127.png";
import CardSmallLocation from "@/components/card/CardSmallLocation";
import {fileupload} from "@/util/fileUtil";
import {deleteFile, getUserinfoByID, updateUserInfo} from "@/util/userinfoDBUtil";
import {updateSettings} from "@/util/settingsUtil";

export default {
  name: "PicturePool",
  props: [
    'pLang',
    'pUserinfo',
    'pictures_place',
    'pictures_rooms'
  ],
  components: {CardSmallLocation},
  data() {
    return {
      notificationsnackbar: false,
      notificationtext: "",
      notificationcolor: "grey",


      showppool: false,
      picturepool: [],
      checkboxpicture: false,
      file: null,
      selectedFile: null,
      loaded: 0,
      error: "",
      photo: "",

      pictures: ""
    }
  },
  methods: {

    notification(text, color) {
      this.notificationsnackbar = true;
      this.notificationtext = text;
      this.notificationcolor = color;
    },

    removePicture(item) {

      let pictures = this.pictures_place.filter(v => v.name !== item.name);
      this.$root.$emit("pictureplace", pictures);
      //this.pictures = this.pictures.filter((v, i) => v.name !== item.name);
      //console.log(pictures);
    },
    checkboxChange(index, pic) {


      if (!this.checkSelectedImages(pic)) {
        let pool = this.picturepool;
        let p = pool[index];

        let pictures = this.pictures_place;
        pictures.push({name: p.name, original_name: p.original_name, url: p.url});
        // this.pictures_place = pictures;
      } else {
        let pool = this.picturepool;
        let p = pool[index];
        let pictures = this.pictures_place.filter(item => item.name !== p.name);
        // this.pictures_place = pictures;


      }

    },
    checkSelectedImages(imagename) {

      let pictures = this.pictures_place;
      return pictures.some(item => item.name === imagename);

    },
    async deletePicturePPool(index) {

      let pool = this.picturepool;
      let pictures = pool;
      let temp = pictures[index];
      pictures = pictures.filter((p, i) => i !== index);
      pool = pictures;
      this.picturepool = pool;


      try {
        //TODO: In DBUtil übertragen
        let userinfo = this.pUserinfo;
        let data = {
          ...userinfo,
          picturepool: this.picturepool
        }
        await updateUserInfo(data);

      } catch (e) {

      }
      let res = await deleteFile(temp.name);
      this.pictures = this.pictures.filter(item => item.name !== temp.name);


      if (res.status === 200) {
        this.notification(this.$t("NotificationFotoGeloescht"), "green");
      }
    },

    onFileHandler(event) {
      let file = event.target.files[0]
      if (this.checkMimeType(event) && this.checkFileSize(event)) {
        // if return true allow to setState
        this.selectedFile = file;
      }
    },

    checkMimeType(event) {
      //getting file object
      let file = event.target.files[0];
      //define message container
      let err = ''
      // list allow mime type
      const types = ['image/png', 'image/jpeg', 'image/gif']
      // loop access array

      // compare file type find doesn't matach
      if (types.every(type => file.type !== type)) {
        // create error message and assign to container
        err += file.type + ' is not a supported format\n';


        this.notification(this.$t("NotificationFormatfalsch"), 'error');


      }


      if (err !== '') { // if message not same old that mean has error
        event.target.value = null // discard selected file
        return false;
      }
      return true;

    },

    checkFileSize(event) {
      let file = event.target.files[0]
      let size = 100000 //100kb
      let err = "";

      if (file.size > size) {
        err += file.type + 'Datei zu groß, bitte die maximale größe beachten.\n';

        this.notification(this.$t("NotificationBildzugross100kb"), 'error');


      } else {
        this.error = "";
      }

      if (err !== '') {
        event.target.value = null
        this.error = err;
        return false
      }
      return true;
    },

    async onClickHandler() {
      if (this.error === "" && this.selectedFile !== null) {
        // await this.deletePhoto();
        let fileUploadData = await fileupload(this.selectedFile);
        this.photo = {
          name: fileUploadData.filename,
          originalname: fileUploadData.originalname,
          url: process.env.VUE_APP_IMAGES + fileUploadData.filename
        };
      }

    },


    async deletePhoto(item) {
      if (item === undefined) {
        item = this.item;
      }

      if (item !== undefined) {

        if (item !== null) {
          let foto = item.photo;

          foto = foto.replace("//", "");
          foto = foto.split("/")[1];

          let res = await deleteFile(foto);

          if (res.status === 200) {
            this.notification("Foto gelöscht", "green");

            await new Promise(resolve => setTimeout(resolve, 500));
            // location.reload();

          }
        } else {
          this.notification("Fehler", "error");
        }

      }
    },


    async savePhoto() {
      await this.onClickHandler();


      this.picturepool.push(this.photo);
      let s = {
        ...this.pUserinfo,
        picturepool: this.picturepool

      }


      await updateUserInfo(s);
      this.overlayPhotoUpload = false;
      // location.reload();
    },

  },
  async created() {
    this.picturepool = this.pUserinfo.picturepool;


  }
}
</script>

<style scoped>
@import "../../css/Picturepool.css";
</style>