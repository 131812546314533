<template>
  <div class="div-settingsdesign">
    <v-snackbar
        v-model="notificationsnackbar"
        :timeout="6000"
        :color="notificationcolor"
        :top="true"
        :right="true"
        :vertical="true"
    >
      {{ notificationtext }}
      <v-btn
          color="white"
          text
          @click="notificationsnackbar = false"
      >
        {{ $t("Notification.Schliessen") }}
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

    <v-overlay :value="loading">
      <v-progress-circular
          v-if="loading"
          indeterminate
          color="primary"
          :size="50"
          :width="7"
      />
    </v-overlay>

    <div class="bodyheader-settingsdesign">
      <label class="label-bodyheader-settingsdesign">

        <v-icon class="icon-start" color="white">mdi-image-multiple</v-icon>
        Design
      </label>
      <span></span>

    </div>

    <div style="margin: 20px 0 30px">
      <h3>
        Startseite
      </h3>
      <div>

        <div>
          <h3 style="margin: 15px 0">
            Fotos auf der Startseite
          </h3>
          <v-btn @click="newPhoto = true">neues Bild hochladen</v-btn>
          <OverlayPhotoUpload
              :overlayPhotoUpload="newPhoto"
              from="settingsdesign"
              :pSetting="pSetting"
          />

          <v-btn
              @click="savePhotosLandingpage"
              color="var(--Primary)"
              class="vbtn-savefav-settingsfavorites"
          >
            {{ $t("Button.Speichern") }}
          </v-btn>
        </div>


        <div style="text-align: center; max-width: 500px; margin: auto">

          <FavRow
              :favPlace="settingsLandingpagePhotos"
              :pLang="pLang"
              favCategory="landingpage"
          />

        </div>


        <br>
        <v-divider></v-divider>
        <br>


      </div>
    </div>

    <div class="div-body-settingsdesign"
         v-if="!loading && halalPlace !== undefined && halalPlace.groups !== undefined && halalPlace!== ''">
      <h3>
        Ansicht für Suchergebnisse
      </h3>

      <div>
        <v-radio-group v-model="cardPlaceDesign">
          <v-radio
              key='1'
              label="Design 1"
              value="1"
          />

          <CardPlace
              :hotelinfos="halalHotels.offers[0]"
              :checkin="checkin"
              :checkout="checkout"
              :groups="groups"
              from="design"
          />

        </v-radio-group>

      </div>
    </div>
    <br>
    <v-divider></v-divider>
    <br>
    <div v-if="!loading">
      <h3>
        Ansicht für Räume
      </h3>
      <div v-if="halalPlace.groups !== undefined && halalPlace !== ''">
        <v-radio-group v-model="cardRoomDesign">
          <div class="div-rooms-settingsdesign">

            <div class="div-rooms-inner-settingsdesign">
              <v-radio
                  key='1'
                  label="Standard"
                  value="1"
              />
              <CardRoom
                  :roominfo="halalPlace.groups[0].offers[0]"
                  :place="halalPlace"
                  from="design"
              />

            </div>

            <!--            <div class="div-rooms-inner-settingsdesign">-->
            <!--              <v-radio-->
            <!--                  key='2'-->
            <!--                  label="Zoom"-->
            <!--                  value="2"-->
            <!--                  disabled-->
            <!--              />-->
            <!--              <CardRoom2-->
            <!--                  :roominfo="halalPlace.groups[0].offers[0]"-->
            <!--                  :place="halalPlace"-->
            <!--                  from="design"-->
            <!--              />-->
            <!--            </div>-->

            <div class="div-rooms-inner-settingsdesign">
              <v-radio
                  key='3'
                  label="Flipper"
                  value="3"
              />

              <CardRoom3
                  :roominfo="halalPlace.groups[0].offers[0]"
                  :place="halalPlace"
                  from="design"
              />
            </div>

            <div class="div-rooms-inner-settingsdesign">
              <v-radio
                  key='4'
                  label="Middle"
                  value="4"
              />

              <CardRoom4
                  :roominfo="halalPlace.groups[0].offers[0]"
                  :place="halalPlace"
                  from="design"
              />
            </div>

          </div>

          <div class="div-savebtn-settingsdesign">
            <v-btn
                class="ma-2"
                color=var(--Primary)
                style="color: white"
                @click="savesettings"
                block
            >
              {{ $t("Button.Speichern") }}
            </v-btn>
          </div>


        </v-radio-group>

      </div>
    </div>


  </div>

</template>

<script>
import CardPlace from "@/components/card/CardPlace";
import CardRoom from "@/components/card/CardRoom";
import CardRoom2 from "@/components/card/CardRoom2";
import CardRoom3 from "@/components/card/CardRoom3";
import CardRoom4 from "@/components/card/CardRoom4";
import {getInitPlaces, onePlaceWithParameter} from "@/util/halalapi";
import {getUser} from "../util/Auth";
import {deleteFile, getUserinfoByUserId, updateUserInfo} from "../util/userinfoDBUtil";
import OverlayPhotoUpload from "@/components/overlay/OverlayPhotoUpload";
import FavRow from "@/components/card/FavRow"
import {updateSettings} from "@/util/settingsUtil";

export default {
  name: "SettingsDesign",
  props: [
    'pLang',
    'pRolle',
    'pUser',
    'pUserinfo',
    'pSetting'
  ],
  components: {
    OverlayPhotoUpload,
    CardPlace,
    CardRoom,
    CardRoom2,
    CardRoom3,
    CardRoom4,
    FavRow
  },
  data() {
    return {

      notificationsnackbar: false,
      notificationtext: "",
      notificationcolor: "grey",

      settingsLandingpagePhotos: {},
      newPhoto: false,

      halalHotels: {},
      halalPlace: {},
      loading: false,

      paging: "1",

      checkin: "",
      checkout: "",
      groups: "2",

      cardPlaceDesign: "1",
      cardRoomDesign: "1",
      selectedCardPlace: 0,
      selectedCardRoom: 0,
      userinfo: {},
      setting: ""


    }
  },
  methods: {

    notification(text, color) {
      this.notificationsnackbar = true;
      this.notificationtext = text;
      this.notificationcolor = color;
    },


    async savePhotosLandingpage() {
      this.loading = true;
      let setting = {
        ...this.pSetting,
        landingpagephotos: this.settingsLandingpagePhotos
      }
      await updateSettings(setting);

      this.loading = false;
      this.notification("Speichern erfolgreich", "green");


    },


    async deletePhotos(item) {

      this.loading = true;

      this.settingsLandingpagePhotos = this.settingsLandingpagePhotos.filter(it => it._id !== item._id);
      let setting = {
        ...this.pSetting,
        landingpagephotos: this.settingsLandingpagePhotos
      }
      await updateSettings(setting);

      this.loading = false;
      this.notification("Bild gelöscht", "green");

    },

    async deletePhotofromServer(item) {
      await deleteFile(item.picture);
    },

    settingsObject(set) {

      let settings = {
        ...set,
        hotelcard: this.cardPlaceDesign,
        roomcard: this.cardRoomDesign
      }

      this.$store.dispatch('settings', settings);
      return settings;
    },
    async savesettings() {
      this.loading = true;
      let user = await getUser(localStorage.getItem("token"));
      let userinfo = await getUserinfoByUserId(user._id);
      let ui = this.settingsObject(userinfo);
      await updateUserInfo(ui);


      this.loading = false;
      this.notification("Speichern erfolgreich", "green");

      await new Promise(resolve => setTimeout(resolve, 500));
      location.reload();

    }
  },

  async created() {
    this.$root.$on('closePhotoUpload', val => {
      this.newPhoto = false;
    })


    this.$root.$on('deletelandingpagePhoto', async val => {
      await this.deletePhotos(val);
      await this.deletePhotofromServer(val);
    })
  },

  async mounted() {

    this.loading = true;
    this.halalHotels = await getInitPlaces("1");


    // let id = this.halalHotels.offers[0].place.id;
    let d = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 16);
    this.checkin = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();

    let d1 = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 18);
    this.checkout = d1.getFullYear() + "-" + (d1.getMonth() + 1) + "-" + d1.getDate();

    let place = await onePlaceWithParameter("8698", this.checkin, this.checkout, '&groups[]=' + this.groups);
    this.halalPlace = place;


    this.cardPlaceDesign = this.pUserinfo.hotelcard;
    this.cardRoomDesign = this.pUserinfo.roomcard;

    if (this.halalHotels !== {}) {
      this.loading = false;
    }
    this.settingsLandingpagePhotos = this.pSetting.landingpagephotos;
  }
}
</script>

<style scoped>
@import "../css/SettingsDesign.css";
</style>