import axios from "axios";


export const fileupload = async (file, loaded) => {
    const data = new FormData()
    let fileUploadData = {};
    data.append('file', file)
    await axios.post(process.env.VUE_APP_DB + "/api2/uploads", data, {

        headers: {
            "Accept": "application/json",
            'Access-Control-Allow-Origin': '*',
            "Access-Control-Allow-Headers": "Origin,Content-Type,X-Requested-With,Accept,Authorization",
            'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PATCH, PUT',
            "authorization": 'Bearer ' + localStorage.getItem('token')
        },

        onUploadProgress: ProgressEvent => {
            loaded = (ProgressEvent.loaded / ProgressEvent.total * 100);
        },

    }).then(res => { // then print response status
        fileUploadData = res.data;
    })

    return fileUploadData;

}