import axios from "axios";
import jwt from "jsonwebtoken";

export const createUser = async (user) => {
    let token = await axios.post(process.env.VUE_APP_DB + "/api2/user", user, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});

    return token;
}


export const getAllUsers = async () => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/users", {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});

    let users = res.data.data;

    return users;
}
export const getAllReiseburosByFirmaID = async () => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/rb", {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});

    let users = res.data.data;

    return users;
}

export const getUserByID = async (user_id) => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/user/" + user_id, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});
    let user = res.data.data;

    return user;
}

export const getUserByEmail = async (email) => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/userbyemail/" + email, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});
    let user = res.data.data;

    return user;
}

export const getUserStatusByEmail = async (email) => {
    let status = 0;
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/usm/" + email).then(res => status = res.status);


    return status;
}

export const getAdminUser = async () => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/auser", {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});
    let user = res.data.data;

    return user;
}

export const getAllHotelUser = async () => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/ht", {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});
    let user = res.data.data;

    return user;
}

export const getIDFromAdminUser = async () => {
    let res = await axios.get(process.env.VUE_APP_DB + "/api2/aid", {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});
    let user = res.data.data;

    return user;
}

export const signIn = async (email, password) => {
    let res = "";
    try {
        let apiBaseUrl = process.env.VUE_APP_DB + "/api2/";

        let payload = {
            "email": email.toLowerCase().trim(),
            "password": password.trim(),
        }
        res = await axios.post(apiBaseUrl + 'auth', payload)
    } catch (e) {
    }
    return res;
}

export const updateUser = async (user) => {
    let baseurl = process.env.VUE_APP_DB + "/api2/user/" + user._id;
    let status = 0;
    await axios.put(baseurl, user, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}}).then(res => status = res.status).catch(err => {

    });
    return status;
}


export const updateUserAtAccounts = async (user) => {
    let baseurl = process.env.VUE_APP_DB + "/api2/useraccount/" + user._id;
    let status = 0;
    await axios.put(baseurl, user, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}}).then(res => status = res.status).catch(err => {

    });
    return status;
}

export const updateUserForResetPW = async (user) => {
    let baseurl = process.env.VUE_APP_DB + "/api2/user/" + user._id;
    let status = 0;
    await axios.put(baseurl, user, {headers: {"authorization": 'Bearer ' + await getToken()}}).then(res => status = res.status).catch(err => {

    });
    return status;
}


export const getToken = async () => {

    let payload = {
        name: "kunde"
    }

    let token = jwt.sign(payload, process.env.VUE_APP_JWTSECRET, {expiresIn: 46800});
    return token;
}
