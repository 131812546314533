export const states_de = ['', 'Admin', 'Kunde', 'Reisebüro', 'iframe'];
const states_tr = ['', 'Admin', 'Müşteri', 'Seyahat acentası', 'iframe'];
const states_en = ['', 'Admin', 'Customer', 'Travel agency', 'iframe'];

export const getOrderBy = (state) => {


    switch (state) {
        case "0":
            return 'Orders.Admin';
        case "1":
            return 'Orders.Kunde';
        case "2":
            return 'Orders.Reisebüro';
        case "3":
            return 'Orders.iframe';

    }
}

export const getOrderByStateNr = (state, lang) => {


    let states = '';
    if (lang === "DE") {
        states = states_de;
        return getStatusCodeDE(states, state)
    }
    if (lang === "TR") {
        states = states_tr;
        return getStatusCodeTR(states, state)
    }
    if (lang === "EN") {
        states = states_en;
        return getStatusCodeEN(states, state)
    }


}

export const orderStateNrExist = (status, selectedStates, lang) => {


    let states = '';
    //status
    // [NEU,IN ZAHLUNG]
    // DE
    for (let i = 0; i < selectedStates.length; i++) {
        let nr = ""
        if (lang === "DE") {
            states = states_de;
            nr = getOrderByStatusCodeDE(states, selectedStates[i])
        }
        if (lang === "TR") {
            states = states_tr;
            nr = getOrderByStatusCodeTR(states, selectedStates[i])
        }
        if (lang === "EN") {
            states = states_en;
            nr = getOrderByStatusCodeEN(states, selectedStates[i])
        }
        if (status === nr) {
            return true;
        }
    }
    return false;

}

const getOrderByStatusCodeDE = (states, state) => {
    switch (state) {
        case "Admin":
            return states.indexOf(state).toString();
        case "Kunde":
            return states.indexOf(state).toString();
        case "Reisebüro":
            return states.indexOf(state).toString();
        case "iframe":
            return states.indexOf(state).toString();
        default:
            return "";

    }
}

const getOrderByStatusCodeTR = (states, state) => {

    switch (state) {
        case "Admin":
            return states.indexOf(state).toString();
        case "Müşteri":
            return states.indexOf(state).toString();
        case "Seyahat acentası":
            return states.indexOf(state).toString();
        case "iframe":
            return states.indexOf(state).toString();
        default:
            return "";

    }
}


const getOrderByStatusCodeEN = (states, state) => {
    switch (state) {
        case "Admin":
            return states.indexOf(state).toString();
        case "Customer":
            return states.indexOf(state).toString();
        case "Travel Agency":
            return states.indexOf(state).toString();
        case "iframe":
            return states.indexOf(state).toString();
        default:
            return "";

    }
}
