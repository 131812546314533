export const getStyles = () => {
    let style = {

        // ------------------------------------------ Header ------------------------------------------

        // Rechtsbündig mit margin
        headerRight: {
            fontSize: 18,
            bold: true,
            alignment: 'right',
            margin: [0, 40, 30, 20],
        },


        // logo: {
        //     fit: [100, 100]
        // },
        // labelheaderurl: {
        //     fontSize: 14,
        //     bold: true,
        //     margin: [50, 30, 30, 0],
        //     color: '#696969'
        // },
        // labelheaderadresse: {
        //     fontSize: 11,
        //     margin: [50, 0, 30, 0],
        //     color: '#696969'
        // },
        // dateHeader: {
        //     fontSize: 10,
        //     margin: [30, 30, 30, 20],
        //     alignment: 'center',
        //     color: '#696969'
        // },


        // ------------------------------------------ Header Ende ------------------------------------------


        // ------------------------------------------ Footer ------------------------------------------
        marginfooter: {
            margin: [40, 0, 40, 0]
        },
        labelfooter: {
            fontSize: 10,
            color: '#696969'
        },

        labelfooterleft: {
            fontSize: 10,
            margin: [20, 40, 0, 0],
            color: '#696969'
        },
        // ------------------------------------------ Footer Ende ------------------------------------------


        // ------------------------------------------ Content ------------------------------------------

        h1: {
            fontSize: 16,
            bold: true
        },
        h2: {
            fontSize: 14,
            bold: true,
        },
        h3: {
            fontSize: 13,
            bold: true
        },
        margin1: {
            margin: [0, 10, 0, 5]
        },
        labelListeTabelle: {
            fontSize: 10
        },
        kursiv: {
            fontStyle: 'italic'
        },
        AnschriftMargin: {
            margin: [0, 60, 0, 8]
        },

        AdminAdress: {
            fontSize: 8,
            color: '#1178BD',
            margin: [0, 5, 0, 8]

        },

        Betreff: {
            fontSize: 24,
            bold: true,
            color: '#1178BD',
            margin: [0, 60, 0, 15]
        },

        tabelleBeschreibung: {
            margin: [0, 20, 0, 10]
        },


        // ------------------------------------------ Content Ende ------------------------------------------


        // ------------------------------------------ style ------------------------------------------
        primary: {
            color: '#f97150'
        },
        secondary: {
            color: '#20BBFB'
        },
        bold: {
            bold: true
        }
        // ------------------------------------------ style Ende ------------------------------------------


    };

    return style;
}
