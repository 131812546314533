<template>
  <div class="div-settingplace" v-if="pRolle==='admin' || pRolle==='superadmin'">
    <v-snackbar
        v-model="notificationsnackbar"
        :timeout="6000"
        :color="notificationcolor"
        :top="true"
        :right="true"
        :vertical="true"
    >
      {{ notificationtext }}
      <v-btn
          color="white"
          text
          @click="notificationsnackbar = false"
      >
        {{ $t("Notification.Schliessen") }}
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

    <v-overlay :value="loading">
      <v-progress-circular
          v-if="loading"
          indeterminate
          color="primary"
          :size="50"
          :width="7"
      />
    </v-overlay>

    <div class="bodyheader-accounts">
      <label class="label-bodyheader-accounts">

        <v-icon class="icon-start" color="white">mdi-home-edit</v-icon>
        {{ $t('Setting.BuchungProvision') }}
      </label>
      <span></span>

    </div>

    <div class="body-settingsbooking">
      <div>
        <h3 style="margin: 15px 0">
          {{ $t("Setting.BuchungFreigabe") }}
        </h3>

        <v-switch
            v-model="order_approval"
            :label="order_approval ? $t('Setting.AutoFreigabe'): $t('Setting.ManuFreigabe')"
            color="green"
        ></v-switch>
        <div v-if="order_approval">
          {{ $t("Setting.AutomatischText") }}
        </div>
        <div v-if="!order_approval">
          {{ $t("Setting.ManuellText") }}
        </div>


        <v-btn @click="saveSettings" color="var(--Primary)" style="color: white; margin: 30px 0">
          {{ $t("Button.Speichern") }}
        </v-btn>


        <br>
        <v-divider></v-divider>
        <br>


        <div>
          <h3>
            {{ $t("Setting.Provisionsklassen") }}
          </h3>


          <div style="max-width: 800px; margin: 50px 0;">
            <template>
              <div>
                <v-data-table
                    :headers="headers"
                    :items="pSetting.provisionclasses"
                    item-key="name"
                    class="elevation-1"
                    sort-by="classname"
                    :footer-props="{
                      'items-per-page-text': $t('Tabelle.ZeilenproSeite'),
                      'items-per-page-options': [15, 30, 100]
                    }"
                >

                  <template v-slot:top>


                    <v-toolbar flat>
                      <v-toolbar-title> {{ $t("Setting.Provisionsklassen") }}</v-toolbar-title>

                      <v-spacer></v-spacer>
                      <v-spacer></v-spacer>

                      <v-dialog
                          v-model="dialog"
                          max-width="600px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              color="primary"
                              dark
                              class="mb-2"
                              v-bind="attrs"
                              v-on="on"
                              @click="neueKlasse"
                          >
                            {{ $t("Button.Neu") }}
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                          </v-card-title>

                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                                >

                                  <v-text-field
                                      v-model="editedItem.classname"
                                      label="Text"

                                  ></v-text-field>
                                </v-col>

                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="close"
                            >
                              {{ $t("Button.Abbrechen") }}
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="save"
                            >
                              {{ $t("Button.Speichern") }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>

                      <v-dialog
                          v-model="dialog"
                          max-width="600px"
                      >
                        <v-card>
                          <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                          </v-card-title>

                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                                >

                                  <v-text-field
                                      v-model="editedItem.classname"
                                      :label="$t('Setting.Klasse')"
                                      disabled
                                  ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                                >
                                  <v-text-field
                                      v-model="editedItem.provision"
                                      :label="$t('Setting.Provision')"
                                      type="number"
                                      append-icon="mdi-percent"
                                  ></v-text-field>
                                </v-col>


                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="close"
                            >
                              {{ $t("Button.Abbrechen") }}
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="save"
                            >
                              {{ $t("Button.Speichern") }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-dialog v-model="dialogDelete" max-width="600px">
                        <v-card>
                          <v-card-title class="text-h5" style="word-break: unset">
                            {{ $t("Setting.KlasseLoeschen") }}

                          </v-card-title>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="closeDelete">
                              {{ $t("Button.Abbrechen") }}
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                              {{ $t("Button.OK") }}
                            </v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>

                  </template>


                  <template v-slot:item.provision="{ item }">

                    {{ item.provision }} %

                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        @click="editItem(item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                        small
                        @click="deleteItem(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>


                </v-data-table>
              </div>
            </template>
          </div>
        </div>


        <v-divider></v-divider>


        <div style="max-width: 300px">

          <h3 style="margin: 20px 0; display: block;">Provision von halalbooking</h3>

          <v-text-field
              v-model="halalprovision"
              label="Provision"
              outlined
              append-icon="mdi-percent"


          />

          <v-btn @click="saveHalalProvision" color="var(--Primary)" style="color: white; margin: 30px 0">
            {{ $t("Button.Speichern") }}
          </v-btn>
        </div>

      </div>


    </div>


  </div>
</template>

<script>
import {updateSettings} from "@/util/settingsUtil";
import ClassUitil from "@/util/ClassUitil";

export default {
  name: "SettingsBooking",
  props: [
    'pLang',
    'pRolle',
    'pSetting'
  ],
  data() {
    return {
      notificationsnackbar: false,
      notificationtext: "",
      notificationcolor: "grey",


      order_approval: false,

      // setting: "",
      loading: false,

      classA: "",
      classB: "",
      classC: "",


      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        classname: "",
        provision: ""

      },
      defaultItem: {
        classname: "",
        provision: ""
      },
      anzahlZeilen: [15, 30, 80],


      halalprovision: "",

    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    notification(text, color) {
      this.notificationsnackbar = true;
      this.notificationtext = text;
      this.notificationcolor = color;
    },

    async saveHalalProvision() {

      let setting = this.pSetting;
      setting = {
        ...setting,
        halalprovision: this.halalprovision
      }

      let status = await updateSettings(setting);

      if (status === 200) {
        this.notification("Speichern erfolgreich", "green");
      } else {

        this.notification("Fehler beim Speichern", "red");
      }
      // await new Promise(resolve => setTimeout(resolve, 1000));

      // location.reload();

    },


    neueKlasse() {

      let cu = new ClassUitil();
      this.editedItem.classname = cu.getNext(this.pSetting.provisionclasses);
    },

    editItem(item) {
      this.editedIndex = this.pSetting.provisionclasses.indexOf(item)
      this.editedItem = Object.assign({}, item)

      this.dialog = true
    },

    async deleteItemConfirm() {
      //await deleteOwnHalalProvisionByID(this.editedItem._id);
      this.pSetting.provisionclasses.splice(this.editedIndex, 1)

      await updateSettings(this.pSetting);
      //this.this.setting.provisionclasses.splice(this.editedIndex, 1)
      this.closeDelete()

      this.notification("Hotel erfolgreich gelöscht!", "green");
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async deleteItem(item) {
      this.editedIndex = this.pSetting.provisionclasses.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true

    },

    async saveSettings() {
      this.loading = true;
      // let user = await getUser(localStorage.getItem("token"));
      let setting = this.pSetting;


      let set = {
        ...setting,
        order_approval: this.order_approval
      }

      let state = await updateSettings(set);
      this.loading = false;
      if (state === 200) {
        this.notification("Speichern erfolgreich", "green");
      }

    },
    async save() {

      let proclass = this.pSetting.provisionclasses;
      if (proclass === undefined) {
        proclass = [];
      }
      if (this.editedIndex === -1) {
        proclass.push({classname: this.editedItem.classname, provision: this.editedItem.provision});
      } else {
        proclass[this.editedIndex] = {classname: this.editedItem.classname, provision: this.editedItem.provision}
      }

      let set = {
        ...this.pSetting,
        provisionclasses: proclass
      }
      await updateSettings(set);
      // this.dialog = false;

      location.reload();
    }
  },


  async created() {
    await new Promise(resolve => setTimeout(resolve, 500));
    let setting = await this.pSetting;


    this.halalprovision = setting.halalprovision;
    this.order_approval = setting.order_approval;
    let cu = new ClassUitil();

    if (setting.provisionclasses) {
      this.editedItem.classname = cu.getNext(setting.provisionclasses);
    }

  },

  computed: {
    headers() {
      return [
        {
          text: this.$t('Setting.Klasse'),
          align: 'start',
          value: 'classname',
        },
        {text: this.$t('Setting.Provision'), value: 'provision'},
        {text: 'Actions', value: 'actions', sortable: false},


      ]
    },
    formTitle() {
      return this.editedIndex === -1 ? this.$t('Setting.NeueProvisionsklasse') : this.$t('Setting.Bearbeiten')
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  }

}
</script>

<style scoped>
@import "../css/SettingsBooking.css";

</style>
