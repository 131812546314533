<template>
  <div class="div-settingplace" v-if="pRolle==='superadmin'">
    <v-snackbar
        v-model="notificationsnackbar"
        :timeout="6000"
        :color="notificationcolor"
        :top="true"
        :right="true"
        :vertical="true"
    >
      {{ notificationtext }}
      <v-btn
          color="white"
          text
          @click="notificationsnackbar = false"
      >
        {{ $t("Notification.Schliessen") }}
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-overlay :value="loading">
      <v-progress-circular
          v-if="loading"
          indeterminate
          color="primary"
          :size="50"
          :width="7"
      />
    </v-overlay>

    <div class="bodyheader-accounts">
      <label class="label-bodyheader-accounts">

        <v-icon class="icon-start" color="white">mdi-home-edit</v-icon>
        Hotel Einstellungen
      </label>
      <span></span>

    </div>

    <div class="body-settingplace">


      <div style="margin-bottom: 30px;">

        <v-btn @click="getAllFac">ALLE CSV</v-btn>
        <v-btn @click="getCSV">CSV</v-btn>
        <v-btn @click="saveSettings">Neue Werte Speichern</v-btn>

      </div>

      <div
          style="margin-bottom: 30px; position: sticky; top: 0px; background: var(--Primary); z-index: 3; color: white !important;"
      >
        <v-btn style="color: white" text href="#general">General</v-btn>
        <v-btn style="color: white" text href="#aktivity">Aktivitäten</v-btn>
        <v-btn style="color: white" text href="#food">Essen</v-btn>
        <v-btn style="color: white" text href="#children">Kinder</v-btn>
        <v-btn style="color: white" text href="#entertainment">Entertainment</v-btn>
        <v-btn style="color: white" text href="#internet">Internet</v-btn>
        <v-btn style="color: white" text href="#parking">Parken</v-btn>
        <v-btn style="color: white" text href="#shops">Shops</v-btn>
        <v-btn style="color: white" text href="#reception">Reception</v-btn>
        <v-btn style="color: white" text href="#business">Business</v-btn>
        <v-btn style="color: white" text href="#cleaning">Reinigung</v-btn>
        <v-btn style="color: white" text href="#transport">Transport</v-btn>
        <v-btn style="color: white" text href="#beach">Beach</v-btn>
        <v-btn style="color: white" text href="#spa">Spa</v-btn>
        <v-btn style="color: white" text href="#pool">Pool</v-btn>
      </div>

      <div>
        <h3>Facilities</h3>

        <v-divider id="general"></v-divider>
        <FacilitiesTable :list="gen" title="General"/>
        <v-divider id="aktivity"></v-divider>
        <FacilitiesTable :list="act" title="Aktivitäten"/>
        <v-divider id="food"></v-divider>
        <FacilitiesTable :list="foo" title="Essen & Trinken"/>
        <v-divider id="children"></v-divider>
        <FacilitiesTable :list="chi" title="Kindern"/>
        <v-divider id="entertainment"></v-divider>
        <FacilitiesTable :list="ent" title="Entertainment"/>
        <v-divider id="internet"></v-divider>
        <FacilitiesTable :list="int" title="Internet"/>
        <v-divider id="parking"></v-divider>
        <FacilitiesTable :list="par" title="Parken"/>
        <v-divider id="shops"></v-divider>
        <FacilitiesTable :list="sho" title="Shops"/>
        <v-divider id="reception"></v-divider>
        <FacilitiesTable :list="rec" title="Reception"/>
        <v-divider id="business"></v-divider>
        <FacilitiesTable :list="bus" title="Business"/>
        <v-divider id="cleaning"></v-divider>
        <FacilitiesTable :list="cle" title="Reinigung"/>
        <v-divider id="transport"></v-divider>
        <FacilitiesTable :list="tra" title="Transport"/>
        <v-divider id="beach"></v-divider>
        <FacilitiesTable :list="bea" title="Beach"/>
        <v-divider id="spa"></v-divider>
        <FacilitiesTable :list="spa" title="Spa & Wellness"/>
        <v-divider id="pool"></v-divider>
        <FacilitiesTable :list="poo" title="Pool"/>


      </div>


    </div>

  </div>
</template>

<script>
import {getAllSettings, updateSettings} from "../util/settingsUtil";
import {create, getValues, parseOnlybyComma} from "../util/ParsingUtil";
import FacilitiesTable from "../components/settings/FacilitiesTable";
import {getAllPlaces, getPlaceBylang} from "@/util/placeUtil";

export default {
  name: "SettingsPlace",
  props: [
    'pLang',
    'pRolle',
  ],
  components: {
    FacilitiesTable
  },

  data() {
    return {
      notificationsnackbar: false,
      notificationtext: "",
      notificationcolor: "grey",
      loading: false,

      set: [],
      generallist: [],
      activitieslist: [],
      foodlist: [],
      childrenlist: [],
      entertainmentlist: [],
      internetlist: [],
      parkinglist: [],
      shopslist: [],
      receptionlist: [],
      businesslist: [],
      cleaninglist: [],
      transportlist: [],
      beachlist: [],
      spalist: [],
      poolslist: [],
      facts: "",
      gen: [],
      act: [],
      foo: [],
      chi: [],
      ent: [],
      int: [],
      par: [],
      sho: [],
      rec: [],
      bus: [],
      cle: [],
      tra: [],
      bea: [],
      spa: [],
      poo: [],


    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    notification(text, color) {
      this.notificationsnackbar = true;
      this.notificationtext = text;
      this.notificationcolor = color;
    },
    async saveSettings() {

      let p = await getValues();

      let allFacilities = await create(p);

      let allSet = await getAllSettings();


      allSet = {
        ...allSet,
        facilities_en: {
          ...allSet.facilities_en,
          general: parseOnlybyComma(allFacilities.getGeneral()),
          activities: parseOnlybyComma(allFacilities.getActivities()),
          food: parseOnlybyComma(allFacilities.getFood()),
          children: parseOnlybyComma(allFacilities.getChildren()),
          entertainment: parseOnlybyComma(allFacilities.getEntertainment()),
          internet: parseOnlybyComma(allFacilities.getInternet()),
          parking: parseOnlybyComma(allFacilities.getParking()),
          shops: parseOnlybyComma(allFacilities.getShops()),
          reception: parseOnlybyComma(allFacilities.getReception()),
          business: parseOnlybyComma(allFacilities.getBusiness()),
          cleaning: parseOnlybyComma(allFacilities.getCleaning()),
          transport: parseOnlybyComma(allFacilities.getTransport()),
          beach: parseOnlybyComma(allFacilities.getBeach()),
          spa: parseOnlybyComma(allFacilities.getSpa()),
          pools: parseOnlybyComma(allFacilities.getPools())
        }
      }
      await updateSettings(allSet);
      // location.reload();

    },
    async getAllFac() {
      let places = await getPlaceBylang();
      let facts = "";
      places.map(item => {
        facts += item.place.place.facilities;
        facts += "\n";
      })
      this.facts = facts;
      this.download(this.facts, 'dowload.csv', 'text/csv;encoding:utf-8');
    }
    ,
    getCSV() {
      let str = "";
      str += "Generall";
      str += "\n";
      this.generallist.map(item => {
        str += item.text;
        str += "\n";
      });

      str += "activities";
      str += "\n";
      this.activitieslist.map(item => {
        str += item.text;
        str += "\n";
      });
      str += "foodlist";
      str += "\n";
      this.foodlist.map(item => {
        str += item.text;
        str += "\n";
      });
      str += "childrenlist";
      str += "\n";
      this.childrenlist.map(item => {
        str += item.text;
        str += "\n";
      });

      str += "entertainmentlist";
      str += "\n";
      this.entertainmentlist.map(item => {
        str += item.text;
        str += "\n";
      });
      str += "internetlist";
      str += "\n";
      this.internetlist.map(item => {
        str += item.text;
        str += "\n";
      });


      str += "parkinglist";
      str += "\n";
      this.parkinglist.map(item => {
        str += item.text;
        str += "\n";
      });
      str += "shopslist";
      str += "\n";
      this.shopslist.map(item => {
        str += item.text;
        str += "\n";
      });


      str += "receptionlist";
      str += "\n";
      this.receptionlist.map(item => {
        str += item.text;
        str += "\n";
      });
      str += "businesslist";
      str += "\n";
      this.businesslist.map(item => {
        str += item.text;
        str += "\n";
      });

      str += "cleaninglist";
      str += "\n";
      this.cleaninglist.map(item => {
        str += item.text;
        str += "\n";
      });
      str += "transportlist";
      str += "\n";
      this.transportlist.map(item => {
        str += item.text;
        str += "\n";
      });


      this.download(str, 'dowload.csv', 'text/csv;encoding:utf-8');

      /*  let csvContent = '';
        generallist.forEach(function(infoArray, index) {
          dataString = infoArray.join(';');
          csvContent += index < data.length ? dataString + '\n' : dataString;
        });*/
// The download function takes a CSV string, the filename and mimeType as parameters
// Scroll/look down at the bottom of this snippet to see how download is called


    },
    download(content, fileName, mimeType) {
      let a = document.createElement('a');
      mimeType = mimeType || 'application/octet-stream';
      if (navigator.msSaveBlob) { // IE10
        navigator.msSaveBlob(new Blob([content], {
          type: mimeType
        }), fileName);
      } else if (URL && 'download' in a) { //html5 A[download]
        a.href = URL.createObjectURL(new Blob([content], {
          type: mimeType
        }));
        a.setAttribute('download', fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
      }
    }
  },
  async created() {


    this.set = await getAllSettings();
    let gen = [];
    let act = [];
    let foo = [];
    let chi = [];
    let ent = [];
    let int = [];
    let par = [];
    let sho = [];
    let rec = [];
    let bus = [];
    let cle = [];
    let tra = [];


    this.set.facilities_de.general.map(item => {

      let f_id = item.f_id;
      let en = this.set.facilities_en.general.find(it => it.f_id === item.f_id);
      let tr = this.set.facilities_tr.general.find(it => it.f_id === item.f_id);
      gen.push({f_id: f_id, text_de: item.text, text_tr: tr.text, text_en: en.text});

    })
    this.gen = gen;


    this.set.facilities_de.activities.map(item => {
      let f_id = item.f_id;
      let en = this.set.facilities_en.activities.find(it => it.f_id === item.f_id);
      let tr = this.set.facilities_tr.activities.find(it => it.f_id === item.f_id);
      act.push({f_id: f_id, text_de: item.text, text_tr: tr.text, text_en: en.text});
    })
    this.act = act;


    this.set.facilities_de.food.map(item => {
      let f_id = item.f_id;
      let en = this.set.facilities_en.food.find(it => it.f_id === item.f_id);
      let tr = this.set.facilities_tr.food.find(it => it.f_id === item.f_id);
      foo.push({f_id: f_id, text_de: item.text, text_tr: tr.text, text_en: en.text});
    })
    this.foo = foo;


    this.set.facilities_de.children.map(item => {
      let f_id = item.f_id;
      let en = this.set.facilities_en.children.find(it => it.f_id === item.f_id);
      let tr = this.set.facilities_tr.children.find(it => it.f_id === item.f_id);
      chi.push({f_id: f_id, text_de: item.text, text_tr: tr.text, text_en: en.text});
    })
    this.chi = chi;


    this.set.facilities_de.entertainment.map(item => {
      let f_id = item.f_id;
      let en = this.set.facilities_en.entertainment.find(it => it.f_id === item.f_id);
      let tr = this.set.facilities_tr.entertainment.find(it => it.f_id === item.f_id);
      ent.push({f_id: f_id, text_de: item.text, text_tr: tr.text, text_en: en.text});
    })
    this.ent = ent;

    this.set.facilities_de.internet.map(item => {
      let f_id = item.f_id;
      let en = this.set.facilities_en.internet.find(it => it.f_id === item.f_id);
      let tr = this.set.facilities_tr.internet.find(it => it.f_id === item.f_id);
      int.push({f_id: f_id, text_de: item.text, text_tr: tr.text, text_en: en.text});
    })
    this.int = int;


    this.set.facilities_de.parking.map(item => {
      let f_id = item.f_id;
      let en = this.set.facilities_en.parking.find(it => it.f_id === item.f_id);
      let tr = this.set.facilities_tr.parking.find(it => it.f_id === item.f_id);
      par.push({f_id: f_id, text_de: item.text, text_tr: tr.text, text_en: en.text});
    })
    this.par = par;


    this.set.facilities_de.shops.map(item => {
      let f_id = item.f_id;
      let en = this.set.facilities_en.shops.find(it => it.f_id === item.f_id);
      let tr = this.set.facilities_tr.shops.find(it => it.f_id === item.f_id);
      sho.push({f_id: f_id, text_de: item.text, text_tr: tr.text, text_en: en.text});
    })
    this.sho = sho;


    this.set.facilities_de.reception.map(item => {
      let f_id = item.f_id;
      let en = this.set.facilities_en.reception.find(it => it.f_id === item.f_id);
      let tr = this.set.facilities_tr.reception.find(it => it.f_id === item.f_id);
      rec.push({f_id: f_id, text_de: item.text, text_tr: tr.text, text_en: en.text});
    })
    this.rec = rec;


    this.set.facilities_de.business.map(item => {
      let f_id = item.f_id;
      let en = this.set.facilities_en.business.find(it => it.f_id === item.f_id);
      let tr = this.set.facilities_tr.business.find(it => it.f_id === item.f_id);
      bus.push({f_id: f_id, text_de: item.text, text_tr: tr.text, text_en: en.text});
    })
    this.bus = bus;


    this.set.facilities_de.cleaning.map(item => {
      let f_id = item.f_id;
      let en = this.set.facilities_en.cleaning.find(it => it.f_id === item.f_id);
      let tr = this.set.facilities_tr.cleaning.find(it => it.f_id === item.f_id);
      cle.push({f_id: f_id, text_de: item.text, text_tr: tr.text, text_en: en.text});
    })
    this.cle = cle;


    this.set.facilities_de.transport.map(item => {
      let f_id = item.f_id;
      let en = this.set.facilities_en.transport.find(it => it.f_id === item.f_id);
      let tr = this.set.facilities_tr.transport.find(it => it.f_id === item.f_id);
      tra.push({f_id: f_id, text_de: item.text, text_tr: tr.text, text_en: en.text});
    })
    this.tra = tra;


    let lang = this.pLang.toLowerCase();
    let fac = {};
    if (lang === "de") {
      fac = this.set.facilities_de;
    }
    if (lang === "tr") {
      fac = this.set.facilities_tr;
    }
    if (lang === "en") {
      fac = this.set.facilities_en;
    }

    this.generallist = parseOnlybyComma(fac.general);
    this.activitieslist = parseOnlybyComma(fac.activities);
    this.foodlist = parseOnlybyComma(fac.food);
    this.childrenlist = parseOnlybyComma(fac.children);
    this.entertainmentlist = parseOnlybyComma(fac.entertainment);
    this.internetlist = parseOnlybyComma(fac.internet);
    this.parkinglist = parseOnlybyComma(fac.parking);
    this.shopslist = parseOnlybyComma(fac.shops);
    this.receptionlist = parseOnlybyComma(fac.reception);
    this.businesslist = parseOnlybyComma(fac.business);
    this.cleaninglist = parseOnlybyComma(fac.cleaning);
    this.transportlist = parseOnlybyComma(fac.transport);
    // this.beachlist = fac.beach;
    // this.spalist = fac.spa;
    // this.poolslist = fac.pools;

    // let beach = await beachParse(this.hb_facilities[beachIndex].text);
    // this.hb_beach = await beachSplit(beach);

    this.$root.$on("updateSet", async (val, title) => {

      let lang = this.pLang.toLowerCase();
      let fac = {};
      if (lang === "de") {
        fac = this.set.facilities_de;
      }
      if (lang === "tr") {
        fac = this.set.facilities_tr;
      }
      if (lang === "en") {
        fac = this.set.facilities_en;
      }

      if (title === "General") {
        let g_de = [];
        let g_tr = [];
        let g_en = [];
        val.map(item => {
          g_de.push({text: item.text_de, f_id: item.f_id});
          g_tr.push({text: item.text_tr, f_id: item.f_id});
          g_en.push({text: item.text_en, f_id: item.f_id});
        })
        this.set.facilities_de.general = g_de;
        this.set.facilities_en.general = g_en;
        this.set.facilities_tr.general = g_tr;
      }
      if (title === "Aktivitäten") {
        let g_de = [];
        let g_tr = [];
        let g_en = [];
        val.map(item => {
          g_de.push({text: item.text_de, f_id: item.f_id});
          g_tr.push({text: item.text_tr, f_id: item.f_id});
          g_en.push({text: item.text_en, f_id: item.f_id});
        })
        this.set.facilities_de.activities = g_de;
        this.set.facilities_en.activities = g_en;
        this.set.facilities_tr.activities = g_tr;
      }
      if (title === "Essen & Trinken") {
        let g_de = [];
        let g_tr = [];
        let g_en = [];
        val.map(item => {
          g_de.push({text: item.text_de, f_id: item.f_id});
          g_tr.push({text: item.text_tr, f_id: item.f_id});
          g_en.push({text: item.text_en, f_id: item.f_id});
        })
        this.set.facilities_de.food = g_de;
        this.set.facilities_en.food = g_en;
        this.set.facilities_tr.food = g_tr;
      }
      if (title === "Kindern") {
        let g_de = [];
        let g_tr = [];
        let g_en = [];
        val.map(item => {
          g_de.push({text: item.text_de, f_id: item.f_id});
          g_tr.push({text: item.text_tr, f_id: item.f_id});
          g_en.push({text: item.text_en, f_id: item.f_id});
        })
        this.set.facilities_de.children = g_de;
        this.set.facilities_en.children = g_en;
        this.set.facilities_tr.children = g_tr;
      }

      if (title === "Entertainment") {

        let g_de = [];
        let g_tr = [];
        let g_en = [];
        val.map(item => {
          g_de.push({text: item.text_de, f_id: item.f_id});
          g_tr.push({text: item.text_tr, f_id: item.f_id});
          g_en.push({text: item.text_en, f_id: item.f_id});
        })
        this.set.facilities_de.entertainment = g_de;
        this.set.facilities_en.entertainment = g_en;
        this.set.facilities_tr.entertainment = g_tr;
      }
      if (title === "Internet") {
        let g_de = [];
        let g_tr = [];
        let g_en = [];
        val.map(item => {
          g_de.push({text: item.text_de, f_id: item.f_id});
          g_tr.push({text: item.text_tr, f_id: item.f_id});
          g_en.push({text: item.text_en, f_id: item.f_id});
        })
        this.set.facilities_de.internet = g_de;
        this.set.facilities_en.internet = g_en;
        this.set.facilities_tr.internet = g_tr;
      }
      if (title === "Parken") {
        let g_de = [];
        let g_tr = [];
        let g_en = [];
        val.map(item => {
          g_de.push({text: item.text_de, f_id: item.f_id});
          g_tr.push({text: item.text_tr, f_id: item.f_id});
          g_en.push({text: item.text_en, f_id: item.f_id});
        })
        this.set.facilities_de.parking = g_de;
        this.set.facilities_en.parking = g_en;
        this.set.facilities_tr.parking = g_tr;
      }
      if (title === "Shops") {
        let g_de = [];
        let g_tr = [];
        let g_en = [];
        val.map(item => {
          g_de.push({text: item.text_de, f_id: item.f_id});
          g_tr.push({text: item.text_tr, f_id: item.f_id});
          g_en.push({text: item.text_en, f_id: item.f_id});
        })
        this.set.facilities_de.shops = g_de;
        this.set.facilities_en.shops = g_en;
        this.set.facilities_tr.shops = g_tr;
      }
      if (title === "Reception") {
        let g_de = [];
        let g_tr = [];
        let g_en = [];
        val.map(item => {
          g_de.push({text: item.text_de, f_id: item.f_id});
          g_tr.push({text: item.text_tr, f_id: item.f_id});
          g_en.push({text: item.text_en, f_id: item.f_id});
        })
        this.set.facilities_de.reception = g_de;
        this.set.facilities_en.reception = g_en;
        this.set.facilities_tr.reception = g_tr;
      }
      if (title === "Business") {
        let g_de = [];
        let g_tr = [];
        let g_en = [];
        val.map(item => {
          g_de.push({text: item.text_de, f_id: item.f_id});
          g_tr.push({text: item.text_tr, f_id: item.f_id});
          g_en.push({text: item.text_en, f_id: item.f_id});
        })
        this.set.facilities_de.business = g_de;
        this.set.facilities_en.business = g_en;
        this.set.facilities_tr.business = g_tr;
      }

      if (title === "Reinigung") {
        let g_de = [];
        let g_tr = [];
        let g_en = [];
        val.map(item => {
          g_de.push({text: item.text_de, f_id: item.f_id});
          g_tr.push({text: item.text_tr, f_id: item.f_id});
          g_en.push({text: item.text_en, f_id: item.f_id});
        })
        this.set.facilities_de.cleaning = g_de;
        this.set.facilities_en.cleaning = g_en;
        this.set.facilities_tr.cleaning = g_tr;
      }

      if (title === "Transport") {
        let g_de = [];
        let g_tr = [];
        let g_en = [];
        val.map(item => {
          g_de.push({text: item.text_de, f_id: item.f_id});
          g_tr.push({text: item.text_tr, f_id: item.f_id});
          g_en.push({text: item.text_en, f_id: item.f_id});
        })
        this.set.facilities_de.transport = g_de;
        this.set.facilities_en.transport = g_en;
        this.set.facilities_tr.transport = g_tr;
      }

      if (title === "Beach") {
        this.beach.facilities = val;
      }

      if (title === "Spa & Wellness") {
        this.spa.facilities = val;
      }
      if (title === "Pool") {
        this.pools.pool.amenities = val;
      }


      // this.loading = true;
      await updateSettings(this.set);
      // this.loading = false;
      // this.notification(val + " erfolgreich", " green");
    })

  },

}
</script>

<style scoped>
@import "../css/SettingsPlace.css";

</style>
