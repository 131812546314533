export const getPDFFooter = () => {
    let footer = [
        // {
        // columns: [
        //     {
        //         text: +'www.mytour-travel.com \n'  'info@mytour-travel.com \n' + '+49 2243 949 98 94',
        //         style: 'labelfooterleft',
        //     }
        // },
        // {
        //     style: 'marginfooter',
        //     table: {
        //         headerRows: 1,
        //         widths: ['30%', '30%', '40%'],
        //         body: [
        //
        //             // Damit Header im Tabel leer bleibt
        //             [
        //                 '1', 'in Kooperation mit mytour-travel.com', '1'
        //             ],
        //             [
        //                 {
        //                     type: 'none',
        //                     ol: [
        //                         info.kontakt.firma,
        //                         info.kontakt.strnr,
        //                         info.kontakt.plz + ' ' + info.kontakt.ort
        //                     ],
        //                     style: 'labelfooter',
        //                 },
        //                 {
        //                     type: 'none',
        //                     ol: [
        //                         'Geschäftsführung:',
        //                         info.rechtliches.geschaftsfuhrer,
        //                         'Umsatzsteuer-ID:',
        //                         info.rechtliches.umsatzsteuerid
        //                     ],
        //                     style: 'labelfooter',
        //                 },
        //                 {
        //                     type: 'none',
        //                     ol: [
        //                         'Bankverbindung:',
        //                         'Inhaber: ' + info.rechtliches.kontoinhaber,
        //                         'IBAN: ' + info.rechtliches.iban,
        //                         'BIC: ' + info.rechtliches.bic,
        //                     ],
        //                     style: 'labelfooter',
        //                 }
        //             ]
        //
        //         ]
        //     },
        //     layout: 'headerLineOnly'
        // }

    ];

    return footer;
}

export const getPDFFooterAgency = (userinfo) => {
    let footeragency = [

        {
            style: 'marginfooter',
            table: {
                headerRows: 1,
                widths: ['30%', '30%', '40%'],
                body: [
                    [
                        // Damit Header im Tabel leer bleibt
                        '', '', ''
                    ],
                    [
                        {
                            type: 'none',
                            ol: [
                                userinfo.kontakt.firma,
                                userinfo.kontakt.strnr,
                                userinfo.kontakt.plz + ' ' + userinfo.kontakt.ort
                            ],
                            style: 'labelfooter',
                        },
                        {
                            type: 'none',
                            ol: [
                                'Geschäftsführung:',
                                userinfo.rechtliches.geschaftsfuhrer,
                                'Steuer-ID:',
                                userinfo.rechtliches.umsatzsteuerid
                            ],
                            style: 'labelfooter',
                        },
                        {
                            type: 'none',
                            ol: [
                                'Bankverbindung:',
                                'Inhaber: ' + userinfo.rechtliches.kontoinhaber,
                                'IBAN: ' + userinfo.rechtliches.iban,
                                'BIC: ' + userinfo.rechtliches.bic,
                            ],
                            style: 'labelfooter',
                        }
                    ],

                ]
            },
            layout: 'headerLineOnly'
        }

    ];

    return footeragency;
}

