<template>

  <div style="max-width: 800px; margin: 50px 15px;">
    <template>
      <div>
        <v-data-table
            :headers="headers"
            :items="list"
            item-key="name"
            class="elevation-1"
            :search="search"
            :custom-filter="filterOnlyCapsText"
            :footer-props="{
              'items-per-page-text': $t('Tabelle.ZeilenproSeite'),
              'items-per-page-options': [15, 30, 100]
            }"
        >

          <template v-slot:top>


            <v-toolbar flat>
              <v-toolbar-title>{{ title }}</v-toolbar-title>


              <v-spacer></v-spacer>

              <v-dialog
                  v-model="dialog"
                  max-width="600px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"

                  >
                    {{ $t("Button.Neu") }}
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col
                            cols="12"
                            sm="6"
                            md="4"
                        >

                          <v-text-field v-model="editedItem.f_id" label="ID" disabled/>
                          <v-text-field v-model="editedItem.text_de" label="Deutsch"/>

                          <v-text-field v-model="editedItem.text_tr" label="Türkce"/>

                          <v-text-field v-model="editedItem.text_en" label="English"/>

                        </v-col>

                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="close"
                    >
                      {{ $t("Button.Abbrechen") }}
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="save"
                    >
                      {{ $t("Button.Speichern") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="600px">
                <v-card>
                  <v-card-title class="text-h5" style="word-break: unset">Wollen Sie diese Facilities löschen?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">
                      {{ $t("Button.Abbrechen") }}
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                      {{ $t("Button.OK") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>

            <v-text-field
                v-model="search"
                label="Suche..."
                class="mx-4"
                prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </template>


          <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
                small
                @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>


        </v-data-table>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "FacilitiesTable",
  props: [
    'list',
    'title'
  ],
  data() {
    return {

      notificationsnackbar: false,
      notificationtext: "",
      notificationcolor: "grey",

      isadmin: true,

      search: '',


      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        text_de: "",

      },
      defaultItem: {
        text: "",
      },


    };
  },
  methods: {

    filterOnlyCapsText(value, search, item) {
      return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLocaleLowerCase().indexOf(search.toString().toLocaleLowerCase()) !== -1
    },

    editItem(item) {
      this.editedIndex = this.list.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true

    },

    deleteItem(item) {
      this.editedIndex = this.list.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      // await updateSettings();

      this.list.splice(this.editedIndex, 1)
      this.closeDelete()
      this.$root.$emit("updateSet", "Löschen");
      // this.notification("Facility gelöscht", "green");
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async save() {

      if (this.editedIndex > -1) {
        Object.assign(this.list[this.editedIndex], this.editedItem)
      } else {
        this.list.push(this.editedItem)
      }

      this.$root.$emit("updateSet", this.list, this.title);
      this.close();
    },
  },
  computed: {
    headers() {
      return [
        {
          text: 'Text',
          align: 'start',
          value: 'text_de',
        },

        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        },


      ]
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Bearbeiten'
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  }
}
</script>

<style scoped>

</style>