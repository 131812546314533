<template>
  <div>
    <template>
      <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              v-model="date"
              :label="$t('Search.GebDatum')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
            ref="picker"
            v-model="date"
            :max="new Date().toISOString().substr(0, 10)"
            min="2004-01-01"
            :locale="getLangDatePicker"
            :first-day-of-week="1"
            @change="save"
        ></v-date-picker>
      </v-menu>
    </template>
  </div>

</template>

<script>
export default {
  name: "BirthdayPicker",
  props: [
    'gruppe',
    'id',
    'selecteddate',
      'saveGruppe'
  ],
  data: () => ({
    date: null,
    menu: false,
  }),
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
  },
  methods: {

    async save(date) {
      this.$root.$emit("geburtstag", this.gruppe, this.id, this.date);
      this.$refs.menu.save(date);
      await this.saveGruppe();
    },
  },
  created() {
    if (this.selecteddate !== "") {
      this.date = this.selecteddate;
    }
  },
  computed: {
    getLangDatePicker() {
      
      let lang = localStorage.getItem('lang');
      if (lang) {
        if (lang === "EN") {
          return "en-en";
        }
        if (lang === "TR") {
          return "tr-tr";
        }
        if (lang === "DE") {
          return "de-de";
        }
      }
      return "de-de";
    }
  }
}
</script>

<style scoped>

</style>