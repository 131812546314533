import MyList from "./MyList";
import {beachParse, beachSplit, parseBeachtext, parseBeachTitle} from "@/util/placeParsingUtil";

export default class Facility {

    constructor() {

        this.general = new MyList();
        this.acitivities = new MyList();
        this.food = new MyList();
        this.children = new MyList();
        this.entertainment = new MyList();
        this.internet = new MyList();
        this.parking = new MyList();
        this.shops = new MyList();
        this.reception = new MyList();
        this.business = new MyList();
        this.cleaning = new MyList();
        this.transport = new MyList();

        this.beach = new MyList();
        this.spa = new MyList();
        this.pools = new MyList();

    }

    async add(facilities) {


        if (facilities.title.includes("GENERAL FACILITIES") || facilities.title.includes("ALLGEMEINE EINRICHTUNGEN") || facilities.title.includes("GENEL TESİS OLANAKLARI")) {
            this.general.add(facilities.text);
        }

        if (facilities.title.includes("ACTIVITIES:") || facilities.title.includes("AKTIVITÄTEN:") || facilities.title.includes("ETKİNLİKLER:")) {
            this.acitivities.add(facilities.text);
        }

        if (facilities.title.includes("FOOD &amp; DRINKS:") || facilities.title.includes("SPEISEN &amp; GETRÄNKE:") || facilities.title.includes("YİYECEK &amp; İÇECEK:")) {
            this.food.add(facilities.text);
        }

        if (facilities.title.includes("FOR CHILDREN:") || facilities.title.includes("FÜR KINDER:") || facilities.title.includes("ÇOCUKLAR İÇİN:")) {
            this.children.add(facilities.text);
        }

        if (facilities.title.includes("ENTERTAINMENT:") || facilities.title.includes("UNTERHALTUNG:") || facilities.title.includes("EĞLENCELER:")) {
            this.entertainment.add(facilities.text);
        }

        if (facilities.title.includes("INTERNET:") || facilities.title.includes("INTERNET:") || facilities.title.includes("İNTERNET:")) {
            this.internet.add(facilities.text);
        }

        if (facilities.title.includes("PARKING:") || facilities.title.includes("PARKPLATZ:") || facilities.title.includes("PARK YERİ:")) {
            this.parking.add(facilities.text);
        }

        if (facilities.title.includes("SHOPS:") || facilities.title.includes("GESCHÄFTE:") || facilities.title.includes("DÜKKANLAR:")) {
            this.shops.add(facilities.text);
        }

        if (facilities.title.includes("RECEPTION SERVICES:") || facilities.title.includes("REZEPTIONSSERVICE:") || facilities.title.includes("RESEPSİYON HİZMETLERİ:")) {
            this.reception.add(facilities.text);
        }

        if (facilities.title.includes("BUSINESS FACILITIES:") || facilities.title.includes("BUSINESS-EINRICHTUNGEN:") || facilities.title.includes("ÇALIŞMA ALANLARI:")) {
            this.business.add(facilities.text);
        }

        if (facilities.title.includes("CLEANING SERVICES:") || facilities.title.includes("REINIGUNGSSERVICE:") || facilities.title.includes("TEMİZLİK HİZMETLERİ:")) {
            this.cleaning.add(facilities.text);
        }

        if (facilities.title.includes("TRANSPORT:") || facilities.title.includes("TRANSPORT:") || facilities.title.includes("ULAŞIM:")) {
            this.transport.add(facilities.text);
        }

        if (facilities.title.includes("BEACH:") || facilities.title.includes("STRAND:") || facilities.title.includes("PLAJ:")) {
            // if (facilities.title.includes("BEACH:") || facilities.title.includes("STRÄNDE:") || facilities.title.includes("PLAJ:")) {

            //let b = await beachParse(facilities.text);

            //let hb_beach = await beachSplit(b);
            // let final_beach = await parseBeachTitle(hb_beach);
            let final_beach = await parseBeachtext(facilities.text);

            this.beach.add(final_beach.filter(it => it.title !== ''));
        }

        if (facilities.title.includes("WELLNESS & SPA:") || facilities.title.includes("WELLNESS & SPA:") || facilities.title.includes("SAĞLIK & SPA:")) {
            this.spa.add(facilities.text);
        }

        if (facilities.title.includes("POOLS:") || facilities.title.includes("POOLS:") || facilities.title.includes("HAVUZLAR:")) {
            this.pools.add(facilities.text);
        }


    }


    getGeneral() {
        return this.general.getList();
    }

    getActivities() {
        return this.acitivities.getList();
    }

    getFood() {
        return this.food.getList();
    }

    getChildren() {
        return this.children.getList();
    }

    getEntertainment() {
        return this.entertainment.getList();
    }

    getInternet() {
        return this.internet.getList();
    }

    getParking() {
        return this.parking.getList();
    }

    getShops() {
        return this.shops.getList();
    }

    getReception() {
        return this.reception.getList();
    }

    getBusiness() {
        return this.business.getList();
    }

    getCleaning() {
        return this.cleaning.getList();
    }

    getTransport() {
        return this.transport.getList();
    }

    getBeach() {
        return this.beach.getList();
    }

    getSpa() {
        return this.spa.getList();
    }

    getPools() {
        return this.pools.getList();
    }
}


