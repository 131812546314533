const axios = require("axios");

export const getOHPbyID = async (id) => {

    let res = "";
    try {
        res = await axios.get(process.env.VUE_APP_DB + "/api2/ownhalalprovisionbyhid/" + id, {headers: {"authorization": 'Bearer ' + localStorage.getItem('token')}});
    } catch (e) {
        return {}
    }


    return res.data.data;
}